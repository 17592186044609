import React from 'react';
import { Product } from 'helper/products';

export default function ProductTag({ product }: { product: Product }) {
  return (
    <div className="box" style={{ backgroundColor: product.color }}>
      { product.acronym }
    </div>
  );
}
