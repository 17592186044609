import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { selectMFComparatorControlPanelSubtitle, selectMFComparatorControlPanelTitle } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import DownloadButton from 'components/Meteorology/Fields/NumericForecast/Toolbar/DownloadButton';
import PeriodSetter from '../PeriodSetter';

export default function Toolbar({
  onDownloadClick,
  downloadDisabled,
}: {
  onDownloadClick: () => void,
  downloadDisabled: boolean,
}) {
  const title = useSelector(selectMFComparatorControlPanelTitle);
  const subtitle = useSelector(selectMFComparatorControlPanelSubtitle);

  return (
    <Row className="align-items-center py-2">
      <Col md={3} />
      <Col className="d-flex flex-column justify-content-center align-items-center text-primary">
        <span className="fw-bold capitalize text-uppercase">{title}</span>
        <span className="text-center">{subtitle}</span>
      </Col>
      <Col md={2}>
        <PeriodSetter />
      </Col>
      <Col md={1}>
        <DownloadButton onClick={onDownloadClick} disabled={downloadDisabled} />
      </Col>
    </Row>
  );
}
