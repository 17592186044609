import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTriggerActivity,
  selectMFComparatorControlPanelPeriod,
  selectMFComparatorControlPanelTriggerIsActive,
} from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import {
  ComparisonPeriodsParams,
  useLazyGetComparisonImagesQuery,
} from 'services/api/Meteorology';
import { ResponseError } from 'services/api/base';
import { ComparisonImages, rawToComparisonImage } from 'helper/products/meteorology';
import useDisplayErrorMessages from '../../useDisplayErrorMessages';

export default function useImages(
  params: ComparisonPeriodsParams | undefined,
  possiblePeriods: string[],
) {
  const dispatch = useDispatch();
  const triggerIsActive = useSelector(selectMFComparatorControlPanelTriggerIsActive);
  const [images, setImages] = useState<ComparisonImages[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, { error, isFetching }] = useLazyGetComparisonImagesQuery();
  const periodPrefix = useSelector(selectMFComparatorControlPanelPeriod);
  const periods = useMemo(() => (
    periodPrefix ? possiblePeriods.filter((period) => period.startsWith(periodPrefix)) : []
  ), [periodPrefix, possiblePeriods]);

  const errorAlerts = useDisplayErrorMessages(error as ResponseError | undefined);

  useEffect(() => {
    if (!triggerIsActive) return () => {};

    let canceled = false;

    setImages([]);
    if (params && periods.length) {
      setIsLoading(true);
      const newImages: ComparisonImages[] = [];
      const addImages = async () => {
        for (let i = 0; i < periods.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const response = await trigger({ ...params, period: periods[i] }, true);
          const comparisonImage = rawToComparisonImage(response.data, periods[i]);

          if (canceled) return;

          if (response.data) {
            newImages.push(comparisonImage);
            setImages([...newImages]);
          }
          setIsLoading(false);
        }
        dispatch(setTriggerActivity(false));
      };

      addImages();
    } else {
      setIsLoading(false);
    }

    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, periods, trigger]);

  return {
    images,
    isLoading,
    isFetching,
    errorAlerts,
    triggerIsActive,
  };
}
