import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

interface AuthState {
  accessToken: string;
  jwt: string;
  refreshToken: string;
  admin: boolean;
  timeout?: number;
}

export interface JWTContent {
  user_id: number
  username: string
  timestamp: number
}

const initialState: AuthState = {
  accessToken: '',
  jwt: '',
  refreshToken: '',
  admin: false,
  timeout: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthState>) => action.payload,
    updateAuthState: (state, action: PayloadAction<Partial<AuthState>>) => (
      { ...state, ...action.payload }
    ),
    reset: () => initialState,
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectAuthToken = (state: any) => state.auth.jwt;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectUserId = (state: any): number => {
  let payload = {} as JWTContent;
  if (state.auth.jwt !== '') payload = jwt_decode<JWTContent>(state.auth.jwt);
  return payload.user_id ?? -1;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectTokenTimeStamp = (state: any) => {
  let payload = {} as JWTContent;
  if (state.auth.jwt !== '') payload = jwt_decode<JWTContent>(state.auth.jwt);
  return payload.timestamp ?? 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectTokenUserName = (state: any) => {
  let payload = {} as JWTContent;
  if (state.auth.jwt !== '') payload = jwt_decode<JWTContent>(state.auth.jwt);
  return payload.username ?? '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectTokenTimeOut = (state: any) => state.auth.timeout ?? 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectUserIsAdmin = (state: any) => state.auth.admin;
export const {
  setAuthState,
  updateAuthState,
  reset,
} = authSlice.actions;

export default authSlice.reducer;
