export function checkDataCompletion(
  pageLength: number,
  isDataFetching: boolean,
  companiesContractsList: unknown[] = [],
) {
  return pageLength > companiesContractsList?.length && !isDataFetching;
}

export function getDataLength(maxLength: number, pageLength: number) {
  return maxLength > 0 ? maxLength : pageLength;
}
