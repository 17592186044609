import React from 'react';
import PasswordReset from 'views/PasswordReset';
import PasswordRecoverySuccess from 'views/PasswordRecoverySuccess';
import Login from '../views/Login';
import PasswordRecovery from '../views/PasswordRecovery';

const authRouter = [
  {
    path: 'signin',
    element: <Login />,
  },
  {
    path: 'password-recovery',
    element: <PasswordRecovery />,
  },
  {
    path: 'password-recovery-success',
    element: <PasswordRecoverySuccess />,
  },
  {
    path: 'password-reset',
    element: <PasswordReset />,
  },
];

export default authRouter;
