import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import './index.scss';

interface CloseButtonProps {
  id: string
  label: string
  onClick: () => void
}

export default function CloseButton({
  id,
  label,
  onClick,
}: CloseButtonProps) {
  return (
    <Stack direction="horizontal" gap={2}>
      <Button
        id={id}
        onClick={onClick}
        className="icon-button close-button"
      >
        ✕
      </Button>
      <div>{label}</div>
    </Stack>
  );
}
