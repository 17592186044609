import { NavigateFunction } from 'react-router';

import logout from 'utils/logout';
import { ResponseError } from '../services/api/base';

export default function handleAtuthorizationErrors(
  error: ResponseError | undefined,
  navigate: NavigateFunction,
) {
  if (error?.status === 403 && error?.data === 'JWT Inválido') {
    const alert = { title: '', message: 'Sua sessão expirou. Faça login novamente.', variant: 'warning' };
    logout(navigate, alert);

    return true;
  }

  return false;
}
