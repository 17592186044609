import React from 'react';

import PermissionWrapper from 'components/RouteWrappers/PermissionWrapper';
import { VideoProduct, videoProductToPermission, videoProductToRoute } from 'helper/videos';
import List from 'views/Videos/List';
import Show from 'views/Videos/Show';

function buildVideoSection(product: VideoProduct, viewOnly: boolean) {
  const productRoute = videoProductToRoute[product];
  const permissionType = videoProductToPermission[product];
  return {
    path: productRoute,
    element: <PermissionWrapper key={permissionType} permissionType={permissionType} />,
    children: [
      { path: ':id', element: <Show product={product} viewOnly={viewOnly} /> },
      { path: 'categories', element: <List product={product} viewOnly={viewOnly} /> },
      { path: 'categories/:id', element: <List product={product} viewOnly={viewOnly} /> },
    ],
  };
}

const videosRouter = {
  children: [
    buildVideoSection(VideoProduct.Meteorology, false),
    buildVideoSection(VideoProduct.Flux, true),
  ],
};

export default videosRouter;
