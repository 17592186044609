import React from 'react';
import MailerTemplates from 'views/Admin/Mailer/Templates';

const templatesRouter = {
  path: 'templates',
  children: [
    { path: '', element: <MailerTemplates /> },
  ],
};

export default templatesRouter;
