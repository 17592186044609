import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

export default function DatePicker<WithRange extends undefined | boolean = undefined>({
  dateFormat = 'P',
  dropdownMode = 'select',
  locale = ptBR,
  showMonthDropdown = true,
  showYearDropdown = true,
  ...otherProps
}: ReactDatePickerProps<never, WithRange>) {
  return (
    <ReactDatePicker
      dateFormat={dateFormat}
      dropdownMode={dropdownMode}
      locale={locale}
      showMonthDropdown={showYearDropdown}
      showYearDropdown={showMonthDropdown}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
}
