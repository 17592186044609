import React from 'react';
import MailerReports from 'views/Admin/Mailer/Reports';

const reportsRouter = {
  path: 'reports',
  children: [
    { path: '', element: <MailerReports /> },
  ],
};

export default reportsRouter;
