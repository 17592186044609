import React from 'react';

import { useNavigate } from 'react-router-dom';
import { handleApiError } from 'helper/error';
import { ResponseError } from 'services/api/base';
import {
  useEnableUserMutation, useDisableUserMutation,
} from 'services/api/users';

export const confirmationDialog = (active: boolean): JSX.Element => (
  <div>
    Você tem certeza que deseja
    <br />
    <strong>{active ? 'desabilitar ' : 'habilitar '}</strong>
    esse usuário?
  </div>
);

export function useRequestUserActivityToggle(requestParams: { user_id: number }): {
  requestUserActivityToggle: (userActive: boolean) => Promise<boolean>,
} {
  const navigate = useNavigate();

  const [disableUser] = useDisableUserMutation();
  const [enableUser] = useEnableUserMutation();

  const requestUserActivityToggle = async (userActive: boolean): Promise<boolean> => {
    const trigger = userActive ? disableUser : enableUser;

    const response = await trigger(requestParams);

    if ('data' in response && response.data.status !== 1) {
      return false;
    }

    if ('error' in response) {
      handleApiError(navigate, response.error as ResponseError);
      return false;
    }

    return true;
  };

  return { requestUserActivityToggle };
}
