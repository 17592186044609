import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import useNewStudyForm from 'utils/hooks/flux/personalized/useNewStudyForm';

function ScenarioCheckbox(
  label: string,
  checked: boolean,
  onChange: () => void,
) {
  return (
    <Form.Check
      type="checkbox"
      label={label}
      key={label}
      onChange={onChange}
      checked={checked}
    />
  );
}
function ScenarioRadioButton(
  label: string,
  checked: boolean,
  onChange: () => void,
) {
  return (
    <div className="pl-3">
      <Form.Check
        type="radio"
        name="dimensions"
        label={label}
        key={label}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}

function AutomaticFlowPercentile() {
  const {
    flowPercentileValue,
    setPercentileValues,
    multiScenarios,
    toggleMultiScenario,
  } = useNewStudyForm();
  const optionLabel = 'Produzir percentis de vazão';
  const setValue = (event: any) => setPercentileValues(event.target.value);
  const isChecked = multiScenarios.includes(optionLabel);
  const displayClass = isChecked ? 'd-block' : 'd-none';

  return (
    <>
      {ScenarioCheckbox(
        optionLabel,
        isChecked,
        () => { toggleMultiScenario(optionLabel); },
      )}
      <div className={`ps-5 ${displayClass}`}>
        <Form.Control value={flowPercentileValue} onChange={setValue} />
      </div>
    </>
  );
}

function AutomaticFlowByGrouping() {
  const {
    multiScenarios,
    toggleMultiScenario,
    toggleMultiScenarioVersusOption,
    groupNumber,
    setGroupNumber,
  } = useNewStudyForm();
  const radioButtonLabels = [
    'SE+NE versus S+N',
    'SE+S versus NE+N',
    'SE+N versus S+NE',
    'SE versus S+NE+N',
    'SE vs S vs NE vs N'];

  const shouldDisplayGroupingDimensions = multiScenarios.includes('Produzir vazão por agrupamento:');

  const shouldDisplayRadioOptions = shouldDisplayGroupingDimensions
    && multiScenarios.includes('Definir dimensões para agrup.');

  const optionLabel = 'Produzir vazão por agrupamento:';

  return (
    <>
      {ScenarioCheckbox(
        optionLabel,
        multiScenarios.includes(optionLabel),
        () => { toggleMultiScenario(optionLabel); },
      )}
      { shouldDisplayGroupingDimensions
        && (
          <div className="ps-3">
            {ScenarioCheckbox(
              'Predefinir número de agrupamentos',
              multiScenarios.includes('Predefinir número de agrupamentos'),
              () => { toggleMultiScenario('Predefinir número de agrupamentos'); },
            )}
            { multiScenarios.includes('Predefinir número de agrupamentos')
              && (
                <div className="ps-5">
                  <FormControl
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={groupNumber && !Number.isNaN(groupNumber) ? groupNumber : 0}
                    onChange={(e) => {
                      setGroupNumber(parseInt(e.target.value || '0', 10));
                    }}
                  />
                </div>
              ) }
            {ScenarioCheckbox(
              'Definir dimensões para agrup.',
              multiScenarios.includes('Definir dimensões para agrup.'),
              () => { toggleMultiScenario('Definir dimensões para agrup.'); },
            )}
          </div>
        )}
      { shouldDisplayRadioOptions && radioButtonLabels
        .map((label) => (
          <div className="ps-5" key={label}>
            { ScenarioRadioButton(
              label,
              multiScenarios.includes(label),
              () => toggleMultiScenarioVersusOption(label),
            ) }
          </div>
        ))}
    </>
  );
}

export default function MultiScenarios() {
  const {
    multiScenarios,
    toggleMultiScenario,
  } = useNewStudyForm();

  return (
    <div>
      {
        ScenarioCheckbox(
          'Produzir vazão média',
          multiScenarios.includes('Produzir vazão média'),
          () => { toggleMultiScenario('Produzir vazão média'); },
        )
      }
      {AutomaticFlowPercentile()}
      {AutomaticFlowByGrouping()}
    </div>
  );
}
