import {
  RawWarningResponse,
  SaveWarningParams,
  DeleteWarningParams,
} from 'helper/home';
import baseApi, { GenericResponse } from './base';

export type ListWarningsResponse = GenericResponse<RawWarningResponse[]>;

export const homeApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listWarnings: build.query<ListWarningsResponse, void>({
      query: () => 'avisos-list',
    }),
    saveWarning: build.mutation<any, SaveWarningParams>({
      query: ({ id, title, message }) => ({
        url: 'avisos-save',
        method: 'POST',
        body: {
          id,
          mensagem: message,
          titulo: title,
        },
      }),
    }),
    deleteWarning: build.mutation<any, DeleteWarningParams>({
      query: ({ id }) => ({
        url: 'avisos-delete',
        method: 'POST',
        body: { id },
      }),
    }),
  }),
});

export const {
  useListWarningsQuery,
  useSaveWarningMutation,
  useDeleteWarningMutation,
} = homeApi;
