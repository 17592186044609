import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { store } from 'redux/store';
import { setProductKey } from 'redux/reducers/productsReducer';
import { PermissionTypes } from 'helper/products';
import { useUserHasPermissionQuery } from 'services/api/products';

export default function usePermissionCheck(product: PermissionTypes) {
  const navigate = useNavigate();
  const [keySaved, setKeySaved] = useState(false);
  const { data, isSuccess, isError } = useUserHasPermissionQuery(product);
  const isDone = isSuccess || isError;

  useEffect(() => {
    if (isDone) {
      const key = data?.data?.product_key;

      if (key) {
        store.dispatch(setProductKey({ product, key }));
        setKeySaved(true);
      } else {
        navigate(`/no-access?product=${product}`);
      }
    }
  }, [product, data, isDone, navigate]);

  return keySaved;
}
