import { useState } from 'react';

export const positivePolarity = 'positive';
export const negativePolarity = 'negative';

export default function usePolaritySelection() {
  const [polarity, setPolarity] = useState(positivePolarity);
  const handlePolarityChange = (value: string) => setPolarity(value);

  return {
    polarity,
    handlePolarityChange,
  };
}
