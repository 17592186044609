import React, { PropsWithChildren } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './index.scss';

interface ConfirmationDialogBoxProps {
  open: boolean,
  confirm: () => void,
  dismiss: () => void,
}

export default function ConfirmationDialogBox(
  {
    children, open, confirm, dismiss,
  }: PropsWithChildren<ConfirmationDialogBoxProps>,
) {
  return (
    <Modal show={open} onHide={dismiss} className="confirmation-dialog-modal">
      <Modal.Header closeButton className="confirmation-dialog-modal-header" />
      <Modal.Body className="my-3 mx-auto confirmation-dialog-modal-body">
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="confirmation-dialog-modal-btn btn-dismiss"
          onClick={dismiss}
        >
          Cancelar
        </Button>
        <Button
          className="confirmation-dialog-modal-btn"
          variant="primary"
          onClick={() => {
            confirm();
            dismiss();
          }}
        >
          Sim
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
