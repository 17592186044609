import React from 'react';
import { format } from 'date-fns';

import { ControlPanelOption } from 'helper/products/meteorology';

export interface Data {
  id: number,
  modelName: string,
  forecastDate: Date,
  lastUpdate: Date,
  member: string,
  runtime: string,
  variable: string,
  status: string,
  statusColor: string,
}

export default function StatusRow({
  data,
  getModelOptions,
}: {
  data: Data,
  getModelOptions: (modelName: string) => ControlPanelOption,
}) {
  const options = getModelOptions(data.modelName.toLowerCase());
  const { runtimes, members } = options;
  const runtime = runtimes.find((r) => r.id === parseInt(data.runtime, 10));
  const member = members.find((m) => m.id === data.member);

  return (
    <tr>
      <td className="model-name">{data.modelName}</td>
      <td className="text-center">
        {format(data.forecastDate, 'd/M/y')}
      </td>
      <td className="text-center">{runtime?.label}</td>
      <td className="text-center">{member?.label}</td>
      <td className="text-center text-uppercase">{data.variable}</td>
      <td className="status">
        <div style={{ backgroundColor: data.statusColor }}>
          {data.status}
        </div>
      </td>
      <td className="last-update">
        <div>{format(data.lastUpdate, 'd/M/y')}</div>
        <div>{format(data.lastUpdate, 'HH:mm:ss a')}</div>
      </td>
    </tr>
  );
}
