import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from 'react-bootstrap';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function CurrentPanel() {
  const download = () => {};

  return (
    <Card className="shadow-sm forecast-instruction-card h-100">
      <Card.Header>
        CONDIÇÃO ATUAL
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-end mt-3 me-3">
          <Button
            size="sm"
            className="icon-button weather-download-button"
            onClick={download}
          >
            <FontAwesomeIcon icon={faDownload as IconProp} />
          </Button>
        </div>
        <EnlargeableImage src="" className="mt-3" />
      </Card.Body>
    </Card>
  );
}
