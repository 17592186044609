import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { store } from 'redux/store';
import { usersApi } from 'services/api/users';
import { setShow, setTitle, setUser } from 'redux/reducers/userModalReducer';
import { handleApiError } from 'helper/error';
import { ResponseError } from 'services/api/base';
import ActionButton from 'components/Admin/ActionButton';

export const onClick = async (
  userId: number,
  navigate: NavigateFunction,
) => {
  const { data, error } = await store.dispatch(usersApi.endpoints.getUser.initiate({ id: userId }));
  if (data) {
    store.dispatch(setUser(data));
    store.dispatch(setTitle('Editar Usuário'));
    store.dispatch(setShow(true));
  } else {
    handleApiError(navigate, error as ResponseError);
  }
};

export default function EditUserButton({ userId }: { userId: number }) {
  const navigate = useNavigate();

  return (
    <div className="action-button-container">
      <ActionButton
        loading={false}
        onClick={() => {
          onClick(userId, navigate);
        }}
        tooltipText="Editar usuário"
      >
        <FontAwesomeIcon
          id={`edit-user-${userId}`}
          icon={faPenToSquare as IconProp}
          size="xs"
        />
      </ActionButton>
    </div>
  );
}
