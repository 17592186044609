import { ResponseError } from 'services/api/base';
import { useGetLatestReportPathQuery } from 'services/api/Meteorology/Weather/Overview';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useLatestReportPath(): {
  latestReportPath: string | undefined, isLoading: boolean
} {
  const { data, error, isLoading } = useGetLatestReportPathQuery();

  useHandleRequestErrors(error as ResponseError);

  return { latestReportPath: data as string, isLoading };
}
