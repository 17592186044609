import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import CompaniesTable from 'components/Admin/Companies/Table';
import PlusButton from 'components/PlusButton';
import FormModal from 'components/FormModal';
import SearchBar from 'components/SearchBar';
import { closeModal, openModal, openModalWithTitle } from 'helper/modals';
import CompaniesForm from 'components/Admin/Companies/Form';
import CompanyFilterModal from 'components/Admin/Companies/FilterModal';
import FilterButton from 'components/Filters/FilterButton';
import { CompanyModalFilters, csvDownloadFilters, modalToQueryFilters } from 'helper/companyFilters';
import { companyAppliedFilters } from 'helper/filterModals';
import { useListProductsQuery } from 'services/api/products';
import executeFunctionList from 'utils/executeFunctionList';
import { useClosure } from 'utils/hooks';
import onCompanyEditClick from 'helper/companyEdit';
import { downloadCompanyListCsvBlob } from 'services/fileDownloadApi';
import DownloadButton from 'components/DownloadButton';
import ContractsForm from 'components/Admin/Contracts/Form';
import CountersRow from '../../components/Admin/Companies/CountersRow';

export default function Companies() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBarText, setSearchBarText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const { data: products } = useListProductsQuery();
  const [filters, setFilters] = useState({
    selectedProducts: [],
    contractEndsBefore: null,
    isActive: false,
    isInactive: false,
    isTest: false,
    isExpiringIn30: false,
    isExpiringIn60: false,
    isMetereology: false,
    isConsultancy: false,
  } as CompanyModalFilters);
  const appliedFilters = companyAppliedFilters(
    products,
    filters,
    setFilters,
    searchQuery,
    setSearchQuery,
    setSearchBarText,
  );
  const filterActive = appliedFilters.length > 0;
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [selectedContractId, setSelectedContractId] = useState(0);

  const [contractModalOpen, setContractModalOpen] = useState(false);
  const [contractModalTitle, setContractModalTitle] = useState('');

  return (
    <div>
      <FormModal
        show={modalOpen}
        title={modalTitle}
        onHide={closeModal(setModalOpen)}
      >
        <CompaniesForm companyId={selectedCompanyId} contractId={selectedContractId} />
      </FormModal>
      <FormModal
        show={contractModalOpen}
        title={contractModalTitle}
        onHide={closeModal(setContractModalOpen)}
      >
        <ContractsForm />
      </FormModal>
      <CountersRow searchQuery={searchQuery} filters={filters} setFilters={setFilters} />
      <CompanyFilterModal
        open={filterModalOpen}
        closeModal={closeModal(setFilterModalOpen)}
        products={products}
        filters={filters}
        setFilters={setFilters}
      />
      <Container>
        <div className="d-lg-flex flex-row align-items-center">
          <div className="d-flex flex-row my-1 align-items-center">
            <h1 className="mb-0 me-1">EMPRESAS</h1>
            <div className="mx-1">
              <FilterButton
                filterActive={filterActive}
                onClick={openModal(setFilterModalOpen)}
              />
            </div>
            <div className="mx-1">
              <DownloadButton
                blobDownloader={downloadCompanyListCsvBlob}
                params={csvDownloadFilters(filters, searchQuery)}
                filename="lista_de_empresas.csv"
                tooltipText="Empresas filtradas (csv)"
              />
            </div>
          </div>
          <div className="mx-lg-3 my-1 flex-grow-1">
            <SearchBar
              text={searchBarText}
              setText={setSearchBarText}
              setSearchQuery={setSearchQuery}
            />
          </div>
          <div className="d-flex flex-row my-2">
            <div className="me-2">
              <PlusButton
                id="new-company-button"
                label="Adicionar Empresa"
                onClick={executeFunctionList([
                  useClosure(setSelectedCompanyId, 0),
                  useClosure(setSelectedContractId, 0),
                  openModalWithTitle(setModalOpen, setModalTitle, 'Nova Empresa'),
                ])}
              />
            </div>
            <div>
              <PlusButton
                id="new-contract-button"
                label="Adicionar Contrato"
                onClick={openModalWithTitle(setContractModalOpen, setContractModalTitle, 'Novo Contrato')}
              />
            </div>
          </div>
        </div>

        {filterActive && (
          <div className="my-3">
            {appliedFilters}
          </div>
        )}

        <Row className={filterActive ? '' : 'pt-5'}>
          <CompaniesTable
            searchQuery={searchQuery}
            filters={modalToQueryFilters(filters)}
            onEditClick={onCompanyEditClick(
              setModalOpen,
              setModalTitle,
              setSelectedCompanyId,
              setSelectedContractId,
            )}
          />
        </Row>
      </Container>
    </div>
  );
}
