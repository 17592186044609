import { useState, useCallback, useEffect } from 'react';

export default function useCurrencyTextInput(initialValue?: number | null) {
  const [value, setValue] = useState<number>();
  const formattedValue = value !== undefined && (value / 100)
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    .replaceAll('.', ' ');
  const display = formattedValue || '';

  const onChangeEvent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value.replace(/[^0-9]/g, '');
    const newValue = newText ? parseInt(newText, 10) : undefined;
    setValue(newValue);
  }, [setValue]);

  useEffect(() => {
    if (initialValue !== undefined && initialValue !== null) {
      const valueAsCents = (initialValue * 100);
      setValue(valueAsCents);
    }
  }, [initialValue, setValue]);

  return {
    display,
    value,
    onChangeEvent,
  };
}
