import { useSelector } from 'react-redux';
import {
  selectOpen,
  openLeft,
  close,
} from 'redux/reducers/sidebarsReducer';
import { store } from 'redux/store';

export default function useLeftCollapse() {
  const collapsed = useSelector(selectOpen) !== 'left';

  const setCollapsed = (newCollapsed) => {
    if (!newCollapsed) {
      store.dispatch(openLeft());
    } else {
      store.dispatch(close());
    }
  };

  return [collapsed, setCollapsed];
}
