import { differenceInCalendarDays, fromUnixTime, isToday } from 'date-fns';
import { RelativeDate } from './flux/automatic';

export default function relativeDateFromToday(timepstamp: number): RelativeDate | undefined {
  if (timepstamp === 0) return undefined;
  const date = fromUnixTime(timepstamp);

  if (isToday(date)) return 'latest';

  return `d${differenceInCalendarDays(date, new Date())}` as RelativeDate;
}
