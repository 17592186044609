import { Product, RawProductList, transformProductList } from 'helper/products';

export interface BaseUser {
  id: number,
  first_name: string,
  last_name: string,
  username: string,
  reset_password_token_date: number | null,
  user_token_timeout: number | null,
  chat_token: string,
  chat_token_date: number,
  id_empresa: number,
  language: string,
  active: boolean,
  cpf: string | null,
  ck_new_user: boolean
}

export interface ListedUser extends BaseUser {
  companyName: string,
  products: Product[],
  dt_inicio?: string,
  dt_fim?: string,
}

export interface RawListedUser extends BaseUser {
  nome_empresa: string,
  nome_completo: string,
  produtos: RawProductList,
}

export interface User extends BaseUser {
  emails: {
    id: number,
    user_id: number,
    email: string,
    confirmed_at:
    number | null,
    confirmation_token: string,
    is_primary: boolean
  }[],
  phones: {
    id: number,
    user_id: number,
    phone: string,
    is_primary: boolean
  }[],
  empresa: {
    id: number,
    razao_social: string,
    nome_fantasia: string,
    apelido: string,
    cnpj: string,
    ie: string,
    im: string,
    endereco: string,
    numero: string,
    complemento: string,
    bairro: string,
    cidade: number,
    uf: number,
    cep: string,
    active: boolean,
    parte: boolean,
    emails_fiscais: string
  },
  avatar: string
}

export interface UserListRawResponse {
  code: number,
  data: RawListedUser[],
  message: null,
  status: number,
  title: null,
  type: number
}

export interface UserRawResponse {
  code: number,
  message: null,
  status: number,
  title: null,
  type: number,
  data: User,
}

export const transformUserResponse = (response: UserRawResponse) => response.data;

export const transformUserListResponse = (response: UserListRawResponse): ListedUser[] => (
  response.data.map((rawUserData: RawListedUser) => {
    const splittedName = rawUserData.nome_completo.split(' ');
    const firstName = String(splittedName.shift());
    const lastName = splittedName.join(' ');
    return {
      ...rawUserData,
      products: transformProductList(rawUserData.produtos),
      companyName: rawUserData.nome_empresa,
      first_name: firstName,
      last_name: lastName,
    };
  })
);

export const transformUserSaveErrorResponse = (response: { status: number, data: string }) => {
  const parsedResponse = response.data.split('\n\t');
  return parsedResponse.slice(1, parsedResponse.length).join('\n');
};
