import { useMemo } from 'react';
import {
  parse, isEqual, format, compareDesc,
} from 'date-fns';

import { useGetPastSimulationsOptionsQuery } from 'services/api/products/flux';

interface NewPastSimulationOptions {
  filterAcomphDates: (date: Date) => boolean,
  filterForecastDates: (date: Date) => boolean,
  openToForecastDate: Date,
  versions: string[],
  models: string[],
}

export default function useNewPastSimulationOptions({
  acomphDate, forecastDate, version,
}: { acomphDate: Date | null, forecastDate: Date | null, version: string }):
  NewPastSimulationOptions {
  const { data, isSuccess } = useGetPastSimulationsOptionsQuery();
  const pastSimulationOptions = data?.data;

  const validAcomphDates: Date[] = useMemo(() => {
    if (isSuccess && pastSimulationOptions) {
      const rawAcomphDates = Object.keys(pastSimulationOptions);

      return rawAcomphDates.map(
        (rawDate) => (
          parse(rawDate.replace(/ACOMPH/g, ''), 'yMd', new Date())
        ),
      );
    }

    return [];
  }, [pastSimulationOptions, isSuccess]);

  const validForecastDates: Date[] = useMemo(() => {
    if (isSuccess && pastSimulationOptions && acomphDate) {
      const rawAcomphDate = `ACOMPH${format(acomphDate, 'yMMdd')}`;

      return Object
        .keys(pastSimulationOptions[rawAcomphDate])
        .map((rawDate) => (rawDate.replace(/-PSAT/g, '')))
        .filter((value, index, array) => array.indexOf(value) === index) // unique
        .map((rawDate) => (parse(rawDate, 'yMd', new Date())));
    }

    return [];
  }, [pastSimulationOptions, isSuccess, acomphDate]);

  const filterAcomphDates = useMemo(
    () => (
      (date: Date) => (
        date !== null && validAcomphDates.some((validAcomphDate) => isEqual(date, validAcomphDate))
      )
    ),
    [validAcomphDates],
  );

  const filterForecastDates = useMemo(
    () => (
      (date: Date) => (
        date !== null && validForecastDates.some(
          (validForecastDate) => isEqual(date, validForecastDate),
        )
      )
    ),
    [validForecastDates],
  );

  const openToForecastDate = useMemo(
    () => validForecastDates?.at(0) ?? new Date(),
    [validForecastDates],
  );

  const versions = useMemo(() => {
    if (!acomphDate || !forecastDate) {
      return [];
    }

    if (compareDesc(acomphDate, forecastDate)) {
      return ['PRE-ACOMPH-PRE-PSAT'];
    }

    if (isEqual(acomphDate, forecastDate)) {
      const rawAcomphDate = `ACOMPH${format(acomphDate, 'yMMdd')}`;
      const rawForecastPsatDate = `${format(forecastDate, 'yMMdd')}-PSAT`;

      if (pastSimulationOptions[rawAcomphDate][rawForecastPsatDate]) {
        return ['POS-ACOMPH-PRE-PSAT', 'POS-ACOMPH-POS-PSAT'];
      }

      return ['POS-ACOMPH-PRE-PSAT'];
    }

    return [];
  }, [acomphDate, forecastDate, pastSimulationOptions]);

  const models = useMemo(
    () => {
      if (!acomphDate || !forecastDate || !version) {
        return [];
      }

      const rawAcomphDate = `ACOMPH${format(acomphDate, 'yMMdd')}`;

      let rawForecastDate = format(forecastDate, 'yMMdd');
      if (version === 'POS-ACOMPH-POS-PSAT') {
        rawForecastDate = `${rawForecastDate}-PSAT`;
      }

      return pastSimulationOptions[rawAcomphDate][rawForecastDate]
        .map((model) => (model.replace(/.zip/g, '')));
    },
    [pastSimulationOptions, acomphDate, forecastDate, version],
  );

  return {
    openToForecastDate,
    filterAcomphDates,
    filterForecastDates,
    versions,
    models,
  };
}
