import React from 'react';
import {
  Accordion, Col, Container, Form, Row,
} from 'react-bootstrap';

import useAcomphMLT from 'utils/hooks/flux/automatic/useAcomphMLT';

export default function AcomphMLT() {
  const {
    acomph, onAcomphChange, mlt, onMltChange,
  } = useAcomphMLT();

  return (
    <Accordion.Item eventKey="3" className="control-panel-item">
      <Accordion.Header>Incluir ACOMPH/MLT</Accordion.Header>
      <Accordion.Body>
        <Container>
          <Row md={12}>
            <Col>
              <Form.Check
                label="ACOMPH (ENA verificada)"
                onChange={onAcomphChange}
                checked={acomph}
              />
            </Col>
          </Row>
          <Row md={12}>
            <Col>
              <Form.Check
                label="MLT (média mensal de referência)"
                onChange={onMltChange}
                checked={mlt}
              />
            </Col>
          </Row>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
