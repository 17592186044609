import { ChangeEvent, useState } from 'react';

import { Invoice } from 'helper/financial';
import sendInvoice from 'services/fileUploadApi/invoice';

export default function useReadFinancialFile({
  setInvoices,
}: {
  setInvoices: (invoices: Invoice[]) => void,
}) {
  let file: File | null = null;
  const [isLoading, setIsLoading] = useState(false);

  const callback = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const fileField = (event.target as HTMLInputElement);

    if (fileField && fileField.files) {
      // Using destructing leads to a worse problem:
      //
      // Type 'FileList' can only be iterated through when using the '--downlevelIteration'
      // flag or with a '--target' of 'es2015' or higher.
      //
      // eslint-disable-next-line prefer-destructuring
      file = fileField.files[0];

      const response = await sendInvoice(file);
      setInvoices(response);
    }
    setIsLoading(false);
  };

  return { callback, isLoading };
}
