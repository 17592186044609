import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { format, fromUnixTime } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFile } from '@fortawesome/free-regular-svg-icons';

import { FileListFile } from 'helper/products/fileViewer';
import { downloadFileViewerFile } from 'services/fileDownloadApi';
import DownloadButton from 'components/DownloadButton';
import formatBytes from 'helper/formatBytes';

export default function File({ file }: { file: FileListFile }) {
  const fileSize = formatBytes(file.size);
  const modifiedAt = fromUnixTime(file.modified);
  const formattedModifiedAt = format(modifiedAt, 'P HH:mm', { locale: ptBR });

  return (
    <Row className="file-list-entry align-items-center mx-0">
      <Col xs={1} className="text-center">
        <FontAwesomeIcon icon={faFile as IconProp} />
      </Col>
      <Col>{file.name}</Col>
      <Col xs={2}>{fileSize}</Col>
      <Col xs={2}>{formattedModifiedAt}</Col>
      <Col xs={1} className="text-center">
        <DownloadButton
          blobDownloader={downloadFileViewerFile}
          filename={file.name}
          params={file.id}
          tooltipText="Baixar arquivo"
        />
      </Col>
    </Row>
  );
}
