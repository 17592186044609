import { handleApiError } from 'helper/error';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';

export default function useFileDownloadApi<ParamsType>({
  blobDownloader,
  params,
  filename,
}: {
  blobDownloader: (params: ParamsType) => Promise<Blob | ResponseError>,
  params: ParamsType,
  filename: string,
}) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function onClick() {
    setLoading(true);

    const blob = await blobDownloader(params);

    if (!(blob instanceof Blob)) {
      handleApiError(navigate, blob);
      return;
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    setLoading(false);
  }

  return {
    isLoading,
    onClick,
  };
}
