import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useClosure } from 'utils/hooks';

import ImageModal from 'components/Meteorology/Fields/ImageModal';

import './index.scss';

export default function EnlargeableImage({
  src,
  className,
  toFirst,
  toPrevious,
  toNext,
  toLast,
}: {
  src: string,
  className?: string,
  toFirst?: () => void,
  toPrevious?: () => void,
  toNext?: () => void,
  toLast?: () => void,
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <ImageModal
        src={src}
        show={show}
        onHide={useClosure(setShow, false)}
        toFirst={toFirst}
        toPrevious={toPrevious}
        toNext={toNext}
        toLast={toLast}
      />
      <Image
        fluid
        alt=""
        src={src}
        className={`clickable-image ${className}`}
        onClick={useClosure(setShow, true)}
      />
    </>
  );
}

EnlargeableImage.defaultProps = {
  className: '',
  toFirst: undefined,
  toPrevious: undefined,
  toNext: undefined,
  toLast: undefined,
};
