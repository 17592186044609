import React from 'react';
import {
  Invoice, SORTABLE_FIELDS, SORT_BY_EMAIL, SORT_BY_NFE, SortableField,
} from 'helper/financial';
import {
  Col, Container, Row, Stack,
} from 'react-bootstrap';
import SortingArrow, { useSorting } from 'components/SortingArrow';
import useInvoiceSorting from 'utils/hooks/financial/useInvoiceSorting';
import InvoiceRow from './Row';

export default function InvoiceTable({ invoices, selectedInvoices, toggleInvoiceSelection }: {
  invoices: Invoice[],
  selectedInvoices: Invoice[],
  toggleInvoiceSelection: (invoice: Invoice) => () => void,
}) {
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<SortableField | undefined>(SORTABLE_FIELDS);
  const sortedInvoices = useInvoiceSorting(invoices, sortBy, sortDirection);

  return (
    <Container>
      <Row className="mt-4 mb-3 fw-bold admin-table-header px-0">
        <Col md={8}>
          <Stack direction="horizontal">
            NOTA
            <SortingArrow
              field={SORT_BY_NFE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={sorters.nfe}
            />
          </Stack>
        </Col>
        <Col md={4}>
          <Stack direction="horizontal">
            EMAIL
            <SortingArrow
              field={SORT_BY_EMAIL}
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={sorters.email}
            />
          </Stack>
        </Col>
      </Row>
      {sortedInvoices.map((invoice) => (
        <InvoiceRow
          invoice={invoice}
          key={invoice.id}
          selected={selectedInvoices.includes(invoice)}
          onCheckChange={toggleInvoiceSelection(invoice)}
        />
      ))}
    </Container>
  );
}
