import { GenericResponse } from 'services/api/base';

export interface StudyScenarioBlocks {
  id: number,
  id_cenario: number,
  ds_modelo: string,
  dt_data_prev: number,
  ck_rmv: boolean,
  dt_inicio: number,
  dt_fim: number,
  dt_recorte_inicio: number,
  dt_recorte_fim: number,
}

export interface StudyScenario {
  id: number,
  id_estudo: number,
  ds_nome: string,
  blocos: StudyScenarioBlocks[]
}

export interface ListedStudy {
  id: number,
  ds_nome_estudo: string,
  id_empresa: number,
  empresa: string,
  id_user: number | null,
  user: string | null,
  dt_inicio: number,
  dt_fim: number,
  dt_last_execution: number | null,
  dt_last_response: number | null,
  vl_execution_counter: number,
  ready: boolean,
  ck_internal_error: boolean,
  cenarios: StudyScenario[],
}
export type RawListedStudiesKeys = keyof ListedStudy;

type RawListedStudiesValue = number | string | null | boolean | StudyScenario[];

interface RawListedStudies {
  id: RawListedStudiesKeys,
  value: RawListedStudiesValue,
}

interface RawDownloadLink {
  link: string,
}

type RawGetDownloadLinkResponse = GenericResponse<RawDownloadLink>;

export interface UploadStudiesParams {
  ds_nome_estudo: string,
  flag_produzir_mapas: boolean,
  flag_produzir_arquivo_pmed_xlsx: boolean,
  flag_produzir_arquivos_dessem: boolean,
  flag_produzir_ena_diaria: boolean,
  flag_tabelar_prevs_produzidos: boolean,
  flag_produzir_prevs_mensais: boolean,
  flag_produzir_vazpast: boolean,
  flag_produzir_todas_revisoes_intermediarias: boolean,
  flag_produzir_somente_rv0_intermediarias: boolean,
  flag_produzir_somente_revisoes_rvf: boolean,
  flag_produzir_vmed: boolean,
  flag_produzir_vpercentil: boolean,
  cenarios_vpercentil: string,
  ck_numero_agrupamentos: boolean,
  flag_produzir_cenarios_por_agrupamento: boolean,
  numero_agrupamentos: string,
  ck_dimensoes_para_agrupamento: boolean,
  se_ne_v_s_n: boolean,
  se_s_v_s_ne_n: boolean,
  se_n_v_s_ne: boolean,
  se_v_s_ne_n: boolean,
  se_v_s_ne_s_n: boolean,
  casos_ampere: boolean,
  dt_inicio: number,
  dt_fim: number,
  file_name: string,
  file_base64: string,
}

export const buildListedStudy = (rawStudies: RawListedStudies[]) => {
  const study = {} as ListedStudy;
  rawStudies.forEach(({ id, value }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    study[id] = value;
  });
  return study;
};

export const transformListStudiesResponse = (
  { data }: { data?: RawListedStudies[][] },
): ListedStudy[] => (!data ? [] : data.map(buildListedStudy));

export const transformDownloadLink = (response: RawGetDownloadLinkResponse | undefined) => (
  response?.data.link || ''
);
