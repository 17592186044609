import React, { useEffect, useState } from 'react';
import {
  Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { handleApiError } from 'helper/error';
import {
  useListUsersQuery,
  SortableField,
  SORTABLE_FIELDS,
  SORT_BY_NAME,
  SORT_BY_EMAIL,
  SORT_BY_COMPANY,
} from 'services/api/users';
import { ResponseError } from 'services/api/base';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import useConfirmationDialogBox from 'utils/useConfirmationDialogBox';
import useListEnd from 'utils/hooks/useListEnd';
import SortingArrow, { useSorting } from 'components/SortingArrow';
import { UserFilterParams } from 'helper/userFilters';
import EmptyListAlert from 'components/EmptyListAlert';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import UserRow from './UserRow';
import 'components/Admin/table.scss';
import { useRefreshUsersOnSearch } from './utils';

interface UsersTableProps {
  searchQuery: string,
  filters: UserFilterParams,
}

export default function UsersTable({
  searchQuery,
  filters,
}: UsersTableProps) {
  const [maxLength, setMaxLength] = useState<number>(-1);
  const pageSize = 12;
  const { page, setPage } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<SortableField | undefined>(SORTABLE_FIELDS);
  const {
    data,
    error,
    isLoading,
    isFetching,
  } = useListUsersQuery({
    page: 1,
    pageSize: dataLength,
    nameContains: searchQuery,
    orderBy: sortBy,
    orderDirection: sortDirection,
    ...filters,
  });
  const navigate = useNavigate();
  const { confirmationDialogBox, setConfirmationDialogBox } = useConfirmationDialogBox();
  const { listEnded } = useListEnd(data, page, pageSize, isFetching);

  useEffect(() => {
    if (error) {
      handleApiError(navigate, error as ResponseError);
    }
  });

  const { isFiltering } = useRefreshUsersOnSearch({
    query: searchQuery,
    sortBy,
    sortDirection,
    ...filters,
  }, setPage, isFetching);

  useEffect(() => {
    setMaxLength(-1);
  }, [filters, searchQuery]);

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, data)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, data, isFetching]);

  if (isLoading || isFiltering) {
    return <Spinner className="mt-5 mx-auto" animation="border" />;
  }

  return (
    <>
      {confirmationDialogBox}
      <div key="UsersTable" className="admin-table-main">
        <Row className="fw-bold admin-table-header" key="tableHeader">
          <Col key="tableHeaderForNames" md="2">
            <Stack direction="horizontal">
              NOME
              <SortingArrow
                field={SORT_BY_NAME}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sorters.nome}
              />
            </Stack>
          </Col>
          <Col key="tableHeaderForEmails" md="2">
            <Stack direction="horizontal">
              USUÁRIO
              <SortingArrow
                field={SORT_BY_EMAIL}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sorters.username}
              />
            </Stack>
          </Col>
          <Col key="tableHeaderForCompanies" md="2">
            <Stack direction="horizontal">
              EMPRESA
              <SortingArrow
                field={SORT_BY_COMPANY}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sorters.empresa}
              />
            </Stack>
          </Col>
          <Col md="3">PRODUTOS</Col>
          <Col md="1" />
          <Col md="2">AÇÕES</Col>
        </Row>
        <EmptyListAlert
          component={UserRow}
          array={data?.map((user) => ({
            user,
            key: user.id,
            setConfirmationDialogBox,
          }))}
          message="Não foram encontrados resultados para os filtros selecionados"
          isFetching={isFetching}
          listEnded={listEnded}
        />
      </div>
    </>
  );
}
