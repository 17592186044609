import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { selectDate, setDate } from 'redux/reducers/synopticChartsReducer';
import useGetAvailableDates from 'utils/hooks/synopticCharts/useGetAvailableDates';
import { DATE_FORMAT } from 'helper/products/synopticCharts';
import DatePicker from 'components/DatePicker';

import './index.scss';

export default function DateSelector() {
  const dispatch = useDispatch();
  const { dates, isFetching } = useGetAvailableDates();
  const dateString = useSelector(selectDate);
  const parsedDate = dateString
    ? parse(dateString, DATE_FORMAT, new Date(), { locale: ptBR })
    : null;

  const handleDate = (date: Date) => {
    const formattedDate = format(date, DATE_FORMAT, { locale: ptBR });
    dispatch(setDate(formattedDate));
  };

  return (
    <div className="synoptic-charts-date-selector-container">
      <DatePicker
        className="form-control synoptic-charts-date-selector"
        placeholderText="Data de previsão"
        selected={parsedDate}
        onChange={handleDate}
        disabled={isFetching}
        includeDates={dates}
      />
    </div>
  );
}
