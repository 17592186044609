import React from 'react';
import {
  Col, Container, Form, Row, Button, Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  useDeletePastSimulationMutation,
  useListPastSimulationsQuery,
} from 'services/api/products/flux';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { useDispatch, useSelector } from 'react-redux';
import {
  togglePastSimulation,
  selectFluxAutomaticControlPanelPastSimulations,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';

export default function New() {
  const dispatch = useDispatch();
  const selectedOptions = useSelector(selectFluxAutomaticControlPanelPastSimulations);
  const { data: pastSimulations } = useListPastSimulationsQuery();
  const isChecked = (simulationId: number) => selectedOptions.includes(simulationId);

  const [
    deletePastSimulation,
    { isLoading: isDeletingPastSimulation, error: deletePastSimulationError },
  ] = useDeletePastSimulationMutation();

  useHandleRequestErrors(deletePastSimulationError as ResponseError);

  const deleteButtonIcon = (isDeletingPastSimulation ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faTrash} size="sm" />);

  return (
    <Container>
      { pastSimulations?.map((pastSimulation) => (
        <Row md={12} key={`past-simulation-${pastSimulation.id}`}>
          <Col className="d-flex">
            <Form.Check
              label={pastSimulation.name}
              onChange={() => dispatch(togglePastSimulation(pastSimulation.id))}
              checked={isChecked(pastSimulation.id)}
              className="flex-grow-1"
            />
            <Button
              className="btn-preference"
              onClick={() => deletePastSimulation(pastSimulation.id)}
            >
              {deleteButtonIcon}
            </Button>
          </Col>
        </Row>
      )) }
    </Container>
  );
}
