import { CustomAlert } from 'components/AlertsCollection';
import { handleApiError } from 'helper/error';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';
import { useSaveContractMutation } from 'services/api/contracts';
import extractContractFormParameters from './parameterExtraction';

export default function useSaveContract(): {
  isLoading: boolean,
  validated: boolean,
  alerts: CustomAlert[],
  requestSuccess: boolean,
  contractSaveHook: (event: React.FormEvent<HTMLFormElement>,
    extraOptions?: { companyId: number } | null) => Promise<void>
} {
  const navigate = useNavigate();
  const [saveContract, { isLoading }] = useSaveContractMutation();
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const [validated, setValidated] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

  return {
    isLoading,
    alerts,
    validated,
    requestSuccess,
    async contractSaveHook(event, extraOptions: { companyId: number } | null = null) {
      // Prevent the browser from reloading the page
      event.preventDefault();

      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);

      if (!form.checkValidity()) {
        setValidated(true);
        return;
      }

      const contractParams = extractContractFormParameters(formData);
      if (extraOptions) {
        contractParams.counterpartyId = extraOptions.companyId;
      }

      const contractSaveResult = await saveContract(contractParams);

      if ('data' in contractSaveResult) {
        const { title, message } = contractSaveResult.data;
        if (contractSaveResult.data.status) {
          setRequestSuccess(true);
          setAlerts([{ title, message, variant: 'success' }]);
        } else {
          setAlerts([{ title, message, variant: 'danger' }]);
        }
      } else if ('error' in contractSaveResult) {
        handleApiError(navigate, contractSaveResult.error as ResponseError);
      }
    },
  };
}
