import React, { useEffect, useMemo } from 'react';
import {
  Alert, Col, Row, Spinner,
} from 'react-bootstrap';
import { store } from 'redux/store';
import { FluxAutomaticLayoutTabs, setCurrentTab } from 'redux/reducers/fluxAutomaticLayoutReducer';
import FilterSelector from 'components/Flux/Automatic/FilterSelector';
import ActiveFilters from 'components/Flux/Automatic/ActiveFilters';
import useDailyData from 'utils/hooks/flux/automatic/useDailyData';
import { DailyData, buildSubdivisionParam } from 'helper/flux/automatic';

import './index.scss';
import Graph from 'components/Flux/Automatic/Daily/Graph';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelSpatialSubdivision,
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { getUnixTime } from 'date-fns';
import useFluxAutomaticZipDownload from 'utils/hooks/flux/automatic/useFluxAutomaticZipDownload';
import usePastSimulationNames from 'utils/hooks/flux/automatic/usePastSimulationNames';

export function fluxZipFile(layoutTab: FluxAutomaticLayoutTabs) {
  return `flux-automatic-${layoutTab}-${getUnixTime(new Date())}.zip`;
}

export default function Automatic() {
  const { pastSimulationNames } = usePastSimulationNames();
  const models = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const { data, isFetching } = useDailyData();
  const subdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const dataIsEmpty = Object.keys(data).length === 0;
  const spatialSubdivisionParam = useMemo(() => buildSubdivisionParam(subdivision), [subdivision]);
  const { DownloadZipButton, DownloadConfirmationDialog } = useFluxAutomaticZipDownload({
    models,
    type: 'daily',
    pastSimulationNames,
    spatialSubdivisionParam,
  });

  useEffect(() => {
    store.dispatch(setCurrentTab('daily'));
  });

  return (
    <div className="pt-4 px-5">
      <DownloadConfirmationDialog />
      <Row>
        <Col className="pe-0 flux-automatic-filter-selector-container"><FilterSelector /></Col>
        <Col className="ps-0"><ActiveFilters /></Col>
        <Col xs={1} className="pt-2">
          <DownloadZipButton />
        </Col>
      </Row>
      <Row>
        {isFetching && <div className="text-center py-4"><Spinner /></div>}
        {!isFetching && dataIsEmpty && <Alert variant="light">Nenhum dado para os filtros selecionados.</Alert>}
        {!isFetching && !dataIsEmpty && <Graph data={data as DailyData} />}
      </Row>
    </div>
  );
}
