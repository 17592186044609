import React from 'react';
import {
  Row, Col, Accordion,
} from 'react-bootstrap';
import { ListedUser } from 'helper/users';
import ProductShortList from 'components/Admin/Products/ShortList';
import Indicator from 'components/Admin/EnablementIndicator';
import useToggle from 'utils/hooks/useToggle';
import Actions from './Actions';
import { useRequestUserActivityToggle } from './Actions/utils';

import { useUserDetails } from './Details/utils';

export default function UserRow({ user, setConfirmationDialogBox }: {
  user: ListedUser,
  setConfirmationDialogBox: (open: boolean, body: JSX.Element, confirm: () => void) => void,
}) {
  const userName = `${user.first_name} ${user.last_name}`;
  const { details: userDetails, load: loadUserDetails } = useUserDetails();
  const { requestUserActivityToggle } = useRequestUserActivityToggle({ user_id: user.id });
  const { toggled, callback: changeUserActivity } = useToggle(
    user.active,
    requestUserActivityToggle,
  );

  return (
    <div key={user.id}>
      <Accordion className="admin-table-row" flush onSelect={() => { loadUserDetails(user); }}>
        <Accordion.Item eventKey={user.id.toString()}>
          <Accordion.Header>
            <Row className="w-100 align-items-center">
              <Col md="2">{userName}</Col>
              <Col md="2">{user.username}</Col>
              <Col md="2">{user.companyName}</Col>
              <Col md="3"><ProductShortList products={user.products} /></Col>
              <Col md="1"><Indicator active={toggled} suffix="o" /></Col>
              <Col md="2">
                <Actions
                  user={user}
                  setConfirmationDialogBox={setConfirmationDialogBox}
                  toggled={toggled}
                  changeUserActivity={changeUserActivity}
                />
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            {userDetails}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr className="admin-table-hr" />
    </div>
  );
}
