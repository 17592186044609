import { useRef } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  setName as setNameRedux,
  setFile as setFileRedux,
  setFilename as setFilenameRedux,
  setEndDate as setEndDateRedux,
  setOutputOptions as setOutputOptionsRedux,
  setMultiScenarios as setMultiScenariosRedux,
  setGroupNumber as setGroupNumberRedux,
  setPrevivazModel as setPrevivazModelRedux,
  selectFluxUploadFormName,
  selectFluxUploadFormFile,
  selectFluxUploadFormFilename,
  selectFluxUploadFormStartDate,
  selectFluxUploadFormEndDate,
  selectFluxUploadFormOutputOptions,
  selectFluxUploadFormMultiScenarios,
  selectFluxUploadFormPrevivazModel,
  reset as resetRedux,
  selectFluxUploadFormPercentileValues,
  selectFluxUploadFormGroupNumber,
} from 'redux/reducers/fluxUploadFormReducer';
import { getUnixTime } from 'date-fns';

export default function useFluxFileUploadForm() {
  const name = useSelector(selectFluxUploadFormName);
  const file = useSelector(selectFluxUploadFormFile);
  const filename = useSelector(selectFluxUploadFormFilename);
  const startDate = useSelector(selectFluxUploadFormStartDate);
  const endDate = useSelector(selectFluxUploadFormEndDate);
  const outputOptions = useSelector(selectFluxUploadFormOutputOptions);
  const multiScenarios = useSelector(selectFluxUploadFormMultiScenarios);
  const previvazModel = useSelector(selectFluxUploadFormPrevivazModel);
  const groupNumber = useSelector(selectFluxUploadFormGroupNumber);
  const flowPercentileValue = useSelector(selectFluxUploadFormPercentileValues);

  const fileRef = useRef<HTMLInputElement>(null);

  const versusLabels = [
    'SE+NE versus S+N',
    'SE+S versus NE+N',
    'SE+N versus S+NE',
    'SE versus S+NE+N',
    'SE vs S vs NE vs N'];

  const setName = (value: string) => {
    store.dispatch(setNameRedux(value));
  };

  const setFile = (value: string) => {
    store.dispatch(setFileRedux(value));
  };

  const setFilename = (value: string) => {
    store.dispatch(setFilenameRedux(value));
  };

  const setEndDate = (value: Date | null) => {
    let unixValue;

    if (value !== null) {
      unixValue = getUnixTime(value);
    } else {
      unixValue = value;
    }

    store.dispatch(setEndDateRedux(unixValue));
  };

  const setOutputOptions = (value: string[]) => {
    store.dispatch(setOutputOptionsRedux(value));
  };

  const setMultiScenarios = (value: string[]) => {
    store.dispatch(setMultiScenariosRedux(value));
  };

  const toggleMultiScenario = (value: string) => {
    let newScenarios: string[];
    if (multiScenarios.includes(value)) {
      const index = multiScenarios.indexOf(value);
      newScenarios = multiScenarios.toSpliced(index, 1);
    } else {
      newScenarios = multiScenarios.concat([value]);
    }
    store.dispatch(setMultiScenariosRedux(newScenarios));
  };

  const toggleOutputOption = (value: string) => {
    let newOptions: string[];
    if (outputOptions.includes(value)) {
      const index = outputOptions.indexOf(value);
      newOptions = outputOptions.toSpliced(index, 1);
    } else {
      newOptions = outputOptions.concat([value]);
    }
    store.dispatch(setOutputOptionsRedux(newOptions));
  };

  const toggleMultiScenarioVersusOption = (value: string) => {
    if (!versusLabels.includes(value)) {
      return;
    }
    const newScenarios: string[] = [];
    multiScenarios.forEach((label: string) => {
      if (!versusLabels.includes(label)) {
        newScenarios.push(label);
      }
    });
    newScenarios.push(value);
    setMultiScenarios(newScenarios);
  };

  const setGroupNumber = (value: number) => {
    store.dispatch(setGroupNumberRedux(value));
  };

  const setPrevivazModel = (value: string) => {
    store.dispatch(setPrevivazModelRedux(value));
  };

  const reset = () => {
    if (fileRef.current) {
      fileRef.current.value = '';
    }
    store.dispatch(resetRedux());
  };

  const validSelectedMultiScenarios = (!multiScenarios.includes('Definir dimensões para agrup.'))
  || (multiScenarios.some((scenarioOption: string) => versusLabels.includes(scenarioOption)));

  const valid = name && file && startDate
  && endDate && previvazModel && validSelectedMultiScenarios;

  return {
    name,
    setName,
    file,
    setFile,
    filename,
    setFilename,
    fileRef,
    startDate,
    endDate,
    setEndDate,
    outputOptions,
    setOutputOptions,
    toggleOutputOption,
    multiScenarios,
    setMultiScenarios,
    toggleMultiScenario,
    toggleMultiScenarioVersusOption,
    groupNumber,
    setGroupNumber,
    previvazModel,
    setPrevivazModel,
    reset,
    valid,
    flowPercentileValue,
  };
}
