import { ContractType } from 'constants/contractType';
import { GenericResponse } from 'services/api/base';
import { APIResponse } from './apiResponse';

interface RawStatusOptions {
  [id: string]: string;
}
type GetStatusOptionsResponse = GenericResponse<RawStatusOptions>;
interface StatusOption {
  id: number;
  label: string;
}
export type StatusOptions = StatusOption[];

interface RawReadjustmentRateOptions {
  [id: string]: string;
}
type GetReadjustmentRatesResponse = GenericResponse<RawReadjustmentRateOptions>;
interface ReadjustmentRateOption {
  id: number;
  label: string;
}
export type ReadjustmentRateOptions = ReadjustmentRateOption[];

export interface RawContractProduct {
  id: number
  id_produto: number
  id_contrato: number
  dt_inicio: number
  dt_fim: number
  ck_ativo: boolean
}

interface RawContractAttachment {
  id_anexo: number
  id_empresa: number
  id_contrato: number
  nome_arquivo_original: string
  diretorio: string
  nome_arquivo_diretorio: string
  data_hora: number
}

export interface RawContract {
  id: number
  id_parte: number
  id_contraparte: number
  dt_inicio: number
  dt_fim: number
  id_proposta: null
  id_status: number
  ck_correcao: boolean
  id_indexador: number | null
  dt_database: number | null
  dt_correcao: number | null
  ds_faturamento: string
  ds_vencimento: string
  arquivos: never[]
  calendario_operacional: never[]
  empresas: never[]
  envio_de_documento: never[]
  observacoes: never[]
  produtos: RawContractProduct[]
  vl_preco: number | null
  anexos: RawContractAttachment[]
}

export type RawGetContractResponse = APIResponse<RawContract>;

export interface ContractProduct {
  id: number
  productId: number
  contractId: number
  startDate: number
  endDate: number
  enabled: boolean
}

export interface ContractAttachment {
  id: number
  originalFilename: string
  directory: string
  directoryFilename: string
  date: number
}

export interface Contract {
  id: number
  type: ContractType
  contractValue: number | null
  readjustmentIndex: number | null
  termStartDate: number
  termEndDate: number
  correctionStartDate: number
  correctionEndDate: number
  statusId: number
  products: ContractProduct[]
  attachments: ContractAttachment[]
}

export const transformGetStatusOptionsResponse = (res: GetStatusOptionsResponse) => (
  Object.entries(res.data).map(([key, value]) => ({ id: Number(key), label: value }))
);

export const transformGetReadjustmentRatesResponse = (res: GetReadjustmentRatesResponse) => (
  Object.entries(res.data).map(([key, value]) => ({ id: Number(key), label: value }))
);

export const productTransform = (params: RawContractProduct): ContractProduct => ({
  id: params.id,
  productId: params.id_produto,
  contractId: params.id_contrato,
  startDate: params.dt_inicio,
  endDate: params.dt_fim,
  enabled: params.ck_ativo,
});

export const attachmentTransform = (params: RawContractAttachment): ContractAttachment => ({
  id: params.id_anexo,
  originalFilename: params.nome_arquivo_original,
  directory: params.diretorio,
  directoryFilename: params.nome_arquivo_diretorio,
  date: params.data_hora,
});

export const transformGetContractResponse = ({ data }: { data: RawContract }): Contract => ({
  id: data.id,
  type: data.id_parte,
  contractValue: data.vl_preco,
  readjustmentIndex: data.id_indexador,
  termStartDate: data.dt_inicio,
  termEndDate: data.dt_fim,
  correctionStartDate: data.dt_database,
  correctionEndDate: data.dt_correcao,
  statusId: data.id_status,
  products: data.produtos.map(productTransform),
  attachments: data.anexos.map(attachmentTransform),
} as Contract);

export const formattedContractValue = (contract: Contract | undefined): string => {
  if (!contract || contract.contractValue === null) {
    return 'Não informado';
  }

  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(contract.contractValue);
};
