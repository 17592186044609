import React from 'react';
import { Col, Row } from 'react-bootstrap';

import FileList from 'components/FileViewer/FileList';

export default function FileViewer() {
  return (
    <div className="h-100 d-flex flex-column">
      <Row>
        <Col className="md-6">
          <h1 className="mb-3">MEUS ARQUIVOS</h1>
        </Col>
      </Row>
      <Row className="overflow-hidden">
        <FileList />
      </Row>
    </div>
  );
}
