import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FluxAutomaticPreferencesModalReducer {
  showFormModal: boolean,
  showConfirmationModal: boolean,
  showErrorModal: boolean,
  error?: string,
}

const initialState: FluxAutomaticPreferencesModalReducer = {
  showFormModal: false,
  showConfirmationModal: false,
  showErrorModal: false,
  error: '',
};

const fluxAutomaticPreferencesModalReducer = createSlice({
  name: 'fluxAutomaticPreferencesModal',
  initialState,
  reducers: {
    setDisplayPreferencesFormModal: (state, action: PayloadAction<boolean>) => {
      state.showFormModal = action.payload;
    },
    setDisplayPreferencesConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.showConfirmationModal = action.payload;
    },
    setDisplayPreferencesErrorModal: (state, action: PayloadAction<boolean>) => {
      state.showErrorModal = action.payload;
    },
    setPreferencesError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
});

export const selectDisplayFluxAutomaticPreferencesModal = (state: any) => state
  .fluxAutomaticPreferencesModal
  .showFormModal;
export const selectDisplayFluxAutomaticConfirmationModal = (state: any) => state
  .fluxAutomaticPreferencesModal
  .showConfirmationModal;
export const selectDisplayFluxAutomaticErrorModal = (state: any) => state
  .fluxAutomaticPreferencesModal
  .showErrorModal;
export const selectFluxAutomaticPreferencesError = (state: any) => state
  .fluxAutomaticPreferencesModal
  .error;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setDisplayPreferencesFormModal,
  setDisplayPreferencesConfirmationModal,
  setDisplayPreferencesErrorModal,
  setPreferencesError,
} = fluxAutomaticPreferencesModalReducer.actions;

export default fluxAutomaticPreferencesModalReducer.reducer;
