import { useMemo, useState } from 'react';

import { DisplayImage } from 'helper/products/meteorology';

export default function useSelectedImageModal({
  imageSets,
  fullSweep,
}: {
  imageSets: DisplayImage[][];
  fullSweep: boolean;
}) {
  const filteredImageSets = useMemo(() => (
    imageSets.map((imageSet) => (
      Object.fromEntries(
        imageSet
          .map((image, i) => ({ image, i }))
          .filter(({ image }) => image.base64)
          .map(({ i, image }) => [i, image]),
      )
    ))
  ), [imageSets]);

  const [show, setShow] = useState(false);
  const [setIndex, setSetIndex] = useState<number>();
  const [imageIndex, setImageIndex] = useState<number>();

  const onShow = () => setShow(true);
  const onHide = () => setShow(false);

  const imageIsSelected = (
    setIndex !== undefined
    && imageIndex !== undefined
    && filteredImageSets[setIndex]?.[imageIndex] !== undefined
  );

  const selectedSet = imageIsSelected && filteredImageSets[setIndex];
  const selectedImage = selectedSet && selectedSet[imageIndex];
  const selectedImageBase64 = selectedImage && selectedImage.base64;
  const imageSrc = `data:image/png;base64,${selectedImageBase64}`;

  const selectedSetIndexes = Object.keys(selectedSet).map(Number);
  const selectedSetFirstImageIndex = selectedSetIndexes[0];
  const selectedSetLastImageIndex = selectedSetIndexes[selectedSetIndexes.length - 1];

  const firstSet = filteredImageSets[0];
  const firstImageIndex = firstSet && Number(Object.keys(firstSet)[0]);
  const lastSetIndex = filteredImageSets.length - 1;
  const lastSet = filteredImageSets[lastSetIndex];
  const lastImageIndex = lastSet && Number(Object.keys(lastSet)[Object.keys(lastSet).length - 1]);

  const isFirstSet = setIndex === 0;
  const isLastSet = setIndex === lastSetIndex;
  const isFirstInTheSet = imageIndex === selectedSetFirstImageIndex;
  const isLastInTheSet = imageIndex === selectedSetLastImageIndex;
  const isFirstOverall = isFirstSet && isFirstInTheSet;
  const isLastOverall = isLastSet && isLastInTheSet;

  let toFirst: (() => void) | undefined;
  let toPrevious: (() => void) | undefined;
  let toNext: (() => void) | undefined;
  let toLast: (() => void) | undefined;
  let up: (() => void) | undefined;
  let down: (() => void) | undefined;

  const buildSetter = (newSetIndex: number, newImageIndex: number) => {
    if (filteredImageSets[newSetIndex]?.[newImageIndex]?.base64) {
      return () => {
        setSetIndex(newSetIndex);
        setImageIndex(newImageIndex);
      };
    }
    return undefined;
  };

  if (selectedImage) {
    if (!isFirstInTheSet) {
      toFirst = buildSetter(setIndex, selectedSetFirstImageIndex);
      toPrevious = buildSetter(setIndex, imageIndex - 1);
    }
    if (!isLastInTheSet) {
      toNext = buildSetter(setIndex, imageIndex + 1);
      toLast = buildSetter(setIndex, selectedSetLastImageIndex);
    }
    if (!isFirstSet && !fullSweep) {
      up = buildSetter(setIndex - 1, imageIndex);
    }
    if (!isLastSet && !fullSweep) {
      down = buildSetter(setIndex + 1, imageIndex);
    }
  }

  if (selectedImage && fullSweep) {
    if (!isFirstOverall) toFirst = buildSetter(0, firstImageIndex);
    if (!isFirstOverall && isFirstInTheSet) {
      toPrevious = buildSetter(setIndex - 1, imageSets[setIndex - 1].length - 1);
    }
    if (!isLastOverall && isLastInTheSet) toNext = buildSetter(setIndex + 1, 0);
    if (!isLastOverall) toLast = buildSetter(lastSetIndex, lastImageIndex);
  }

  return {
    imageSrc,
    show,
    onShow,
    onHide,
    toFirst,
    toPrevious,
    toNext,
    toLast,
    up,
    down,
    setSetIndex,
    setImageIndex,
  };
}
