import { useState } from 'react';

type AsyncCallback = (toggled: boolean) => Promise<boolean>;

export default function useToggle(initial = true, afterToggle: AsyncCallback = async () => true): {
  toggled: boolean,
  callback: () => Promise<void>,
} {
  const [toggled, setToggled] = useState(initial);

  const callback = async () => {
    const original = toggled;

    setToggled(!original);

    const success = await afterToggle(original);

    if (!success) {
      setToggled(original);
    }
  };

  return { toggled, callback };
}
