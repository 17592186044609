import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  showFormModal: false,
  showConfirmationModal: false,
  showErrorModal: false,
  error: '',
};

export const savePreferencesModal = createSlice({
  name: 'savePreferencesModal',
  initialState,
  reducers: {
    setShowFormModal: (state, action: PayloadAction<boolean>) => {
      state.showFormModal = action.payload;
    },
    setShowConfirmationModal: (state, action: PayloadAction<boolean>) => {
      state.showConfirmationModal = action.payload;
    },
    setShowErrorModal: (state, action: PayloadAction<boolean>) => {
      state.showErrorModal = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectSavePreferencesModalShow = (state: any) => state
  .savePreferencesModal
  .showFormModal;
export const selectConfirmationModalShow = (state: any) => state
  .savePreferencesModal
  .showConfirmationModal;
export const selectErrorModalShow = (state: any) => state
  .savePreferencesModal
  .showErrorModal;
export const selectError = (state: any) => state
  .savePreferencesModal
  .error;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setShowFormModal,
  setShowConfirmationModal,
  setShowErrorModal,
  setError,
} = savePreferencesModal.actions;

export default savePreferencesModal.reducer;
