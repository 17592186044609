import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';

export default function EditButton({
  onClick,
  id,
} : {
  onClick: () => void,
  id: number,
}) {
  return (
    <Button id={`edit-video-${id}`} size="sm" className="icon-button video-action-button" onClick={() => onClick()}>
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
    </Button>
  );
}
