import { useCallback } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  setRowSize as setRowSizeRedux,
  setMaxRowSize as setMaxRowSizeRedux,
  selectRowSize,
  selectMaxRowSize,
} from 'redux/reducers/imagesRowSizeReducer';

export default function useImageRowSize() {
  const setRowSize = useCallback((value: number) => {
    store.dispatch(setRowSizeRedux(value));
  }, []);
  const setMaxRowSize = useCallback((value: number) => {
    store.dispatch(setMaxRowSizeRedux(value));
    store.dispatch(setRowSizeRedux(value));
  }, []);
  const rowSize = useSelector(selectRowSize);
  const maxRowSize = useSelector(selectMaxRowSize);
  const increaseZoom = () => {
    setRowSize(Math.max(1, rowSize - 1));
  };
  const decreaseZoom = () => {
    setRowSize(Math.min(maxRowSize, rowSize + 1));
  };

  return {
    rowSize,
    setRowSize,
    maxRowSize,
    setMaxRowSize,
    increaseZoom,
    decreaseZoom,
  };
}
