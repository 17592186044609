import React from 'react';
import { Outlet } from 'react-router';
import usePermissionCheck from 'utils/hooks/usePermissionCheck';
import CentralizedSpinner from 'components/CentralizedSpinner';
import { PermissionTypes } from 'helper/products';

export default function PermissionWrapper({ permissionType }: { permissionType: PermissionTypes }) {
  const keySaved = usePermissionCheck(permissionType);

  if (!keySaved) {
    return <CentralizedSpinner />;
  }

  return <Outlet />;
}
