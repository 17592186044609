import { Dispatch, SetStateAction } from 'react';
import { ListedCompany } from './companies';

type SetterFunction = Dispatch<SetStateAction<number[]>>;

export const addCompanies = (
  setCompanyIds: SetterFunction,
  companyIds: number[],
  idsToAdd: number[],
) => {
  const newSelected = [...companyIds, ...idsToAdd];
  const distinctNewSelected = newSelected.filter((v, i) => newSelected.indexOf(v) === i);
  setCompanyIds(distinctNewSelected);
};

export const removeCompanies = (
  setCompanyIds: SetterFunction,
  companyIds: number[],
  idsToRemove: number[],
) => {
  const newSelected = companyIds.filter((id) => !idsToRemove.includes(id));
  setCompanyIds(newSelected);
};

export const addAllCompanies = (setCompanyIds: SetterFunction, companies: ListedCompany[]) => {
  const allIds = companies.map(({ id }) => id);
  setCompanyIds(allIds);
};

export const removeAllCompanies = (setCompanyIds: SetterFunction) => {
  setCompanyIds([]);
};
