import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import useLatestReportPath from 'hooks/Meteorology/Weather/Overview/useLatestReportPath';

export default function LatestReportLink() {
  const { latestReportPath, isLoading } = useLatestReportPath();

  if (isLoading || latestReportPath === undefined) {
    return null;
  }

  return (
    <Link
      to={`/${latestReportPath}`}
      className="link-secondary text-decoration-none mb-4"
    >
      <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconProp} className="me-1" />
      Clique aqui para acessar o
      <span className="fw-bold"> Latest Report</span>
    </Link>
  );
}
