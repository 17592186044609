import React from 'react';
import './index.scss';

interface ToggleProps {
  name?: string,
  toggled: boolean | null,
  callback: (userActive: boolean) => void,
}

export default function Toggle({ toggled, callback, name }: ToggleProps) {
  const value = Boolean(toggled);
  const tooltipMessage = value ? 'Desabilitar usuário' : 'Habilitar usuário';

  return (
    <>
      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="toggle-box">
        <input
          type="checkbox"
          className="toggle-input"
          checked={value}
          readOnly
          onClick={() => callback(value)}
        />
        <span
          className="toggle-switch"
          title={tooltipMessage}
          aria-label={tooltipMessage}
        />
      </label>
      <input
        type="hidden"
        name={name}
        value={value.toString()}
      />
    </>
  );
}

Toggle.defaultProps = {
  name: 'toggleName',
};
