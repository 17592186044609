import React, { PropsWithChildren, ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

interface LoadingSpinnerProps {
  isLoading: boolean,
}

export default function LoadingSpinner(
  { isLoading, children }: Partial<PropsWithChildren<LoadingSpinnerProps>>,
) {
  if (isLoading) {
    return <Spinner className="mx-auto" animation="border" />;
  }
  return children as ReactElement || null;
}
