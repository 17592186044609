import { useState } from 'react';

export default function useDelayAction(action) {
  const [canCancel, setCanCancel] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>();

  const trigger = () => {
    setCanCancel(true);

    setTimeoutId(
      setTimeout(() => {
        setCanCancel(false);

        action();
      }, 30000),
    );
  };

  const cancel = () => {
    clearTimeout(timeoutId);

    setCanCancel(false);
  };

  return { trigger, cancel, canCancel };
}
