import React from 'react';
import {
  Spinner,
} from 'react-bootstrap';
import { MailTemplateType } from 'helper/mailer';
import { CustomAlert } from 'components/AlertsCollection';
import Item from './Item';
import { SelectedTemplate } from './Item/EditButton';

export interface MailerTemplateListProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedTemplate: React.Dispatch<React.SetStateAction<SelectedTemplate | null>>,
  setAlerts: React.Dispatch<React.SetStateAction<CustomAlert[]>>,
  types: MailTemplateType[] | undefined,
  typesLoading: boolean,
}

export default function List({
  setModalOpen,
  setSelectedTemplate,
  setAlerts,
  types,
  typesLoading,
}: MailerTemplateListProps) {
  if (typesLoading) {
    return (
      <div className="text-center">
        <Spinner className="my-auto mx-auto" animation="border" />
      </div>
    );
  }

  return (
    <div className="px-3">
      { types?.map((type) => (type.subTypes.map((subType) => (
        <Item
          key={`${type.id}-${subType.id}`}
          type={type}
          subType={subType}
          setModalOpen={setModalOpen}
          setSelectedTemplate={setSelectedTemplate}
          setAlerts={setAlerts}
        />
      )))) }
    </div>
  );
}
