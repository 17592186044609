import { differenceInCalendarDays, isSameDay } from 'date-fns';

export default function formatDateDifference(
  date: Date | null,
  availableDates: Date[],
): string | undefined {
  if (!date || availableDates.length === 0) return undefined;

  const lastDate = availableDates[availableDates.length - 1];
  const secondToLastDate = availableDates[availableDates.length - 2];

  if (isSameDay(date, lastDate)) return 'latest';
  if (isSameDay(date, secondToLastDate)) return '2nd_latest';

  const dayDiff = differenceInCalendarDays(date, new Date());

  return dayDiff < 0 ? `d${dayDiff}` : `d+${dayDiff}`;
}
