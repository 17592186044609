import { store } from 'redux/store';
import { ListPersonalizedParams, PersonalizedStudyResponse } from 'helper/personalized';
import {
  ListedStudy, transformDownloadLink, transformListStudiesResponse, UploadStudiesParams,
} from '../../helper/studies';
import baseApi, { GenericResponse } from './base';

interface GetDownloadLinkParams {
  id: number,
}

export const studiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listStudies: build.query<ListedStudy[], ListPersonalizedParams>({
      query: ({ ampereCases, page, pageSize }) => ({
        url: 'produtos/previvaz-arquivo/get-list',
        method: 'POST',
        body: {
          casos_ampere: ampereCases,
          page,
          page_size: pageSize,
        },
      }),
      providesTags: ['Study'],
      transformResponse: transformListStudiesResponse,
    }),
    getDownloadLink: build.query<string, GetDownloadLinkParams>({
      query: ({ id }) => {
        const productKey = store.getState().productKeys['prevs-personalizado'];

        return {
          url: `produtos/previvaz-arquivo/get-download-link/?product_key=${productKey}`,
          method: 'POST',
          body: { caso_id: id },
        };
      },
      transformResponse: transformDownloadLink,
    }),
    uploadStudies: build.mutation<GenericResponse<PersonalizedStudyResponse>, UploadStudiesParams>({
      query: (params: UploadStudiesParams) => {
        const productKey = store.getState().productKeys['prevs-personalizado'];

        return {
          url: `produtos/previvaz-arquivo/save-v2/?product_key=${productKey}`,
          method: 'POST',
          body: params,
        };
      },
    }),
  }),
});

export const {
  useListStudiesQuery,
  useGetDownloadLinkQuery,
  useUploadStudiesMutation,
} = studiesApi;
