import React from 'react';
import {
  Col, Modal, Row, Button,
} from 'react-bootstrap';

type ConfirmationModalProps = {
  open: boolean;
  closing: () => void;
};

export default function ConfirmationModal({ open, closing }: ConfirmationModalProps) {
  return (
    <Modal show={open} onHide={closing} className="confirmation-dialog-modal">
      <Modal.Header closeButton className="confirmation-dialog-modal-header bg-success" />

      <Modal.Body className="my-3 mx-auto confirmation-dialog-modal-body">
        <p>
          As edições foram salvas
          com sucesso!
        </p>
      </Modal.Body>
      <Row className="justify-content-center mb-3 mt-3">
        <Col className="col-md-2">
          <Button
            variant="success"
            onClick={closing}
          >
            Ok
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
