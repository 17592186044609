import React from 'react';
import { Stack } from 'react-bootstrap';
import { Category, VideoProduct } from 'helper/videos';
import VideoTab from './Tab';

export default function VideoTabs({
  categories,
  product,
}: {
  categories: Category[];
  product: VideoProduct;
}) {
  return (
    <Stack direction="horizontal" gap={1} className="nav nav-tabs" style={{ backgroundColor: '#ffffff' }}>
      {categories.map((category) => (
        <VideoTab
          key={category.id}
          category={category}
          product={product}
        />
      ))}
    </Stack>
  );
}
