import React from 'react';
import { Button } from 'react-bootstrap';
import useNumericForecastCP from 'utils/hooks/meteorology/useNumericForecastControlPanel';

export default function RegionButton({ text, value }: { text: string, value: string }) {
  const { setPeriod, period, setDateIndex } = useNumericForecastCP();

  const activeStyle = period === value ? 'btn-control-panel-active' : '';

  const changePeriod = () => {
    setPeriod(value);
    setDateIndex(0);
  };

  return (
    <Button variant="link" onClick={changePeriod} className={`btn-control-panel ${activeStyle}`}>
      { text }
    </Button>
  );
}
