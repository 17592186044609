import { useState } from 'react';
import SearchParams from 'helper/searchParams';
import { CompanyFilterParams } from 'services/api/companies';
import { areArraysEqual } from 'components/Admin/Users/utils';

export type CompanySearchParams = SearchParams & CompanyFilterParams;

export const areSearchParametersDifferent = (
  params1: CompanySearchParams,
  params2: CompanySearchParams,
): boolean => (
  params1.query !== params2.query
  || params1.sortBy !== params2.sortBy
  || params1.sortDirection !== params2.sortDirection
  || !areArraysEqual(params1.productIds, params2.productIds)
  || params1.contractEndsBefore !== params2.contractEndsBefore
  || params1.consulting !== params2.consulting
  || params1.enabled !== params2.enabled
  || params1.expiringIn30Days !== params2.expiringIn30Days
  || params1.expiringIn60Days !== params2.expiringIn60Days
  || params1.isTest !== params2.isTest
);

export const useRefreshCompaniesOnSearch = (
  searchParams: CompanySearchParams,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  isFetching: boolean,
) => {
  const [localParams, setLocalParams] = useState(searchParams);
  const [isFiltering, setFiltering] = useState(false);

  if (isFiltering && !isFetching) {
    setFiltering(false);
  }

  if (areSearchParametersDifferent(searchParams, localParams)) {
    setPage(1);
    setFiltering(true);
    setLocalParams(searchParams);
  }

  return { isFiltering };
};
