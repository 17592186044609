import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortDirection, SORT_ASC, SORT_DESC } from 'constants/sorting';
import { BLACK, GRAY2 } from 'constants/colors';
import { useClosure } from 'utils/hooks';
import { Button, Stack } from 'react-bootstrap';
import { nextDirection, SortableField, Sorter } from './helpers';
import './index.scss';

export { useSorting } from './helpers';

export interface SortingArrowProps {
  field: string;
  sort: Sorter;
  sortBy: SortableField;
  sortDirection: SortDirection | undefined;
}

export default function SortingArrow({
  field,
  sortBy,
  sortDirection,
  sort,
}: SortingArrowProps) {
  const realDirection = sortBy === field ? sortDirection : undefined;
  const onClick = useClosure(nextDirection, sort, realDirection);
  const id = `${field}-sort`;
  const upColor = realDirection === SORT_DESC ? GRAY2 : BLACK;
  const downColor = realDirection === SORT_ASC ? GRAY2 : BLACK;
  return (
    <Button id={id} className="sorting-arrow" onClick={onClick}>
      <Stack gap={0}>
        <FontAwesomeIcon icon={faChevronUp as IconProp} size="2xs" color={upColor} />
        <FontAwesomeIcon icon={faChevronDown as IconProp} size="2xs" color={downColor} />
      </Stack>
    </Button>
  );
}
