import React, { useState, FormEvent } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { SavePntPreferencesParams, useSavePntPreferencesMutation, useListPntPreferencesQuery } from 'services/api/Meteorology';
import useNumericForecastCP from 'utils/hooks/meteorology/useNumericForecastControlPanel';
import useSavePreferencesModal from 'utils/hooks/meteorology/useSavePreferencesModal';
import relativeDateFromToday from 'helper/relativeDateFromToday';

interface ErrorObj {
  status: number;
  data: string;
}

export default function SavePreferencesForm() {
  const [name, setName] = useState('');
  const {
    region, models, forecastUnixDate, period, anomaly,
  } = useNumericForecastCP();
  const {
    setShowFormModal,
    setShowConfirmationModal,
    setShowErrorModal,
    setError,
  } = useSavePreferencesModal();
  const [savePntPreferences, {
    isLoading,
  }] = useSavePntPreferencesMutation();
  const { data } = useListPntPreferencesQuery();
  const preferenceNames = data?.data.map((preference) => preference.name);
  const valid = name.length > 0 && !(preferenceNames?.includes(name)) && !isLoading;
  const forecastDate = relativeDateFromToday(forecastUnixDate) || 'latest';

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params: SavePntPreferencesParams = {
      id: 0,
      meteorologicVariable: 'prec',
      models,
      forecastDate,
      interestPeriod: period,
      plotArea: region,
      anomaly,
      name,
    };
    const response = await savePntPreferences(params);
    setName('');
    if ('error' in response) {
      const error = response.error as ErrorObj;
      setShowErrorModal(true);
      setError(error.data);
    } else {
      setShowConfirmationModal(true);
    }
    setShowFormModal(false);
  };

  const nameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Col className="col-md-6">
            Antes de salvar, dê um
            {' '}
            <b>nome</b>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-md-6">
            <p>
              para sua nova preferência.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="preferenceName">
              <Form.Control required type="text" onInput={nameChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col className="col-md-6">
            <Button className={valid ? 'mt-3 primary-button' : 'mt-3 secondary-button'} type="submit" disabled={!valid}>
              {isLoading && <Spinner size="sm" />}
              <span>
                Salvar nova preferência
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
