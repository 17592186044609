import './index.scss';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function Instructions() {
  return (
    <div className="h-75 mx-auto my-4 instruction-panel">
      <div className="w-100 text-center instruction-panel-title py-3">
        INSTRUÇÕES AO USUÁRIO
      </div>
      <div className="instruction-panel-content mt-4 mx-5">
        <Row>
          <Col md={5} className="mx-3">
            <div className="instruction-panel-content-title mb-4">
              Recomenda-se o uso desta ferramenta aos usuários que desejam construir seus próprios
              estudos de previsão de vazão a partir dos insumos de precipitação disponíveis com
              horizontes de curto, médio e longo prazo.
            </div>
            <div className="instruction-panel-content-block mb-4">
              <p>Para nivelamento de conceitos e termos:</p>
              <ul className="ps-4">
                <li>Cada simulação solicitada é um estudo;</li>
                <li>Um estudo pode conter um ou mais cenários;</li>
                <li>Um cenário pode conter um ou mais blocos.</li>
              </ul>
            </div>
            <div className="instruction-panel-content-block mb-4">
              Um bloco representa um recorte contínuo de alguma premissa de chuva a ser utilizada
              na simulação e pode ser montado a partir de previsões meteorológicas, da precipitação
              histórica observada ou mesmo da climatologia
            </div>
          </Col>
          <Col md={1} />
          <Col md={5} className="mx-3">
            <div className="instruction-panel-content-title mb-4">
              A construção de um estudo personalizado compreende o seguinte passo a passo:
            </div>
            <div className="instruction-panel-content-block mb-4">
              <ol>
                <li>
                  Preencher formulário com elementos básicos do estudo (nome, data final do
                  horizonte de estudo e opções)
                </li>
                <li> Preencher nome do cenário </li>
                <li>
                  Adicionar blocos ao cenário até que todo o horizonte do estudo esteja preenchido
                  com premissas
                </li>
                <li> Salvar cenário </li>
                <li>
                  Caso deseje solicitar um estudo multi-cenários, adicionar novo cenário ao estudo e
                  repetir passos 2 a 4
                </li>
                <li> Enviar estudo para simulação </li>
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
