import React from 'react';
import { Accordion } from 'react-bootstrap';
import {
  useDeleteAutomaticPreferenceMutation,
  useGetAutomaticPreferencesQuery,
} from 'services/api/products/flux';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { useSelector } from 'react-redux';
import { FluxAutomaticLayoutTabs, selectFluxAutomaticLayoutCurrentTab } from 'redux/reducers/fluxAutomaticLayoutReducer';
import { AutomaticPreferenceType } from 'helper/products/flux';
import useAutomaticOptions from 'utils/hooks/flux/useAutomaticOptions';
import Preference from './Preference';

const tabToPreferenceType = new Map<FluxAutomaticLayoutTabs, AutomaticPreferenceType>([
  ['daily', 'diaria'],
  ['monthly', 'mensal'],
  ['revision', 'revisao'],
]);

export default function AutomaticSavedPreferences() {
  const currentTab: FluxAutomaticLayoutTabs = useSelector(selectFluxAutomaticLayoutCurrentTab);
  const type = tabToPreferenceType.get(currentTab) || 'diaria';
  const { data, error } = useGetAutomaticPreferencesQuery(type);
  const [
    deleteMethod,
    { error: deleteError, isLoading: deleteLoading },
  ] = useDeleteAutomaticPreferenceMutation();
  const deletePreferenceMethod = (preferenceId: number) => () => deleteMethod(preferenceId);
  const { subdivisions } = useAutomaticOptions();

  useHandleRequestErrors(error as ResponseError);
  useHandleRequestErrors(deleteError as ResponseError);
  const automaticPreferences = data ?? [];

  return (
    <Accordion.Item eventKey="1" className="control-panel-item">
      <Accordion.Header>Preferências Salvas</Accordion.Header>
      <Accordion.Body>
        { automaticPreferences.map((preference) => (
          <Preference
            subdivisions={subdivisions}
            key={preference.id}
            preference={preference}
            onDelete={deletePreferenceMethod(preference.id)}
            deleteLoading={deleteLoading}
          />
        )) }
      </Accordion.Body>
    </Accordion.Item>
  );
}
