import { useCallback, useEffect, useState } from 'react';

import { ResponseError } from 'services/api/base';
import { useLazyGetFileListQuery, useUploadFileMutation } from 'services/api/fileViewer';
import { UploadFileResponse, UploadFileParams } from 'helper/products/fileViewer';
import usePermissionCheck from '../usePermissionCheck';
import useHandleRequestErrors from '../useHandleRequestErrors';

type TriggerFunction = (id: UploadFileParams) => void;
interface Response {
  data: UploadFileResponse | undefined;
  isLoading: boolean;
}
type UseUploadFileReturnValue = [TriggerFunction, Response];

export default function useFileUpload(): UseUploadFileReturnValue {
  const keySaved = usePermissionCheck('file-viewer');
  const [refetch] = useLazyGetFileListQuery();
  const [uploadTrigger, { data, error }] = useUploadFileMutation();
  const [uploadParams, setUploadParams] = useState<UploadFileParams>();
  const [isLoading, setIsLoading] = useState(false);

  const trigger = (params: UploadFileParams) => {
    setUploadParams(params);
  };

  const uploadFile = useCallback(async () => {
    if (uploadParams) {
      try {
        setIsLoading(true);
        await uploadTrigger(uploadParams);
        await refetch();
      } finally {
        setIsLoading(false);
      }
    }
  }, [uploadTrigger, uploadParams, refetch]);

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (keySaved && uploadParams) {
      uploadFile();
      setUploadParams(undefined);
    }
  }, [keySaved, uploadParams, uploadFile]);

  return [trigger, { data, isLoading }];
}
