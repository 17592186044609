import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import FormModal from 'components/FormModal';
import {
  selectSendInvoiceModalShow,
} from 'redux/reducers/sendInvoiceModalReducer';
import useInvoiceSubmission from 'utils/hooks/financial/useInvoiceSubmission';
import onModalHide from './utils';
import SimpleInvoiceRow from './SimpleInvoiceRow';
import Summary from './Summary';

export default function SendInvoiceModal() {
  const show = useSelector(selectSendInvoiceModalShow);
  const { reports } = useInvoiceSubmission();

  return (
    <FormModal
      show={show}
      title="Enviar notas fiscais"
      onHide={onModalHide}
      lastAsFooter
    >
      <Row className="mb-3">
        <Col xs={8} />
        <Col xs={2} className="text-center">Criação</Col>
        <Col xs={2} className="text-center">Envio</Col>
      </Row>
      <hr />
      {reports.map((report) => (
        <SimpleInvoiceRow report={report} key={report.invoice.id} />
      ))}
      <Summary />
    </FormModal>
  );
}
