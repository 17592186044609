import { getUnixTime, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { SaveContractParams } from 'services/api/contracts';

function parseFormDate(v: FormDataEntryValue | null) {
  const dateString = String(v);
  const parsedDate = parse(dateString, 'P', new Date(), { locale: ptBR });
  return getUnixTime(parsedDate);
}

export default function extractContractFormParameters(formData: FormData): SaveContractParams {
  const contractId = Number(formData.get('contract_id'));
  const readjustmentRate = formData.get('readjustmentRate');
  const correctionStartDate = formData.get('correctionStartDate');
  const correctionEndDate = formData.get('correctionEndDate');
  const productsId = formData.getAll('product_id[]');
  const productsInstancesId = formData.getAll('product_instance_id[]');
  const productStartDates = formData.getAll('product_start_date[]');
  const productEndDates = formData.getAll('product_end_date[]');
  const productsEnabled = formData.getAll('product_enabled[]');

  const products = productsId.map((productId, index) => ({
    id: Number(productsInstancesId[index]) || null,
    contractId: contractId || null,
    productId: Number(productId),
    enabled: String(productsEnabled[index]) === 'true',
    startDate: parseFormDate(productStartDates[index]),
    endDate: parseFormDate(productEndDates[index]),
  }));

  return {
    id: contractId,
    partyId: Number(formData.get('productCategory')),
    counterpartyId: Number(formData.get('contract-company')),
    startDate: parseFormDate(formData.get('termStartDate')),
    endDate: parseFormDate(formData.get('termEndDate')),
    statusId: Number(formData.get('status')),
    products,
    correctionEnabled: Boolean(readjustmentRate || correctionStartDate || correctionEndDate),
    indexId: Number(readjustmentRate),
    baseDate: parseFormDate(correctionStartDate),
    correctionDate: parseFormDate(correctionEndDate),
    billingDescription: 'M+',
    dueDateDescription: 'M+',
    valueCents: Number(String(formData.get('contractValue')).replace(/\D/g, '')),
  };
}
