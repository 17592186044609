import React from 'react';
import ReactSlider from 'react-slider';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { addHours, parse } from 'date-fns';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';

import { DATE_FORMAT, DisplayMode, IMAGE_DATE_REGEX } from 'helper/products/synopticCharts';
import {
  selectDisplayMode,
  selectImageIndex,
  setDisplayMode,
  setImageIndex,
} from 'redux/reducers/synopticChartsReducer';
import useGetImages from 'utils/hooks/synopticCharts/useGetImages';

import './index.scss';

export default function ForecastDaySlider() {
  const dispatch = useDispatch();
  const displayMode = useSelector(selectDisplayMode);
  const { data, isFetching } = useGetImages();
  const selectedIndex = useSelector(selectImageIndex);
  const selectedDate = data && data[selectedIndex];

  const dataIsAvailable = data && data.length > 0;
  const disabled = displayMode !== DisplayMode.Images || isFetching || !dataIsAvailable;

  const minIndex = 0;
  const maxIndex = data ? data.length - 1 : 0;

  const match = selectedDate && selectedDate.date.match(IMAGE_DATE_REGEX);
  const baseDate = match?.[1];
  const offset = match?.[2];
  const parsedBaseDate = baseDate && parse(baseDate, DATE_FORMAT, new Date());
  const parsedOffset = offset && parseInt(offset, 10);
  const dateWasParsed = parsedBaseDate instanceof Date && typeof parsedOffset === 'number';
  const parsedFinalDate = dateWasParsed && addHours(parsedBaseDate, parsedOffset);
  const thumbLabel = parsedFinalDate ? format(parsedFinalDate, "dd.MM - HH'h'") : '';

  const handleEnter = () => {
    dispatch(setDisplayMode(DisplayMode.Images));
  };

  const handleChange = (i: number) => {
    dispatch(setImageIndex(i));
  };

  const firstIndex = () => {
    handleChange(minIndex);
  };
  const previousIndex = () => {
    const newIndex = Math.max(selectedIndex - 1, minIndex);
    handleChange(newIndex);
  };
  const nextIndex = () => {
    const newIndex = Math.min(selectedIndex + 1, maxIndex);
    dispatch(setImageIndex(newIndex));
  };
  const lastIndex = () => {
    handleChange(maxIndex);
  };

  return (
    <div
      onClick={handleEnter}
      onKeyDown={handleEnter}
      onFocus={handleEnter}
      role="button"
      tabIndex={0}
      className={`
        synoptic-charts-range-control
        ${disabled ? 'deselected' : ''}
      `}
    >
      <Button variant="link" onClick={firstIndex}>
        <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleDoubleLeft} />
      </Button>
      <Button variant="link" onClick={previousIndex}>
        <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleLeft} />
      </Button>
      <ReactSlider
        min={0}
        max={maxIndex}
        value={selectedIndex}
        onChange={handleChange}
        className="synoptic-charts-horizontal-slider"
        thumbClassName="synoptic-charts-thumb"
        trackClassName="synoptic-charts-track"
        renderThumb={(props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...props}>
            <div className="synoptic-charts-thumb-label">{thumbLabel}</div>
          </div>
        )}
        pearling
        minDistance={1}
      />
      <Button variant="link" onClick={nextIndex}>
        <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleRight} />
      </Button>
      <Button variant="link" onClick={lastIndex}>
        <FontAwesomeIcon className="synoptic-charts-slider-control-button" icon={faAngleDoubleRight} />
      </Button>
    </div>
  );
}
