import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from 'components/Admin/ActionButton';
import executeFunctionList from 'utils/executeFunctionList';
import { useClosure } from 'utils/hooks';
import { MailTemplateSubtype, MailTemplateType } from 'helper/mailer';

export interface SelectedTemplate {
  type: MailTemplateType,
  subType: MailTemplateSubtype,
}

interface MailTemplateListItemEditButton {
  type: MailTemplateType,
  subType: MailTemplateSubtype,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedTemplate: React.Dispatch<React.SetStateAction<SelectedTemplate | null>>,
}

export default function EditButton({
  setModalOpen, setSelectedTemplate, type, subType,
}: MailTemplateListItemEditButton) {
  return (
    <div className="action-button-container">
      <ActionButton
        loading={false}
        onClick={executeFunctionList([
          useClosure(setModalOpen, true),
          useClosure(setSelectedTemplate, { type, subType }),
        ])}
        tooltipText="Editar template"
      >
        <FontAwesomeIcon
          icon={faPenToSquare as IconProp}
          size="xs"
        />
      </ActionButton>
    </div>
  );
}
