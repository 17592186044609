import React from 'react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onTextChange } from 'helper/controlledField';
import './index.scss';

interface SearchBarProps {
  text: string,
  setText: React.Dispatch<React.SetStateAction<string>>,
  setSearchQuery: (text: string) => void,
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean,
}

export const onSearchKeyDown = (
  setSearchQuery: (text: string) => void,
) => (
  (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchQuery(event.currentTarget.value);
    }
  }
);

export default function SearchBar({
  text, setText, setSearchQuery, disabled = false,
}: SearchBarProps) {
  return (
    <div className="form-group search-bar">
      <span className="search-icon">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </span>
      <input
        disabled={disabled}
        id="search-bar"
        type="text"
        className="form-control"
        placeholder="Pesquisar"
        onKeyDown={onSearchKeyDown(setSearchQuery)}
        value={text}
        onChange={onTextChange(setText)}
      />
    </div>
  );
}
