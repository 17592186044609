import React, { PropsWithChildren } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import './index.scss';

export interface ActionButtonProps {
  loading: boolean,
  onClick: () => void,
  tooltipText: string,
  // eslint-disable-next-line react/require-default-props
  active?: boolean,
  // eslint-disable-next-line react/require-default-props
  extraClassName?: string,
}

export default function ActionButton(
  {
    children, loading, onClick, tooltipText, active = true, extraClassName = '',
  }: PropsWithChildren<ActionButtonProps>,
) {
  return (
    <Button
      variant="outline-primary"
      size="sm"
      className={`action-button ${extraClassName}`}
      disabled={loading || !active}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      title={tooltipText}
      aria-label={tooltipText}
    >
      {loading && <Spinner size="sm" className="action-spinner" />}
      {!loading && children}
    </Button>
  );
}
