import React, { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import SearchBar from 'components/SearchBar';
import { ListedCompany } from 'helper/companies';
import SelectedCompanyList from './SelectedCompanyList';
import CompanyList from './CompanyList';
import './index.scss';

export default function CompanySelect({
  selectedCompanyIds,
  setSelectedCompanyIds,
  companies,
  companiesAreLoading,
} : {
  companies: ListedCompany[],
  companiesAreLoading: boolean,
  selectedCompanyIds: number[],
  setSelectedCompanyIds: (value: React.SetStateAction<number[]>) => void,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  let filteredCompanies = companies;
  const selectedCompanies = companies.filter(({ id }) => selectedCompanyIds.includes(id));

  if (!filteredCompanies) {
    if (companiesAreLoading) {
      return <div className="text-center pb-3"><Spinner /></div>;
    }

    return <div>Erro</div>;
  }

  if (searchQuery) {
    filteredCompanies = filteredCompanies
      .filter(({ nome_fantasia, cnpj }) => {
        const lowerCaseName = nome_fantasia.toLowerCase();
        const digitsOnlyCnpj = cnpj.replace(/\D/, '');
        const lowerCaseQuery = searchQuery.toLowerCase();
        const digitsOnlyQuery = lowerCaseQuery.replace(/\D/, '');

        const nameMatch = lowerCaseName.startsWith(lowerCaseQuery);
        const cnpjMatch = digitsOnlyCnpj.startsWith(digitsOnlyQuery);

        return nameMatch || cnpjMatch;
      });
  }

  return (
    <div className="mb-3">
      <div className="mb-4 d-flex flex-row-reverse">
        <Col xs={7}>
          <SearchBar
            text={searchQuery}
            setText={setSearchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Col>
      </div>
      <Row>
        <Col xs={3}>
          <SelectedCompanyList
            companies={selectedCompanies}
            companyIds={selectedCompanyIds}
            setCompanyIds={setSelectedCompanyIds}
          />
        </Col>
        <Col xs={9}>
          <CompanyList
            companies={filteredCompanies}
            companyIds={selectedCompanyIds}
            setCompanyIds={setSelectedCompanyIds}
          />
        </Col>
      </Row>
    </div>
  );
}
