import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import About from 'components/Meteorology/Weather/Area/About';
import Status from 'components/Meteorology/Weather/Area/Status';
import History from 'components/Meteorology/Weather/Area/History';
import { useClosure } from 'utils/hooks';
import './index.scss';

export default function Area({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string,
  areaLabel: string,
}) {
  const [key, setKey] = useState('about');

  return (
    <Tabs
      activeKey={key}
      onSelect={useClosure(setKey)}
      className="px-4 pt-4 justify-content-center weather-inner-tabs"
      id="weather-inner-tabs"
    >
      <Tab eventKey="about" title="Sobre">
        <About areaAcronym={areaAcronym} areaLabel={areaLabel} />
      </Tab>
      <Tab eventKey="status" title="Status / Previsão">
        <Status />
      </Tab>
      <Tab eventKey="impacts" title="Impactos / Referências">
        {`${areaAcronym} - Impactos / Referências`}
      </Tab>
      <Tab eventKey="history" title="Histórico">
        <History areaAcronym={areaAcronym} areaLabel={areaLabel} />
      </Tab>
    </Tabs>
  );
}
