import React, { useState } from 'react';
import {
  Col, Container, Row, Stack,
} from 'react-bootstrap';
import SearchBar from 'components/SearchBar';
import UsersTable from 'components/Admin/Users/UsersTable';
import UserModal from 'components/Admin/Users/UserModal';
import NewUserButton from 'components/Admin/Users/NewUserButton';
import FilterButton from 'components/Filters/FilterButton';
import UserFilterModal from 'components/Admin/Users/UserFilterModal';
import { useListCompaniesQuery } from 'services/api/companies';
import { useListProductsQuery } from 'services/api/products/index';
import { UserModalFilters, modalToQueryFilters } from 'helper/userFilters';
import { userAppliedFilters } from 'helper/filterModals';
import { closeModal, openModal } from 'helper/modals';

export default function Users() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBarText, setSearchBarText] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const {
    data: companiesData,
    isLoading: companiesLoading,
  } = useListCompaniesQuery();
  const {
    data: productsData,
    isLoading: productsLoading,
  } = useListProductsQuery();
  const [filters, setFilters] = useState({
    selectedProducts: [],
    selectedCompany: '',
    isEnabled: false,
    isDisabled: false,
    isExpiringIn30: false,
    isExpiringIn60: false,
  } as UserModalFilters);
  const appliedFilters = userAppliedFilters(
    productsData,
    companiesData,
    filters,
    setFilters,
    searchQuery,
    setSearchQuery,
    setSearchBarText,
  );
  const filterActive = appliedFilters.length > 0;

  return (
    <div>
      <UserModal />
      <UserFilterModal
        open={filterModalOpen}
        closeModal={closeModal(setFilterModalOpen)}
        filters={filters}
        setFilters={setFilters}
        productsLoading={productsLoading}
        products={productsData}
        companiesLoading={companiesLoading}
        companies={companiesData}
      />
      <Container>
        <Row className={filterActive ? 'align-items-center' : 'align-items-center pb-4'}>
          <Col>
            <Stack direction="horizontal" gap={3}>
              <h1 className="mb-0">USUÁRIOS</h1>
              <FilterButton
                filterActive={filterActive}
                onClick={openModal(setFilterModalOpen)}
              />
            </Stack>
          </Col>
          <Col>
            <SearchBar
              text={searchBarText}
              setText={setSearchBarText}
              setSearchQuery={setSearchQuery}
            />
          </Col>
          <Col>
            <NewUserButton />
          </Col>
        </Row>

        {filterActive && (
          <div className="my-3">
            {appliedFilters}
          </div>
        )}

        <Row className={filterActive ? '' : 'pt-5'}>
          <UsersTable
            searchQuery={searchQuery}
            filters={modalToQueryFilters(filters, companiesData)}
          />
        </Row>
      </Container>
    </div>
  );
}
