import React from 'react';
import ErrorPage from '../components/ErrorPage';
import NoAccessPage from '../components/NoAccessPage';
import Layout from '../components/Layout';
import authRouter from './AuthRouter';
import adminRouter from './AdminRouter';
import meteorologyRouter from './MeteorologyRouter';
import Home from '../views/Home';
import fluxRouter from './FluxRouter';
import fileViewerRouter from './FileViewerRouter';
import videosRouter from './VideosRouter';

const rootRouter = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { path: 'home', element: <Home /> },
      ...authRouter,
      { path: 'error', element: <ErrorPage /> },
      { path: 'no-access', element: <NoAccessPage /> },
      adminRouter,
      meteorologyRouter,
      fluxRouter,
      fileViewerRouter,
      videosRouter,
    ],
  },
];

export default rootRouter;
