import { GenericResponse } from 'services/api/base';
import { SpatialSubdivisionParam, SpatialSubdivisionParamKey } from '../flux/automatic';

export type AutomaticPreferenceType = 'diaria' | 'mensal' | 'revisao';

export interface RawAutomaticPreference {
  id: number
  user_id: number
  name: string
  json: {
    versao: string
    bacia?: string
    posto?: string
    subsistema?: string
    reservatorio?: string
    lista_cenarios: string[]
    data_acomph: string | boolean
    data_previsao: string | boolean
    lista_simulacoes_antigas?: number[]
  }
}

export type RawGetAutomaticPreferencesResponse = GenericResponse<RawAutomaticPreference[]>;

export interface AutomaticPreference {
  id: number
  userId: number
  name: string
  spatialSubDivisionKey: string,
  spatialSubDivisionValue: string,
  scenarios: string[],
  acomph: boolean,
  mlt: boolean,
  pastSimulations: number[]
}

export interface SaveAutomaticPreferenceParams {
  name: string
  scenarios: string[]
  spatialSubdivisionParam: SpatialSubdivisionParam
  type: AutomaticPreferenceType
  pastSimulations: number[]
  acomph: boolean
  mlt: boolean
}

export interface VerifyZipParams {
  user: string,
  empresa: string,
  nome_estudo: string,
  options: string,
  data_inicial: number,
  data_final: number,
  file_name: string,
  file_base64: string,
}

export interface VerifyZipResponse {
  status: number,
  code: number,
  type: number,
  title: string,
  message: string,
  data: object | null,
}

export interface GetZipFileParams {
  review?: string,
  version: string,
  models: string[],
  data_acomph: string,
  data_previsao: string,
  unidade?: '%mlt' | 'mwmed',
  type: 'diaria' | 'mensal' | 'revisao',
  spatialSubdivisionParam: SpatialSubdivisionParam
}

export interface RawStatus {
  data_acomph: string,
  last_update: number, // Unix timestamp in seconds
  data_previsao: string,
  cenario: string,
  id: number,
}

interface RawQueueItem {
  pid: number,
  running: boolean,
  queue_position: number,
  time_on_queue: string,
  time_running: string,
}

export interface RawQueue {
  [key: string]: RawQueueItem,
}

export const subDivisionTransformation = (data: RawAutomaticPreference) => {
  const subdivisions = ['subsistema', 'reservatorio', 'bacia', 'posto'];
  const subdivision = subdivisions.find(
    (item) => Object.keys(data.json).includes(item),
  ) as SpatialSubdivisionParamKey;

  return {
    spatialSubDivisionKey: subdivision,
    spatialSubDivisionValue: data.json[subdivision] as string,
  };
};

export const transformAutomaticPreferences = (
  res: RawGetAutomaticPreferencesResponse,
): AutomaticPreference[] => {
  const data = res.data || [];

  return data.map((rawPreference) => ({
    id: rawPreference.id,
    userId: rawPreference.user_id,
    name: rawPreference.name,
    ...subDivisionTransformation(rawPreference),
    scenarios: rawPreference.json.lista_cenarios,
    acomph: rawPreference.json.data_acomph === true,
    mlt: rawPreference.json.data_previsao === true,
    pastSimulations: rawPreference.json.lista_simulacoes_antigas ?? [],
  }));
};
