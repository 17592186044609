import React from 'react';
import { store } from 'redux/store';
import { setShow, setTitle, setUser } from 'redux/reducers/userModalReducer';
import PlusButton from 'components/PlusButton';

export const onClick = () => {
  store.dispatch(setUser(null));
  store.dispatch(setTitle('Novo Usuário'));
  store.dispatch(setShow(true));
};

export default function NewUserButton() {
  return (
    <PlusButton
      id="new-user-button"
      label="Adicionar Usuário"
      onClick={onClick}
    />
  );
}
