export const openModal = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  setModalOpen(true);
};

export const openModalWithTitle = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalTitle: React.Dispatch<React.SetStateAction<string>>,
  title: string,
) => () => {
  setModalTitle(title);
  setModalOpen(true);
};

export const closeModal = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  setModalOpen(false);
};
