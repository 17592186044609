import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import '../../NumericForecast/Instructions/index.scss';
import './index.scss';
import PopularComparisons from './PopularComparisons';

export default function Instructions() {
  return (
    <Row className="p-5">
      <Col md={7}>
        <Card className="shadow-sm forecast-instruction-card">
          <Card.Header>
            COMPARAÇÕES POPULARES
          </Card.Header>
          <Card.Body>
            <p className="card-element">
              As comparações populares são aquelas mais buscadas dentro
              da meteorologia. Criamos atalhos para que você as possa
              acessar facilmente. Selecionando qualquer uma das comparações
              abaixo, você será direcionado a comparação completa.
            </p>
            <PopularComparisons />
          </Card.Body>
        </Card>
      </Col>
      <Col md={5}>
        <Row>
          <Col md={10}>
            <p>
              Navegue entre os diferentes períodos de sua comparação
              através destes botões no canto superior esquerdo da tela.
              As opções são:
              <b>
                {' '}
                diário, pêntada, semanal ou mensal.
              </b>
            </p>
          </Col>
          <Col md={2}>
            <Row>
              <Col className="letter-box">D</Col>
              <Col className="letter-box">P</Col>
            </Row>
            <Row>
              <Col className="letter-box">S</Col>
              <Col className="letter-box">M</Col>
            </Row>
          </Col>
        </Row>
        <Card className="shadow-sm forecast-instruction-card mb-4">
          <Card.Header>
            PREFERÊNCIAS SALVAS
          </Card.Header>
          <Card.Body>
            <p className="comparison-text">
              Se você costuma buscar sempre as mesmas comparações, essa funcionalidade
              é para você! Criamos uma área para que possa salvar buscas frequentes,
              assim você pode visualizá-las quando abrir o comparar e rapidamente
              selecionar a que deseja ver em detalhe.
            </p>
            <p className="comparison-text">
              Para configurar preferências você seleciona no painel de controle ao lado
              a comparação que deseja ver e clica em salvar nova preferência. Ao salvar,
              você vai poder indicar um nome personalizado. O número de preferências
              salvas é ilimitado.
            </p>
          </Card.Body>
        </Card>
        <Card className="shadow-sm forecast-instruction-card">
          <Card.Header>
            NOVA COMPARAÇÃO
          </Card.Header>
          <Card.Body>
            <p className="comparison-text">
              Selecione as variáveis do modelo base e do modelo confrontante no painel
              de controle ao lado e sua comparação aparecerá na tela.
            </p>
            <p className="comparison-text">
              Lembre que você sempre tem a opção de salvar a comparação para rápido
              acesso.
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
