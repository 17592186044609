import { useEffect, useRef, useState } from 'react';
import {
  MassMailSendingFile,
  SendMassMailBodyType,
  useSendMassMailMutation,
} from 'services/api/mailer';
import { ResponseError } from 'services/api/base';
import { CustomAlert } from 'components/AlertsCollection';
import useDelayAction from 'utils/hooks/useDelayAction';
import useMassMailClientQueryAdapter from 'utils/hooks/mailer/useMassMailClientQueryAdapter';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function useMassMailForm() {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [companyIds, setCompanyIds] = useState<number[]>([]);
  const [productIds, setProductIds] = useState<number[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isTest, setIsTest] = useState(true);
  const [files, setFiles] = useState<File[]>([]);
  const [bodyType, setBodyType] = useState<SendMassMailBodyType | null>(null);
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const [massMailSendingTrigger, {
    isLoading: sendProcessLoading, data: submissionData, error: submissionError, isError,
  }] = useSendMassMailMutation();
  const {
    data: massMailClientList, isLoading: massMailClientListLoading,
  } = useMassMailClientQueryAdapter(productIds);

  const isLoading = sendProcessLoading || massMailClientListLoading;
  const bodyHasContent = body.length > 0;
  const subjectHasContent = subject.length > 0;
  const companiesListNotEmpty = companyIds.length > 0;
  const productsListNotEmpty = productIds.length > 0;
  const hasValidBodyType = bodyType !== null;
  const showMassMailSubmission = submissionData || isLoading || isError;
  const showCompanySelect = bodyHasContent && productsListNotEmpty
    && hasValidBodyType && subjectHasContent && !showMassMailSubmission;
  const formFilled = bodyHasContent && subjectHasContent
    && companiesListNotEmpty && productsListNotEmpty && hasValidBodyType;
  const clearInputsDisabled = !(
    bodyHasContent || subjectHasContent || companiesListNotEmpty
    || productsListNotEmpty || hasValidBodyType
  );
  const previewButtonDisabled = !(bodyHasContent && hasValidBodyType);
  const submissionDisabled = !(formFilled);

  useHandleRequestErrors(submissionError as ResponseError);

  useEffect(() => {
    if (submissionData) {
      const { title, message, status } = submissionData;
      if (status) {
        setAlerts([{ title, message, variant: 'success' }]);
      } else {
        setAlerts([{ title, message, variant: 'danger' }]);
      }
    }
  }, [submissionData]);

  const updateFile = () => {
    if (fileRef.current?.files?.length) {
      setFiles(Array.from(fileRef.current.files));
    } else {
      setFiles([]);
    }
  };

  const massMailPreviewParams = {
    companyIds,
    subject,
    bodyType: bodyType || 'nenhum',
    body,
    isTest,
    productIds,
    files: [],
  };

  const clearInputs = () => {
    setBody('');
    setSubject('');
    setCompanyIds([]);
    setProductIds([]);
    setIsTest(false);
    setBodyType(null);
    if (fileRef.current) {
      fileRef.current.value = '';
      updateFile();
    }
  };

  const submitMassMail = () => {
    if (!bodyType) {
      return;
    }

    let counter = files.length;

    if (counter === 0) {
      massMailSendingTrigger({
        body,
        bodyType,
        companyIds,
        productIds,
        subject,
        files: [],
        isTest,
      });

      return;
    }

    const filesArray: MassMailSendingFile[] = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        filesArray.push({
          fileName: file.name,
          base64File: reader.result as string,
        });
        counter -= 1;
        if (counter < 1) {
          massMailSendingTrigger({
            body,
            bodyType,
            companyIds,
            productIds,
            subject,
            files: filesArray,
            isTest,
          });
        }
      };
    });
  };

  const {
    trigger: delayedSubmitMassMail,
    cancel: cancelSubmitMassMail,
    canCancel: canCancelSubmitMassMail,
  } = useDelayAction(submitMassMail);

  return {
    files,
    alerts,
    updateFile,
    body,
    setBody,
    bodyType,
    setBodyType,
    showPreview,
    setShowPreview,
    isTest,
    setIsTest,
    subject,
    setSubject,
    companyIds,
    setCompanyIds,
    showCompanySelect,
    productIds,
    setProductIds,
    fileRef,
    previewButtonDisabled,
    submissionDisabled,
    clearInputsDisabled,
    clearInputs,
    massMailPreviewParams,
    isLoading,
    submitMassMail: delayedSubmitMassMail,
    canCancelSubmitMassMail,
    cancelSubmitMassMail,
    companies: massMailClientList,
  };
}
