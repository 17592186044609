import { useSelector } from 'react-redux';
import {
  ProcessingStatus,
  SubmissionReport,
  SubmissionStatus,
  selectProcessingState,
  selectSubmissionReport,
} from 'redux/reducers/nfeProcessingReducer';

export const submissionProgressPercentage = (submissionReports: SubmissionReport[]) => {
  if (submissionReports.length === 0) {
    return 0;
  }

  const completedSubmissionReports = submissionReports.filter((report) => (
    report.submission.status === SubmissionStatus.CANCELED
    || report.submission.status === SubmissionStatus.DONE
    || report.submission.status === SubmissionStatus.FAILED
  ));
  return 100 * (completedSubmissionReports.length / submissionReports.length);
};

export default function useSubmissionStatus() {
  const isProcessing = useSelector(selectProcessingState) === ProcessingStatus.PROCESSING;
  const submissionReport = useSelector(selectSubmissionReport);
  const progress = submissionProgressPercentage(submissionReport);

  return { isProcessing, progress };
}
