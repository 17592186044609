import React from 'react';
import FluxAutomaticWrapper from 'components/RouteWrappers/Flux/Automatic';
import Automatic from 'views/Flux/Automatic';
import Monthly from 'views/Flux/Automatic/Monthly';
import Revision from 'views/Flux/Automatic/Revision';

const automaticRouter = {
  path: 'automatic',
  element: <FluxAutomaticWrapper />,
  children: [
    { path: '', element: <Automatic /> },
    { path: 'monthly', element: <Monthly /> },
    { path: 'revision', element: <Revision /> },
  ],
};

export default automaticRouter;
