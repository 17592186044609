import React from 'react';
import { ScrollRestoration, Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function EmptyLayout() {
  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col className="pb-2 px-5 body-outlet overflow-auto vh-100">
          <Outlet />
        </Col>

        <ScrollRestoration />
      </Row>
    </Container>
  );
}
