import filterUndefined from 'utils/filterUndefined';
import { ContractCsvParams } from './companies';

export interface CompanyModalFilters {
  [key: string]: number[] | Date | null | boolean
  selectedProducts: number[]
  contractEndsBefore: Date | null
  isActive: boolean
  isInactive: boolean
  isTest: boolean
  isExpiringIn30: boolean
  isExpiringIn60: boolean
  isMetereology: boolean
  isConsultancy: boolean
}

export const mockedCompanyFilters: CompanyModalFilters = {
  selectedProducts: [1, 2],
  contractEndsBefore: new Date(),
  isActive: true,
  isInactive: false,
  isTest: true,
  isExpiringIn30: true,
  isExpiringIn60: true,
  isMetereology: true,
  isConsultancy: false,
};

export const modalToQueryFilters = (modalFilters: CompanyModalFilters) => {
  const productIds = modalFilters.selectedProducts;

  return filterUndefined({
    productIds: productIds.length === 0 ? undefined : productIds,
    contractEndsBefore: modalFilters.contractEndsBefore?.toISOString().split('T')[0] || undefined,
    consulting: modalFilters.isConsultancy || (modalFilters.isMetereology ? false : undefined),
    enabled: modalFilters.isActive || (modalFilters.isInactive ? false : undefined),
    expiringIn30Days: modalFilters.isExpiringIn30 || undefined,
    expiringIn60Days: modalFilters.isExpiringIn60 || undefined,
    isTest: modalFilters.isTest || undefined,
  });
};

export const csvDownloadFilters = (
  modalFilters: CompanyModalFilters,
  nameContains: string,
): ContractCsvParams => {
  const companyListParams = modalToQueryFilters(modalFilters);

  return {
    name_contains: nameContains,
    product_ids: companyListParams.productIds,
    contract_ends_before: companyListParams.contractEndsBefore,
    consulting: companyListParams.consulting,
    enabled: companyListParams.enabled,
    expiring_in_30_days: companyListParams.expiringIn30Days,
    expiring_in_60_days: companyListParams.expiringIn60Days,
    is_test: companyListParams.isTest,
  };
};
