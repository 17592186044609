import { useEffect, useMemo, useState } from 'react';
import { AutomaticRevisionData, buildSubdivisionParam } from 'helper/flux/automatic';
import { useLazyGetRevisionDataQuery } from 'services/api/products/flux';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelMltOption,
  selectFluxAutomaticControlPanelPastSimulations,
  selectFluxAutomaticControlPanelSelectedRevision,
  selectFluxAutomaticControlPanelSpatialSubdivision,
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { ResponseError } from 'services/api/base';
import { RowData } from 'components/Flux/Revision/Table';
import { displayValue } from 'components/Flux/GeneralTable';
import useAcomphMLT from './useAcomphMLT';
import { useHandleRequestErrors } from '../../index';

export default function useRevisionData() {
  const [revisionData, setRevisionData] = useState<AutomaticRevisionData | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [trigger, { error }] = useLazyGetRevisionDataQuery();
  const spatialSubdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const models = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const { acomph, mlt } = useAcomphMLT();
  const pastSimulations = useSelector(selectFluxAutomaticControlPanelPastSimulations);
  const review = useSelector(selectFluxAutomaticControlPanelSelectedRevision);
  const revision = review ?? '';
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  useHandleRequestErrors(error as ResponseError);

  const triggerParameters = useMemo(() => ({
    data_acomph: 'latest',
    data_previsao: 'latest',
    versao: 'latest',
    ndiasprev: 15,
    lista_cenarios: models,
    incluir_acomph: acomph,
    incluir_mlt: mlt,
    pastSimulations,
    unidade,
    revision,
  }), [acomph, mlt, models, pastSimulations, revision, unidade]);

  useEffect(() => {
    if (spatialSubdivision !== undefined && models.length > 0 && revision.length > 0) {
      const spatialSubdivisionParam = buildSubdivisionParam(spatialSubdivision);

      (async () => {
        setIsFetching(true);
        const res = await trigger({
          spatialSubdivisionParam,
          ...triggerParameters,
        });
        setRevisionData(res?.data?.data);
        setIsFetching(false);
      })();
    } else {
      setRevisionData(undefined);
      setIsFetching(false);
    }
  }, [models, revision, spatialSubdivision, trigger, triggerParameters]);

  const expectedDataResponse = useMemo(() => {
    const colLabels = revisionData?.colLabels ?? [];
    const rowLabels = revisionData?.rowLabels ?? [];
    return {
      rowLabels,
      colLabels,
      values: (revisionData?.values || [[]])
        .map((rowValues) => rowValues
          .map(([value, colorName]) => ({
            value: displayValue(value, unidade === '%mlt'),
            colorName,
          }))),
    };
  }, [revisionData, unidade]);

  const revisionRows = useMemo(() => {
    const rows: RowData[] = [];
    expectedDataResponse.rowLabels.forEach((rowLabel, index) => {
      const values = expectedDataResponse.values[index];
      rows.push({
        label: rowLabel,
        values,
      });
    });

    return rows;
  }, [expectedDataResponse]);

  return {
    revisionData, isFetching, expectedDataResponse, revisionRows,
  };
}
