import React, { PropsWithChildren } from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import './filters.scss';

interface FilterModalProps {
  open: boolean,
  closeModal: () => void,
  onClearClick: () => void,
  onSaveClick: () => void,
}

export default function FilterModal({
  children,
  open,
  closeModal,
  onClearClick,
  onSaveClick,
}: PropsWithChildren<FilterModalProps>) {
  return (
    <Modal
      className="filter-modal"
      show={open}
      onHide={closeModal}
    >
      <Modal.Header className="filter-modal-header">
        <div className="d-flex flex-row flex-grow-1">
          <div className="flex-grow-1">
            Filtros
          </div>
          <div>
            <Button
              className="secondary-button mx-3"
              onClick={onClearClick}
            >
              Limpar
            </Button>
          </div>
          <div>
            <Button
              className="primary-button"
              onClick={onSaveClick}
            >
              Salvar
            </Button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}
