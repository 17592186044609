import { useMemo } from 'react';
import { useListProductsQuery } from 'services/api/products';

export default function useProductOptions(): {
  options: { value: number, label: string }[] | undefined,
  isLoading: boolean,
} {
  const {
    data,
    isLoading,
  } = useListProductsQuery();

  const options = useMemo(
    () => data?.map(
      (product) => ({ value: product.id, label: product.nome }),
    ),
    [data],
  );

  return {
    options,
    isLoading,
  };
}
