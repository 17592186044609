import React, { useEffect } from 'react';

import { Spinner } from 'react-bootstrap';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/meteorologyFieldsLayoutReducer';
import Instructions from 'components/Meteorology/Fields/Comparator/Instructions';
import Toolbar from 'components/Meteorology/Fields/Comparator/Toolbar';
import Display from 'components/Meteorology/Fields/Display';
import buildImageDisplayData from 'helper/products/comparator/buildImageDisplayData';
import useComparatorControlPanel from 'utils/hooks/meteorology/comparator/useComparatorControlPanel';
import useComparatorParams from 'utils/hooks/meteorology/comparator/useComparatorParams';
import useComparatorPeriods from 'utils/hooks/meteorology/comparator/useComparatorPeriods';
import useImages from 'utils/hooks/meteorology/comparator/useImages';
import useImagesDownload from 'utils/hooks/meteorology/comparator/useImagesDownload';
import useImageRowSize from 'utils/hooks/meteorology/useImageRowSize';
import AlertsCollection from 'components/AlertsCollection';

export default function Comparator() {
  const {
    baseModel,
    confrontationalModel,
    baseForecastDateDiff,
    confrontationalForecastDateDiff,
    baseRuntime,
    confrontationalRuntime,
    baseMember,
    confrontationalMember,
    period,
  } = useComparatorControlPanel();
  const fillableFields = [
    baseModel, confrontationalModel, baseForecastDateDiff, confrontationalForecastDateDiff,
    baseRuntime, confrontationalRuntime, baseMember, confrontationalMember, period,
  ];
  const showInstructions = fillableFields.every((v) => v === undefined);
  const comparatorParams = useComparatorParams();
  const {
    periods,
    isFetching: periodsFetching,
    errorAlerts: periodsErrors,
  } = useComparatorPeriods(comparatorParams);
  const {
    images,
    isLoading: imagesLoading,
    isFetching: imagesFetching,
    triggerIsActive,
    errorAlerts: imagesErrors,
  } = useImages(comparatorParams, periods);
  const errorAlerts = [...periodsErrors, ...imagesErrors];
  const {
    imageSets,
    columnLabels,
    rowLabels,
  } = buildImageDisplayData(images);
  const {
    onDownloadClick,
    downloadDisabled,
  } = useImagesDownload(images, !triggerIsActive);
  const { setRowSize } = useImageRowSize();

  useEffect(() => {
    setRowSize(3);
    store.dispatch(setCurrentTab('comparator'));
  }, [setRowSize]);

  const spinner = (
    <div className="pb-3 text-center">
      <Spinner />
    </div>
  );
  const display = (periodsFetching || imagesLoading) ? spinner : (
    <Display
      imageSets={imageSets}
      columnLabels={columnLabels}
      rowLabels={rowLabels}
      fetchingImages={false}
    />
  );

  return (
    <div>
      { showInstructions && <Instructions />}
      {!showInstructions && (
        <>
          <Toolbar onDownloadClick={onDownloadClick} downloadDisabled={downloadDisabled} />
          <div className="mt-3">
            <AlertsCollection alerts={errorAlerts} />
          </div>
          {display}
          {(!imagesLoading && imagesFetching) && spinner}
        </>
      )}
    </div>
  );
}
