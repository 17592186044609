import React from 'react';
import { Button } from 'react-bootstrap';
import useRevisionData from 'utils/hooks/flux/automatic/useRevisionData';
import './index.scss';

export interface RowData {
  label: string,
  values: {
    value: string,
    colorName: string,
  }[]
}

export function translateClosestDates(dateCode: string) {
  switch (dateCode) {
    case 'd-00':
      return 'hoje';
    case 'd-01':
      return 'ontem';
    default:
      return dateCode;
  }
}

export default function RevisionTable({ popDataModal }: {
  popDataModal: (chosenModel: string, chosenPeriod: string) => void
}) {
  const { expectedDataResponse, revisionRows, revisionData } = useRevisionData();
  const colLabels = revisionData?.colLabels ?? [];

  const colLabelStyle: React.CSSProperties = {
    color: '#26548A',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
  };

  const rowLabelStyle: React.CSSProperties = {
    color: '#598CBB',
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
  };

  const valueCellStyle: React.CSSProperties = {
    color: '#212F58',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
  };
  const cellStyle = (backgroundColor = 'white'): React.CSSProperties => ({
    textDecoration: 'none',
    color: backgroundColor === '#FF00007F' ? 'white' : '#212F58',
    backgroundColor,
  });

  return (
    <div style={{ maxHeight: '650px' }}>
      <table className="table sticky-table-col-row">
        <thead>
          <tr>
            <th>
              Simulações automáticas
            </th>
            {expectedDataResponse
              .colLabels
              .map((colLabel) => (
                <th
                  scope="col"
                  style={colLabelStyle}
                  key={`${colLabel}`}
                >
                  {translateClosestDates(colLabel)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {revisionRows.map((rowData) => (
            <tr key={rowData.label}>
              <th style={rowLabelStyle}>{rowData.label}</th>
              {rowData.values.map(({ value, colorName }, index) => (
                <td
                  style={valueCellStyle}
                  key={`${rowData.label}-${colLabels[index]}-${value}`}
                >
                  <Button variant="link" style={cellStyle(colorName)} onClick={() => popDataModal(rowData.label, colLabels[index])}>
                    {value || ''}
                  </Button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
