type Valuable<T> = { [K in keyof T as T[K] extends undefined ? never : K]: T[K] };

export default function filterUndefined<
  T extends object,
  V = Valuable<T>,
>(obj: T): V {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) => typeof v !== 'undefined',
    ),
  ) as V;
}
