import { useNavigate } from 'react-router-dom';
import { handleApiError } from 'helper/error';
import { ResponseError } from 'services/api/base';
import {
  useEnableCompanyMutation, useDisableCompanyMutation,
} from 'services/api/companies';

export default function useRequestToggle(requestParams: { companyId: number }): {
  requestCompanyActivityToggle: (companyActive: boolean) => Promise<boolean>,
} {
  const navigate = useNavigate();

  const [disableCompany] = useDisableCompanyMutation();
  const [enableCompany] = useEnableCompanyMutation();

  const requestCompanyActivityToggle = async (companyActive: boolean): Promise<boolean> => {
    const trigger = companyActive ? disableCompany : enableCompany;

    const response = await trigger(requestParams);

    if ('data' in response && response.data.status !== 1) {
      return false;
    }

    if ('error' in response) {
      handleApiError(navigate, response.error as ResponseError);
      return false;
    }

    return true;
  };

  return { requestCompanyActivityToggle };
}
