import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import { useSaveWarningMutation, useDeleteWarningMutation } from 'services/api/home';
import { ResponseError } from 'services/api/base';
import { useHandleRequestErrors } from 'utils/hooks';
import useConfirmationDialogBox from 'utils/useConfirmationDialogBox';
import WarningFormModal from './WarningFormModal';
import DeleteButton from './DeleteButton';

export default function Warnings({
  warnings,
  refetch,
}: {
  warnings: { id: number; titulo: string; mensagem: string }[];
  refetch: () => void;
}) {
  const [saveTrigger, { error: saveError }] = useSaveWarningMutation();
  const [deleteTrigger, { error: deleteError }] = useDeleteWarningMutation();
  const {
    confirmationDialogBox: confirmDeletionBox,
    setConfirmationDialogBox: setConfirmDeletionBox,
  } = useConfirmationDialogBox();
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [formHeader, setFormHeader] = useState('Novo aviso');
  const [formOpen, setFormOpen] = useState(false);

  useHandleRequestErrors(saveError as ResponseError);
  useHandleRequestErrors(deleteError as ResponseError);

  const openForm = (initialId = 0, initialTitle = '', initialMessage = '') => {
    setId(initialId);
    setTitle(initialTitle);
    setMessage(initialMessage);
    setFormHeader(initialId === 0 ? 'Novo aviso' : 'Editar aviso');
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };

  const saveWarning = async () => {
    try {
      await saveTrigger({ id, title, message });
    } finally {
      closeForm();
      refetch();
    }
  };

  const deleteWarning = async (warningId: number) => {
    try {
      await deleteTrigger({ id: warningId });
    } finally {
      refetch();
    }
  };

  return (
    <div className="pt-3">
      {userIsAdmin && (
        <div className="mb-3 mx-3 d-flex justify-content-end">
          <WarningFormModal
            key={id}
            header={formHeader}
            title={title}
            setTitle={setTitle}
            message={message}
            setMessage={setMessage}
            show={formOpen}
            dismiss={closeForm}
            saveWarning={saveWarning}
          />
          {confirmDeletionBox}
          <Button size="sm" onClick={() => openForm()}>Novo Aviso</Button>
        </div>
      )}
      { warnings.map(({ id: warningId, titulo, mensagem }) => (
        <div key={warningId} className="d-flex justify-content-between mb-3">
          <div className="flex-grow-1">
            <b>
              {titulo}
              :
            </b>
            {' '}
            {mensagem}
          </div>
          {userIsAdmin && (
            <Stack direction="horizontal" gap={2} className="align-self-start mx-3">
              <Button size="sm" onClick={() => openForm(warningId, titulo, mensagem)}>
                <FontAwesomeIcon icon={faPenToSquare as IconProp} />
              </Button>
              <DeleteButton
                warningTitle={titulo}
                onConfirm={() => deleteWarning(warningId)}
                setConfirmationDialogBox={setConfirmDeletionBox}
              />
            </Stack>
          )}
        </div>
      )) }
    </div>
  );
}
