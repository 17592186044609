import React from 'react';
import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './assets/bootstrap.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import rootRouter from './routes/RootRouter';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Espaço Exclusivo - Ampere Consultoria</title>
        <meta name="title" content="Espaço Exclusivo - Ampere Consultoria" />
        <meta name="description" content="Buscando fazer diferença no mercado de energia, a Ampere Consultoria oferece produtos de alta qualidade e inovadores, capazes de ampliar as vantagens estratégicas de nossos clientes." />
      </Helmet>

      <RouterProvider router={createBrowserRouter(rootRouter)} />
    </HelmetProvider>
  );
}

export default App;
