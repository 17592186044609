import { ResponseError } from 'services/api/base';
import { ContractCsvParams } from 'helper/companies';
import { ampereFileApi, prepareResponse } from './base';
import { GetZipFileParams } from '../../helper/products/flux';
import { store } from '../../redux/store';
import filterUndefined from '../../utils/filterUndefined';

// eslint-disable-next-line import/prefer-default-export
export async function downloadCompanyListCsvBlob(
  params: ContractCsvParams,
): Promise<Blob | ResponseError> {
  const response = await ampereFileApi().post('contratos-empresa-list-csv', params);

  return prepareResponse(response);
}

export async function downloadContractBlob(
  params: number,
): Promise<Blob | ResponseError> {
  const response = await ampereFileApi().get(`admin/contratos/download-file/?id_anexo=${params}`);

  return prepareResponse(response);
}

export async function downloadFaDailyZip({
  unidade, models, data_acomph, spatialSubdivisionParam, data_previsao, version, review, type,
}: GetZipFileParams) {
  const productKey = store.getState().productKeys['prevs-automatico'];
  const url = `produtos/previvaz-ena-diaria/get-zip/?product_key=${productKey}`;
  const response = await ampereFileApi().post(url, filterUndefined({
    type,
    unidade,
    data_acomph,
    data_previsao,
    versao: version,
    revisao: review,
    lista_cenarios: models,
    ...spatialSubdivisionParam,
  }));
  return prepareResponse(response);
}

export async function downloadFileViewerFile(fileId: string): Promise<Blob | ResponseError> {
  const productKey = store.getState().productKeys['file-viewer'];
  const url = `/produtos/file-viewer/get-file/?product_key=${productKey}&file-id=${fileId}`;
  const response = await ampereFileApi().get(url);

  return prepareResponse(response);
}
