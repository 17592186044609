import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FileListDirectory } from 'helper/products/fileViewer';

export default function Directory({
  dir,
  selectDir,
}: {
  dir: FileListDirectory,
  selectDir: (dir: FileListDirectory) => void,
}) {
  return (
    <Row onClick={() => selectDir(dir)} className="file-list-entry mx-0">
      <Col xs={1} className="text-center">
        <FontAwesomeIcon icon={faFolder as IconProp} />
      </Col>
      <Col>{dir.label}</Col>
    </Row>
  );
}
