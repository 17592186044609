import { NavigateFunction } from 'react-router';

import baseApi from 'services/api/base';
import { reset } from 'redux/reducers/authReducer';
import { store } from 'redux/store';

interface Alert {
  title: string;
  message: string;
  variant: string;
}

const logout = (navigate: NavigateFunction, alert: Alert) => {
  store.dispatch(reset());
  store.dispatch(baseApi.util.resetApiState());
  navigate('/signin', { state: { alerts: [alert] } });
};

export default logout;
