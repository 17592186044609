import React from 'react';
import { Product } from 'helper/products';
import ProductTag from 'components/Admin/Products/Tag';

export function includeIndexToProducts(productsList: Product[]) {
  return productsList.map((product) => {
    const activeString = product.available ? 'a' : 'i';
    return {
      ...product,
      index: `${activeString}-${product.endDateString}`,
    };
  });
}

export function sortProducts(productsList: Product[]) {
  return includeIndexToProducts(productsList)
    .sort((a, b) => a.index.localeCompare(b.index));
}

export default function ProductShortList({ products }: { products: Product[] }) {
  const sortedList = sortProducts(products);
  const tags = sortedList.slice(0, 4)
    .map((product) => (
      <ProductTag product={product} key={product.name} />
    ));
  const plus = products.length > 4 && <div className="outline-box">+</div>;
  return (
    <>
      {tags}
      {plus}
    </>
  );
}
