import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router';
import {
  selectTokenTimeOut,
  selectTokenTimeStamp,
  updateAuthState,
} from 'redux/reducers/authReducer';
import { isPast, addSeconds, differenceInMilliseconds } from 'date-fns';
import { useEffect } from 'react';

import { useRefreshTokenMutation } from 'services/api/auth';
import logout from 'utils/logout';

const INTERVAL_MILLIS = 3 * 60 * 1000; // 3 minutes
const REFRESH_THRESHOLD = INTERVAL_MILLIS * 3;

export default function useTokenExpirationCheck(navigate: NavigateFunction) {
  const dispatch = useDispatch();
  const jwtExpeditionTime = useSelector(selectTokenTimeStamp);
  const jwtExpirationTimeout = useSelector(selectTokenTimeOut);
  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    const check = async () => {
      const expirationDate = addSeconds(jwtExpeditionTime * 1000, jwtExpirationTimeout);
      const timeUntilExpiration = differenceInMilliseconds(expirationDate, new Date());

      const isTokenExpired = isPast(expirationDate);
      const isTokenCloseToExpiration = !isTokenExpired && timeUntilExpiration < REFRESH_THRESHOLD;

      if (isTokenExpired) {
        const alert = { title: '', message: 'Sua sessão expirou. Faça login novamente.', variant: 'warning' };
        logout(navigate, alert);
      }

      if (isTokenCloseToExpiration) {
        const res = await refreshToken();

        if ('data' in res) {
          const authData = res.data.data;
          dispatch(updateAuthState({
            jwt: authData.jwt,
            refreshToken: authData.refresh_token,
          }));
        }
      }
    };

    check();

    const intervalId = setInterval(check, INTERVAL_MILLIS);

    return () => { clearInterval(intervalId); };
  }, [jwtExpeditionTime, jwtExpirationTimeout, navigate, dispatch, refreshToken]);
}
