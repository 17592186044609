import React from 'react';
import '../Box/index.scss';
import { HomeTutorialLink } from 'helper/products/home';
import {
  Col, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';

export default function TutorialList({ tutorials }: { tutorials: HomeTutorialLink[] }) {
  return (
    <>
      {tutorials.map((tutorial) => (
        <Row key={tutorial.id}>
          <Col>
            <OverlayTrigger overlay={<Tooltip>Ir para produto</Tooltip>}>
              <Link to={`/${tutorial.link}`} className="text-decoration-none text-dark">
                {tutorial.label}
              </Link>
            </OverlayTrigger>
          </Col>
          {tutorial.tutorial_video !== null && (
            <Col sm={2}>
              <OverlayTrigger overlay={<Tooltip>Tutorial</Tooltip>}>
                <Link to={`/${tutorial.tutorial_video}`} className="text-decoration-none text-secondary">
                  <FontAwesomeIcon icon={faCirclePlay} />
                </Link>
              </OverlayTrigger>
            </Col>
          )}
        </Row>
      ))}
    </>
  );
}
