import React from 'react';

import { Link } from 'react-router-dom';

export default function ErrorPage() {
  // To get the route error, you may do the following:
  // import { useRouteError } from "react-router-dom";
  // const error = useRouteError();

  return (
    <div>
      <h1>Oops!</h1>
      <p>Alguma coisa deu errado</p>
      <Link to="/home" className="text-dark">
        Voltar para a home
      </Link>
    </div>
  );
}
