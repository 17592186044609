import React, { useEffect, useState } from 'react';

import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/meteorologyFieldsLayoutReducer';
import Instructions from 'components/Meteorology/Fields/NumericForecast/Instructions';
import Toolbar from 'components/Meteorology/Fields/NumericForecast/Toolbar';
import Display from 'components/Meteorology/Fields/Display';
import ZoomButtons from 'components/Meteorology/Fields/ZoomButtons';
import { ResponseError } from 'services/api/base';
import { useSelector } from 'react-redux';
import {
  selectMFControlPanelRegion,
  selectMFControlPanelModels,
  selectMFControlPanelForecastUnixDate,
  selectMFControlPanelPeriod,
} from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import buildImageDisplayData from 'helper/products/numericForecast/buildImageDisplayData';
import useImagesDownload from 'utils/hooks/meteorology/numericForecast/useImagesDownload';
import { useHandleRequestErrors } from 'utils/hooks';
import useImages from 'utils/hooks/meteorology/numericForecast/useImages';
import useImageRowSize from 'utils/hooks/meteorology/useImageRowSize';

export default function NumericForecast() {
  const [multiMapMode, setMultiMapMode] = useState(false);
  const {
    rowSize,
    maxRowSize,
  } = useImageRowSize();

  const forecastUnixDate = useSelector(selectMFControlPanelForecastUnixDate);
  const models = useSelector(selectMFControlPanelModels);
  const region = useSelector(selectMFControlPanelRegion);
  const period = useSelector(selectMFControlPanelPeriod);

  const showInstructions = !forecastUnixDate && !region && !period && models.length === 0;

  const {
    images, isFetching, error, areAllImagesFetched,
  } = useImages(multiMapMode);

  const {
    imageSets,
    columnLabels,
    rowLabels,
  } = buildImageDisplayData(images, rowSize, multiMapMode);
  const {
    onDownloadClick,
    downloadDisabled,
  } = useImagesDownload(images, areAllImagesFetched);

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    store.dispatch(setCurrentTab('numericforecast'));
  }, []);

  return (
    <div>
      <Toolbar
        showInstructions={showInstructions}
        multiMapMode={multiMapMode}
        setMultiMapMode={setMultiMapMode}
        onDownloadClick={onDownloadClick}
        downloadDisabled={downloadDisabled}
      />
      {showInstructions && <Instructions />}
      {!showInstructions && (
        <>
          <Display
            imageSets={imageSets}
            columnLabels={columnLabels}
            rowLabels={rowLabels}
            fetchingImages={isFetching}
            fullSweep={!multiMapMode}
          />
          {maxRowSize > 1 && (
            <ZoomButtons
              imagesQuantity={maxRowSize}
            />
          )}
        </>
      )}
    </div>
  );
}
