import React from 'react';
import { Alert } from 'react-bootstrap';
import './index.scss';

export interface CustomAlert {
  title: string,
  message: string,
  variant: string,
}

export default function AlertsCollection({ alerts }: { alerts: CustomAlert[] }) {
  return (
    <>
      {alerts.map(({ title, message, variant }) => (
        <Alert key={message} variant={variant} className="mx-5 display-linebreak">
          {title && <Alert.Heading>{title}</Alert.Heading>}
          {message}
        </Alert>
      ))}
    </>
  );
}
