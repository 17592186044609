import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FluxPersonalizedFormReducer {
  showInstructions: boolean,
  name: string,
  startDate: number | null,
  endDate: number | null,
  outputOptions: string[],
  multiScenarios: string[],
  groupNumber: number | undefined,
  previvazModel: string | undefined,
  showSuccessModal: boolean,
  percentileValuesText: string,
}

const initialState: FluxPersonalizedFormReducer = {
  showInstructions: true,
  name: '',
  startDate: null,
  endDate: null,
  outputOptions: [
    'Produzir mapas de preciptação',
    'Produzir arquivo excel PMED',
    'Produzir arquivos DESSEM',
    'Produzir arquivo.csv de ENA diária',
    'Produzir memória de cálculo ENA semanal',
    'Produzir arquivos PREVS.RVX mensais',
    'Produzir arquivos VAZPAST.DAT',
  ],
  multiScenarios: [],
  groupNumber: undefined,
  previvazModel: 'Produzir todas as revisões',
  showSuccessModal: false,
  percentileValuesText: '',
};

export const fluxPersonalizedFormSlice = createSlice({
  name: 'fluxPersonalizedForm',
  initialState,
  reducers: {
    setShowInstructions: (state, action: PayloadAction<boolean>) => {
      state.showInstructions = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setStartDate: (state, action: PayloadAction<number>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<number>) => {
      state.endDate = action.payload;
    },
    setOutputOptions: (state, action: PayloadAction<string[]>) => {
      state.outputOptions = action.payload;
    },
    setMultiScenarios: (state, action: PayloadAction<string[]>) => {
      state.multiScenarios = action.payload;
    },
    setPrevivazModel: (state, action: PayloadAction<string>) => {
      state.previvazModel = action.payload;
    },
    setShowSuccessModal: (state, action: PayloadAction<boolean>) => {
      state.showSuccessModal = action.payload;
    },
    setGroupNumber: (state, action: PayloadAction<number | undefined>) => {
      state.groupNumber = action.payload;
    },
    setPercentileValues: (state, action: PayloadAction<string>) => {
      state.percentileValuesText = action.payload;
    },
    reset: (state) => {
      state.showInstructions = true;
      state.name = '';
      state.startDate = null;
      state.endDate = null;
      state.outputOptions = [
        'Produzir mapas de preciptação',
        'Produzir arquivo excel PMED',
        'Produzir arquivos DESSEM',
        'Produzir arquivo.csv de ENA diária',
        'Produzir memória de cálculo ENA semanal',
        'Produzir arquivos PREVS.RVX mensais',
        'Produzir arquivos VAZPAST.DAT',
      ];
      state.multiScenarios = [];
      state.groupNumber = undefined;
      state.previvazModel = 'Produzir todas as revisões';
      state.percentileValuesText = '';
    },
  },
});

// eslint-disable-next-line max-len
export const selectFluxPersonalizedFormShowInstructions = (state: any) => state.fluxPersonalizedForm.showInstructions;
export const selectFluxPersonalizedFormName = (state: any) => state.fluxPersonalizedForm.name;
// eslint-disable-next-line max-len
export const selectFluxPersonalizedFormStartDate = (state: any) => state.fluxPersonalizedForm.startDate;
export const selectFluxPersonalizedFormEndDate = (state: any) => state.fluxPersonalizedForm.endDate;
// eslint-disable-next-line max-len
export const selectFluxPersonalizedFormOutputOptions = (state: any) => state.fluxPersonalizedForm.outputOptions;
export const selectFluxPersonalizedFormMultiScenarios = (state: any) => state
  . fluxPersonalizedForm
  . multiScenarios;
export const selectFluxPersonalizedFormGroupNumber = (state: any): number | undefined => state
  . fluxPersonalizedForm
  . groupNumber;
// eslint-disable-next-line max-len
export const selectFluxPersonalizedFormPrevivazModel = (state: any) => state.fluxPersonalizedForm.previvazModel;
// eslint-disable-next-line max-len
export const selectFluxPersonalizedFormShowSuccessModal = (state: any) => state.fluxPersonalizedForm.showSuccessModal;
export const selectFluxPersonalizedFormPercentileValues = (state: any): string => state
  .fluxPersonalizedForm
  .percentileValuesText;

export const {
  setShowInstructions,
  setName,
  setStartDate,
  setEndDate,
  setOutputOptions,
  setMultiScenarios,
  setGroupNumber,
  setPrevivazModel,
  setShowSuccessModal,
  setPercentileValues,
  reset,
} = fluxPersonalizedFormSlice.actions;

export default fluxPersonalizedFormSlice.reducer;
