import useImageRowSize from '../useImageRowSize';

export default function useImageZoom(
  imageQuantity: number,
) {
  const { increaseZoom, decreaseZoom, rowSize } = useImageRowSize();

  const minusDisabled = rowSize === imageQuantity;
  const plusDisabled = rowSize === 1;
  const onMinusClicked = decreaseZoom;
  const onPlusClicked = increaseZoom;

  return {
    minusDisabled,
    plusDisabled,
    onMinusClicked,
    onPlusClicked,
  };
}
