import React, { useState } from 'react';
import {
  Button,
  Form,
  Modal,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FileListDirectory, FileListEntry } from 'helper/products/fileViewer';
import useDirectoryCreation from 'utils/hooks/file_viewer/useDirectoryCreation';

export default function AddDirectoryButton({
  path,
  entries,
}: {
  path: FileListDirectory[],
  entries: FileListEntry[],
}) {
  const [trigger, { isLoading }] = useDirectoryCreation();
  const [name, setName] = useState('');
  const [show, setShow] = useState(false);
  const existingDirNames = entries
    .filter(({ type }) => type === 'D')
    .map(({ label }) => label);
  const isNameValid = name.length > 0;
  const isNameInvalid = existingDirNames.includes(name);
  const isSaveDisabled = !isNameValid || isNameInvalid;

  const addDirectory = async () => {
    const basePath = path
      .map(({ label }) => label)
      .join('/');
    const formattedPath = `${basePath}/${name}/`;
    trigger({ formattedPath });
    setName('');
    setShow(false);
  };

  return (
    <div className="mx-4">
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adicionar Diretório</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nome do diretório</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              isValid={isNameValid}
              isInvalid={isNameInvalid}
            />
            <Form.Control.Feedback type="invalid">
              Nome não está disponível
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={addDirectory} disabled={isSaveDisabled}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack direction="horizontal" gap={2}>
        <Button onClick={() => setShow(true)} className="px-2 py-1" disabled={isLoading}>
          {!isLoading && <FontAwesomeIcon icon={faPlus as IconProp} />}
          {isLoading && <Spinner size="sm" />}
        </Button>
        <div>Novo Diretório</div>
      </Stack>
    </div>
  );
}
