import React from 'react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FilterDatalistProps {
  datalistOptions: string[],
  categoryName: string,
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export default function FilterDatalist({
  datalistOptions,
  categoryName,
  value,
  onChange,
}: FilterDatalistProps) {
  const datalistId = `${categoryName}-datalist-options`;

  return (
    <div className="form-group search-bar">
      <span className="search-icon">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </span>
      <input
        className="form-control"
        placeholder="Pesquisar"
        list={datalistId}
        value={value}
        onChange={onChange}
      />
      <datalist id={datalistId}>
        {datalistOptions.map((option) => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <option key={option} value={option} />
        ))}
      </datalist>
    </div>
  );
}
