import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMFComparatorControlPanelBaseModel,
  setBaseModel as setBaseModelRedux,
  setConfrontationalModel as setConfrontationalModelRedux,
  selectMFComparatorControlPanelBaseForecastDateDiff,
  setBaseForecastDateDiff as setBaseForecastDateDiffRedux,
  setConfrontationalForecastDateDiff as setConfrontationalForecastDateDiffRedux,
  selectMFComparatorControlPanelBaseRuntime,
  selectMFComparatorControlPanelConfrontationalRuntime,
  setBaseRuntime as setBaseRuntimeRedux,
  setConfrontationalRuntime as setConfrontationalRuntimeRedux,
  selectMFComparatorControlPanelBaseMember,
  selectMFComparatorControlPanelConfrontationalMember,
  setBaseMember as setBaseMemberRedux,
  setConfrontationalMember as setConfrontationalMemberRedux,
  selectMFComparatorControlPanelBaseRMV,
  setBaseRMV as setBaseRMVRedux,
  reset,
  selectMFComparatorControlPanelConfrontationalModel,
  selectMFComparatorControlPanelConfrontationalForecastDateDiff,
  selectMFComparatorControlPanelConfrontationalRMV,
  setConfrontationalRMV as setConfrontationalRMVRedux,
  selectMFComparatorControlPanelPeriod,
  setPeriod,
  setTitle as setTitleRedux,
  PeriodOptions,
} from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';

export default function useNumericForecastControlPanel() {
  const baseModel = useSelector(selectMFComparatorControlPanelBaseModel);
  const confrontationalModel = useSelector(selectMFComparatorControlPanelConfrontationalModel);
  const period = useSelector(selectMFComparatorControlPanelPeriod);

  const setBaseModel = (value: string) => {
    store.dispatch(setBaseModelRedux(value));
  };

  const setConfrontationalModel = (value: string) => {
    store.dispatch(setConfrontationalModelRedux(value));
  };

  const setComparativePeriod = (value: PeriodOptions) => {
    store.dispatch(setPeriod(value));
  };

  const setBaseForecastDateDiff = (value: string | undefined) => {
    store.dispatch(setBaseForecastDateDiffRedux(value));
  };

  const setConfrontationalForecastDateDiff = (value: string | undefined) => {
    store.dispatch(setConfrontationalForecastDateDiffRedux(value));
  };

  const baseForecastDateDiff = useSelector(selectMFComparatorControlPanelBaseForecastDateDiff);
  const confrontationalForecastDateDiff = useSelector(
    selectMFComparatorControlPanelConfrontationalForecastDateDiff,
  );

  const baseRuntime = useSelector(selectMFComparatorControlPanelBaseRuntime);
  const confrontationalRuntime = useSelector(selectMFComparatorControlPanelConfrontationalRuntime);

  const setBaseRuntime = (value: number | undefined) => {
    store.dispatch(setBaseRuntimeRedux(value));
  };

  const setConfrontationalRuntime = (value: number | undefined) => {
    store.dispatch(setConfrontationalRuntimeRedux(value));
  };

  const baseMember = useSelector(selectMFComparatorControlPanelBaseMember);
  const confrontationalMember = useSelector(selectMFComparatorControlPanelConfrontationalMember);

  const setBaseMember = (value: string | undefined) => {
    store.dispatch(setBaseMemberRedux(value));
  };

  const setConfrontationalMember = (value: string | undefined) => {
    store.dispatch(setConfrontationalMemberRedux(value));
  };

  const baseRMV = useSelector(selectMFComparatorControlPanelBaseRMV);
  const confrontationalRMV = useSelector(selectMFComparatorControlPanelConfrontationalRMV);

  const setBaseRMV = (value: boolean) => {
    store.dispatch(setBaseRMVRedux(value));
  };

  const setConfrontationalRMV = (value: boolean) => {
    store.dispatch(setConfrontationalRMVRedux(value));
  };

  const setTitle = (title: string, subtitle: string) => {
    store.dispatch(setTitleRedux([title, subtitle]));
  };

  const resetPreferences = () => {
    store.dispatch(reset());
  };

  return {
    baseModel,
    setBaseModel,
    confrontationalModel,
    setConfrontationalModel,
    setBaseForecastDateDiff,
    baseForecastDateDiff,
    setConfrontationalForecastDateDiff,
    confrontationalForecastDateDiff,
    baseRuntime,
    setBaseRuntime,
    confrontationalRuntime,
    setConfrontationalRuntime,
    baseMember,
    setBaseMember,
    confrontationalMember,
    setConfrontationalMember,
    setBaseRMV,
    baseRMV,
    setConfrontationalRMV,
    confrontationalRMV,
    resetPreferences,
    period,
    setTitle,
    setComparativePeriod,
  };
}
