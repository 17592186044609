import React, { useRef, useState } from 'react';
import { Dropdown, Overlay } from 'react-bootstrap';

import { Monitoring } from 'helper/products/synopticCharts';
import ChartSelector from './ChartSelector';

import './index.scss';

export default function MonitoringOption({ monitoring }: { monitoring: Monitoring }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <Dropdown.Item ref={target} onClick={() => setShow(!show)} className="monitoring-option-container">
      <div className="monitoring-option px-1 py-2">
        {monitoring.label}
      </div>
      {show && (
        <Overlay
          target={target.current}
          show
          rootClose
          onHide={() => setShow(false)}
          placement="right-start"
        >
          <div>
            <ChartSelector charts={monitoring.charts} />
          </div>
        </Overlay>
      )}
    </Dropdown.Item>
  );
}
