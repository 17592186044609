import { downloadZip } from 'helper/downloadFile';
import { NumericForecastImage } from 'helper/products/meteorology';

export default function useImagesDownload(
  data: NumericForecastImage[] | undefined,
  areAllImagesFetched: boolean,
) {
  const downloadDisabled = !areAllImagesFetched || !data?.length;
  const onDownloadClick = () => {
    if (!downloadDisabled) {
      const files = data.map((image) => ({
        filename: `${image.region}_${image.period}_${image.model}.png`,
        content: image.base64,
      }));
      const date = new Date();
      const zipFilename = `pnt_${date.toLocaleString('pt-BR').replace(/\/|:|,|\s/g, '_')}.zip`;
      downloadZip(files, zipFilename);
    }
  };

  return {
    onDownloadClick,
    downloadDisabled,
  };
}
