import React from 'react';
import ReactECharts from 'echarts-for-react';
import useParseHistoryCategories from 'hooks/Meteorology/Weather/Area/History/useParseHistoryCategories';

export default function HistoryGraph({
  categoryData,
  valueData,
}: {
  categoryData: string[],
  valueData: number[],
}) {
  const { categories } = useParseHistoryCategories(categoryData);

  return (
    <div>
      <ReactECharts
        option={{
          xAxis: {
            type: 'time',
            splitNumber: 10,
            axisLine: {
              onZero: false,
            },
          },
          yAxis: {
            type: 'value',
          },
          series: [{
            data: categories.map((date, index) => ({
              name: date,
              value: [date, valueData[index]],
            })),
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {},
          }],
          tooltip: {
            trigger: 'axis',
          },
          visualMap: {
            show: false,
            min: -0.0001,
            max: 0.0001,
            dimension: 1,
            inRange: {
              color: ['blue', 'red'],
            },
          },
          dataZoom: [
            {
              type: 'slider',
              show: 'true',
              xAxisIndex: [0],
              start: 0,
              end: 100,
              borderRadius: 32,
              borderColor: '#c1c1c1',
              height: 9,
              fillerColor: '#26548a',
              showDetail: false,
              showDataShadow: false,
              handleIcon: 'path://m50,50 a100,100 0 1,0 -200,0 a 100,100 0 1,0 200,0 Z',
              handleStyle: {
                borderColor: '#26548a',
                borderWidth: 2,
              },
              moveHandleStyle: {
                opacity: 0,
              },
              brushSelect: false,
              brushStyle: {
                opacity: 0,
              },
              dataBackground: {
                lineStyle: {
                  opacity: 0,
                },
                areaStyle: {
                  opacity: 0,
                },
              },
            },
          ],
        }}
      />
    </div>
  );
}
