import React from 'react';
import { useLocation } from 'react-router';

import { PermissionTypes, permissionProductMap } from 'helper/products';

export default function NoAccessPage() {
  const { search } = useLocation();
  const permission = new URLSearchParams(search).get('product') as PermissionTypes | null;
  const product = permission && permissionProductMap[permission];

  return (
    <div>
      <h1>Oops!</h1>
      {product ? (
        <p>
          Você não tem acesso a este produto:
          <span className="ms-1 fw-bold">{product}</span>
        </p>
      ) : (
        <p>Você não tem acesso a esta página.</p>
      )}
    </div>
  );
}
