import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import CounterBox from './CounterBox';
import { CompaniesCount, useCompaniesCountQuery } from '../../../../services/api/companies';
import { CompanyModalFilters, modalToQueryFilters } from '../../../../helper/companyFilters';

interface CountersRowProps {
  searchQuery: string,
  filters: CompanyModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<CompanyModalFilters>>,
}

export default function CountersRow({ searchQuery, filters, setFilters }: CountersRowProps) {
  const {
    data: companiesCountData,
    isLoading: companiesCountLoading,
    refetch,
  } = useCompaniesCountQuery({
    ...modalToQueryFilters(filters),
    nameContains: searchQuery,
  });
  const boxTypes = [
    'active', 'test', '30_days_to_due_date', '60_days_to_due_date', 'meteorologia', 'consulting',
  ];

  useEffect(() => {
    refetch();
  }, [filters, refetch, searchQuery]);

  return (
    <Container>
      <div className="d-flex flex-row flex-wrap justify-content-around">
        { boxTypes.map(
          (key) => (
            <CounterBox
              type={key as keyof CompaniesCount}
              isLoading={companiesCountLoading}
              data={companiesCountData?.data}
              setFilters={setFilters}
              filters={filters}
              key={key}
            />
          ),
        ) }
      </div>
    </Container>
  );
}
