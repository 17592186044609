import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

import { FileListDirectory } from 'helper/products/fileViewer';

export default function Breadcrums({
  path,
  goBack,
}: {
  path: FileListDirectory[],
  goBack: (level: number) => void,
}) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => goBack(0)} active={path.length === 0}>
        ROOT
      </Breadcrumb.Item>
      {path.map((dir, i) => (
        <Breadcrumb.Item key={dir.id} onClick={() => goBack(i + 1)} active={path.length - 1 === i}>
          {dir.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
