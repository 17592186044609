import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import { getUnixTime } from 'date-fns';
import {
  setName as setNameRedux,
  setStartDate as setStartDateRedux,
  setEndDate as setEndDateRedux,
  setOutputOptions as setOutputOptionsRedux,
  setMultiScenarios as setMultiScenariosRedux,
  setPrevivazModel as setPrevivazModelRedux,
  setGroupNumber as setGroupNumberRedux,
  setPercentileValues as setPercentileValuesRedux,
  selectFluxPersonalizedFormEndDate,
  selectFluxPersonalizedFormMultiScenarios,
  selectFluxPersonalizedFormGroupNumber,
  selectFluxPersonalizedFormName,
  selectFluxPersonalizedFormOutputOptions,
  selectFluxPersonalizedFormPrevivazModel,
  selectFluxPersonalizedFormStartDate,
  reset as resetRedux,
  setShowInstructions,
  selectFluxPersonalizedFormShowInstructions,
  selectFluxPersonalizedFormPercentileValues,
} from 'redux/reducers/fluxPersonalizedFormReducer';
import { useEffect } from 'react';

export default function useNewStudyForm() {
  const showInstructions = useSelector(selectFluxPersonalizedFormShowInstructions);
  const name = useSelector(selectFluxPersonalizedFormName);
  const startDate = useSelector(selectFluxPersonalizedFormStartDate);
  const endDate = useSelector(selectFluxPersonalizedFormEndDate);
  const outputOptions = useSelector(selectFluxPersonalizedFormOutputOptions);
  const multiScenarios = useSelector(selectFluxPersonalizedFormMultiScenarios);
  const previvazModel = useSelector(selectFluxPersonalizedFormPrevivazModel);
  const groupNumber = useSelector(selectFluxPersonalizedFormGroupNumber);
  const addScenarioDisabled = name === '' || startDate === null || endDate === null;
  const flowPercentileValue = useSelector(selectFluxPersonalizedFormPercentileValues);

  useEffect(() => {
    if (!multiScenarios.includes('Predefinir número de agrupamentos')) {
      setGroupNumberRedux(undefined);
    }
  }, [multiScenarios]);

  const setName = (value: string) => {
    store.dispatch(setNameRedux(value));
  };

  const setStartDate = (value: Date | null) => {
    let unixValue;

    if (value !== null) {
      unixValue = getUnixTime(value);
    } else {
      unixValue = value;
    }

    store.dispatch(setStartDateRedux(unixValue));
  };

  const setEndDate = (value: Date | null) => {
    let unixValue;

    if (value !== null) {
      unixValue = getUnixTime(value);
    } else {
      unixValue = value;
    }

    store.dispatch(setEndDateRedux(unixValue));
  };

  const setOutputOptions = (value: string[]) => {
    store.dispatch(setOutputOptionsRedux(value));
  };

  const setMultiScenarios = (value: string[]) => {
    store.dispatch(setMultiScenariosRedux(value));
  };

  const setGroupNumber = (value: number) => {
    store.dispatch(setGroupNumberRedux(value));
  };

  const toggleMultiScenario = (value: string) => {
    let newScenarios: string[];
    if (multiScenarios.includes(value)) {
      const index = multiScenarios.indexOf(value);
      newScenarios = multiScenarios.toSpliced(index, 1);
    } else {
      newScenarios = multiScenarios.concat([value]);
    }
    store.dispatch(setMultiScenariosRedux(newScenarios));
  };

  const toggleOutputOption = (value: string) => {
    let newOptions: string[];
    if (outputOptions.includes(value)) {
      const index = outputOptions.indexOf(value);
      newOptions = outputOptions.toSpliced(index, 1);
    } else {
      newOptions = outputOptions.concat([value]);
    }
    store.dispatch(setOutputOptionsRedux(newOptions));
  };

  const toggleMultiScenarioVersusOption = (value: string) => {
    const versusOptions = [
      'SE+NE versus S+N',
      'SE+S versus NE+N',
      'SE+N versus S+NE',
      'SE versus S+NE+N',
      'SE vs S vs NE vs N'];
    if (!versusOptions.includes(value)) {
      return;
    }
    const newScenarios: string[] = [];
    multiScenarios.forEach((label: string) => {
      if (!versusOptions.includes(label)) {
        newScenarios.push(label);
      }
    });
    newScenarios.push(value);
    setMultiScenarios(newScenarios);
  };

  const setPrevivazModel = (value: string) => {
    store.dispatch(setPrevivazModelRedux(value));
  };

  const setPercentileValues = (value: string) => {
    store.dispatch(setPercentileValuesRedux(value));
  };

  const onAddScenarioClick = () => {
    store.dispatch(setShowInstructions(false));
  };

  const onCancelClick = () => {
    store.dispatch(resetRedux());
    store.dispatch(setShowInstructions(true));
  };

  return {
    name,
    setName,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    outputOptions,
    setOutputOptions,
    toggleOutputOption,
    multiScenarios,
    setMultiScenarios,
    toggleMultiScenario,
    toggleMultiScenarioVersusOption,
    groupNumber,
    setGroupNumber,
    previvazModel,
    setPrevivazModel,
    addScenarioDisabled,
    onAddScenarioClick,
    onCancelClick,
    horizonsDisabled: !showInstructions,
    flowPercentileValue,
    setPercentileValues,
  };
}
