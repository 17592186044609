import filterUndefined from 'utils/filterUndefined';
import {
  Contract,
  RawContract,
  ReadjustmentRateOptions,
  StatusOptions,
  transformGetContractResponse,
  transformGetReadjustmentRatesResponse,
  transformGetStatusOptionsResponse,
} from 'helper/contracts';
import baseApi, { GenericResponse } from './base';
import { ContractParams } from './companies';

export type SaveContractResponse = GenericResponse<RawContract>;

export interface SaveContractProductParams {
  id: number | null
  contractId: number | null
  productId: number
  enabled: boolean
  startDate: number
  endDate: number
}

export interface SaveContractParams {
  id: number
  partyId: number
  counterpartyId: number
  startDate: number
  endDate: number
  statusId: number
  products: SaveContractProductParams[]
  correctionEnabled: boolean
  indexId?: number
  baseDate?: number
  correctionDate?: number
  billingDescription: string
  dueDateDescription: string
  valueCents: number
}

export interface SendAttachmentParams {
  userId: number
  companyId: number
  contractId: number
  file: Blob
}

export const productParamsToRequest = (params: SaveContractProductParams) => ({
  id: params.id,
  id_contrato: params.contractId,
  id_produto: params.productId,
  ck_ativo: params.enabled,
  dt_inicio: params.startDate,
  dt_fim: params.endDate,
});

export const contractsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStatusOptions: build.query<StatusOptions, void>({
      query: () => 'admin/contratos/status-options',
      transformResponse: transformGetStatusOptionsResponse,
    }),
    getReadjustmentRates: build.query<ReadjustmentRateOptions, void>({
      query: () => 'admin/contratos/indexadores-labels',
      transformResponse: transformGetReadjustmentRatesResponse,
    }),
    saveContract: build.mutation<SaveContractResponse, SaveContractParams>({
      query: (params: SaveContractParams) => ({
        url: 'admin/contratos/save/',
        method: 'PUT',
        body: filterUndefined({
          id: params.id,
          id_parte: params.partyId,
          id_contraparte: params.counterpartyId,
          dt_inicio: params.startDate,
          dt_fim: params.endDate,
          id_status: params.statusId,
          produtos: params.products.map(productParamsToRequest),
          ck_correcao: params.correctionEnabled,
          id_indexador: params.indexId,
          dt_database: params.baseDate,
          dt_correcao: params.correctionDate,
          ds_faturamento: params.billingDescription,
          ds_vencimento: params.dueDateDescription,
          valor: params.valueCents,
        }),
      }),
      invalidatesTags: ['Contract'],
    }),
    getContract: build.query<Contract, ContractParams>({
      query: ({ contractId }) => `admin/contratos/get-by-id/?contrato_id=${contractId}`,
      providesTags: ['Company', 'Contract'],
      transformResponse: transformGetContractResponse,
    }),
    sendContractAttachment: build.mutation<GenericResponse<null>, SendAttachmentParams>({
      query: (params) => {
        const formData = new FormData();
        formData.append('id_usuario', String(params.userId));
        formData.append('id_empresa', String(params.companyId));
        formData.append('id_contrato', String(params.contractId));
        formData.append('file', params.file);

        return {
          url: 'admin/contratos/upload-file',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['Contract'],
    }),
    deleteContractAttachment: build.mutation<GenericResponse<null>, { attachmentId: number }>({
      query: (params) => ({
        url: 'admin/contratos/delete-file',
        method: 'DELETE',
        body: {
          id_anexo: params.attachmentId,
        },
      }),
      invalidatesTags: ['Contract'],
    }),
  }),
});

export const {
  useGetStatusOptionsQuery,
  useGetReadjustmentRatesQuery,
  useSaveContractMutation,
  useGetContractQuery,
  useSendContractAttachmentMutation,
  useDeleteContractAttachmentMutation,
} = contractsApi;
