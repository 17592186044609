import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { possibleMltOptions } from 'redux/reducers/fluxAutomaticControlPanelReducer';
import useFluxAutomaticFilterSelector from 'utils/hooks/flux/automatic/filterSelector';

export default function MLTSelector({ disabled } : { disabled?: boolean }) {
  const { mltOption, setMltOption } = useFluxAutomaticFilterSelector();

  return (
    <Dropdown>
      <Dropdown.Toggle className="flux-automatic-filter-selector-toggle" disabled={disabled}>
        {mltOption.toLocaleUpperCase()}
      </Dropdown.Toggle>
      <Dropdown.Menu className="flux-automatic-filter-selector-menu">
        {possibleMltOptions.map((label) => (
          <Dropdown.Item
            key={label}
            onClick={() => setMltOption(label)}
            className={`flux-automatic-filter-selector-item ${label === mltOption ? 'active' : ''}`}
          >
            {label.toLocaleUpperCase()}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

MLTSelector.defaultProps = {
  disabled: false,
};
