import buildSets from 'helper/buildSets';
import { DisplayData, DisplayImage, NumericForecastImage } from 'helper/products/meteorology';

export default function buildSingleMapImageDisplayData(
  images: NumericForecastImage[],
  setSize: number,
): DisplayData {
  const data: DisplayData = {
    columnLabels: [],
    rowLabels: [],
    imageSets: [],
  };

  const displayImages: DisplayImage[] = images.map((i) => ({
    key: `${i.model}-${i.period}-${i.region}`,
    title: i.model,
    base64: i.base64,
  }));
  const imageSets = buildSets(displayImages, setSize);

  if (imageSets.length > 0) {
    const lastSet = imageSets[imageSets.length - 1];
    const paddingSize = Math.max(setSize - lastSet.length, 0);
    const paddingArr = new Array(paddingSize)
      .fill(null)
      .map((_, i) => ({ key: `empty-${i}`, title: '', base64: '' }));
    lastSet.push(...paddingArr);
  }

  data.imageSets = imageSets;

  return data;
}
