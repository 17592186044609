import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import { ResponseError } from 'services/api/base';
import useFileDownloadApi from 'utils/hooks/useFileDownloadApi';
import './index.scss';

export default function DownloadButton<ParamsType>({
  blobDownloader,
  params,
  filename,
  tooltipText,
  className,
}: {
  blobDownloader: (params: ParamsType) => Promise<Blob | ResponseError>,
  params: ParamsType,
  filename: string,
  tooltipText: string,
  className?: string
}) {
  const {
    isLoading,
    onClick,
  } = useFileDownloadApi({ blobDownloader, params, filename });

  return (
    <Button
      onClick={onClick}
      title={tooltipText}
      aria-label={tooltipText}
      className={className}
      disabled={isLoading}
    >
      {!isLoading && <FontAwesomeIcon icon={faDownload} />}
      {isLoading && <Spinner className="spinner-border-sm" role="status" aria-hidden />}
    </Button>
  );
}

DownloadButton.defaultProps = {
  className: 'icon-button download-button',
};
