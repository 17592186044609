import { SORT_ASC, SortDirection } from 'constants/sorting';
import { NamedListedReportRecipientCompany, RecipientCompanySortableFields } from 'helper/mailer';

const compareRecipientCompanies = (
  sortDirection: SortDirection,
) => {
  if (sortDirection === SORT_ASC) {
    return (
      a: NamedListedReportRecipientCompany,
      b: NamedListedReportRecipientCompany,
    ) => (a.name.localeCompare(b.name));
  }
  return (
    a: NamedListedReportRecipientCompany,
    b: NamedListedReportRecipientCompany,
  ) => (b.name.localeCompare(a.name));
};

export default function useRecipientCompanySorting(
  recipientCompanies: NamedListedReportRecipientCompany[],
  sortBy: RecipientCompanySortableFields | undefined,
  sortDirection: SortDirection | undefined,
) {
  const sortedRecipientCompanies = recipientCompanies.map((company) => (company));

  if (sortBy && sortDirection) {
    sortedRecipientCompanies.sort(compareRecipientCompanies(sortDirection));
  }

  return sortedRecipientCompanies;
}
