import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { Chart, DisplayMode } from 'helper/products/synopticCharts';
import {
  selectChart,
  selectDisplayMode,
  selectGifIndex,
  selectImageIndex,
  setGifIndex,
  setImageIndex,
} from 'redux/reducers/synopticChartsReducer';
import useGetGifs from 'utils/hooks/synopticCharts/useGetGifs';
import useGetImages from 'utils/hooks/synopticCharts/useGetImages';
import AlertsCollection from 'components/AlertsCollection';
import EnlargeableImage from './EnlargeableImage';

export default function Map() {
  const dispatch = useDispatch();
  const chart: Chart = useSelector(selectChart);
  const displayMode: DisplayMode = useSelector(selectDisplayMode);
  const gifIndex = useSelector(selectGifIndex);
  const imageIndex = useSelector(selectImageIndex);
  const { data: gifData, isFetching: gifsAreLoading, alert: gifAlert } = useGetGifs();
  const { data: imageData, isFetching: imagesAreLoading, alert: imageAlert } = useGetImages();

  const isLoading = displayMode === DisplayMode.Gif ? gifsAreLoading : imagesAreLoading;
  const dataset = displayMode === DisplayMode.Gif ? gifData : imageData;
  const index = displayMode === DisplayMode.Gif ? gifIndex : imageIndex;
  const data = dataset && dataset[index];
  const base64 = typeof data === 'string' ? data : data?.base64;
  const prefix = displayMode === DisplayMode.Gif ? 'image/gif' : 'image/png';
  const alert = displayMode === DisplayMode.Gif ? gifAlert : imageAlert;

  if (isLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner />
      </div>
    );
  }

  const getImageNavigator = (i: number) => () => {
    if (displayMode === DisplayMode.Gif) dispatch(setGifIndex(i));
    if (displayMode === DisplayMode.Images) dispatch(setImageIndex(i));
  };
  const canNavigate = dataset && dataset.length > 1;
  const maxIndex = (dataset && dataset.length - 1) || 0;
  const toFirst = canNavigate && index > 0 ? getImageNavigator(0) : undefined;
  const toPrevious = canNavigate && index > 0 ? getImageNavigator(index - 1) : undefined;
  const toNext = canNavigate && index < maxIndex ? getImageNavigator(index + 1) : undefined;
  const toLast = canNavigate && index < maxIndex ? getImageNavigator(maxIndex) : undefined;

  return (
    <div>
      {alert && (
        <div className="mt-4">
          <AlertsCollection alerts={[alert]} />
        </div>
      )}
      <div className="d-flex justify-content-center mt-4">
        {base64 && (
          <div className="text-center">
            <p className="meteorology-fields-display-row-image-label mb-2">{chart.campo}</p>
            <EnlargeableImage
              src={`data:${prefix};base64,${base64}`}
              toFirst={toFirst}
              toPrevious={toPrevious}
              toNext={toNext}
              toLast={toLast}
              className="meteorology-fields-display-row-image"
            />
          </div>
        )}
      </div>
    </div>
  );
}
