import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import useEditAboutDescription from 'hooks/Meteorology/Weather/Area/About/useEditAboutDescription';
import AlertsCollection from 'components/AlertsCollection';

import './index.scss';

export default function DescriptionForm({
  areaId, aboutDescription, setAboutDescription,
}: {
  areaId: number | undefined,
  aboutDescription: string,
  setAboutDescription: (aboutDescription: string) => void,
}) {
  const {
    submit, description, setDescription, isLoading, requestSuccess, validated,
  } = useEditAboutDescription(areaId, aboutDescription, setAboutDescription);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Form onSubmit={submit} noValidate>
      <fieldset disabled={false}>
        <Row>
          <Col>
            <Form.Group>
              {!requestSuccess && (
                <>
                  <Form.Label>Descrição</Form.Label>
                  <ReactQuill
                    id="edit-description-description"
                    className="description-form-quill"
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                  {!validated && (
                    <p className="text-danger">Descrição não pode ser vazia.</p>
                  )}
                </>
              )}
              {requestSuccess && (
                <AlertsCollection
                  alerts={[{
                    title: 'Salvo',
                    message: 'Descrição salva com sucesso!',
                    variant: 'success',
                  }]}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex align-items-center flex-row-reverse mt-3">
          {!requestSuccess && (
            <Button disabled={isLoading} type="submit" variant="primary">
              {isLoading ? <Spinner size="sm" className="mx-3" /> : 'Salvar'}
            </Button>
          )}
        </div>
      </fieldset>
    </Form>
  );
}
