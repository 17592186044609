import React, { useState, useEffect } from 'react';
import { store } from 'redux/store';
import { setCurrentTab, setSearchQuery } from 'redux/reducers/mailerLayoutReducer';
import ReportsTable from 'components/Admin/Mailer/ReportsTable';
import ReportForm from 'components/Admin/Mailer/ReportForm';

export default function MailerReports() {
  const [showReportForm, setShowReportForm] = useState(false);

  useEffect(() => {
    store.dispatch(setCurrentTab('reports'));
    store.dispatch(setSearchQuery(''));
  }, []);

  return (
    <div style={{ backgroundColor: '#f4f4f4' }}>
      {showReportForm
        ? <ReportForm close={() => setShowReportForm(false)} />
        : <ReportsTable openReportForm={() => setShowReportForm(true)} />}
    </div>
  );
}
