import React from 'react';

import useControlPanelOptions from 'utils/hooks/meteorology/useControlPanelOptions';
import StatusRow, { Data } from './StatusRow';

export default function Status({ data }: { data: Data[] }) {
  const { getModelOptions } = useControlPanelOptions('comparador');

  return (
    <table className="status-tab w-100 mt-3">
      <thead>
        <tr>
          <th>Modelo</th>
          <th style={{ textAlign: 'center' }}>
            <div>Data de</div>
            <div>Previsão</div>
          </th>
          <th style={{ textAlign: 'center' }}>Runtime</th>
          <th style={{ textAlign: 'center' }}>Membro</th>
          <th style={{ textAlign: 'center' }}>Variável</th>
          <th style={{ textAlign: 'center' }}>Status</th>
          <th style={{ textAlign: 'center' }}>
            <div>Última</div>
            <div>atualização</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((d) => (
          <StatusRow key={d.id} data={d} getModelOptions={getModelOptions} />
        ))}
      </tbody>
    </table>
  );
}
