import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface VerifyZipFileModalState {
  title: string
  body: string
  headerClass: string
  show: boolean
}

const initialState: VerifyZipFileModalState = {
  title: '',
  body: '',
  headerClass: 'bg-success',
  show: false,
};

export const verifyZipFileModalSlice = createSlice({
  name: 'verifyZipFileModal',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setBody: (state, action: PayloadAction<string>) => {
      state.body = action.payload;
    },
    setHeaderClass: (state, action: PayloadAction<string>) => {
      state.headerClass = action.payload;
    },
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectVerifyZipFileTitle = (state: any): string => state.verifyZipFileModal.title;
export const selectVerifyZipFileBody = (state: any): string => state.verifyZipFileModal.body;
export const selectVerifyZipFileHeaderClassName = (state: any): string => state
  .verifyZipFileModal.headerClass;
export const selectVerifyZipFileShow = (state: any): boolean => state.verifyZipFileModal.show;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setTitle,
  setBody,
  setHeaderClass,
  setShow,
} = verifyZipFileModalSlice.actions;

export default verifyZipFileModalSlice.reducer;
