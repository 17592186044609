import {
  FormEvent, useEffect, useRef, useState,
} from 'react';

import {
  Category,
  VideoData,
  VideoProduct,
  videoProductToPath,
} from 'helper/videos';
import {
  useGetVideoCategoriesQuery,
  useLazyDownloadBulletinQuery,
  useSaveVideoMutation,
} from 'services/api/videos';
import { ResponseError } from 'services/api/base';
import { CustomAlert } from 'components/AlertsCollection';
import useNumericTextInput from 'hooks/Videos/Form/useNumericTextInput';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function useVideoSave(product: VideoProduct, video?: VideoData) {
  const productPath = videoProductToPath[product];
  const isEditing = video !== undefined;
  const [bulletinTrigger, { data: bulletinData }] = useLazyDownloadBulletinQuery();
  const bulletin = bulletinData?.data;

  const fileRef = useRef<HTMLInputElement>(null);
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const [tags, setTags] = useState('');
  const [videoCategory, setVideoCategory] = useState(-1);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState<Date | null>(null);
  const [richTextValue, setRichTextValue] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [vimeoId, setVimeoId, vimeoIdText, onChangeVimeoId] = useNumericTextInput();

  useEffect(() => {
    if (video) {
      bulletinTrigger({ id: video.id, productPath });

      const newVimeoId = parseInt(video.video_id as string, 10);
      const newDate = new Date(video.uri.release_time || new Date());

      setTags(Array.isArray(video.tags) ? video.tags.join(';') : video.tags);
      setVideoCategory(video.categoria);
      setTitle(video.video_title);
      setDate(newDate);
      setVimeoId(newVimeoId);
      setRichTextValue(video.video_description);
    }
  }, [video, productPath, bulletinTrigger, setVimeoId]);

  useEffect(() => {
    if (bulletin && fileRef.current) {
      const fileInput = fileRef.current;
      const mime = 'text/plain';
      const blobData = atob(bulletin.file_b64);
      const u8arr = new Uint8Array(blobData.length);
      for (let i = 0; i < blobData.length; i += 1) {
        u8arr[i] = blobData.charCodeAt(i);
      }
      const f = new File([u8arr], bulletin.file_name, { type: mime });
      setFile(f);

      // TODO: Have a file input component which simulates an actual <input type="file" />, but
      // without all the overhead (i.e. useRef, DataTransfer etc.).
      if (typeof DataTransfer !== 'undefined') {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(f);
        fileInput.files = dataTransfer.files;
      }
    }
  }, [bulletin, fileRef]);

  const [videoSavingMutation,
    {
      data: videoSubmissionData,
      error: videoSubmissionError,
      isLoading: isSaving,
    },
  ] = useSaveVideoMutation();
  const { data: optionsQueryData, error } = useGetVideoCategoriesQuery({ productPath });
  const videoCategories = (optionsQueryData?.data || []) as Category[];
  useHandleRequestErrors(error as ResponseError | undefined);
  useHandleRequestErrors(videoSubmissionError as ResponseError | undefined);

  useEffect(() => {
    if (videoSubmissionData) {
      const { title: dataTitle, message, status } = videoSubmissionData;
      if (status) {
        setAlerts([{ title: dataTitle, message, variant: 'success' }]);
      } else {
        setAlerts([{ title: dataTitle, message, variant: 'danger' }]);
      }
    }
  }, [videoSubmissionData]);

  const dateWasSet = date !== null;
  const titleWasFilled = title !== '';
  const videoCategoryWasChosen = videoCategory >= 0;
  const submitEnabled = dateWasSet && titleWasFilled && videoCategoryWasChosen;

  const updateFile = () => {
    if (fileRef.current?.files) {
      const [referencedFile] = Array.from(fileRef.current.files);
      setFile(referencedFile || null);
    }
  };

  const submitVideoSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (date == null) {
      return;
    }

    if (file == null) {
      videoSavingMutation({
        id: video?.id,
        videoId: vimeoId,
        tags,
        videoTitle: title,
        videoDescription: richTextValue,
        category: videoCategory,
        dtUpload: date.toLocaleDateString('pt-br'),
        productPath,
      });

      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      videoSavingMutation({
        id: video?.id,
        videoId: vimeoId,
        tags,
        videoTitle: title,
        videoDescription: richTextValue,
        category: videoCategory,
        dtUpload: date.toLocaleDateString('pt-br'),
        fileName: file.name,
        base64file: (reader.result as string).split(',')[1],
        productPath,
      });
    };
  };

  return {
    isEditing,
    alerts,
    fileRef,
    setFile,
    tags,
    setTags,
    videoCategory,
    setVideoCategory,
    title,
    setTitle,
    date,
    setDate,
    vimeoId,
    setVimeoId,
    vimeoIdText,
    onChangeVimeoId,
    richTextValue,
    setRichTextValue,
    videoCategories,
    updateFile,
    submitEnabled,
    submitVideoSave,
    isSaving,
  };
}
