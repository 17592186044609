import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import { Chart } from 'helper/products/synopticCharts';
import { setChart } from 'redux/reducers/synopticChartsReducer';

import './index.scss';

export default function ChartSelector({ charts }: { charts: Chart[] }) {
  const dispatch = useDispatch();
  const handleChart = (c: Chart) => dispatch(setChart(c));

  return (
    <div className="chart-selector ms-3">
      {charts.map((chart) => (
        <Button
          key={chart.id}
          onClick={() => handleChart(chart)}
          variant="link"
          className="px-3 py-2"
        >
          {chart.campo}
        </Button>
      ))}
    </div>
  );
}
