import { ComparisonImages, DisplayData, DisplayImage } from 'helper/products/meteorology';

const COLUMN_MAP = {
  confronting: 'Modelo Confrontante',
  base: 'Modelo Base',
  differences: 'Diferença',
};

function sortPeriodsPairs(first: string, last: string): number {
  const [, firstOffset] = first.split('+');
  const [, lastOffset] = last.split('+');

  return Number(firstOffset) - Number(lastOffset);
}

export default function buildImageDisplayData(comparisonSets: ComparisonImages[]): DisplayData {
  const columnKeys = Object.keys(COLUMN_MAP);
  const columnLabels = Object.values(COLUMN_MAP);

  const allPeriods = comparisonSets.map(({ period }) => period);
  const uniquePeriods = new Set(allPeriods);
  const rowLabels: string[] = [];
  uniquePeriods.forEach((v) => { rowLabels.push(v); });
  rowLabels.sort(sortPeriodsPairs);

  const imageSets: DisplayImage[][] = [];
  rowLabels.forEach((row) => {
    const imageSet: DisplayImage[] = [];
    const comparisonSet = comparisonSets.find(({ period }) => period === row);
    const setIsEmpty = columnKeys.every((col) => comparisonSet?.[col] === undefined);

    if (setIsEmpty) return;

    columnKeys.forEach((col) => {
      const base64 = comparisonSet?.[col] || '';

      imageSet.push({
        key: `${row}-${col}`,
        title: '',
        base64,
      });
    });
    imageSets.push(imageSet);
  });

  return {
    imageSets,
    columnLabels,
    rowLabels,
  };
}
