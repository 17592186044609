import React, { useState } from 'react';

import ConfirmationDialogBox from 'components/ConfirmationDialogBox';

export default function useConfirmationDialogBox() {
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState<JSX.Element>();
  const [confirm, setConfirm] = useState(() => () => {});
  const defaultDismiss = () => setOpen(false);
  const [dismiss, setDismiss] = useState(() => defaultDismiss);

  const setConfirmationDialogBox = (
    newOpen: boolean,
    newBody: JSX.Element,
    newConfirm: () => void,
    newDismiss: () => void = defaultDismiss,
  ) => {
    setOpen(newOpen);
    setBody(newBody);
    setConfirm(() => newConfirm);
    setDismiss(() => newDismiss);
  };

  const confirmationDialogBox = (
    <ConfirmationDialogBox
      open={open}
      confirm={confirm}
      dismiss={dismiss}
    >
      {body}
    </ConfirmationDialogBox>
  );

  return {
    setConfirmationDialogBox,
    confirmationDialogBox,
  };
}
