import React from 'react';
import { Modal } from 'react-bootstrap';
import useSaveFluxAutomaticPreference from 'utils/hooks/flux/automatic/useSaveFluxAutomaticPreference';
import SaveFluxAutomaticPreferenceForm from './saveFluxAutomaticPreferenceForm';

export default function SavePreferencesModal() {
  const { setShowFormModal, showFormModal } = useSaveFluxAutomaticPreference();

  const headerStyle = {
    backgroundColor: '#D9D9D9',
  };

  return (
    <Modal
      show={showFormModal}
      onHide={() => { setShowFormModal(false); }}
      dialogClassName="default-modal modal-dialog-scrollable"
    >
      <Modal.Header closeButton className="confirmation-dialog-modal-header" style={headerStyle} />
      <Modal.Body className="p-4">
        <SaveFluxAutomaticPreferenceForm />
      </Modal.Body>
    </Modal>
  );
}
