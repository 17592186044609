import React, { PropsWithChildren } from 'react';
import { Accordion } from 'react-bootstrap';
import './filters.scss';

interface FilterCategoryProps {
  name: string
}

export default function FilterCategory({
  children,
  name,
}: PropsWithChildren<FilterCategoryProps>) {
  return (
    <Accordion.Item
      eventKey={name}
      className="filter-category-accordion"
    >
      <Accordion.Header>
        {name}
      </Accordion.Header>
      <Accordion.Body>
        {children}
      </Accordion.Body>
    </Accordion.Item>
  );
}
