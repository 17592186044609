import React from 'react';
import { Button } from 'react-bootstrap';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFluxAutomaticFilterSelector from 'utils/hooks/flux/automatic/filterSelector';
import { automaticOptionMap } from 'helper/flux/automatic';

export default function ActiveFilters() {
  const { currentSubOption, clearFilter } = useFluxAutomaticFilterSelector();

  if (currentSubOption === undefined) {
    return <div />;
  }

  const label = currentSubOption?.spacialSubdivisionOption.label;
  const subdivisionName = automaticOptionMap[currentSubOption.spatialSubdivision];
  const buttonText = `${subdivisionName.toUpperCase()} - ${label}`;

  return (
    <div className="mx-3 d-flex align-items-center flex-grow-1 flex-wrap">
      <Button
        className="d-flex shadow-sm mx-2 my-2 border-0"
        key={`${currentSubOption?.spatialSubdivision}-${currentSubOption?.spacialSubdivisionOption.value}`}
        style={{ backgroundColor: '#F4F4F4', borderRadius: '4px', padding: '0px 5px' }}
        onClick={clearFilter}
      >
        <span className="text-nowrap" style={{ color: '#212F58', fontFamily: 'Source Sans Pro', fontWeight: 400 }}>
          {buttonText}
          {' '}
          <FontAwesomeIcon
            icon={faXmarkCircle as IconProp}
          />
        </span>
      </Button>
    </div>
  );
}
