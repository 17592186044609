import React from 'react';
import {
  Form,
} from 'react-bootstrap';
import useFluxFileUploadForm from 'utils/hooks/flux/fileUploadForm';

function PrevivazModelRadioButton(label) {
  const { previvazModel, setPrevivazModel } = useFluxFileUploadForm();

  return (
    <Form.Check
      type="radio"
      name="previvaz"
      label={label}
      key={label}
      checked={previvazModel === label}
      onChange={() => {
        setPrevivazModel(label);
      }}
    />
  );
}

export default function PrevivazModel() {
  const labels = [
    'Produzir todas as revisões',
    'Produzir somente RV0/RVF/RVXmax',
    'Produzir RVF/RVXmax',
  ];
  return (
    <div>
      {labels.map((label) => PrevivazModelRadioButton(label))}
    </div>
  );
}
