import { useEffect, useMemo, useState } from 'react';
import {
  addDays, differenceInCalendarDays, fromUnixTime, getUnixTime, startOfToday,
} from 'date-fns';
import { useLazyPersonalizedHorizonQuery } from 'services/api/personalized';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { ResponseError } from 'services/api/base';
import { SpecialModels } from 'helper/personalized';
import { useSelector } from 'react-redux';
import { selectFluxPersonalizedFormEndDate } from 'redux/reducers/fluxPersonalizedFormReducer';
import useNewStudyForm from './useNewStudyForm';

export default function useBlockFormDates(
  blockStartDate: Date,
  model: string,
  runtime: number,
  member: string,
) {
  const studyEndDate = useSelector(selectFluxPersonalizedFormEndDate);
  const [forecastDate, setForecastDate] = useState<Date | null>(null);
  const [forecastStartDate, setForecastStartDate] = useState<Date | null>(null);
  const [forecastEndDate, setForecastEndDate] = useState<Date | null>(null);
  const { endDate } = useNewStudyForm();
  const [horizonTrigger, {
    data: horizonData,
    error: horizonError,
  }] = useLazyPersonalizedHorizonQuery();
  const maxHorizon = horizonData?.days;

  const specialModelSelected = Object.values(SpecialModels).includes(model as SpecialModels);
  const climatologySelected = model === SpecialModels.Climatology;
  const historicalRainSelected = model === SpecialModels.HistoricalRain;
  const zeroSelected = model === SpecialModels.Zero;
  const climatologyOrZeroSelected = climatologySelected || zeroSelected;

  useEffect(() => {
    if (model && runtime !== -1 && member !== '' && forecastDate) {
      horizonTrigger({
        model,
        runtime,
        member,
        forecastDate: getUnixTime(forecastDate),
      });
    }
  }, [model, runtime, member, forecastDate, horizonTrigger]);

  useHandleRequestErrors(horizonError as ResponseError);

  const blockEndDate = useMemo(() => {
    if (forecastStartDate && forecastEndDate) {
      const diff = differenceInCalendarDays(forecastEndDate, forecastStartDate);
      const daysToStudyEnd = differenceInCalendarDays(
        fromUnixTime(studyEndDate),
        blockStartDate,
      );
      return addDays(blockStartDate, Math.min(diff, daysToStudyEnd));
    }
    return null;
  }, [forecastStartDate, forecastEndDate, studyEndDate, blockStartDate]);

  const forecastStartMin = useMemo(() => {
    if (historicalRainSelected) return new Date('2000-01-01T00:00:00');
    if (forecastDate) return addDays(forecastDate, 1);
    return null;
  }, [forecastDate, historicalRainSelected]);

  const forecastStartMax = useMemo(() => {
    if (historicalRainSelected) return startOfToday();
    if (forecastDate && maxHorizon) return addDays(forecastDate, maxHorizon);
    return null;
  }, [forecastDate, maxHorizon, historicalRainSelected]);

  const [forecastEndMin, forecastEndMax] = useMemo(() => {
    if (climatologyOrZeroSelected) return [forecastStartDate, fromUnixTime(endDate)];
    if (historicalRainSelected) return [forecastStartDate, startOfToday()];
    return [forecastStartDate, forecastStartMax];
  }, [
    climatologyOrZeroSelected,
    historicalRainSelected,
    forecastStartDate,
    forecastStartMax,
    endDate,
  ]);

  const forecastStartDisabled = !forecastStartMin || !forecastStartMax || climatologyOrZeroSelected;
  const forecastEndDisabled = !forecastStartDate || !forecastEndMin || !forecastEndMax;

  useEffect(() => {
    if (specialModelSelected) {
      setForecastDate(startOfToday());
    } else {
      setForecastDate(null);
    }
    setForecastStartDate(null);
    setForecastEndDate(null);
  }, [model, specialModelSelected]);

  useEffect(() => {
    if (climatologyOrZeroSelected) setForecastStartDate(forecastStartMin);
  }, [climatologyOrZeroSelected, forecastStartMin]);

  return {
    forecastDate,
    forecastStartDate,
    forecastEndDate,
    blockEndDate,
    setForecastDate,
    setForecastStartDate,
    setForecastEndDate,
    forecastStartMin,
    forecastStartMax,
    forecastEndMin,
    forecastEndMax,
    forecastStartDisabled,
    forecastEndDisabled,
  };
}
