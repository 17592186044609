import { useEffect } from 'react';

import { ResponseError } from 'services/api/base';
import { useLazyGetFileListQuery } from 'services/api/fileViewer';
import usePermissionCheck from '../usePermissionCheck';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function useFileList() {
  const keySaved = usePermissionCheck('file-viewer');
  const [trigger, { data, error, isSuccess }] = useLazyGetFileListQuery();
  const isFetching = !isSuccess;

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (keySaved) trigger();
  }, [trigger, keySaved]);

  return { data, isFetching };
}
