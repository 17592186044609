import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';
import mapAnnotations from 'helper/Meteorology/Weather/Overview/AnomalyPanel/mapAnnotations';

import './index.scss';

export default function AnomalyPanel({
  isFetching,
  imageSrc,
  period,
  handlePeriodChange,
  periodOptions,
}: {
  isFetching: boolean,
  imageSrc: string,
  period: string,
  handlePeriodChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  periodOptions: { value: string; label: string; }[],
}) {
  if (isFetching) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row className="mx-3">
        <Col md={8} className="anomaly-title">
          Anomalia da TMS
        </Col>
        <Col md={4}>
          <Form.Select
            id="periodSelect"
            value={period}
            onChange={handlePeriodChange}
          >
            {periodOptions.map((option) => (
              <option value={option.value} key={option.value}>{option.label}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      <div className="anomaly-map">
        <EnlargeableImage src={imageSrc} className="mt-3" />
        <div className="map-tropics" />
        {mapAnnotations.map((annotation) => (
          <Link
            to="/meteorology/weather/overview" // TODO: CHANGE TO TAB URL
            className="map-annotation"
            key={annotation.acronym}
            style={{ left: `${annotation.left}%`, top: `${annotation.top}%` }}
          >
            {annotation.acronym}
          </Link>
        ))}
      </div>
      <Row className="mx-4 mt-3">
        {mapAnnotations.map((legend) => (
          <Col key={legend.acronym} md={6} lg={4} className="text-start my-2">
            <span className="map-legend-acronym py-1 px-2">{legend.acronym}</span>
            <span className="map-legend-label ms-2">{legend.label}</span>
          </Col>
        ))}
      </Row>
    </>
  );
}
