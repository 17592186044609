import { DisplayData, NumericForecastImage } from 'helper/products/meteorology';
import buildSingleMapImageDisplayData from './buildSingleMapImageDisplayData';
import buildMultiMapImageDisplayData from './buildMultiMapImageDisplayData';

export default function buildImageDisplayData(
  images: NumericForecastImage[],
  setSize: number,
  multiMapMode: boolean,
): DisplayData {
  if (!images) return { columnLabels: [], rowLabels: [], imageSets: [] };
  if (!multiMapMode) return buildSingleMapImageDisplayData(images, setSize);
  return buildMultiMapImageDisplayData(images);
}
