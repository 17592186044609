import { downloadZip } from 'helper/downloadFile';
import { ComparisonImages } from 'helper/products/meteorology';

export default function useImagesDownload(
  data: ComparisonImages[] | undefined,
  areAllImagesFetched: boolean,
) {
  const downloadDisabled = !areAllImagesFetched || !data?.length;
  const onDownloadClick = () => {
    if (!downloadDisabled) {
      const files = data.flatMap((comparison) => [
        {
          filename: `${comparison.period}_base.png`,
          content: comparison.base,
        }, {
          filename: `${comparison.period}_confronting.png`,
          content: comparison.confronting,
        }, {
          filename: `${comparison.period}_differences.png`,
          content: comparison.differences,
        },
      ]);
      const date = new Date();
      const zipFilename = `comparator_${date.toLocaleString('pt-BR').replace(/\/|:|,|\s/g, '_')}.zip`;
      downloadZip(files, zipFilename);
    }
  };

  return {
    onDownloadClick,
    downloadDisabled,
  };
}
