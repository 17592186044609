import React from 'react';
import {
  Accordion, Col, Container, Form, Row,
} from 'react-bootstrap';
import useAutomaticOptions from 'utils/hooks/flux/useAutomaticOptions';
import { Scenario } from 'helper/flux/automatic';

export default function AutomaticStudies() {
  const { labeledScenarios, switchOptionsSelection, checkOptionSelection } = useAutomaticOptions();

  return (
    <Accordion.Item eventKey="2" className="control-panel-item">
      <Accordion.Header>Estudo Automático</Accordion.Header>
      <Accordion.Body>
        {labeledScenarios.map((option) => (
          <Accordion key={option.label}>
            <Accordion.Item eventKey={option.label} className="control-panel-item">
              <Accordion.Header>
                {option.label}
              </Accordion.Header>
              <Accordion.Body>
                <Container>
                  <Row md={2}>
                    {
                      option.scenarios.map((scenario: Scenario) => (
                        <Col key={scenario.scenario}>
                          <Form.Check
                            label={scenario.scenario}
                            onChange={() => switchOptionsSelection(scenario.scenario)}
                            checked={checkOptionSelection(scenario.scenario)}
                          />
                        </Col>
                      ))
                    }
                  </Row>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
