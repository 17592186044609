import { ControlPanelOption, ControlPanelTypes } from 'helper/products/meteorology';
import { useControlPanelOptionsQuery } from 'services/api/Meteorology';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';

const EMPTY_MODEL_OPTIONS: ControlPanelOption = {
  members: [],
  runtimes: [],
  variables: [],
  model: {
    label: '',
    value: '',
  },
};

export default function useControlPanelOptions(type: ControlPanelTypes) {
  const { data, isFetching, error } = useControlPanelOptionsQuery(type);
  const options = data || [];
  const models = options.map(({ model }) => model);

  const getModelOptions = (modelValue: string) => (
    options.find((o) => o.model.value === modelValue) || EMPTY_MODEL_OPTIONS
  );

  useHandleRequestErrors(error as ResponseError);

  return {
    models,
    getModelOptions,
    isFetching,
  };
}
