import React from 'react';
import { Outlet } from 'react-router';
import { Tab } from 'react-bootstrap';

export default function TabbedPageTab({
  title,
  path,
  showControlPanel,
}: {
  title: string;
  path: string;
  showControlPanel?: boolean;
}) {
  return (
    <Tab title={title} data-path={path} data-show-control-panel={showControlPanel}>
      <Outlet />
    </Tab>
  );
}

TabbedPageTab.defaultProps = {
  showControlPanel: false,
};
