import React from 'react';
import {
  Card,
  Button,
} from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';

export default function PreviewPanel() {
  const download = () => {};

  return (
    <Card className="shadow-sm forecast-instruction-card h-100">
      <Card.Header>
        PREVISÃO ESPACIAL
      </Card.Header>
      <Card.Body>
        <div className="d-lg-flex mt-3 mx-3">
          <div className="">TODO: Modelo</div>
          <div className="me-auto">TODO: Período</div>
          <Button
            size="sm"
            className="icon-button weather-download-button ms-lg-3"
            onClick={download}
          >
            <FontAwesomeIcon icon={faDownload as IconProp} />
          </Button>
        </div>
        <EnlargeableImage src="" className="mt-3" />
      </Card.Body>
    </Card>
  );
}
