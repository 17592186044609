import { ResponseError } from 'services/api/base';
import { useGetAboutQuery } from 'services/api/Meteorology/Weather/Area/About';
import GetAboutParams from 'types/Meteorology/Weather/Area/getAboutParams';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useAreaAbout(params: GetAboutParams) {
  const {
    data, error, isLoading, isFetching,
  } = useGetAboutQuery(params);

  useHandleRequestErrors(error as ResponseError);

  return { about: data, isLoading, isFetching };
}
