import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function DeleteButton({
  warningTitle,
  onConfirm,
  setConfirmationDialogBox,
}: {
  warningTitle: string,
  onConfirm: () => void,
  setConfirmationDialogBox: (open: boolean, body: JSX.Element, onConfirm: () => void) => void,
}) {
  const openConfirmationModal = () => {
    setConfirmationDialogBox(
      true,
      (
        <span>
          Deseja realmente remover o aviso
          <span className="fw-bold ms-1">{warningTitle}</span>
          ?
        </span>
      ),
      onConfirm,
    );
  };

  return (
    <Button size="sm" onClick={() => openConfirmationModal()}>
      <FontAwesomeIcon icon={faTrash} size="sm" />
    </Button>
  );
}
