import React, { useState } from 'react';
import {
  Button, Card, Form, Stack,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import AlertsCollection from 'components/AlertsCollection';
import usePasswordReset from './utils';

export function validateInfo(fieldInfo: string, expected: string | RegExp): boolean {
  let answer = false;
  if (typeof expected === 'string') {
    answer = fieldInfo === expected;
  } else {
    answer = expected.test(fieldInfo);
  }
  return answer;
}

export function fieldSetValidator(...validatorResults: boolean[]): boolean {
  return !validatorResults.some((validatorResult) => !validatorResult);
}

export default function PasswordRecovery() {
  const { search } = useLocation();
  const userToken = new URLSearchParams(search).get('user_token');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { passwordResethook, alerts } = usePasswordReset(
    { resetToken: userToken || '', password, confirm: passwordConfirmation },
  );
  const passwordsMatch = validateInfo(password, passwordConfirmation);
  const minLengthCheck = validateInfo(password, /.{8,}/);
  const upperCaseCheck = validateInfo(password, /[A-Z]+/);
  const lowerCaseCheck = validateInfo(password, /[a-z]+/);
  const numbersCheck = validateInfo(password, /\d+/);
  const specialCharsCheck = validateInfo(password, /[!@#$%&*]/);
  const passwordIsValid = fieldSetValidator(
    minLengthCheck,
    upperCaseCheck,
    lowerCaseCheck,
    numbersCheck,
    specialCharsCheck,
  );
  const formIsValid = fieldSetValidator(passwordsMatch, passwordIsValid, !!userToken);

  return (
    <>
      <Card.Title className="auth-card-title mt-5 mb-1">
        Alteração de senha
      </Card.Title>
      <AlertsCollection alerts={alerts} />
      <Card.Body className="my-3 mx-5">
        <Stack>
          <h6 style={{ fontWeight: 'bold' }}>
            Bem vindo(a)!
          </h6>
          <p>
            Este espaço destina-se à criação da sua senha.
            <br />
            Para sua maior segurança, sua senha deve ser criada contendo as
            seguintes características:
          </p>
          <p>
            Ter no mínimo 8 caracteres
            <br />
            Ser formada por ao menos uma letra maiúscula e uma minúscula
            <br />
            Conter números
            <br />
            Ao menos um caractere especial (!@#$%&)
            <br />
            As senhas coincidem
          </p>
        </Stack>
        <Form
          noValidate
          validated={formIsValid}
          onSubmit={(event) => passwordResethook(event)}
        >
          <fieldset>
            <Form.Group controlId="formPassword">
              <Form.Label className="auth-form-label">Senha</Form.Label>
              <Form.Control
                required
                type="password"
                isValid={passwordIsValid}
                onChange={(event) => { setPassword(event.target.value); }}
              />
            </Form.Group>
            <Form.Group controlId="formPasswordConfirmation">
              <Form.Label className="auth-form-label">Confirmar Senha</Form.Label>
              <Form.Control
                required
                type="password"
                isValid={formIsValid}
                onChange={(event) => { setPasswordConfirmation(event.target.value); }}
              />
              <Form.Control.Feedback type="invalid">
                Verifique as condições acima
              </Form.Control.Feedback>
            </Form.Group>
            <Stack direction="horizontal" className="mt-5 mb-2">
              <div style={{ width: '85%' }} />
              <Button disabled={!formIsValid} className="primary-button mr-5" type="submit">
                Alterar
              </Button>
            </Stack>
          </fieldset>
        </Form>
      </Card.Body>
    </>
  );
}
