import React, { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponseError } from 'services/api/base';
import ActionButton from 'components/Admin/ActionButton';
import DialogBox from 'components/DialogBox';
import { useForgotPasswordMutation } from 'services/api/auth';
import { useHandleRequestErrors } from 'utils/hooks';

export default function ResetPasswordButton({
  userId,
  username,
}: {
  userId: number,
  username: string,
}) {
  const [resetPassword, { isLoading, isSuccess, error }] = useForgotPasswordMutation();
  const [dialogOpen, setDialogOpen] = useState(false);

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (isSuccess) setDialogOpen(true);
  }, [isSuccess]);

  return (
    <div className="action-button-container">
      <DialogBox open={dialogOpen} dismiss={() => setDialogOpen(false)} type="success">
        <div>E-mail de recuperação de senha enviado com sucesso.</div>
      </DialogBox>

      <ActionButton
        loading={isLoading}
        onClick={() => resetPassword({ username })}
        tooltipText="Recuperar senha"
      >
        <FontAwesomeIcon
          id={`reset-password-${userId}`}
          icon={faEnvelope as IconProp}
          size="xs"
        />
      </ActionButton>
    </div>
  );
}
