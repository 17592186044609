import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentTab } from 'redux/reducers/fluxUploadLayoutReducer';
import './index.scss';
import { Card } from 'react-bootstrap';
import { openRight } from 'redux/reducers/sidebarsReducer';

export default function New() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openRight());
    dispatch(setCurrentTab('new'));
  }, [dispatch]);

  return (
    <>
      <Card className="new-upload-card shadow-sm">
        <Card.Header>
          INSTRUÇÕES AO USUÁRIO
        </Card.Header>
        <Card.Body>
          <div className="new-upload-frame d-flex">
            <div className="new-upload-left-column col-6">
              <p className="new-upload-warning-text">
                Recomenda-se o uso desta ferramenta aos
                usuários que tenham boa familiaridade com os
                arquivos de previsão de precipitação em formato
                de texto. Os arquivos fornecidos serão avaliados e
                validados de forma automática.
              </p>
              <p className="new-upload-warning-text">
                Para que não haja nenhum problema durante a
                validação, é importante que as seguintes instruções
                sejam seguidas:
              </p>
              <ul className="new-upload-instructions-text">
                <li>
                  Os arquivos devem ser fornecidos em um único
                  arquivo zip, disponíveis diretamente na raiz do arquivo
                  compactado, sem pastas internas;
                </li>
                <li>
                  O número de arquivos contidos no arquivo zip deve ser
                  igual ao número de dias do período definido no
                  intervalo entre a data inicial e final da simulação;
                </li>
                <li>
                  Os arquivos devem ser nomeados seguindo a
                  {' formatação {NOME}_{AAAAMMDD}.dat, onde:'}
                </li>
                <div className="new-upload-explanation-text">
                  <p>
                    NOME é um nome de livre escolha do usuário mas com
                    uso proibido do caractere &quot;_&quot;;
                    {'\n'}
                    AAAAMMDD é a data para qual a previsão se refere;
                    {'\n'}
                    Exemplo: PREV-EXEMPLO_20211231.dat seria o arquivo
                    de previsão para o dia 31/12/2021.
                  </p>
                </div>
              </ul>
            </div>
            <div className="new-upload-right-column col-6">
              <ul className="new-upload-instructions-text">
                <li>
                  Os dias atribuídos a cada arquivo devem condizer com
                  os dias definidos no intervalo entre a data inicial e
                  final da simulação;
                </li>
                <li>
                  Os arquivos devem conter informações de coordenada
                  geográfica e previsão de precipitação em milímetros
                  conforme a formatação a seguir:
                </li>
                <div className="new-upload-explanation-text">
                  LONGxxx LATxxx MMxxx;
                  {'\n'}
                  Longitude com 6 caracteres incluindo sinal negativo;
                  {'\n'}
                  Espaçamento simples;
                  {'\n'}
                  Latitude com 6 caracteres incluindo o sinal negativo;
                  {'\n'}
                  Espaçamento simples;
                  {'\n'}
                  Milímetros de chuva com 5 caracteres incluindo a
                  primeira casa decimal.
                </div>
                <li>
                  A verificação das localidades atribuída a cada
                  coordenada geográfica é feita de forma automática.
                  Exige-se que ao menos uma coordenada geográfica
                  interna a cada sub-bacia modelada pelo SMAP seja
                  fornecida. Não há exigências de regularidade da grade
                  de pontos, nem de adequação aos padrões oficiais do
                  ONS;
                </li>
                <li>
                  Cumpridas essas exigências, o caso será simulado com
                  as informações de vazões naturais e precipitação
                  observadas mais recentes em nossa base de dados.
                </li>
              </ul>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="p-4" />
    </>
  );
}
