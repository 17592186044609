import React, { FormEvent, useState } from 'react';
import { useGetAutomaticPreferencesQuery } from 'services/api/products/flux';
import useSaveFluxAutomaticPreference from 'utils/hooks/flux/automatic/useSaveFluxAutomaticPreference';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';

export default function SaveFluxAutomaticPreferenceForm() {
  const {
    savePreference, error, setShowConfirmationModal, setShowFormModal, setShowErrorModal,
  } = useSaveFluxAutomaticPreference();
  const [name, setName] = useState('');
  const { data, isLoading } = useGetAutomaticPreferencesQuery('diaria');
  const preferenceNames = data?.map((preference) => preference.name) ?? [];
  const valid = name.length > 0 && !(preferenceNames?.includes(name)) && !isLoading;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await savePreference(name);
    if (error !== undefined) {
      setShowErrorModal(true);
    } else {
      setShowConfirmationModal(true);
    }
    setShowFormModal(false);
  };

  const nameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Col className="col-md-6">
            Antes de salvar, dê um
            {' '}
            <b>nome</b>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-md-6">
            <p>
              para sua nova preferência.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="preferenceName">
              <Form.Control required type="text" onInput={nameChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col className="col-md-6">
            <Button className={valid ? 'mt-3 primary-button' : 'mt-3 secondary-button'} type="submit" disabled={!valid}>
              {isLoading && <Spinner size="sm" />}
              <span>
                Salvar nova preferência
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
