import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';
import { handleApiError } from 'helper/error';

export default function useHandleRequestErrors(error: ResponseError | undefined) {
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      handleApiError(navigate, error);
    }
  }, [error, navigate]);
}
