import React, { useEffect } from 'react';

import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/meteorologyFieldsLayoutReducer';
import SynopticChart from 'components/Meteorology/Fields/SynopticCharts/SynopticChart';

import './index.scss';

export default function SynopticCharts() {
  useEffect(() => {
    store.dispatch(setCurrentTab('synopticcharts'));
  }, []);

  return (
    <div className="synoptic-chart-display">
      <SynopticChart />
    </div>
  );
}
