import React from 'react';
import MeteorologyWrapper from 'components/RouteWrappers/Meteorology';
import fieldsRouter from './FieldsRouter';
import weatherRouter from './WeatherRouter';

const meteorologyRouter = {
  path: 'meteorology',
  element: <MeteorologyWrapper />,
  children: [
    fieldsRouter,
    weatherRouter,
  ],
};

export default meteorologyRouter;
