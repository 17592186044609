import { store } from 'redux/store';
import { reset as resetNumericForecastControlPanel } from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { reset as resetComparatorControlPanel } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import { reset as resetObservedDataControlPanel } from 'redux/reducers/meteorologyFieldsObservedDataControlPanelReducer';
import { reset as resetFluxPersonalizedPanel } from 'redux/reducers/fluxPersonalizedFormReducer';

export default function resetMeteorologicFieldsPanels() {
  store.dispatch(resetNumericForecastControlPanel());
  store.dispatch(resetComparatorControlPanel());
  store.dispatch(resetObservedDataControlPanel());
  store.dispatch(resetFluxPersonalizedPanel());
}
