import { createSlice } from '@reduxjs/toolkit';

interface SidebarsState {
  open: string
}

const initialState: SidebarsState = {
  open: 'left',
};

export const sidebarsSlice = createSlice({
  name: 'sidebars',
  initialState,
  reducers: {
    openLeft: (state) => {
      state.open = 'left';
    },
    openRight: (state) => {
      state.open = 'right';
    },
    close: (state) => {
      state.open = 'none';
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectOpen = (state: any) => state.sidebars.open;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  openLeft,
  openRight,
  close,
} = sidebarsSlice.actions;

export default sidebarsSlice.reducer;
