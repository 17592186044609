import md5 from 'md5';

import { store } from 'redux/store';
import baseApi, { GenericResponse } from './base';

export interface LoginResponse {
  status: boolean,
  code: number,
  title: string,
  message: string,
  data: {
    access_token: string,
    timeout: number,
    jwt: string,
    refresh_token: string,
    admin: boolean,
  }
}

export interface LoginParams {
  username: string,
  password: string
}

export interface ForgotPasswordResponse {
  status: number,
  code: number,
  title: string,
  message: string,
  data: null
}

export interface ForgotPasswordParams {
  username: string
}

export interface UserPasswordResetParams {
  resetToken: string
  password: string
  confirm: string
}

export type UserPasswordResetResponse = GenericResponse<null>;

interface RefreshTokenData {
  jwt: string,
  refresh_token: string,
}
export type RefreshTokenResponse = GenericResponse<RefreshTokenData>;

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginParams>({
      query: ({ username, password }) => ({
        url: 'login/',
        method: 'PUT',
        body: {
          username,
          password: md5(password),
        },
      }),
    }),
    forgotPassword: build.mutation<ForgotPasswordResponse, ForgotPasswordParams>({
      query: ({ username }) => ({
        url: 'forgot-password',
        method: 'PUT',
        body: {
          username,
        },
      }),
    }),
    userPasswordReset: build.mutation<UserPasswordResetResponse, UserPasswordResetParams>({
      query: ({ resetToken, password, confirm }) => ({
        url: 'user-reset-password',
        method: 'PUT',
        body: { reset_token: resetToken, password: md5(password), confirm: md5(confirm) },
      }),
    }),
    refreshToken: build.mutation<RefreshTokenResponse, void>({
      query: () => {
        const token = store.getState().auth.refreshToken;
        return {
          url: 'refresh-token',
          method: 'PUT',
          body: { refresh_token: token },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useUserPasswordResetMutation,
  useRefreshTokenMutation,
} = authApi;
