import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { DisplayImage } from 'helper/products/meteorology';
import useSelectedImageModal from 'utils/hooks/meteorology/useSelectedImageModal';
import ColumnLabels from './ColumnLabels';
import MeteorologyFieldsRow from './Row';
import ImageModal from '../ImageModal';

import './index.scss';

export default function MeteorologyFieldsDisplay({
  imageSets,
  rowLabels,
  columnLabels,
  fetchingImages,
  fullSweep = false,
} : {
  imageSets: DisplayImage[][],
  rowLabels: string[],
  columnLabels: string[],
  fetchingImages: boolean,
  fullSweep?: boolean,
}) {
  const {
    imageSrc,
    show,
    onShow,
    onHide,
    toFirst,
    toPrevious,
    toNext,
    toLast,
    up,
    down,
    setSetIndex,
    setImageIndex,
  } = useSelectedImageModal({ imageSets, fullSweep });

  let content: JSX.Element;

  if (fetchingImages) {
    content = <div className="text-center mt-5"><Spinner /></div>;
  } else if (imageSets.length === 0) {
    content = (
      <div className="images-not-found">
        Não foram encontradas imagens para as variáveis selecionadas
      </div>
    );
  } else {
    content = (
      <>
        <ColumnLabels labels={columnLabels} />
        {imageSets.map((imageSet, i) => (
          <MeteorologyFieldsRow
            key={imageSet.map(({ key }) => key).join('-')}
            images={imageSet}
            label={rowLabels[i]}
            showImageLabels={!columnLabels.length}
            selectSet={() => {
              setSetIndex(i);
              onShow();
            }}
            setImageIndex={setImageIndex}
          />
        ))}
        <ImageModal
          src={imageSrc}
          show={show}
          onHide={onHide}
          toFirst={toFirst}
          toPrevious={toPrevious}
          toNext={toNext}
          toLast={toLast}
          up={up}
          down={down}
        />
      </>
    );
  }
  const displayElement = document.getElementById('met-fields-display');
  const topScrollbarElement = document.getElementById('met-fields-top-scrollbar');
  const [scrollerWidth, setScrollerWidth] = useState(0);

  useEffect(() => {
    const width = (displayElement ? displayElement.scrollWidth : 0) / 12.6;

    if (width > 121) {
      setScrollerWidth(width);
    } else {
      setScrollerWidth(0);
    }
  }, [displayElement, imageSets]);

  return (
    <>
      <div
        id="met-fields-top-scrollbar"
        onScroll={(event) => {
          if (displayElement) {
            displayElement.scrollLeft = event.currentTarget.scrollLeft;
          }
        }}
      >
        <div
          style={{
            width: `${scrollerWidth}%`,
            paddingTop: '1px',
          }}
        >
          {'\xA0'}
        </div>
      </div>
      <div
        id="met-fields-display"
        className="px-4 pb-3 mb-5 meteorology-fields-display"
        onScroll={(event) => {
          if (topScrollbarElement) {
            topScrollbarElement.scrollLeft = event.currentTarget.scrollLeft;
          }
        }}
      >
        {content}
      </div>
    </>
  );
}

MeteorologyFieldsDisplay.defaultProps = {
  fullSweep: false,
};
