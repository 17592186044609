import {
  DisplayData,
  DisplayImage,
  ObservedDataColumnMap as ColumnMap,
} from 'helper/products/meteorology';

const COLUMN_MAP: ColumnMap<string> = {
  observedData: 'Dados Observados',
  climatology: 'Climatologia',
  anomaly: 'Anomalia',
};

export default function buildImageDisplayData(imageMap: ColumnMap<string>): DisplayData {
  const columnLabels: string[] = [];
  const images: DisplayImage[] = [];

  Object
    .entries(imageMap)
    .filter(([, base64]) => base64)
    .forEach(([col, base64]) => {
      columnLabels.push(COLUMN_MAP[col]);
      images.push({ key: col, title: '', base64 });
    });

  return {
    imageSets: [images],
    columnLabels,
    rowLabels: [],
  };
}
