import {
  FormEvent, useState, useEffect, useCallback,
} from 'react';
import { ResponseError } from 'services/api/base';
import { useEditAboutDescriptionMutation } from 'services/api/Meteorology/Weather/Area/About';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useEditAboutDescription(
  areaId: number | undefined,
  aboutDescription: string,
  setAboutDescription: (description: string) => void,
): {
    submit: (event: FormEvent) => void;
    description: string;
    setDescription: (description: string) => void;
    isLoading: boolean;
    requestSuccess: boolean;
    validated: boolean;
  } {
  const [saveDescription, { data, error, isLoading }] = useEditAboutDescriptionMutation();
  const [description, setDescription] = useState(aboutDescription);
  const [validated, setValidated] = useState(true);
  const requestSuccess = 'data' in (data || {});

  const submit = useCallback((event: FormEvent) => {
    event.preventDefault();
    if (description === '<p><br></p>' || description === '' || areaId === undefined) {
      setValidated(false);
      return;
    }
    setValidated(true);
    saveDescription({ description, id: areaId });
  }, [description, areaId, saveDescription]);

  useEffect(() => {
    if (requestSuccess) {
      setAboutDescription(description);
    }
  }, [requestSuccess, setAboutDescription, description]);

  useHandleRequestErrors(error as ResponseError);

  return {
    submit, description, setDescription, isLoading, requestSuccess, validated,
  };
}
