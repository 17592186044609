import { APIResponse } from 'helper/apiResponse';
import { GRAY1, GREEN } from 'constants/colors';
import { MailTemplateResponseSubtypes, MailTemplateTypes } from '../services/api/mailer';
import { GenericResponse } from '../services/api/base';

export interface RawListedReportMailStatus {
  [key: string]: boolean | null,
  ck_word_gerado: boolean,
  ck_pdf_gerado: boolean,
  ck_email_gerado: boolean,
  ck_finalizado: boolean,
}

export interface ListedReportMailStatus {
  [key: string]: boolean | null,
  ckWordGenerated: boolean,
  ckPdfGenerated: boolean,
  ckMailGenerated: boolean,
  ckFinalized: boolean,
}

export interface RawListedReportMail {
  id: number,
  process_id: string,
  tipo: string,
  file_name: string,
  dt_request: string,
  status: RawListedReportMailStatus[]
}

export type RawReportMailListResponse = APIResponse<RawListedReportMail[]>;

export interface ListedReportMail {
  [key: string]: number | string | ListedReportMailStatus[] | null,
  id: number,
  processId: string,
  type: string,
  fileName: string,
  dtRequest: string,
  status: ListedReportMailStatus[]
}

export interface RawRead {
  [key: string]: number | string
  id: number
  ip_address: string
  ds_identificador: string
  ds_geoip_info: string
  request_data: string
  date_request: number
  date_geoip: number
}

export interface RawEmail {
  [key: string]: number | boolean | string | RawRead[] | null
  id: number
  ds_subject: string
  ds_html_body: string
  ds_text_body: string
  ds_mail_to: string
  id_user_from: number | null
  dt_requisicao: number
  dt_envio: number
  dt_visualizacao: number | null
  ds_identificador: string
  ds_unificador: string
  ds_dados_recebedor: string | null
  ds_temp_pass: string
  ds_temp_user: string
  dt_last_login: number | null
  ds_table_ref: string
  ds_table_id: string
  ds_from_email: string
  ck_attach: boolean
  reads: RawRead[]
}

export interface Read {
  [key: string]: number | string
  id: number
  ipAddress: string
  identifier: string
  geoIpInfo: string
  requestData: string
  requestDate: number
  geoIpDate: number
}

export interface Email {
  [key: string]: number | boolean | string | Read[] | null
  id: number
  subject: string
  htmlBody: string
  textBody: string
  mailTo: string
  idUserFrom: number | null
  request: number
  submission: number
  viewed: number | null
  identifier: string
  unifier: string
  receiverData: string | null
  tempPass: string
  tempUser: string
  lastLogin: number | null
  tableRef: string
  tableId: string
  fromEmail: string
  attach: boolean
  reads: Read[]
}

export interface ListedReportRecipientCompany {
  [key: string]: number | string | boolean | Email[] | null,
  id: number
  companyId: number
  wordId: string
  pdfId: string
  submissionId: string
  messageTitle: string
  messageBody: string
  reportMailId: number
  wordGenerated: boolean
  pdfGenerated: boolean
  emailGenerated: boolean
  emails: Email[]
}

export interface NamedListedReportRecipientCompany extends ListedReportRecipientCompany {
  name: string
}

export interface MailTemplateOptionType {
  id: number,
  label: string,
}

export type MailTemplateSubtype = MailTemplateOptionType;
export interface MailTemplateType extends MailTemplateOptionType {
  subTypes: MailTemplateSubtype[],
}

export interface RawReportRecipientCompany {
  [key: string]: number | string | boolean | RawEmail[] | null,
  id: number
  id_contraparte: number
  id_arquivo_word: string
  id_arquivo_pdf: string
  id_envio: string
  ds_titulo: string
  ds_corpo: string
  id_processo: number
  ck_word_gerado: boolean
  ck_pdf_gerado: boolean
  ck_email_gerado: boolean
  emails: RawEmail[]
}

export type ReportRecipientCompaniesResponse = APIResponse<RawReportRecipientCompany[]>;

export interface RawListedReportMailParams {
  page: number,
  page_size: number,
  order_by: string,
  order_direction: 'ASC' | 'DESC',
  search_terms?: string,
}

export interface ListedMassMailStatusInterface {
  ck_enviado: boolean,
  ck_finalizado: boolean,
}

export type RawListedMassMail = {
  id: number,
  subject: string,
  send_date: string,
  identificador: string,
  arquivo: string,
  produtos: string,
  status: ListedMassMailStatusInterface,
};
export type RawMassMailListResponse = APIResponse<RawListedMassMail[]>;

export interface ListedMassMail {
  // NOTE: defining indexable fields allows us to read and write to fields of this type using
  // variables and other dynamic values.
  [key: string]: number | string | boolean | null,
  id: number
  subject: string
  identifierCode: string
  isSent: boolean
  isFinished: boolean,
  sendDate: string,
}

export interface RawSendReportMailResponse {
  process_identification: string
  pid: number
}

export interface FillInMessageParams {
  type: number,
  subtype: number,
  printDate: string,
}

export interface FillInMessageResponse {
  subject: string,
  body: string,
}

export type RecipientCompanySortableFields = 'name';
export const SORT_BY_NAME = 'name';
export const RECIPIENT_COMPANY_SORTABLE_FIELDS: (RecipientCompanySortableFields | undefined)[] = [
  SORT_BY_NAME,
];

export const MASS_MAIL_BODY_TYPE_OPTIONS = [
  { value: 'nenhum', label: 'Nenhum' },
  { value: 'default', label: 'Corpo Padrão' },
  { value: 'notify', label: 'Corpo de notificação' },
];

export const rawToListedReportMail = (
  response: RawReportMailListResponse,
): ListedReportMail[] => response.data.map((rawListedReportMail: RawListedReportMail) => ({
  id: rawListedReportMail.id,
  processId: rawListedReportMail.process_id,
  type: rawListedReportMail.tipo,
  fileName: rawListedReportMail.file_name,
  dtRequest: rawListedReportMail.dt_request,
  status: rawListedReportMail.status.map((rawStatus: RawListedReportMailStatus) => ({
    ckWordGenerated: rawStatus.ck_word_gerado,
    ckPdfGenerated: rawStatus.ck_pdf_gerado,
    ckMailGenerated: rawStatus.ck_email_gerado,
    ckFinalized: rawStatus.ck_finalizado,
  } as ListedReportMailStatus)),
} as ListedReportMail));

export const rawToListedReportRecipientCompanies = (
  response: ReportRecipientCompaniesResponse,
): ListedReportRecipientCompany[] => (
  response.data.map((rawReportRecipientCompany: RawReportRecipientCompany) => ({
    id: rawReportRecipientCompany.id,
    companyId: rawReportRecipientCompany.id_contraparte,
    wordId: rawReportRecipientCompany.id_arquivo_word,
    pdfId: rawReportRecipientCompany.id_arquivo_pdf,
    submissionId: rawReportRecipientCompany.id_envio,
    messageTitle: rawReportRecipientCompany.ds_titulo,
    messageBody: rawReportRecipientCompany.ds_corpo,
    reportMailId: rawReportRecipientCompany.id_processo,
    wordGenerated: rawReportRecipientCompany.ck_word_gerado,
    pdfGenerated: rawReportRecipientCompany.ck_pdf_gerado,
    emailGenerated: rawReportRecipientCompany.ck_email_gerado,
    emails: rawReportRecipientCompany.emails.map((rawEmail) => ({
      id: rawEmail.id,
      subject: rawEmail.ds_subject,
      htmlBody: rawEmail.ds_html_body,
      textBody: rawEmail.ds_text_body,
      mailTo: rawEmail.ds_mail_to,
      idUserFrom: rawEmail.id_user_from,
      request: rawEmail.dt_requisicao,
      submission: rawEmail.dt_envio,
      viewed: rawEmail.dt_visualizacao,
      identifier: rawEmail.ds_identificador,
      unifier: rawEmail.ds_unificador,
      receiverData: rawEmail.ds_dados_recebedor,
      tempPass: rawEmail.ds_temp_pass,
      tempUser: rawEmail.ds_temp_user,
      lastLogin: rawEmail.dt_last_login,
      tableRef: rawEmail.ds_table_ref,
      tableId: rawEmail.ds_table_id,
      fromEmail: rawEmail.ds_from_email,
      attach: rawEmail.ck_attach,
      reads: rawEmail.reads.map((rawRead) => ({
        id: rawRead.id,
        ipAddress: rawRead.ip_address,
        identifier: rawRead.ds_identificador,
        geoIpInfo: rawRead.ds_geoip_info,
        requestData: rawRead.request_data,
        requestDate: rawRead.date_request,
        geoIpDate: rawRead.date_geoip,
      })),
    })),
  }))
);

export const rawToListedMassMail = (
  response: RawMassMailListResponse,
): ListedMassMail[] => response.data.map(({
  id, identificador, subject, status: { ck_enviado, ck_finalizado }, send_date,
}: RawListedMassMail) => ({
  id,
  subject,
  isSent: !!ck_enviado,
  sendDate: send_date,
  isFinished: !!ck_finalizado,
  identifierCode: identificador,
}));

export const rawToSubtypeOptions = (
  subTypeCollection: MailTemplateResponseSubtypes[],
): MailTemplateSubtype[] => subTypeCollection.map((subtypeObject) => {
  const [[label, id]] = Object.entries(subtypeObject);
  return { id, label };
});

export const rawToMailerOptions = (
  response: GenericResponse<MailTemplateTypes>,
): MailTemplateType[] => Object.entries(response.data)
  .map(([label, template]) => ({
    label, id: template.id, subTypes: rawToSubtypeOptions(template.subtipos),
  }));

export const readIconColor = (requestData: object | undefined, key = '') => {
  if (!requestData) {
    return GRAY1;
  }
  if (!key) {
    return GREEN;
  }

  return key in requestData ? GREEN : GRAY1;
};
