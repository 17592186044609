import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './filters.scss';

interface AppliedFilterProps {
  name: string,
  onClick: () => void
}

export default function AppliedFilter({
  name,
  onClick,
}: AppliedFilterProps) {
  return (
    <Button
      className="applied-filter-button text-white me-2 mb-5 mt-2"
      variant="secondary"
      size="sm"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faXmarkCircle as IconProp}
        className="pe-2"
      />
      {name}
    </Button>
  );
}
