import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Category, VideoProduct, videoProductToRoute } from 'helper/videos';

export default function VideoTab({
  category,
  product,
}: {
  category: Category,
  product: VideoProduct,
}) {
  const activeTabStyle = {
    backgroundColor: '#f4f4f4',
    color: '#212f58',
    border: 'none',
  };
  const inactiveTabStyle = {
    backgroundColor: '#598cbb',
    color: 'white',
    border: 'none',
  };

  const currentId = useParams().id;
  const productPath = videoProductToRoute[product];
  const path = `${productPath}/categories/${category.id}`;
  const active = currentId && parseInt(currentId, 10) === category.id;

  return (
    <Link
      to={path}
      className="nav-link text-uppercase"
      style={active ? activeTabStyle : inactiveTabStyle}
    >
      {category.label}
    </Link>
  );
}
