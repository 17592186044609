import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import DatePicker from 'components/DatePicker';

import './index.scss';

export default function InvoiceDatePicker({ startDate, endDate, onDateChange }: {
  startDate: Date | null,
  endDate: Date | null,
  onDateChange: (dates: [Date | null, Date | null]) => void,
}) {
  const iconClassName = `me-2 calendar-icon${startDate && endDate ? ' calendar-active' : ''}`;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="d-flex flex-row align-items-center flex-shrink-1">
      <div className={iconClassName}>
        <FontAwesomeIcon icon={faCalendar as IconProp} />
      </div>
      <DatePicker
        className="invoice-datepicker"
        placeholderText="Período"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={onDateChange}
      />
    </label>
  );
}
