import { store } from 'redux/store';
import {
  AddDirectoryParams,
  AddDirectoryResponse,
  FileList,
  UploadFileParams,
  UploadFileResponse,
  transformFileListReponse,
} from 'helper/products/fileViewer';
import baseApi from './base';

const fileViewerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFileList: build.query<FileList, void>({
      query: () => {
        const productKey = store.getState().productKeys['file-viewer'];
        return `produtos/file-viewer/get-file-list/?product_key=${productKey}`;
      },
      transformResponse: transformFileListReponse,
    }),
    addDirectory: build.mutation<AddDirectoryResponse, AddDirectoryParams>({
      query: ({ formattedPath }) => {
        const productKey = store.getState().productKeys['file-viewer'];
        return {
          url: `produtos/file-viewer/new-directory?product_key=${productKey}`,
          method: 'PUT',
          body: { final_dir: formattedPath },
        };
      },
    }),
    uploadFile: build.mutation<UploadFileResponse, UploadFileParams>({
      query: (params) => {
        const productKey = store.getState().productKeys['file-viewer'];
        return {
          url: `produtos/file-viewer/upload-file?product_key=${productKey}`,
          method: 'PUT',
          body: {
            file_base64: params.fileBase64,
            dir_info: params.formattedPath,
            dir_token: params.dirName,
            file_name: params.filename,
          },
        };
      },
    }),
  }),
});

export const {
  useGetFileListQuery,
  useLazyGetFileListQuery,
  useAddDirectoryMutation,
  useUploadFileMutation,
} = fileViewerApi;
