import './index.scss';
import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export default function PasswordRecoverySuccess() {
  const navigate = useNavigate();

  return (
    <>
      <Card.Title className="success-title" />
      <Card.Body className="success-body">
        <Stack>
          <span>O link para redefinir sua senha foi enviado por email com sucesso!</span>

          <Button onClick={() => navigate('/signin')} className="success-button mx-auto mt-4 mb-1">
            Ok
          </Button>
        </Stack>
      </Card.Body>
    </>
  );
}
