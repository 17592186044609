import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

export default function WarningFormModal({
  header,
  title,
  setTitle,
  message,
  setMessage,
  show,
  dismiss,
  saveWarning,
}: {
  header: string,
  title: string,
  setTitle: (v: string) => void,
  message: string,
  setMessage: (v: string) => void,
  show: boolean,
  dismiss: () => void,
  saveWarning: () => void,
}) {
  return (
    <Modal show={show} onHide={dismiss}>
      <Modal.Header className="ms-3 me-3" closeButton>
        <h3 className="preview-title"><strong>{header}</strong></h3>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Título</Form.Label>
          <Form.Control
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Form.Label>Mensagem</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button className="confirmation-dialog-modal-btn" variant="primary" onClick={saveWarning}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
