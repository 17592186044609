import React from 'react';

import CloseButton from 'components/CloseButton';

export default function BlockDisplay({
  title, model, rmv, startDate, endDate, sliceEndDate, sliceStartDate, onBlockDelete,
}: {
  title: string,
  model: string,
  rmv: boolean,
  startDate: string,
  endDate: string,
  sliceEndDate: string,
  sliceStartDate: string,
  onBlockDelete?: () => void,
}) {
  return (
    <div className="flux-personalized-block d-flex justify-content-between">
      <div>
        <div className="block-title">{title}</div>
        <div className="block-model">{model}</div>
        <div>{`${rmv ? 'COM' : 'SEM'} remoção de viés`}</div>
        <div>{`${startDate} H ${sliceStartDate}`}</div>
        <div>{`${endDate} H ${sliceEndDate}`}</div>
      </div>
      {onBlockDelete && (
        <div className="mt-2">
          <CloseButton onClick={onBlockDelete} label="" id={`${sliceStartDate}_${sliceEndDate}`} />
        </div>
      )}
    </div>
  );
}

BlockDisplay.defaultProps = {
  onBlockDelete: undefined,
};
