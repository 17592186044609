import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { selectRuntime, setRuntime } from 'redux/reducers/synopticChartsReducer';
import useGetOptions from 'utils/hooks/synopticCharts/useGetOptions';
import { Runtime } from 'helper/products/synopticCharts';

export default function RuntimeSelector() {
  const dispatch = useDispatch();
  const { runtimes } = useGetOptions();
  const defaultRuntime = runtimes && runtimes[0];
  const runtime = useSelector(selectRuntime);

  const handleRuntime = useCallback((r: Runtime) => {
    dispatch(setRuntime(r));
  }, [dispatch]);

  useEffect(() => {
    if (defaultRuntime) handleRuntime(defaultRuntime);
  }, [defaultRuntime, handleRuntime]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className="synoptic-charts-dropdown-selector-toggle d-flex justify-content-between align-items-center w-100"
      >
        {runtime?.label || 'Runtime'}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {runtimes && (
          runtimes.map((r) => (
            <Dropdown.Item
              key={r.id}
              onClick={() => handleRuntime(r)}
              className="synoptic-chart-dropdown-item"
            >
              {r.label}
            </Dropdown.Item>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
