import React, { useEffect, useRef } from 'react';

import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/meteorologyFieldsLayoutReducer';
import MeteorologyFieldsDisplay from 'components/Meteorology/Fields/Display';
import useObservedDataControlPanel from 'utils/hooks/meteorology/useObservedDataControlPanel';
import useImageRowSize from 'utils/hooks/meteorology/useImageRowSize';
import buildImageDisplayData from 'helper/products/observedData/buildImageDisplayData';
import './index.scss';
import AlertsCollection from 'components/AlertsCollection';

export default function ObservedData() {
  useEffect(() => {
    store.dispatch(setCurrentTab('observeddata'));
  }, []);
  const {
    region,
    selectedPeriod,
    obsBase64,
    climatologyBase64,
    anomalyBase64,
    imagesAreFetching,
    queryTrigger,
    alerts,
  } = useObservedDataControlPanel();
  const { imageSets, columnLabels, rowLabels } = buildImageDisplayData({
    observedData: obsBase64,
    climatology: climatologyBase64,
    anomaly: anomalyBase64,
  });
  const { rowSize, setRowSize } = useImageRowSize();
  const initialRowSize = useRef(rowSize);

  const triggerActivationCondition = region && selectedPeriod;

  useEffect(() => () => { setRowSize(initialRowSize.current); }, [setRowSize]);

  useEffect(() => {
    if (triggerActivationCondition) queryTrigger();
  }, [queryTrigger, triggerActivationCondition]);

  useEffect(() => {
    if (columnLabels.length) setRowSize(columnLabels.length);
  }, [setRowSize, columnLabels.length]);

  return (
    <>
      <div className="mt-3">
        <AlertsCollection alerts={alerts} />
      </div>
      <div className="observed-data-display">
        <div className="my-4">
          <MeteorologyFieldsDisplay
            imageSets={imageSets}
            columnLabels={columnLabels}
            rowLabels={rowLabels}
            fetchingImages={imagesAreFetching}
          />
        </div>
      </div>
    </>
  );
}
