import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Invoice } from '../../helper/financial';

export enum SubmissionStatus {
  QUEUED,
  PROCESSING,
  DONE,
  FAILED,
  CANCELED,
}

export enum ProcessingStatus {
  IDLE,
  PROCESSING,
  DONE,
  CANCELED,
}

export interface SubmissionProcessInterface {
  status: SubmissionStatus,
  error: string | null,
}

export interface SubmissionReport {
  creation: SubmissionProcessInterface,
  submission: SubmissionProcessInterface,
  invoice: Invoice,
}

export interface NfeProcessingState {
  processingStatus: ProcessingStatus,
  nfeSubmissionReport: SubmissionReport[],
}

const initialState: NfeProcessingState = {
  processingStatus: ProcessingStatus.IDLE,
  nfeSubmissionReport: [],
};

export interface SubmissionChange {
  index: number
}

export interface SubmissionStateChange extends SubmissionChange {
  newStatus: SubmissionStatus,
}

export interface SubmissionErrorChange extends SubmissionChange {
  errorMessage?: string
}

export const nfeProcessSlice = createSlice({
  name: 'nfeProcessing',
  initialState,
  reducers: {
    createSubmissionReport: (state, action: PayloadAction<Invoice[]>) => {
      state.nfeSubmissionReport = action.payload.map((invoice) => ({
        creation: {
          error: null,
          status: SubmissionStatus.QUEUED,
        },
        submission: {
          error: null,
          status: SubmissionStatus.QUEUED,
        },
        invoice,
      }));
    },
    modifyProcessingState: (state, action: PayloadAction<ProcessingStatus>) => {
      state.processingStatus = action.payload;
    },
    modifyCreationState: (state, action: PayloadAction<SubmissionStateChange>) => {
      const { index, newStatus } = action.payload;
      state.nfeSubmissionReport[index].creation.status = newStatus;
    },
    modifySubmissionState: (state, action: PayloadAction<SubmissionStateChange>) => {
      const { index, newStatus } = action.payload;
      state.nfeSubmissionReport[index].submission.status = newStatus;
    },
    modifyCreationErrorMessage: (state, action: PayloadAction<SubmissionErrorChange>) => {
      const { index, errorMessage } = action.payload;
      state.nfeSubmissionReport[index].creation.error = errorMessage || null;
    },
    modifySubmissionErrorMessage: (state, action: PayloadAction<SubmissionErrorChange>) => {
      const { index, errorMessage } = action.payload;
      state.nfeSubmissionReport[index].submission.error = errorMessage || null;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectProcessingState = (state: any) => state.nfeProcessing.processingStatus;
export const selectSubmissionReport = (state: any) => state.nfeProcessing.nfeSubmissionReport;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const {
  createSubmissionReport,
  modifyCreationState,
  modifyProcessingState,
  modifySubmissionState,
  modifyCreationErrorMessage,
  modifySubmissionErrorMessage,
} = nfeProcessSlice.actions;

export default nfeProcessSlice.reducer;
