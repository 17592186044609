export interface InvoiceResponseData {
  nfe: string,
  cnpj: string,
  link: string,
  destino: string,
  emissao: string,
  vencimento: string,
  nome_empresa: string,
  site: string[],
  emails: string[],
}

export interface InvoiceResponse {
  data: {
    data: InvoiceResponseData[] | string | null,
    code?: number,
    [otherKeys: string]: unknown,
  } | null,
}

export const transformInvoiceData = (responseData: InvoiceResponse) => {
  const success = Array.isArray(responseData.data?.data);

  if (success) {
    const invoiceData = responseData.data?.data as InvoiceResponseData[];

    return invoiceData.map(({
      nfe, cnpj, nome_empresa, emissao, vencimento, link, emails, site, destino,
    }, index) => ({
      id: index,
      nfe,
      cnpj,
      corporateName: nome_empresa,
      issueDate: emissao,
      dueDate: vencimento,
      url: link,
      emails,
      site,
      city: destino.split(' - ')[1],
      uf: destino.split(' - ')[0],
      tags: [],
    }));
  }

  return [];
};
