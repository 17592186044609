import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MailerLayoutState {
  currentTab: string
  isDanger: boolean
  searchQuery: string
}

const initialState: MailerLayoutState = {
  currentTab: 'reports',
  isDanger: false,
  searchQuery: '',
};

export const mailerLayoutSlice = createSlice({
  name: 'mailerLayout',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    setIsDanger: (state, action: PayloadAction<boolean>) => {
      state.isDanger = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectMailerLayoutCurrentTab = (state: any) => state.mailerLayout.currentTab;
export const selectMailerLayoutIsDanger = (state: any) => state.mailerLayout.isDanger;
export const selectMailerLayoutSearchQuery = (state: any) => state.mailerLayout.searchQuery;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setCurrentTab,
  setIsDanger,
  setSearchQuery,
} = mailerLayoutSlice.actions;

export default mailerLayoutSlice.reducer;
