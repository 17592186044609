import {
  useState, useEffect, useMemo, ChangeEvent,
} from 'react';
import { getUnixTime } from 'date-fns';

import { useSavePastSimulationMutation } from 'services/api/products/flux';
import relativeDateFromToday from 'helper/relativeDateFromToday';
import { RelativeDate } from 'helper/flux/automatic';

interface NewPastSimulation {
  forecastDate: Date | null,
  setForecastDate: (Date) => void,
  acomphDate: Date | null,
  setAcomphDate: (Date) => void,
  setModel: (event: ChangeEvent<HTMLInputElement>) => void,
  version: string,
  setVersion: (event: ChangeEvent<HTMLInputElement>) => void,
  submit: () => void,
  enablePastSimulationAddition: boolean,
}

export default function useNewPastSimulation(afterSuccess: () => void): NewPastSimulation {
  const [forecastDate, setForecastDate] = useState(0);
  const [acomphDate, setAcomphDate] = useState(0);
  const [model, setModel] = useState('');
  const [version, setVersion] = useState('');

  const [savePastSimulation, { isSuccess: successfullySaved }] = useSavePastSimulationMutation();
  const enablePastSimulationAddition = forecastDate > 0 && acomphDate > 0 && model !== '' && version !== '';

  const submit = () => {
    savePastSimulation({
      forecastDate: relativeDateFromToday(forecastDate) as RelativeDate,
      version,
      model,
      acomphDate: relativeDateFromToday(acomphDate) as RelativeDate,
    });
  };

  const setForecastUnixDate = (date: Date) => {
    setForecastDate(getUnixTime(date));
    setVersion('');
  };

  const setAcomphUnixDate = (date: Date) => {
    setAcomphDate(getUnixTime(date));
    setForecastDate(0);
    setVersion('');
  };

  const setValueFromEvent = (event: ChangeEvent<HTMLInputElement>, setter: (string) => void) => {
    setter(event.target.value);
  };

  const setModelFromEvent = (event: ChangeEvent<HTMLInputElement>) => {
    setValueFromEvent(event, setModel);
  };

  const setVersionFromEvent = (event: ChangeEvent<HTMLInputElement>) => {
    setValueFromEvent(event, setVersion);
  };

  useEffect(() => {
    if (successfullySaved) {
      afterSuccess();
    }
  }, [afterSuccess, successfullySaved]);

  const forecastDateConverted = useMemo(
    () => (forecastDate ? new Date(forecastDate * 1000) : null),
    [forecastDate],
  );

  const acomphDateConverted = useMemo(
    () => (acomphDate ? new Date(acomphDate * 1000) : null),
    [acomphDate],
  );

  return {
    enablePastSimulationAddition,
    forecastDate: forecastDateConverted,
    setForecastDate: setForecastUnixDate,
    acomphDate: acomphDateConverted,
    setAcomphDate: setAcomphUnixDate,
    setModel: setModelFromEvent,
    version,
    setVersion: setVersionFromEvent,
    submit,
  };
}
