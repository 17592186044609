import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelPastSimulations,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { useListPastSimulationsQuery } from 'services/api/products/flux';

export default function usePastSimulationNames() {
  const pastSimulationIDs = useSelector(selectFluxAutomaticControlPanelPastSimulations);
  const { data: pastSimulations } = useListPastSimulationsQuery();
  const pastSimulationNames = pastSimulations?.filter(({ id }) => pastSimulationIDs.includes(id))
    .map(({ name }) => name) ?? [];

  return { pastSimulationIDs, pastSimulationNames };
}
