import { useSorting } from 'components/SortingArrow';
import { Sorter } from 'components/SortingArrow/helpers';
import { SORT_ASC, SortDirection } from 'constants/sorting';
import { MASS_EMAIL_SORTABLE_FIELDS, MassEmailSortableField, SORT_BY_IDENTIFIER } from 'services/api/mailer';

export type MassEmailSortingState = [
  MassEmailSortableField,
  SortDirection,
  { [field: string]: Sorter },
];

export default function useMassEmailSorting(): MassEmailSortingState {
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<MassEmailSortableField | undefined>(MASS_EMAIL_SORTABLE_FIELDS);

  return [
    sortBy || SORT_BY_IDENTIFIER,
    sortDirection || SORT_ASC,
    sorters,
  ];
}
