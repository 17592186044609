import { useSelector } from 'react-redux';
import { add, differenceInMonths, fromUnixTime } from 'date-fns';
import {
  selectMFControlPanelDateOffset,
  selectMFControlPanelForecastUnixDate,
  selectMFControlPanelModels,
  selectMFControlPanelPeriod,
  selectMFControlPanelRegion,
} from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { useGetMaxHorizonDateQuery } from 'services/api/Meteorology';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { ResponseError } from 'services/api/base';

function getMaxOffset(baseDate: Date, period: string, nDays: number) {
  const maxDate = add(baseDate, { days: nDays });

  switch (period[0]) {
    case 'd':
      return nDays;
    case 'p':
      return Math.floor(nDays / 5);
    case 's':
      return Math.floor(nDays / 7);
    case 'm':
      return differenceInMonths(maxDate, baseDate);
    default:
      return 0;
  }
}

export default function useMaxOffset() {
  const forecastUnixDate: number = useSelector(selectMFControlPanelForecastUnixDate);
  const baseDate = forecastUnixDate ? fromUnixTime(forecastUnixDate) : new Date();
  const models = useSelector(selectMFControlPanelModels);
  const region = useSelector(selectMFControlPanelRegion);
  const period: string = useSelector(selectMFControlPanelPeriod) || 's+0';
  const offset: number = useSelector(selectMFControlPanelDateOffset) || 0;

  const { data, error } = useGetMaxHorizonDateQuery({
    provisionalDate: forecastUnixDate,
    region: region || '',
    modelList: models || [],
    runtime: 0,
    member: '0',
    AtmLevel: 'single_level',
    variable: 'prec',
    period: period.replace(/\d+/, offset.toString(10)),
  });
  const maxHorizon = data?.data?.num_dias || 0;
  const maxOffset = getMaxOffset(baseDate, period, maxHorizon);

  useHandleRequestErrors(error as ResponseError);

  return maxOffset;
}
