import { useLazyGetReportMailerClientsQuery } from 'services/api/mailer';
import { MailTemplateType } from 'helper/mailer';
import { ResponseError } from 'services/api/base';
import { useEffect } from 'react';
import { useHandleRequestErrors } from '../index';

interface ReportMailClientsHookParams {
  typeId: number,
  date: Date | null,
  subProductId: number,
  types?: MailTemplateType[],
}

export default function useReportMailClientQueryAdapter(
  { date, typeId, subProductId } : ReportMailClientsHookParams,
) {
  const dataString = JSON.stringify({
    year: date?.getFullYear(),
    month: (date?.getMonth() || 0) + 1,
    day: date?.getDate(),
    hours: 0,
    minutes: 0,
    seconds: 0,
  }).replace(/"/g, '%22');

  const [reportMailClientsQueryTrigger, {
    data: reportMailClients, isLoading, error,
  }] = useLazyGetReportMailerClientsQuery();

  useEffect(() => {
    if (typeId >= 0 && subProductId >= 0 && date !== null) {
      reportMailClientsQueryTrigger({ subProductId, dataString, productId: typeId });
    }
  }, [dataString, date, subProductId, reportMailClientsQueryTrigger, typeId]);

  useHandleRequestErrors(error as ResponseError | undefined);
  return { data: reportMailClients?.data?.empresas || [], isLoading };
}
