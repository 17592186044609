import { ListedStudy, transformListStudiesResponse } from 'helper/studies';
import {
  DownloadLinkResponse,
  ListPersonalizedParams,
  PersonalizedDownloadLinkParams,
  PersonalizedHorizon,
  PersonalizedHorizonParams,
  PersonalizedStudyParams,
  RawPersonalizedHorizonResponse,
  SaveCustomStudyResponse,
  transformPersonalizedMaxHorizonResponse,
} from 'helper/personalized';
import { store } from 'redux/store';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import baseApi, { rawBaseQuery } from './base';

export const personalizedApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listPersonalizedStudies: build.query<ListedStudy[], ListPersonalizedParams>({
      query: ({ ampereCases, page, pageSize }) => ({
        url: 'produtos/previvaz-personalizado/get-list',
        method: 'POST',
        body: {
          casos_ampere: ampereCases,
          page,
          page_size: pageSize,
        },
      }),
      transformResponse: transformListStudiesResponse,
      providesTags: ['PersonalizedStudy'],
    }),
    createPersonalizedRequest: build.mutation<SaveCustomStudyResponse, PersonalizedStudyParams>({
      query: (params) => {
        const productKey = store.getState().productKeys['prevs-personalizado'];

        return {
          url: `produtos/previvaz-personalizado/save/?product_key=${productKey}`,
          method: 'POST',
          body: params,
        };
      },
    }),
    personalizedExecutionQueue: build.mutation<SaveCustomStudyResponse, number>({
      query: (studyID) => {
        const productKey = store.getState().productKeys['prevs-personalizado'];

        return {
          url: `produtos/previvaz-personalizado/send-data/?product_key=${productKey}&estudo=${studyID}`,
          method: 'POST',
        };
      },
    }),
    personalizedDownloadLink: build.mutation<DownloadLinkResponse, PersonalizedDownloadLinkParams>({
      query: (params) => {
        const productKey = store.getState().productKeys['prevs-personalizado'];

        return {
          url: `produtos/previvaz-personalizado/get-download-link/?product_key=${productKey}`,
          method: 'POST',
          body: params,
        };
      },
    }),
    personalizedHorizon: build.query<PersonalizedHorizon, PersonalizedHorizonParams>({
      queryFn: async (params, api, extraOptions) => {
        const productKey = store.getState().productKeys['prevs-personalizado'];

        const response = await rawBaseQuery(
          {
            url: `produtos/previvaz-personalizado/horizonte-max/?product_key=${productKey}`,
            method: 'POST',
            body: {
              data_previsao: params.forecastDate,
              lista_modelos: [params.model],
              runtime: params.runtime,
              membro: params.member,
              nivel_atm: 'single_level',
              var: 'prec',
            },
          },
          api,
          extraOptions,
        ) as QueryReturnValue<RawPersonalizedHorizonResponse, unknown, unknown>;

        const transformedResponse = {
          ...response,
          ...transformPersonalizedMaxHorizonResponse(response.data),
        } as QueryReturnValue<PersonalizedHorizon, unknown, unknown>;

        return transformedResponse;
      },
    }),
  }),
});

export const {
  useListPersonalizedStudiesQuery,
  useCreatePersonalizedRequestMutation,
  usePersonalizedExecutionQueueMutation,
  usePersonalizedDownloadLinkMutation,
  useLazyPersonalizedHorizonQuery,
} = personalizedApi;
