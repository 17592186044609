import {
  BaseQueryFn, createApi, fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { selectAuthToken } from '../../redux/reducers/authReducer';

export type ResponseError = {
  status: number | string,
  data: string,
  meta?: {
    url?: string,
    method?: string,
    args: any,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GetState = { getState: () => any };
type PrepareHeadersFunc = (headers: Headers, options: GetState) => Headers;

export interface GenericResponse<DataType, MessageType = string> {
  status: number,
  code: number,
  type: number,
  title: string,
  message: MessageType,
  data: DataType,
}

const prepareHeaders: PrepareHeadersFunc = (headers: Headers, { getState }: GetState): Headers => {
  const token = selectAuthToken(getState());
  if (token !== '') {
    headers.set('Authorization', token);
  }

  return headers;
};

export const baseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AMPERE_API_URL,
    prepareHeaders,
  })(args, api, extraOptions);

  if (result.error) {
    const error: ResponseError = {
      status: result.error.status,
      data: (result.error as any).error,
      meta: {
        url: result.meta?.request?.url,
        method: result.meta?.request?.method,
        args: args.body,
      },
    };

    return { error };
  }

  const data = result.data as { code: number, message: string };
  if (!data) {
    return result;
  }

  if (data.code !== 200) {
    const error: ResponseError = {
      status: data.code,
      data: data.message,
      meta: {
        url: result.meta?.request?.url,
        method: result.meta?.request?.method,
        args: args.body,
      },
    };

    return { error };
  }

  return result;
};

export const rawBaseQuery: BaseQueryFn = async (args, api, extraOptions) => fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AMPERE_API_URL,
  prepareHeaders,
})(args, api, extraOptions);

const baseApi = createApi({
  baseQuery,
  tagTypes: ['User', 'Company', 'Contract', 'Videos', 'ObservedDataPreferences', 'PntPreferences', 'ComparatorPreferences', 'Study', 'PastSimulation', 'FaPreferences', 'PersonalizedStudy'],
  endpoints: () => ({}),
});

export { prepareHeaders };
export default baseApi;
