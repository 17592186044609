import React, { useEffect, useState } from 'react';
import FilterCategory from 'components/Filters/FilterCategory';
import FilterModal from 'components/Filters/FilterModal';
import { onCheckChange, onDatePickerChange, onExclusiveCheckChange } from 'helper/controlledField';
import { ListedProduct } from 'helper/products';
import { Accordion, FormCheck, Spinner } from 'react-bootstrap';
import { loadCompanyFilters, onProductCheckChange } from 'helper/filterModals';
import './CompanyFilterModal.scss';
import { useClosure } from 'utils/hooks';
import executeFunctionList from 'utils/executeFunctionList';
import { CompanyModalFilters } from 'helper/companyFilters';
import DatePicker from 'components/DatePicker';

interface CompanyFilterModalProps {
  open: boolean
  closeModal: () => void
  products: ListedProduct[] | undefined
  filters: CompanyModalFilters
  setFilters: React.Dispatch<React.SetStateAction<CompanyModalFilters>>
}

export default function CompanyFilterModal({
  open,
  closeModal,
  products,
  filters,
  setFilters,
}: CompanyFilterModalProps) {
  const [selectedProducts, setSelectedProducts] = useState([] as number[]);
  const [contractEndsBefore, setContractEndsBefore] = useState<Date | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [isExpiringIn30, setIsExpiringIn30] = useState(false);
  const [isExpiringIn60, setIsExpiringIn60] = useState(false);
  const [isMetereology, setIsMetereology] = useState(false);
  const [isConsultancy, setIsConsultancy] = useState(false);

  const productCheckboxes = products === undefined ? <Spinner /> : (
    products.map((product) => (
      <FormCheck
        key={product.id}
        label={product.nome}
        checked={selectedProducts.includes(product.id)}
        onChange={onProductCheckChange(
          product.id,
          selectedProducts,
          setSelectedProducts,
        )}
      />
    ))
  );

  useEffect(() => {
    if (open) {
      loadCompanyFilters(
        filters,
        setSelectedProducts,
        setContractEndsBefore,
        setIsActive,
        setIsInactive,
        setIsTest,
        setIsExpiringIn30,
        setIsExpiringIn60,
        setIsMetereology,
        setIsConsultancy,
      );
    }
  }, [open, filters]);

  return (
    <FilterModal
      open={open}
      closeModal={closeModal}
      onClearClick={executeFunctionList([
        useClosure(setSelectedProducts, []),
        useClosure(setContractEndsBefore, null),
        useClosure(setIsActive, false),
        useClosure(setIsInactive, false),
        useClosure(setIsTest, false),
        useClosure(setIsExpiringIn30, false),
        useClosure(setIsExpiringIn60, false),
        useClosure(setIsMetereology, false),
        useClosure(setIsConsultancy, false),
      ])}
      onSaveClick={executeFunctionList([
        useClosure(setFilters, {
          selectedProducts,
          contractEndsBefore,
          isActive,
          isInactive,
          isTest,
          isExpiringIn30,
          isExpiringIn60,
          isMetereology,
          isConsultancy,
        }),
        useClosure(closeModal),
      ])}
    >
      <Accordion>
        <FilterCategory name="Produto">
          {productCheckboxes}
        </FilterCategory>
        <FilterCategory name="Vigência">
          <DatePicker
            inline
            selected={contractEndsBefore}
            onChange={onDatePickerChange(setContractEndsBefore)}
          />
        </FilterCategory>
        <FilterCategory name="Status">
          <FormCheck
            id="check-active"
            label="Ativo"
            checked={isActive}
            onChange={onExclusiveCheckChange(isActive, isInactive, setIsActive, setIsInactive)}
          />
          <FormCheck
            id="check-inactive"
            label="Inativo"
            checked={isInactive}
            onChange={onExclusiveCheckChange(isInactive, isActive, setIsInactive, setIsActive)}
          />
          <FormCheck
            id="check-test"
            label="Teste"
            checked={isTest}
            onChange={onCheckChange(isTest, setIsTest)}
          />
          <FormCheck
            id="check-expiring-30"
            label="30 dias"
            checked={isExpiringIn30}
            onChange={onCheckChange(isExpiringIn30, setIsExpiringIn30)}
          />
          <FormCheck
            id="check-expiring-60"
            label="30 - 60 dias"
            checked={isExpiringIn60}
            onChange={onCheckChange(isExpiringIn60, setIsExpiringIn60)}
          />
        </FilterCategory>
      </Accordion>
      <div className="outer-checkboxes">
        <hr />
        <FormCheck
          id="check-metereology"
          label="Ampere Meteorologia"
          checked={isMetereology}
          onChange={
            onExclusiveCheckChange(isMetereology, isConsultancy, setIsMetereology, setIsConsultancy)
          }
        />
        <FormCheck
          id="check-consultancy"
          label="Ampere Consultoria"
          checked={isConsultancy}
          onChange={
            onExclusiveCheckChange(isConsultancy, isMetereology, setIsConsultancy, setIsMetereology)
          }
        />
      </div>
    </FilterModal>
  );
}
