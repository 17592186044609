import React, { useEffect, useRef } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import Select from 'react-select';
import { CEPInput, CNPJInput } from 'components/Forms/Input';
import AlertsCollection from 'components/AlertsCollection';
import useStatesAndCitiesSelect from 'utils/hooks/useStatesAndCitiesSelect';
import ContractFormFields from 'components/Admin/Contracts/FormFields';
import useSaveCompany from './hooks/useSaveCompany';
import useGetCompanyData from './hooks/useGetCompanyData';
import useSendContractAttachment from './hooks/useSendContractAttachment';

interface CompaniesFormProps {
  companyId: number,
  contractId: number,
}

export default function CompaniesForm({ companyId, contractId }: CompaniesFormProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    alerts, companySaveHook, requestSuccess, validated, isLoading: isSaveLoading,
  } = useSaveCompany();
  const { companyData, isCompanyLoading } = useGetCompanyData(companyId);

  const {
    stateOptions,
    cityOptions,
    isStatesLoading,
    cityValue,
    stateValue,
    onCityChange,
    onStateChange,
  } = useStatesAndCitiesSelect();

  useEffect(() => {
    onStateChange({ label: companyData?.estado?.uf, value: companyData?.estado?.id });
    onCityChange({ label: companyData?.municipio?.nome, value: companyData?.municipio?.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  const {
    isFileUploadLoading,
    isFileUploadSuccess,
    fileInputOnChange,
  } = useSendContractAttachment({ companyId, contractId });

  useEffect(() => {
    const modalBody = containerRef.current?.parentElement;
    const hasError = alerts.some((alert) => alert.variant === 'danger');
    if (modalBody && hasError) modalBody.scrollTop = 0;
  }, [alerts]);

  if (requestSuccess) {
    return (<> Empresa salva com sucesso </>);
  }

  if (isStatesLoading || isCompanyLoading) {
    return <Spinner />;
  }

  return (
    <div ref={containerRef}>
      <Form
        onSubmit={companySaveHook}
        validated={validated}
        noValidate
      >
        <fieldset disabled={isSaveLoading}>
          <Row>
            <Col>
              <AlertsCollection alerts={alerts} />
            </Col>
          </Row>
          <Form.Control hidden name="id" value={companyId} readOnly />
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="corporateName">
                <Form.Label>Razão Social</Form.Label>
                <Form.Control required type="text" name="corporateName" defaultValue={companyData?.razao_social} />
                <Form.Control.Feedback type="invalid">
                  A razão social não pode ficar em branco
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Group controlId="companyFantasyName">
                <Form.Label>Nome Fantasia</Form.Label>
                <Form.Control type="text" name="fantasyName" defaultValue={companyData?.nome_fantasia} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Group controlId="companyMoniker">
                <Form.Label>Apelido</Form.Label>
                <Form.Control required type="text" name="moniker" defaultValue={companyData?.apelido} />
                <Form.Control.Feedback type="invalid">
                  O apelido não pode ficar em branco
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* Should this be the fiscal_emails? Will we need more than one? */}
          <Row className="my-4">
            <Col>
              <Form.Group controlId="companyEmail">
                <Form.Label>E-mails fiscais (separados por ponto e vírgula)</Form.Label>
                <Form.Control type="text" name="fiscalEmails" defaultValue={companyData?.emails_fiscais} />
                <Form.Control.Feedback type="invalid">
                  E-mail inválido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Group controlId="companyCnpj">
                <Form.Label>CNPJ</Form.Label>
                <CNPJInput defaultValue={companyData?.cnpj} />
                <Form.Control.Feedback type="invalid">
                  O CNPJ deve ser válido
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Group controlId="companyBelongsToAmpere">
                <Form.Check type="checkbox" name="internal" label="Pertence a Ampere" defaultChecked={companyData?.parte} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Group controlId="companyAddress">
                <Form.Label>Endereço</Form.Label>
                <Form.Control type="text" name="address" defaultValue={companyData?.endereco} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="3">
              <Form.Group controlId="companyNumber">
                <Form.Label>Número</Form.Label>
                <Form.Control type="text" name="number" defaultValue={companyData?.numero} />
              </Form.Group>
            </Col>
            <Col md="3">
              <Form.Group controlId="companyComplement">
                <Form.Label>Complemento</Form.Label>
                <Form.Control type="text" name="complement" defaultValue={companyData?.complemento} />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="companyCEP">
                <Form.Label>CEP</Form.Label>
                <CEPInput defaultValue={companyData?.cep} />
                <Form.Control.Feedback type="invalid">
                  O CEP não pode ficar em branco
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="2">
              <Form.Group controlId="companyUF">
                <Form.Label>UF</Form.Label>
                <Select
                  id="companyState"
                  options={stateOptions}
                  onChange={onStateChange}
                  placeholder=""
                  name="uf"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: '1.6px',
                    }),
                  }}
                  value={stateValue}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="companyCity">
                <Form.Label>Cidade</Form.Label>
                <Select
                  id="companyCity"
                  options={cityOptions}
                  onChange={onCityChange}
                  placeholder=""
                  name="city"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderRadius: '1.6px',
                    }),
                  }}
                  value={cityValue}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="companyDistrict">
                <Form.Label>Bairro</Form.Label>
                <Form.Control type="text" name="district" defaultValue={companyData?.bairro} />
              </Form.Group>
            </Col>
          </Row>

          {companyId !== 0 && (
          <Row>
            <Col>
              <Form.Group controlId="companyAttachment">
                <Form.Label>Anexar arquivos</Form.Label>
                <Form.Control type="file" name="attachment" onChange={fileInputOnChange} disabled={isFileUploadLoading} />
                {isFileUploadLoading && <Spinner />}
                {isFileUploadSuccess && <div className="form-text">Arquivo anexado com sucesso.</div>}
              </Form.Group>
            </Col>
          </Row>
          )}

          <ContractFormFields contractId={contractId} />

          <Row className="mt-4 mb-1">
            <Col>
              <Button className="primary-button mt-3 float-end" type="submit">
                Salvar
                {isSaveLoading && <Spinner as="span" size="sm" className="ms-2" />}
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
}
