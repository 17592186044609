import React from 'react';
import MailerLayout from 'components/Layout/Admin/Mailer';
import massEmailRouter from './MassEmailRouter';
import reportsRouter from './ReportsRouter';
import templatesRouter from './TemplatesRouter';

const mailerRouter = {
  path: 'mailer',
  element: <MailerLayout />,
  children: [
    reportsRouter,
    massEmailRouter,
    templatesRouter,
  ],
};

export default mailerRouter;
