import React from 'react';
import { APIResponse } from 'helper/apiResponse';
import { Product, RawProductList, transformProductList } from 'helper/products';
import { ListedUser } from 'helper/users';

export interface BaseCompany {
  razao_social: string,
  active: boolean,
}

export interface State {
  id: number,
  nome: string,
  uf: string,
  id_regiao: number,
  regiao: string,
}

export interface City {
  id: number,
  nome: string,
  id_uf: number,
  cod_ibge: string,
}

export interface ListedCompany extends BaseCompany {
  // NOTE: defining indexable fields allows us to read and write to fields of this type using
  // variables and other dynamic values.
  [key: string]: number | string | boolean | null | State | City | ListedUser[],
  id: number,
  nome_fantasia: string,
  apelido: string,
  cnpj: string,
  ie: string,
  im: string,
  endereco: string,
  numero: string,
  complemento: string,
  bairro: string,
  cidade: string | number,
  uf: number,
  cep: string,
  parte: boolean,
  emails_fiscais: string,
  estado: State
  municipio: City,
  users: ListedUser[]
}

export interface ContractCsvParams {
  name_contains?: string,
  product_ids?: number[],
  contract_ends_before?: string,
  consulting?: boolean,
  enabled?: boolean,
  expiring_in_30_days?: boolean,
  expiring_in_60_days?: boolean,
  is_test?: boolean,
}

export interface RawListedCompanyField { id: string, value: string | number | boolean | null }
export type RawListedCompany = RawListedCompanyField[];
export type RawCompanyListResponse = APIResponse<RawListedCompany[]>;
export type RawGetCompanyResponse = APIResponse<ListedCompany>;

export interface BaseCompanyContract extends BaseCompany {
  contrato_id: number,
  empresa_id: number,
  dt_inicio: string,
  dt_fim: string,
  status: string,
}

export interface RawCompanyContract extends BaseCompanyContract {
  produtos: RawProductList,
}

export interface CompaniesContractsRawResponse {
  code: number,
  data: RawCompanyContract[],
  message: null,
  status: number,
  title: null,
  type: number
}

export interface ListedCompanyContract extends BaseCompanyContract {
  products: Product[],
}

export const transformCompanyListResponse = (response: RawCompanyListResponse): ListedCompany[] => (
  response.data.map((companyFields: RawListedCompany) => {
    const listedCompany: ListedCompany = {} as ListedCompany;
    companyFields.forEach((companyField: RawListedCompanyField) => {
      listedCompany[companyField.id] = companyField.value;
    });
    return listedCompany;
  })
);

// eslint-disable-next-line max-len
export const transformCompaniesContractsResponse = (response: CompaniesContractsRawResponse): ListedCompanyContract[] => (
  response.data.map((rawCompanyContractData: RawCompanyContract) => ({
    ...rawCompanyContractData,
    products: transformProductList(rawCompanyContractData.produtos),
  }))
);

export const activityToggleMessage = (active: boolean): JSX.Element => (
  <div>
    Você tem certeza que deseja
    <br />
    <strong>{active ? 'desabilitar ' : 'habilitar '}</strong>
    essa empresa?
  </div>
);
