import { ResponseError } from 'services/api/base';
import { useGetOverviewOptionsQuery } from 'services/api/Meteorology/Weather/Overview';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useOverviewOptions() {
  const {
    data, error, isLoading,
  } = useGetOverviewOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  return { overviewOptions: data, isLoading };
}
