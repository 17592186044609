import React, { useState } from 'react';
import PlusButton from 'components/PlusButton';
import FormModal from 'components/FormModal';
import VideoForm from 'components/Videos/Form';
import { useClosure } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import EmptyListAlert from 'components/EmptyListAlert';
import { VideoProduct } from 'helper/videos';
import useVideoList from 'utils/hooks/videos/useVideoList';
import VideoListRow from './VideoListRow';
import VideoGridRow from './VideoGridRow';

import './index.scss';

export default function List({
  categoryId,
  searchQuery,
  uploadDate,
  product,
  viewOnly,
}: {
  categoryId: any;
  searchQuery: string;
  uploadDate: string;
  product: VideoProduct;
  viewOnly: boolean;
}) {
  const [showVideoForm, setShowVideoForm] = useState(false);
  const [videoFormTitle, setVideoFormTitle] = useState('');
  const [videoId, setVideoId] = useState<number | undefined>();
  const {
    rowSize,
    setRowSize,
    videos,
    videoSets,
    isFetching,
    listEnded,
  } = useVideoList(
    categoryId,
    searchQuery,
    uploadDate,
    product,
  );
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const openForm = useClosure(setShowVideoForm, true);

  const closeForm = () => {
    setShowVideoForm(false);
    setVideoId(undefined);
  };

  const openCreateForm = () => {
    setVideoFormTitle('Adicionar Vídeo');
    setVideoId(undefined);
    openForm();
  };

  const openEditForm = (id: number) => {
    setVideoFormTitle('Editar Vídeo');
    setVideoId(id);
    openForm();
  };

  const plusButton = (
    <div className="p-3">
      <PlusButton
        id="new-video"
        label="Adicionar vídeo"
        onClick={openCreateForm}
      />
    </div>
  );

  return (
    <div>
      <div>
        <FormModal
          show={showVideoForm}
          title={videoFormTitle}
          onHide={closeForm}
        >
          <VideoForm
            key={videoId}
            id={videoId}
            product={product}
          />
        </FormModal>
      </div>
      <div className="pb-4">
        <div className="d-flex justify-content-between">
          {userIsAdmin && !viewOnly ? plusButton : <div />}
          <div className="d-flex justify-content-between mt-4 me-2">
            <FontAwesomeIcon
              icon={faList}
              size="xl"
              className={`me-3 video-list-grid-toggle ${rowSize === 1 ? 'enabled' : 'disabled'}`}
              onClick={() => setRowSize(1)}
            />
            <FontAwesomeIcon
              icon={faGripVertical}
              size="xl"
              className={`me-3 video-list-grid-toggle ${rowSize > 1 ? 'enabled' : 'disabled'}`}
              onClick={() => setRowSize(3)}
            />
          </div>
        </div>
        {rowSize === 1 && (
          <EmptyListAlert
            component={VideoListRow}
            array={videos.map((video) => ({
              product,
              viewOnly,
              openEditForm,
              data: video,
              key: video.id,
            }))}
            message="Não foram encontrados vídeos nesta categoria"
            isFetching={isFetching}
            listEnded={listEnded}
          />
        )}
        {rowSize > 1 && (
          <EmptyListAlert
            component={VideoGridRow}
            array={videoSets.map((videoSet) => ({
              product,
              viewOnly,
              openEditForm,
              data: videoSet,
              key: videoSet[0].id,
            }))}
            message="Não foram encontrados vídeos nesta categoria"
            isFetching={isFetching}
            listEnded={listEnded}
          />
        )}
      </div>
    </div>
  );
}
