import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { NamedListedReportRecipientCompany } from 'helper/mailer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { GRAY2, GREEN } from 'constants/colors';
import ReportUserCard from '../../ReportUserCard';
import './index.scss';

export default function RecipientCompanyRow({ recipientCompany }: {
  recipientCompany: NamedListedReportRecipientCompany,
}) {
  return (
    <Accordion.Item eventKey={String(recipientCompany.id)}>
      <Accordion.Header>
        <Row className="w-100 align-items-center">
          <Col md="9">{recipientCompany.name}</Col>
          <Col md="1">
            <FontAwesomeIcon
              icon={faFileWord}
              color={recipientCompany.wordGenerated ? GREEN : GRAY2}
              size="lg"
            />
          </Col>
          <Col md="1">
            <FontAwesomeIcon
              icon={faFilePdf}
              color={recipientCompany.pdfGenerated ? GREEN : GRAY2}
              size="lg"
            />
          </Col>
          <Col md="1">
            <div>
              <FontAwesomeIcon
                icon={faEnvelope}
                color={recipientCompany.emailGenerated ? GREEN : GRAY2}
                size="lg"
              />
              <span className="mail-read-badge">{recipientCompany.emails.length}</span>
            </div>
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        <Row className="mx-3">
          {recipientCompany.emails.map((email) => (
            <Col key={email.id} md={6} xl={4}>
              <ReportUserCard email={email} />
            </Col>
          ))}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
