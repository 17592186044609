import { LabeledScenarios, SpatialSubdivisions } from 'helper/flux/automatic';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAutomaticStudyScenarios,
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
  setAutomaticStudyScenarios,
  toggleAutomaticStudy,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { useAutomaticOptionsQuery } from 'services/api/products/flux';
import { ResponseError } from 'services/api/base';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function useAutomaticOptions() {
  const dispatch = useDispatch();
  const { data, isFetching, error } = useAutomaticOptionsQuery();
  useHandleRequestErrors(error as ResponseError);

  const labeledScenarios = data?.cenarios || [];

  const subdivisions: SpatialSubdivisions = {
    bacias: data?.bacias || [],
    reservatorios: data?.reservatorios || [],
    postos: data?.postos || [],
    subsistemas: data?.subsistemas || [],
  };

  const loadedStudyOptions = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);

  const setAutomaticStudyOption = (automaticStudyOption: LabeledScenarios) => {
    dispatch(setAutomaticStudyScenarios(automaticStudyOption));
  };

  const loadFrequentScenario = (frequentScenario: LabeledScenarios) => {
    const newScenarios = frequentScenario.scenarios.filter((scenario) => scenario.selectable);
    loadedStudyOptions.forEach((scenario) => {
      if (!(
        frequentScenario.scenarios.some(
          (frequentScenarioValue) => frequentScenarioValue.scenario === scenario,
        )
      )) {
        newScenarios.push({ scenario, selectable: true });
      }
    });
    const scenariosToBeEnabled: LabeledScenarios = {
      label: frequentScenario.label,
      scenarios: newScenarios,
    };
    setAutomaticStudyOption(scenariosToBeEnabled);
  };

  const switchOptionsSelection = (optionName: string) => dispatch(toggleAutomaticStudy(optionName));

  const clearStudyOptions = () => dispatch(clearAutomaticStudyScenarios());

  const checkOptionSelection = (option: string) => loadedStudyOptions.includes(option);

  const checkLabeledScenarios = (selectedLabeledScenarios: LabeledScenarios) => {
    const allScenariosIncluded = selectedLabeledScenarios.scenarios.every((labeledScenario) => {
      const isIncluded = loadedStudyOptions.includes(labeledScenario.scenario);
      if (labeledScenario.selectable) {
        return isIncluded;
      }
      return !isIncluded;
    });
    const selectedOptions = selectedLabeledScenarios.scenarios.map(
      (scenario) => scenario.scenario,
    );
    const noExtraScenarios = loadedStudyOptions.every(
      (loadedStudyOption) => !selectedOptions.includes(loadedStudyOption)
        || selectedLabeledScenarios.scenarios.some(
          (selectedScenario) => selectedScenario.scenario === loadedStudyOption
          && selectedScenario.selectable,
        ),
    );
    return allScenariosIncluded && noExtraScenarios;
  };

  return {
    subdivisions,
    labeledScenarios,
    isFetching,
    setAutomaticStudyOption,
    loadFrequentScenario,
    switchOptionsSelection,
    loadedStudyOptions,
    clearStudyOptions,
    checkOptionSelection,
    checkLabeledScenarios,
  };
}
