import React, { useState } from 'react';

import { ListedCompanyContract } from 'helper/companies';
import Details from 'components/Admin/Companies/Table/Row/Details';

export default function useDetails() {
  const [details, setDetails] = useState(<div>Detalhes</div>);

  const load = (company: ListedCompanyContract) => {
    setDetails(<Details company={company} />);
  };

  return { details, load };
}
