import React from 'react';

import ObservedData from 'views/Meteorology/Fields/ObservedData';
import NumericForecast from 'views/Meteorology/Fields/NumericForecast';
import Comparator from 'views/Meteorology/Fields/Comparator';
import SynopticCharts from 'views/Meteorology/Fields/SynopticCharts';
import MeteorologyFieldsLayout from 'components/Layout/Meteorology/Fields';

const fieldsRouter = {
  path: 'fields',
  element: <MeteorologyFieldsLayout />,
  children: [
    { path: 'observeddata', element: <ObservedData /> },
    { path: 'numericforecast', element: <NumericForecast /> },
    { path: 'comparator', element: <Comparator /> },
    { path: 'synopticcharts', element: <SynopticCharts /> },
  ],
};

export default fieldsRouter;
