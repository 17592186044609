import React from 'react';
import FluxUploadWrapper from 'components/RouteWrappers/Flux/Upload';
import Upload from 'views/Flux/Upload';
import New from 'views/Flux/Upload/New';

const uploadRouter = {
  path: 'upload',
  element: <FluxUploadWrapper />,
  children: [
    { path: '', element: <Upload /> },
    { path: 'new', element: <New /> },
  ],
};

export default uploadRouter;
