import React from 'react';

import { useLocation } from 'react-router-dom';

import DefaultLayout from './Default';
import AuthLayout from './Auth';
import EmptyLayout from './Empty';

export default function Layout() {
  const authLayoutPaths = [/(signin|password-*)/];
  const emptyLayoutPaths = [/error/];

  const location = useLocation();

  if (authLayoutPaths.some((path) => path.test(location.pathname))) {
    return <AuthLayout />;
  }

  if (emptyLayoutPaths.some((path) => path.test(location.pathname))) {
    return <EmptyLayout />;
  }

  return <DefaultLayout />;
}
