import { useEffect, useState } from 'react';
import { handleApiError } from 'helper/error';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';
import {
  ReportSortableField, useListReportsQuery,
} from 'services/api/mailer';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import useListEnd from '../useListEnd';

export default function useReportListing({
  pageSize,
  orderBy,
  orderDirection,
  searchQuery,
}: {
  pageSize: number,
  orderBy: ReportSortableField,
  orderDirection: 'ASC' | 'DESC',
  searchQuery: string,
}) {
  const [maxLength, setMaxLength] = useState<number>(-1);
  const { page } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);
  const {
    data,
    error,
    isLoading,
    isFetching,
  } = useListReportsQuery({
    page: 1,
    page_size: dataLength,
    order_by: orderBy,
    order_direction: orderDirection,
    search_terms: searchQuery,
  });
  const navigate = useNavigate();
  const { listEnded } = useListEnd(data, page, pageSize, isFetching);

  useEffect(() => {
    if (error) {
      handleApiError(navigate, error as ResponseError);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, data)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, data, isFetching]);

  return {
    reportList: data,
    isLoading,
    isFetching,
    listEnded,
  };
}
