import React, { useEffect, useState } from 'react';
import { store } from 'redux/store';
import { setCurrentTab, setSearchQuery } from 'redux/reducers/mailerLayoutReducer';
import MassEmailListing from 'components/Admin/Mailer/MassEmail/Listing';
import MassEmailNew from 'components/Admin/Mailer/MassEmail/New';
import { useClosure } from 'utils/hooks';

export default function MailerMassEmail() {
  useEffect(() => {
    store.dispatch(setCurrentTab('mass-emails'));
    store.dispatch(setSearchQuery(''));
  }, []);
  const [isNewMassEmail, setIsNewMassEmail] = useState(false);
  const onNewClick = useClosure(setIsNewMassEmail, true);
  const onCloseClick = useClosure(setIsNewMassEmail, false);

  return (
    <>
      {isNewMassEmail && <MassEmailNew close={onCloseClick} />}
      {!isNewMassEmail && <MassEmailListing onNewClick={onNewClick} />}
    </>
  );
}
