import React, { useState, FormEvent } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import {
  useSaveComparatorPreferencesMutation,
  useListComparatorPreferencesQuery,
  SaveComparatorPreferencesParams,
  ComparatorPreferenceModel,
} from 'services/api/Meteorology';
import useComparatorSavePreferencesModal from 'utils/hooks/meteorology/useComparatorSavePreferencesModal';
import useComparatorControlPanel from 'utils/hooks/meteorology/comparator/useComparatorControlPanel';

interface ErrorObj {
  status: number;
  data: string;
}

export default function SavePreferencesForm() {
  const [name, setName] = useState('');
  const {
    setShowFormModal,
    setShowConfirmationModal,
    setShowErrorModal,
    setError,
  } = useComparatorSavePreferencesModal();
  const {
    baseModel,
    baseForecastDateDiff,
    baseRuntime,
    baseMember,
    baseRMV,
    confrontationalModel,
    confrontationalForecastDateDiff,
    confrontationalRuntime,
    confrontationalMember,
    confrontationalRMV,
    period,
  } = useComparatorControlPanel();
  const [saveComparatorPreferences, {
    isLoading,
  }] = useSaveComparatorPreferencesMutation();
  const { data } = useListComparatorPreferencesQuery();
  const preferenceNames = data?.data.map((preference) => preference.name);
  const valid = name.length > 0 && !(preferenceNames?.includes(name)) && !isLoading;

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const baseModelParam: ComparatorPreferenceModel = {
      atmLevel: 'single_level',
      forecastDate: baseForecastDateDiff,
      member: baseMember,
      meteorologicVariable: 'prec',
      modelName: baseModel,
      rmv: baseRMV ? 1 : 0,
      runtime: baseRuntime,
    };
    const confrontingModelParam: ComparatorPreferenceModel = {
      atmLevel: 'single_level',
      forecastDate: confrontationalForecastDateDiff,
      member: confrontationalMember,
      meteorologicVariable: 'prec',
      modelName: confrontationalModel,
      rmv: confrontationalRMV ? 1 : 0,
      runtime: confrontationalRuntime,
    };
    const params: SaveComparatorPreferencesParams = {
      id: 0,
      baseModel: baseModelParam,
      confrontingModel: confrontingModelParam,
      name,
      period: `${period}+0`,
    };
    const response = await saveComparatorPreferences(params);
    setName('');
    if ('error' in response) {
      const error = response.error as ErrorObj;
      setShowErrorModal(true);
      setError(error.data);
    } else {
      setShowConfirmationModal(true);
    }
    setShowFormModal(false);
  };

  const nameChange = (event) => {
    setName(event.target.value);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Col className="col-md-6">
            Antes de salvar, dê um
            {' '}
            <b>nome</b>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-md-6">
            <p>
              para sua nova preferência.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="preferenceName">
              <Form.Control required type="text" onInput={nameChange} />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col className="col-md-6">
            <Button className={valid ? 'mt-3 primary-button' : 'mt-3 secondary-button'} type="submit" disabled={!valid}>
              {isLoading && <Spinner size="sm" />}
              <span>
                Salvar nova preferência
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
