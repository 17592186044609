import React from 'react';
import { Button } from 'react-bootstrap';
import CloseButton from 'components/CloseButton';
import { ListedCompany } from 'helper/companies';
import { removeAllCompanies, removeCompanies } from 'helper/reportForm';

export default function SelectedCompanyList({
  companies,
  companyIds,
  setCompanyIds,
} : {
  companies: ListedCompany[],
  companyIds: number[],
  setCompanyIds: React.Dispatch<React.SetStateAction<number[]>>,
}) {
  if (companies.length < 1) {
    return (
      <div className="text-danger">
        <span className="ms-3 fw-bold">SELECIONADAS</span>
        <hr className="bg-white" />
        <p className="text-center report-form-company-select">
          Nenhuma empresa selecionada
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between position-relative mb-2">
        <span className="ms-3 fw-bold">SELECIONADAS</span>
        <Button
          onClick={() => removeAllCompanies(setCompanyIds)}
          size="sm"
          className="position-absolute top-0 end-0"
        >
          Limpar
        </Button>
      </div>
      <hr className="bg-white" />
      <div className="report-form-company-select">
        {companies.map((company) => (
          <div key={company.id}>
            <div className="mx-3">
              <CloseButton
                id={`add-company-${company.id}`}
                label={company.nome_fantasia}
                onClick={() => removeCompanies(setCompanyIds, companyIds, [company.id])}
              />
            </div>
            <hr className="bg-white" />
          </div>
        ))}
      </div>
    </>
  );
}
