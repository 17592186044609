import React, { useEffect } from 'react';
import {
  ScrollRestoration, Outlet, useNavigate, NavigateFunction, useLocation,
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import { useSelector } from 'react-redux';
import { selectAuthToken } from 'redux/reducers/authReducer';
import SubmissionProgressIndicator from 'components/SubmissionProgressIndicator';
import useSubmissionStatus from 'utils/hooks/financial/useSubmissionStatus';
import logout from 'utils/logout';
import useTokenExpirationCheck from '../../../utils/hooks/useTokenExpirationCheck';
import Sidebar from './Sidebar';
import './index.scss';
import { store } from '../../../redux/store';
import { setInfiniteScrollBottomState } from '../../../redux/reducers/infiniteScrollReducer';

export function defaultLayoutOnScroll(event: React.UIEvent<HTMLElement>) {
  const div = event.target as HTMLDivElement;
  const bottom = Math.abs(div.scrollHeight - div.scrollTop - div.clientHeight) < 2;
  store.dispatch(setInfiniteScrollBottomState(bottom));
}

export function checkAccessToken(navigate: NavigateFunction, accessToken?: string): void {
  if (!accessToken) {
    logout(navigate, {
      title: '',
      message: 'Você precisa estar logado para acessar esta página.',
      variant: 'warning',
    });
  }
}

export default function DefaultLayout() {
  const navigate = useNavigate();
  const accessToken = useSelector(selectAuthToken);
  const location = useLocation();
  useTokenExpirationCheck(navigate);
  const adminPath = /admin/;
  const isAdminPage = adminPath.test(location.pathname);
  const bodyOutletClassName = isAdminPage ? 'body-outlet-admin' : 'body-outlet';

  useEffect(() => {
    checkAccessToken(navigate, accessToken);
  });

  const { isProcessing, progress } = useSubmissionStatus();

  return (
    <Container fluid className="h-100 p-0">
      <div className="h-100 d-flex flex-row">
        <Sidebar />

        <div
          className={`flex-grow-1 pb-2 px-5 ${bodyOutletClassName} overflow-auto vh-100`}
          onScroll={defaultLayoutOnScroll}
        >
          <Outlet />

          <SubmissionProgressIndicator isVisible={isProcessing} progress={progress} />
        </div>

        <ScrollRestoration />
      </div>
    </Container>
  );
}
