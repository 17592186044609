import React from 'react';
import { ListedStudy } from 'helper/studies';
import './index.scss';

export default function StatusTag({ study }: { study: ListedStudy }) {
  let status: string;
  let colorClass: string;
  if (study.ck_internal_error) {
    status = 'Com Erro';
    colorClass = 'study-status-red';
  } else if (study.ready) {
    status = 'Estudo Pronto';
    colorClass = 'study-status-green';
  } else {
    status = 'Em Fila';
    colorClass = 'study-status-gray';
  }

  return (
    <div className="d-flex flex-row mb-2">
      <div className={`align-self-center study-status-tag ${colorClass}`}>{status}</div>
      {study.ck_internal_error && (
        <div className="align-self-center study-status-info">
          Verifique seu e-mail de processamento interrompido para mais informações
        </div>
      )}
    </div>
  );
}
