import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SubmissionStatus } from 'redux/reducers/nfeProcessingReducer';

export function statusToPill(status: SubmissionStatus) {
  switch (status) {
    case SubmissionStatus.PROCESSING:
      return <Badge pill bg="secondary">Executando</Badge>;
    case SubmissionStatus.DONE:
      return <Badge pill bg="success">Finalizado</Badge>;
    case SubmissionStatus.FAILED:
      return <Badge pill bg="danger">Erro</Badge>;
    case SubmissionStatus.CANCELED:
      return <Badge pill bg="danger">Cancelado</Badge>;
    default:
      return <Badge pill bg="dark">Na fila</Badge>;
  }
}

export default function StatusPill({
  status,
  error,
}: {
  status: SubmissionStatus,
  error: string | null
}) {
  const pill = statusToPill(status);

  if (error?.length && error.length > 0) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{error}</Tooltip>}
      >
        {pill}
      </OverlayTrigger>
    );
  }
  return pill;
}
