import React from 'react';
import { useSelector } from 'react-redux';
import FormModal from 'components/FormModal';
import {
  selectUserModalShow,
  selectUserModalTitle,
  selectUserModalUser,
} from 'redux/reducers/userModalReducer';
import UserForm from './UserForm';
import onModalHide from './utils';

export default function UserModal() {
  const user = useSelector(selectUserModalUser);
  const title = useSelector(selectUserModalTitle);
  const show = useSelector(selectUserModalShow);

  return (
    <FormModal
      show={show}
      title={title}
      onHide={onModalHide}
    >
      <UserForm user={user} />
    </FormModal>
  );
}
