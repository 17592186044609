import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import AlertsCollection, { CustomAlert } from 'components/AlertsCollection';
import { useLoginMutation } from '../../services/api/auth';
import { processLogin, initialAlerts } from './utils';

export default function Login() {
  const [login, { isLoading: isLoginLoading }] = useLoginMutation({ fixedCacheKey: 'loginOperation' });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const [alerts, setAlerts] = useState<CustomAlert[]>(initialAlerts(location.state));

  return (
    <>
      <Card.Title className="auth-card-title my-5">
        Espaço Exclusivo Ampere
      </Card.Title>
      <AlertsCollection alerts={alerts} />
      <Card.Body className="my-3 mx-5">
        <Form
          onSubmit={(event) => (processLogin(
            event,
            login,
            username,
            password,
            navigate,
            setValidated,
            setAlerts,
          ))}
          noValidate
          validated={validated}
        >
          <fieldset disabled={isLoginLoading}>
            <Form.Group controlId="loginFormUsername">
              <Form.Label className="auth-form-label">Username ou e-mail</Form.Label>
              <Form.Control
                required
                type="email"
                onChange={(event) => { setUsername(event.target.value); }}
              />
              <Form.Control.Feedback type="invalid">
                Por favor insira um e-mail válido.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="loginFormPassword" className="my-4">
              <Form.Label className="auth-form-label">Senha</Form.Label>
              <Form.Control
                required
                type="password"
                onChange={(event) => { setPassword(event.target.value); }}
              />
              <Form.Control.Feedback type="invalid">
                Por favor insira a senha.
              </Form.Control.Feedback>
            </Form.Group>
            <Stack direction="horizontal" gap={3} className="mt-5 mb-2">
              <Button onClick={() => navigate('/password-recovery')} className="ms-auto secondary-button">
                Esqueci minha senha
              </Button>
              <Button className="primary-button" type="submit">
                Login
              </Button>
            </Stack>
          </fieldset>
        </Form>
      </Card.Body>
    </>
  );
}
