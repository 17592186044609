import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import HomeBoxes from 'components/Home/Boxes';
import HomeTabs from 'components/Home/Tabs';
import { selectTokenUserName } from '../../redux/reducers/authReducer';

export default function Home() {
  const userName = useSelector(selectTokenUserName);

  return (
    <div>
      <Row>
        <Col md={9}>
          <h1>Home</h1>
        </Col>
        <Col md={2}>
          <p>
            Bem vindo(a),
            {' '}
            <b>{userName}</b>
          </p>
        </Col>
        <Col>
          <FontAwesomeIcon icon={faCircleUser} />
        </Col>
      </Row>
      <Row>
        <HomeBoxes />
        <HomeTabs />
      </Row>
    </div>
  );
}
