import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './index.scss';

export default function SubmissionProgressIndicator({ isVisible, progress }: {
  isVisible: boolean,
  progress: number,
}) {
  if (isVisible) {
    return (
      <div className="submission-progress-indicator">
        <CircularProgressbarWithChildren
          value={progress}
          background
          styles={buildStyles({
            backgroundColor: 'white',
            strokeLinecap: 'butt',
            trailColor: '#F4F4F4',
            pathColor: '#598CBB',
            pathTransitionDuration: 0.3,
          })}
          strokeWidth={3}
        >
          <FontAwesomeIcon icon={faPaperPlane as IconProp} />
        </CircularProgressbarWithChildren>
      </div>
    );
  }

  return null;
}
