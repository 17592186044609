import { useGetMassMailClientsQuery } from 'services/api/mailer';
import { ResponseError } from 'services/api/base';
import { useHandleRequestErrors } from '../index';

export default function useMassMailClientQueryAdapter(selectedProductIds: number[]) {
  const { data: massEmailClients, isLoading, error } = useGetMassMailClientsQuery({
    products: selectedProductIds,
  });

  useHandleRequestErrors(error as ResponseError | undefined);
  return { data: massEmailClients?.data?.empresas || [], isLoading };
}
