import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from 'components/Admin/ActionButton';

export default function EditButton({ contractId, onEditClick }: {
  contractId: number, onEditClick: () => void
}) {
  return (
    <ActionButton
      loading={false}
      onClick={onEditClick}
      tooltipText="Editar empresa"
    >
      <FontAwesomeIcon
        id={`edit-company-${contractId}`}
        icon={faPenToSquare as IconProp}
        size="xs"
      />
    </ActionButton>
  );
}
