import React from 'react';
import { useSelector } from 'react-redux';
import { format, fromUnixTime } from 'date-fns';
import { selectMFControlPanelDateOffset, selectMFControlPanelForecastUnixDate, selectMFControlPanelPeriod } from 'redux/reducers/meteorologyFieldsControlPanelReducer';

export default function Title({
  showInstructions,
  multiMapMode,
}: {
  showInstructions: boolean,
  multiMapMode: boolean,
}) {
  const basePeriod = useSelector(selectMFControlPanelPeriod);
  const offset = useSelector(selectMFControlPanelDateOffset) || 0;
  const period = basePeriod?.replace(/\d+/, offset.toString(10));
  const periodLabel = multiMapMode ? 'Previsão' : `Previsão ${period}`;
  const unixDate = useSelector(selectMFControlPanelForecastUnixDate);
  const date = unixDate && fromUnixTime(unixDate);
  const dateLabel = date && format(date, 'dd.MM.yy');

  if (showInstructions) {
    return (
      <div className="text-primary">
        <span className="fw-bold">Previsão D+X</span>
        {' - XX.XX.XX'}
      </div>
    );
  }

  return (
    <div className="text-primary">
      {period && <span className="fw-bold">{periodLabel}</span>}
      {period && dateLabel && <span> - </span>}
      {dateLabel && <span>{dateLabel}</span>}
    </div>
  );
}
