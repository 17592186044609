import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { NavigateFunction } from 'react-router';
import { CustomAlert } from 'components/AlertsCollection';
import { handleApiError } from '../../helper/error';
import { ResponseError } from '../../services/api/base';
import { ForgotPasswordParams, ForgotPasswordResponse } from '../../services/api/auth';

// eslint-disable-next-line import/prefer-default-export
export const recoverPassword = async (
  event: React.FormEvent<HTMLFormElement>,
  hookTrigger: MutationTrigger<MutationDefinition<ForgotPasswordParams, BaseQueryFn<unknown>, never, ForgotPasswordResponse, 'api'>>,
  username: string,
  navigate: NavigateFunction,
  setAlerts: (alerts: CustomAlert[]) => void,
) => {
  // Prevent the browser from reloading the page
  event.preventDefault();

  const passwordRecoveryResult = await hookTrigger({ username });

  if ('data' in passwordRecoveryResult) {
    if (passwordRecoveryResult.data.status) {
      navigate('/password-recovery-success');
    } else {
      setAlerts([{
        title: passwordRecoveryResult.data.title,
        message: passwordRecoveryResult.data.message,
        variant: 'danger',
      }]);
    }
  } else {
    handleApiError(navigate, passwordRecoveryResult.error as ResponseError);
  }
};
