import React, { useState } from 'react';
import {
  Container, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Form from './Form';

export default function New() {
  const [showForm, setShowForm] = useState(false);

  return (
    <Container className="px-0">
      { !showForm && (
        <Button variant="link" onClick={() => setShowForm(true)}>
          <FontAwesomeIcon icon={faPlus} />
          <span>Nova simulação antiga</span>
        </Button>
      ) }
      { showForm && <Form setShowForm={setShowForm} /> }
    </Container>
  );
}
