import React from 'react';
import { Dropdown } from 'react-bootstrap';
import useAutomaticOptions from 'utils/hooks/flux/useAutomaticOptions';
import { SpatialSubdivision, automaticOptionMap } from 'helper/flux/automatic';
import useFluxAutomaticFilterSelector from 'utils/hooks/flux/automatic/filterSelector';
import SuboptionsModal from './SuboptionsModal';
import './index.scss';

export default function FilterSelector() {
  const {
    showDropdown, toggleDropdown,
    selectedFilter, showModal,
    setFilter, closeModal, handleFilter,
  } = useFluxAutomaticFilterSelector();
  const { subdivisions, isFetching } = useAutomaticOptions();
  const suboptions = selectedFilter === undefined
    ? []
    : subdivisions[selectedFilter];

  return (
    <>
      <SuboptionsModal
        key={selectedFilter}
        suboptions={suboptions}
        show={showModal}
        onHide={closeModal}
        onSave={setFilter}
      />
      <div className="d-flex flex-row">
        <Dropdown show={showDropdown} onToggle={toggleDropdown} autoClose={false}>
          <Dropdown.Toggle className="flux-automatic-filter-selector-toggle">
            Subdivisão espacial
          </Dropdown.Toggle>
          <Dropdown.Menu className="flux-automatic-filter-selector-menu">
            {!isFetching && Object.entries(automaticOptionMap).map(([key, label]) => (
              <Dropdown.Item
                key={key}
                onClick={() => handleFilter(key as SpatialSubdivision)}
                className={`flux-automatic-filter-selector-item ${key === selectedFilter ? 'active' : ''}`}
              >
                {label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
