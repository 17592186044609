import { useMemo, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectFluxAutomaticControlPanelAcomph,
  selectFluxAutomaticControlPanelMLT,
  setAcomph,
  setMLT,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';

interface AcomphMLT {
  acomph: boolean,
  onAcomphChange: (event: ChangeEvent<HTMLInputElement>) => void,
  mlt: boolean,
  onMltChange: (event: ChangeEvent<HTMLInputElement>) => void,
}

export default function useAcomphMLT(): AcomphMLT {
  const acomph = useSelector(selectFluxAutomaticControlPanelAcomph);
  const mlt = useSelector(selectFluxAutomaticControlPanelMLT);
  const dispatch = useDispatch();

  const onAcomphChange = useMemo(() => (
    () => {
      dispatch(setAcomph(!acomph));
    }
  ), [dispatch, acomph]);
  const onMltChange = useMemo(() => (
    () => {
      dispatch(setMLT(!mlt));
    }
  ), [dispatch, mlt]);

  return {
    acomph,
    onAcomphChange,
    mlt,
    onMltChange,
  };
}
