import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import EditButton from 'components/Videos/EditButton';
import RemoveButton from 'components/Videos/RemoveButton';
import { VideoProduct, VideosGetLast, videoProductToRoute } from 'helper/videos';

import './index.scss';

export default function VideoGridRow({
  openEditForm,
  data,
  product,
  viewOnly,
}: {
  openEditForm: (videoId: number) => void,
  data: VideosGetLast[],
  product: VideoProduct,
  viewOnly: boolean,
}) {
  const productRoute = videoProductToRoute[product];
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const width = `${96 / data.length}%`;

  return (
    <div className="m-3 d-flex justify-content-between">
      {data.map((video) => (
        <div className="d-flex flex-column justify-content-between bg-white " style={{ width }}>
          <Link
            to={`${productRoute}/${video.id}`}
            key={video.id}
            className="text-decoration-none"
          >
            <div className="pb-3">
              <Image
                src={video.uri.pictures?.base_link}
                alt="video-preview"
                thumbnail
              />
            </div>
            <div className="px-3 mx-1">
              <div className="video-grid-row-date">
                {new Date(video.uri.release_time).toLocaleDateString('pt-BR')}
              </div>
              <div className="video-grid-row-title text-truncate">
                {video.titulo}
              </div>
            </div>
          </Link>
          {userIsAdmin && !viewOnly && (
            <div className="d-flex flex-row justify-content-end mb-2 me-3">
              <EditButton id={video.id} onClick={() => openEditForm(video.id)} />
              <RemoveButton
                id={video.id}
                product={product}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
