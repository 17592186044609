import { store } from 'redux/store';
import {
  GetAvailableDatesResponse,
  GetGifsParams,
  GetImagesParams,
  Gifs,
  Images,
  Options,
  transformGetGifsResponse,
  transformGetImagesResponse,
  transformGetOptionsResponse,
} from 'helper/products/synopticCharts';
import baseApi from '../base';

const fileViewerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOptions: build.query<Options, void>({
      query: () => {
        const productKey = store.getState().productKeys.meteorologia;
        return `produtos/campos_sinoticos/options/?product_key=${productKey}`;
      },
      transformResponse: transformGetOptionsResponse,
    }),
    getAvailableDates: build.query<GetAvailableDatesResponse, void>({
      query: () => {
        const productKey = store.getState().productKeys.meteorologia;
        return `produtos/campos_sinoticos/check-availables-dates/?product_key=${productKey}`;
      },
    }),
    getGifs: build.query<Gifs, GetGifsParams>({
      query: (params) => {
        const productKey = store.getState().productKeys.meteorologia;
        return {
          url: `produtos/campos_sinoticos/get-gif/?product_key=${productKey}`,
          method: 'POST',
          body: {
            data_da_rodada: params.dateString,
            runtime: params.runtimeId,
            membro: '00',
            id_campo: params.chartId,
          },
        };
      },
      transformResponse: transformGetGifsResponse,
    }),
    getImages: build.query<Images, GetImagesParams>({
      query: (params) => {
        const productKey = store.getState().productKeys.meteorologia;
        return {
          url: `produtos/campos_sinoticos/get-image/?product_key=${productKey}`,
          method: 'POST',
          body: {
            data_da_rodada: params.dateString,
            runtime: params.runtimeId,
            membro: '00',
            id_campo: params.chartId,
          },
        };
      },
      transformResponse: transformGetImagesResponse,
    }),
  }),
});

export const {
  useGetOptionsQuery,
  useGetAvailableDatesQuery,
  useLazyGetGifsQuery,
  useLazyGetImagesQuery,
} = fileViewerApi;
