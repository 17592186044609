import { ResponseError } from 'services/api/base';
import { useGetAvailableComparisonDatesQuery } from 'services/api/Meteorology';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import sortDates from '../../../sortDates';

export default function useAvailableDates(model: string) {
  const { data, error } = useGetAvailableComparisonDatesQuery({ model });

  useHandleRequestErrors(error as ResponseError | undefined);

  return sortDates(data);
}
