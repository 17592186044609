import React, { useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from 'components/Admin/ActionButton';
import { useDeleteTemplateMutation } from 'services/api/mailer';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { CustomAlert } from 'components/AlertsCollection';

interface MailerTemplateDeleteButton {
  type: number | string,
  subType: number | string,
  setAlerts: React.Dispatch<React.SetStateAction<CustomAlert[]>>,
}

export default function DeleteButton({
  type, subType, setAlerts,
}: MailerTemplateDeleteButton) {
  const [deleteTemplateTrigger, {
    data: deletionData, error: deletionError,
  }] = useDeleteTemplateMutation();

  useEffect(() => {
    if (deletionData) {
      setAlerts([{
        title: deletionData?.title || '',
        message: deletionData?.message || '',
        variant: 'info',
      }]);
    }
  }, [setAlerts, deletionData]);

  const deleteTemplate = () => {
    deleteTemplateTrigger({
      type,
      subtypeId: subType,
    });
  };

  useHandleRequestErrors(deletionError as ResponseError);

  return (
    <div className="action-button-container">
      <ActionButton
        loading={false}
        onClick={deleteTemplate}
        tooltipText="Excluir template"
      >
        <FontAwesomeIcon
          icon={faTrashCan as IconProp}
          size="xs"
        />
      </ActionButton>
    </div>
  );
}
