import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import useFluxFileUploadForm from 'utils/hooks/flux/fileUploadForm';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFluxUploadFormPercentileValues, setPercentileValues,
} from 'redux/reducers/fluxUploadFormReducer';

function ScenarioCheckbox(
  label: string,
  checked: boolean,
  onChange: () => void,
) {
  return (
    <Form.Check
      type="checkbox"
      label={label}
      key={label}
      onChange={onChange}
      checked={checked}
    />
  );
}
function ScenarioRadioButton(
  label: string,
  checked: boolean,
  onChange: () => void,
) {
  return (
    <div className="pl-3">
      <Form.Check
        type="radio"
        name="dimensions"
        label={label}
        key={label}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}

function ScenarioTextField(
  displayField: boolean,
  dispatcherFunction: ActionCreatorWithPayload<string>,
  selectorFunction: (state: any) => string,
) {
  const value = useSelector(selectorFunction);
  const dispatch = useDispatch();
  const setValue = (event: any) => dispatch(dispatcherFunction(event.target.value));
  const displayClass = displayField ? 'd-block' : 'd-none';

  return (
    <div className={`ps-5 ${displayClass}`}>
      <Form.Control value={value} onChange={setValue} />
    </div>
  );
}

function FlowPercentile(
  multiScenarios: any[],
  toggleMultiScenario: (value: string) => void,
) {
  const optionLabel = 'Produzir percentis de vazão';
  // eslint-disable-next-line react/destructuring-assignment
  const isChecked = multiScenarios.includes(optionLabel);

  return (
    <>
      {ScenarioCheckbox(
        optionLabel,
        isChecked,
        () => { toggleMultiScenario(optionLabel); },
      )}
      {ScenarioTextField(isChecked, setPercentileValues, selectFluxUploadFormPercentileValues)}
    </>
  );
}

function GroupingNumber(
  multiScenarios: any[],
  toggleMultiScenario: (value: string) => void,
  groupNumber: number | undefined,
  setGroupNumber: (value: number) => void,
) {
  const optionLabel = 'Predefinir número de agrupamentos';
  // eslint-disable-next-line react/destructuring-assignment
  const includesFlowByGroup = multiScenarios.includes('Predefinir número de agrupamentos');

  return (
    <div className="ps-3">
      {ScenarioCheckbox(
        optionLabel,
        // eslint-disable-next-line react/destructuring-assignment
        multiScenarios.includes(optionLabel),
        () => { toggleMultiScenario(optionLabel); },
      )}
      { includesFlowByGroup
        && (
          <div className="ps-4">
            <FormControl
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={groupNumber && !Number.isNaN(groupNumber) ? groupNumber : 0}
              onChange={(e) => {
                setGroupNumber(parseInt(e.target.value || '0', 10));
              }}
            />
          </div>
        )}
    </div>
  );
}

function FlowByGrouping() {
  const {
    multiScenarios,
    toggleMultiScenario,
    toggleMultiScenarioVersusOption,
    groupNumber,
    setGroupNumber,
  } = useFluxFileUploadForm();

  const radioButtonLabels = [
    'SE+NE versus S+N',
    'SE+S versus NE+N',
    'SE+N versus S+NE',
    'SE versus S+NE+N',
    'SE vs S vs NE vs N',
  ];

  const optionLabel = 'Produzir vazão por agrupamento:';

  const shouldDisplayGroupingDimensions = multiScenarios.includes(optionLabel);

  const shouldDisplayRadioOptions = shouldDisplayGroupingDimensions
    && multiScenarios.includes('Definir dimensões para agrup.');

  return (
    <>
      {ScenarioCheckbox(
        optionLabel,
        // eslint-disable-next-line react/destructuring-assignment
        multiScenarios.includes(optionLabel),
        () => { toggleMultiScenario(optionLabel); },
      )}
      { shouldDisplayGroupingDimensions
        && (GroupingNumber(multiScenarios, toggleMultiScenario, groupNumber, setGroupNumber))}
      { shouldDisplayGroupingDimensions
        && (
          <div className="ps-3">
            {ScenarioCheckbox(
              'Definir dimensões para agrup.',
              // eslint-disable-next-line react/destructuring-assignment
              multiScenarios.includes('Definir dimensões para agrup.'),
              () => { toggleMultiScenario('Definir dimensões para agrup.'); },
            )}
          </div>
        )}
      { shouldDisplayRadioOptions && radioButtonLabels
        .map((label) => (
          <div className="ps-5" key={label}>
            { ScenarioRadioButton(
              label,
              // eslint-disable-next-line react/destructuring-assignment
              multiScenarios.includes(label),
              () => toggleMultiScenarioVersusOption(label),
            ) }
          </div>
        ))}
    </>
  );
}

export default function MultiScenarios() {
  const {
    multiScenarios,
    toggleMultiScenario,
  } = useFluxFileUploadForm();

  return (
    <div>
      {
        ScenarioCheckbox(
          'Produzir vazão média',
          multiScenarios.includes('Produzir vazão média'),
          () => { toggleMultiScenario('Produzir vazão média'); },
        )
      }
      {FlowPercentile(multiScenarios, toggleMultiScenario)}
      {FlowByGrouping()}
    </div>
  );
}
