import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { store } from 'redux/store';
import {
  modifyCreationState,
  modifySubmissionState,
  modifyCreationErrorMessage,
  modifySubmissionErrorMessage,
  ProcessingStatus,
  SubmissionStatus,
} from 'redux/reducers/nfeProcessingReducer';
import {
  CreateInvoiceParams,
  SendInvoiceParams,
} from 'services/api/finances';
import { Invoice } from './financial';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationDefinitionType<Req> = MutationDefinition<Req, BaseQueryFn, string, any>;

export function isProcessingCanceled(processingState: ProcessingStatus) {
  return processingState === ProcessingStatus.CANCELED;
}

export async function processNfeSubmission(
  invoice: Invoice,
  index: number,
  submissionHook: MutationTrigger<MutationDefinitionType<SendInvoiceParams>>,
) {
  const processingState = store.getState().nfeProcessing.processingStatus;
  const isCanceled = isProcessingCanceled(processingState);

  if (isCanceled) {
    store.dispatch(modifySubmissionState({ index, newStatus: SubmissionStatus.CANCELED }));
    return;
  }

  store.dispatch(modifySubmissionState({ index, newStatus: SubmissionStatus.PROCESSING }));

  const submissionResult = await submissionHook({
    notas: [
      {
        nfe: invoice.nfe,
        emissao: invoice.issueDate,
        vencimento: invoice.dueDate,
        contraparte: '',
        cnpj: invoice.cnpj,
        cidade: invoice.city,
        uf: invoice.uf,
        link: invoice.url,
        emails: invoice.emails,
      },
    ],
  });

  if ('data' in submissionResult && submissionResult.data.status) {
    store.dispatch(modifySubmissionState({ index, newStatus: SubmissionStatus.DONE }));
  } else {
    store.dispatch(modifySubmissionState({ index, newStatus: SubmissionStatus.FAILED }));
    if ('error' in submissionResult) {
      const error = submissionResult.error as { data: string };

      store.dispatch(
        modifySubmissionErrorMessage({ index, errorMessage: error.data }),
      );
    }
  }
}

export async function processNfeCreation(
  invoice: Invoice,
  index: number,
  creationHook: MutationTrigger<MutationDefinitionType<CreateInvoiceParams>>,
  submissionHook: MutationTrigger<MutationDefinitionType<SendInvoiceParams>>,
) {
  const processingState = store.getState().nfeProcessing.processingStatus;
  const isCanceled = isProcessingCanceled(processingState);

  if (isCanceled) {
    store.dispatch(modifyCreationState({ index, newStatus: SubmissionStatus.CANCELED }));
    store.dispatch(modifySubmissionState({ index, newStatus: SubmissionStatus.CANCELED }));
    return;
  }

  store.dispatch(modifyCreationState({ index, newStatus: SubmissionStatus.PROCESSING }));

  const creationResult = await creationHook({
    link: invoice.url,
    n_nota: invoice.nfe,
    cnpj: invoice.cnpj,
    r_social: invoice.corporateName,
  });

  if ('data' in creationResult && creationResult.data.status) {
    store.dispatch(modifyCreationState({ index, newStatus: SubmissionStatus.DONE }));
    await processNfeSubmission(invoice, index, submissionHook);
  } else {
    if ('data' in creationResult) {
      const error = creationResult.data as { message: string };
      store.dispatch(
        modifyCreationErrorMessage({ index, errorMessage: error.message }),
      );
    }
    store.dispatch(modifyCreationState({ index, newStatus: SubmissionStatus.FAILED }));
    store.dispatch(modifySubmissionState({ index, newStatus: SubmissionStatus.CANCELED }));
  }
}
