import React from 'react';
import { Form } from 'react-bootstrap';
import useNewStudyForm from 'utils/hooks/flux/personalized/useNewStudyForm';

function OutputOptionCheckbox(label: string) {
  const { outputOptions, toggleOutputOption } = useNewStudyForm();

  return (
    <Form.Check
      type="checkbox"
      label={label}
      key={label}
      checked={outputOptions.includes(label)}
      onChange={() => toggleOutputOption(label)}
    />
  );
}

export default function OutputOptions() {
  const labels = [
    'Produzir mapas de preciptação',
    'Produzir arquivo excel PMED',
    'Produzir arquivos DESSEM',
    'Produzir arquivo.csv de ENA diária',
    'Produzir memória de cálculo ENA semanal',
    'Produzir arquivos PREVS.RVX mensais',
    'Produzir arquivos VAZPAST.DAT',
  ];

  return (
    <div>
      {labels.map((label) => OutputOptionCheckbox(label))}
    </div>
  );
}
