import React from 'react';
import {
  Form, Button, Row, Col, Accordion,
} from 'react-bootstrap';

import useNewPastSimulation from 'utils/hooks/flux/automatic/useNewPastSimulation';
import useNewPastSimulationOptions from 'utils/hooks/flux/automatic/useNewPastSimulationOptions';
import DatePicker from 'components/DatePicker';
import Model from './Model';
import Version from './Version';

interface FormAttributes {
  setShowForm: (boolean) => void,
}

export default function PastSimulationForm({ setShowForm }: FormAttributes) {
  const labelStyle = { fontWeight: 400, color: '#585858' };

  const {
    forecastDate,
    setForecastDate,
    setModel,
    version,
    setVersion,
    submit,
    acomphDate,
    setAcomphDate,
    enablePastSimulationAddition,
  } = useNewPastSimulation(() => setShowForm(false));

  const {
    filterAcomphDates, filterForecastDates, versions, models, openToForecastDate,
  } = useNewPastSimulationOptions({ acomphDate, forecastDate, version });

  return (
    <Form>
      <Row className="mb-3">
        <Form.Label column className="text-start" style={labelStyle}>Data da ACOMPH</Form.Label>
      </Row>
      <div className="mb-3 d-flex">
        <DatePicker
          showIcon
          placeholderText="Selecione uma data"
          onChange={setAcomphDate}
          wrapperClassName="w-100"
          className="form-control"
          filterDate={filterAcomphDates}
          selected={acomphDate}
        />
      </div>
      <Row className="mb-3">
        <Form.Label column className="text-start" style={labelStyle}>Data da Simulação</Form.Label>
      </Row>
      <div className="mb-3 d-flex">
        <DatePicker
          showIcon
          placeholderText="Selecione uma data"
          onChange={setForecastDate}
          wrapperClassName="w-100"
          className="form-control"
          filterDate={filterForecastDates}
          selected={forecastDate}
          openToDate={openToForecastDate}
        />
      </div>
      <Accordion key="past-simulation-model-version">
        <Version onChange={setVersion} versions={versions} />
        <Model onChange={setModel} models={models} />
      </Accordion>
      <Row>
        <Col className="text-end">
          <Button
            variant="link"
            disabled={!enablePastSimulationAddition}
            onClick={submit}
          >
            Incluir Simulação
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
