import React from 'react';
import { Form } from 'react-bootstrap';
import { useIMask } from 'react-imask';

export default function CPFInput({ defaultValue }: { defaultValue?: string }) {
  const { ref: cpfRef } = useIMask({ mask: '000.000.000-00' });

  return (
    <Form.Control
      ref={cpfRef}
      pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
      type="text"
      name="cpf"
      required
      defaultValue={defaultValue}
    />
  );
}

CPFInput.defaultProps = { defaultValue: '' };

export function CNPJInput({ defaultValue }: { defaultValue?: string }) {
  const { ref: cnpjRef } = useIMask({ mask: '00.000.000/0000-00' });

  return (
    <Form.Control
      ref={cnpjRef}
      pattern="\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}"
      type="text"
      name="cnpj"
      required
      defaultValue={defaultValue}
    />
  );
}

CNPJInput.defaultProps = { defaultValue: '' };

export function PhoneInput({ defaultValue, placeholder }: {
  defaultValue?: string,
  placeholder?: string,
}) {
  const { ref: phoneRef } = useIMask({
    mask: [
      { mask: '+55 (00) 0000-0000', overwrite: true },
      { mask: '+55 (00) 00000-0000', overwrite: true },
    ],
  });

  return (
    <Form.Control
      placeholder={placeholder}
      ref={phoneRef}
      pattern="\+55 \(\d{2}\) \d{4,5}-\d{4}"
      type="text"
      name="phone[]"
      defaultValue={defaultValue}
    />
  );
}

PhoneInput.defaultProps = { defaultValue: '', placeholder: '' };

export function CEPInput({ defaultValue }: { defaultValue?: string }) {
  const { ref: cepRef } = useIMask({ mask: '00000-000' });

  return (
    <Form.Control
      ref={cepRef}
      pattern="\d{5}-\d{3}"
      type="text"
      name="cep"
      defaultValue={defaultValue}
    />
  );
}

CEPInput.defaultProps = { defaultValue: '' };
