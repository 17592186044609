import React from 'react';

interface Data {
  id: number;
  acomphDate: string;
  forecastDate: string;
  scenario: string;
  lastUpdate: Date;
}

export default function Status({ data }: { data: Data[] }) {
  return (
    <table className="status-tab w-100 mt-3">
      <thead>
        <tr>
          <th>Cenário</th>
          <th style={{ textAlign: 'center' }}>Data Acomph</th>
          <th style={{ textAlign: 'center' }}>Data Previsão</th>
          <th style={{ textAlign: 'center' }}>Última atualização</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={`flux-status-${item.id}`}>
            <td className="model-name">{item.scenario}</td>
            <td className="last-update">{item.acomphDate}</td>
            <td className="last-update">{item.forecastDate}</td>
            <td className="last-update">{item.lastUpdate.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
