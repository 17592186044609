import { Invoice } from 'helper/financial';
import { useSelector } from 'react-redux';

import {
  selectProcessingState,
  selectSubmissionReport,
  ProcessingStatus,
  SubmissionStatus,
  SubmissionReport,
  createSubmissionReport,
  modifyProcessingState,
} from 'redux/reducers/nfeProcessingReducer';
import { setShow } from 'redux/reducers/sendInvoiceModalReducer';
import { store } from 'redux/store';
import useNfeProcessors from './useNfeProcessors';

export default function useInvoiceSubmission() {
  const processingState: ProcessingStatus = useSelector(selectProcessingState);
  const reports: SubmissionReport[] = useSelector(selectSubmissionReport);
  const runner = useNfeProcessors();
  const reportCount = reports.length;

  const finishedStatus = [
    SubmissionStatus.DONE, SubmissionStatus.FAILED, SubmissionStatus.CANCELED,
  ];
  const finishedCount = reports
    .filter((report) => {
      const creationFinished = finishedStatus.includes(report.creation.status);
      const submissionFinished = finishedStatus.includes(report.submission.status);
      return creationFinished && submissionFinished;
    })
    .length;
  const progress = `${finishedCount}/${reportCount}`;

  const errors = reports
    .filter((report) => {
      const creationError = report.creation.status === SubmissionStatus.FAILED;
      const submissionError = report.submission.status === SubmissionStatus.FAILED;
      return creationError || submissionError;
    })
    .length;

  const openModal = () => {
    store.dispatch(setShow(true));
  };

  const addToQueue = (invoices: Invoice[]) => {
    store.dispatch(createSubmissionReport(invoices));
    store.dispatch(modifyProcessingState(ProcessingStatus.IDLE));
    openModal();
  };

  const startProcessing = () => {
    runner();
  };

  const cancelProcessing = () => {
    store.dispatch(modifyProcessingState(ProcessingStatus.CANCELED));
  };

  return {
    processingState,
    reports,
    progress,
    errors,
    addToQueue,
    openModal,
    startProcessing,
    cancelProcessing,
  };
}
