import React, { useMemo } from 'react';
import { PersonalizedBlockDraft } from 'helper/personalized';
import {
  Button,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { onDatePickerChange } from 'helper/controlledField';
import useBlockFormOptions from 'utils/hooks/flux/personalized/useBlockFormOptions';
import useBlockFormDates from 'utils/hooks/flux/personalized/useBlockFormDates';
import useFluxPersonalisedAvailableDates from 'utils/hooks/flux/personalized/useAvailableDates';
import { fromUnixTime, isAfter } from 'date-fns';
import useNewStudyForm from 'utils/hooks/flux/personalized/useNewStudyForm';
import DatePicker from 'components/DatePicker';
import BlockDatePickerWrapper from './BlockDatePickerWrapper';

import './index.scss';

export default function BlockForm({ setShowBlockForm, onBlockSave, blockStartDate }: {
  setShowBlockForm: React.Dispatch<React.SetStateAction<boolean>>,
  onBlockSave: (block: PersonalizedBlockDraft) => void,
  blockStartDate: Date,
}) {
  const {
    model,
    runtime,
    member,
    rmv,
    modelOptions,
    runtimeOptions,
    memberOptions,
    onModelChange,
    onRuntimeChange,
    onMemberChange,
    setRmv,
    runtimeDisabled,
    memberDisabled,
    rmvDisabled,
  } = useBlockFormOptions();
  const {
    forecastDate,
    forecastStartDate,
    forecastEndDate,
    blockEndDate,
    setForecastDate,
    setForecastStartDate,
    setForecastEndDate,
    forecastStartMin,
    forecastStartMax,
    forecastEndMin,
    forecastEndMax,
    forecastStartDisabled,
    forecastEndDisabled,
  } = useBlockFormDates(blockStartDate, model, runtime, member);
  const onSaveClick = () => onBlockSave({
    key: blockStartDate.toLocaleDateString('pt-BR'),
    model,
    runtime,
    member,
    rmv,
    forecastDate: forecastDate as Date,
    startDate: blockStartDate,
    endDate: blockEndDate as Date,
    sliceStartDate: forecastStartDate as Date,
    sliceEndDate: forecastEndDate as Date,
  });
  const { startDate } = useNewStudyForm();
  const availableDates = useFluxPersonalisedAvailableDates(model);
  const filteredDates = useMemo(
    () => availableDates.filter((date) => !isAfter(date, fromUnixTime(startDate))),
    [availableDates, startDate],
  );
  const forecastDisabled = filteredDates.length === 0;

  const saveDisabled = model === '' || runtime === -1 || member === '' || rmv === -1
    || forecastDate === null || blockEndDate === null;

  return (
    <div className="block-form mt-4">
      <div className="block-form-title">Novo Bloco</div>
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Select
              onChange={onModelChange}
              disabled={modelOptions.length === 0}
            >
              <option value="">Modelo</option>
              {modelOptions.map((m) => (
                <option key={m.value} value={m.value}>{m.label}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <BlockDatePickerWrapper disabled={forecastDisabled}>
            <DatePicker
              className="form-control"
              placeholderText="Data de previsão"
              selected={forecastDate}
              onChange={onDatePickerChange(setForecastDate)}
              disabled={forecastDisabled}
              includeDates={filteredDates}
            />
          </BlockDatePickerWrapper>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Select
              value={runtime}
              onChange={onRuntimeChange}
              disabled={runtimeDisabled}
            >
              <option value={-1}>Runtime</option>
              {runtimeOptions.map((r) => (
                <option key={r.id} value={r.id}>{r.label}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Select
              value={member}
              onChange={onMemberChange}
              disabled={memberDisabled}
            >
              <option value={-1}>Membro</option>
              {memberOptions.map((m) => (
                <option key={m.id} value={m.id}>{m.label}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <BlockDatePickerWrapper disabled={forecastStartDisabled}>
            <DatePicker
              className="form-control"
              placeholderText="Início recorte de previsão"
              selected={forecastStartDate}
              onChange={onDatePickerChange(setForecastStartDate)}
              minDate={forecastStartMin}
              maxDate={forecastStartMax}
              disabled={forecastStartDisabled}
            />
          </BlockDatePickerWrapper>
        </Col>
        <Col md={4}>
          <BlockDatePickerWrapper disabled={forecastEndDisabled}>
            <DatePicker
              className="form-control"
              placeholderText="Final recorte de previsão"
              selected={forecastEndDate}
              onChange={onDatePickerChange(setForecastEndDate)}
              minDate={forecastEndMin}
              maxDate={forecastEndMax}
              disabled={forecastEndDisabled}
            />
          </BlockDatePickerWrapper>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <Row>
            <Col md>
              <Form.Check
                type="radio"
                id="rvm_1"
                label="Com Remoção de Viés"
                checked={rmv === 1}
                onChange={() => setRmv(1)}
                disabled={rmvDisabled}
              />
            </Col>
            <Col md>
              <Form.Check
                type="radio"
                id="rvm_0"
                label="Sem Remoção de Viés"
                checked={rmv === 0}
                onChange={() => setRmv(0)}
                disabled={rmvDisabled}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <BlockDatePickerWrapper disabled>
            <DatePicker
              className="form-control"
              selected={blockStartDate}
              disabled
              onChange={() => {}}
            />
          </BlockDatePickerWrapper>
        </Col>
        <Col md={4}>
          <BlockDatePickerWrapper disabled>
            <DatePicker
              className="form-control"
              selected={blockEndDate}
              disabled
              onChange={() => {}}
            />
          </BlockDatePickerWrapper>
        </Col>
      </Row>
      <div className="d-flex justify-content-end mt-5">
        <Button
          className="secondary-button"
          onClick={() => setShowBlockForm(false)}
        >
          Cancelar
        </Button>
        <Button
          className="text-white"
          variant="secondary"
          onClick={onSaveClick}
          disabled={saveDisabled}
        >
          Salvar bloco
        </Button>
      </div>
    </div>
  );
}
