import React, { useEffect, useState } from 'react';

import { store } from 'redux/store';
import { setSearchQuery } from 'redux/reducers/mailerLayoutReducer';
import { ResponseError } from 'services/api/base';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { useListMailerOptionTypesQuery } from 'services/api/mailer';
import List from 'components/Admin/Mailer/Templates/TemplateList/List';
import ListHeaders from 'components/Admin/Mailer/Templates/TemplateList/List/Headers';
import 'components/Admin/table.scss';
import PlusButton from 'components/PlusButton';
import FormModal from 'components/FormModal';
import { closeModal } from 'helper/modals';
import { SelectedTemplate } from 'components/Admin/Mailer/Templates/TemplateList/List/Item/EditButton';
import AlertsCollection, { CustomAlert } from 'components/AlertsCollection';
import TemplateForm from 'components/Admin/Mailer/Templates/Form';

export default function TemplateList({
  openNewTemplateForm,
}: {
  openNewTemplateForm: () => void;
}) {
  const { data: types, isLoading: typesLoading, error } = useListMailerOptionTypesQuery();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate | null>(null);
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    store.dispatch(setSearchQuery(''));
  }, []);

  return (
    <div className="admin-table-main pt-3">
      <div>
        <FormModal
          show={modalOpen}
          title="Editar Template"
          onHide={closeModal(setModalOpen)}
        >
          <TemplateForm
            types={types}
            typesLoading={typesLoading}
            templateType={selectedTemplate?.type}
            templateSubtype={selectedTemplate?.subType}
          />
        </FormModal>
      </div>
      <div className="mx-3 mb-5">
        <PlusButton
          id="new-tamplete"
          label="Novo Template"
          onClick={openNewTemplateForm}
        />
      </div>
      <AlertsCollection alerts={alerts} />
      <ListHeaders />
      <List
        setModalOpen={setModalOpen}
        setSelectedTemplate={setSelectedTemplate}
        setAlerts={setAlerts}
        types={types}
        typesLoading={typesLoading}
      />
    </div>
  );
}
