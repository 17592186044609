import React from 'react';
import { Form } from 'react-bootstrap';
import useNumericForecastCP from 'utils/hooks/meteorology/useNumericForecastControlPanel';

export default function ModelCheckbox({ value, label }: { value: string, label: string }) {
  const { toggleModel, models } = useNumericForecastCP();
  const checked = models.includes(value);

  return (
    <Form.Check type="checkbox" className="text-uppercase pe-1" label={label} onChange={() => toggleModel(value)} checked={checked} />
  );
}
