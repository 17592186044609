import { useState } from 'react';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import buildSets from 'helper/buildSets';
import {
  VideoProduct,
  VideosGetLast,
  videoProductToPath,
  videoProductToPermission,
} from 'helper/videos';
import useGetVideos from './useGetVideos';

export default function useVideoList(
  category: number,
  searchTerm: string,
  uploadDate: string,
  product: VideoProduct,
) {
  const [rowSize, setRowSize] = useState(1);
  const { page } = useInfiniteScrollPaging();
  const pageSize = 6;
  const productPath = videoProductToPath[product];
  const permissionType = videoProductToPermission[product];
  const { videos, isFetching, listEnded } = useGetVideos({
    page, pageSize, category, searchTerm, uploadDate, productPath, permissionType,
  });
  const videoSets: VideosGetLast[][] = buildSets(videos, rowSize);

  return {
    rowSize,
    setRowSize,
    videos,
    videoSets,
    isFetching,
    listEnded,
  };
}
