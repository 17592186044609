import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { SubmissionReport } from 'redux/reducers/nfeProcessingReducer';

import './index.scss';
import StatusPill from './StatusPill';

export default function SimpleInvoiceRow({
  report,
}: {
  report: SubmissionReport,
}) {
  const {
    nfe, cnpj, corporateName, issueDate, dueDate, uf, city, url,
  } = report.invoice;
  const creationStatus = report.creation.status;
  const creationError = report.creation.error;
  const submissionStatus = report.submission.status;
  const submissionError = report.submission.error;

  return (
    <>
      <Row className="mb-2">
        <Col xs={8}>
          <div className="d-flex flex-row">
            <div className="ms-3">
              <h5 className="simple-invoice-row-header simple-invoice-row-title">
                <a href={url} target="_blank" rel="noreferrer">
                  {`Nº NFe: ${nfe}`}
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="small ms-2" />
                </a>
              </h5>
              <h6 className="simple-invoice-row-header simple-invoice-row-small-title">{cnpj}</h6>
              <h6 className="simple-invoice-row-header simple-invoice-row-small-title">{corporateName}</h6>
              <div className="my-2 simple-invoice-row-info-section">
                <div>
                  <span className="simple-invoice-row-info-title">Emissão:</span>
                  <span>{issueDate}</span>
                </div>
                <div>
                  <span className="simple-invoice-row-info-title">Vencimento:</span>
                  <span>{dueDate}</span>
                </div>
                <div>
                  <span className="simple-invoice-row-info-title">Destino:</span>
                  <span>{`${uf} - ${city}`}</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={2} className="d-flex justify-content-center align-items-center">
          <StatusPill status={creationStatus} error={creationError} />
        </Col>
        <Col xs={2} className="d-flex justify-content-center align-items-center">
          <StatusPill status={submissionStatus} error={submissionError} />
        </Col>
      </Row>
      <hr />
    </>
  );
}
