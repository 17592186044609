import React from 'react';
import { MonthlyData } from 'helper/flux/automatic';

type TableData = MonthlyData;
interface RowData {
  label: string,
  values: string[],
}

export function displayValue(value: number, percentView: boolean) {
  if (value) {
    return percentView ? new Intl.NumberFormat('en', {
      style: 'percent', minimumIntegerDigits: 2, maximumFractionDigits: 1,
    }).format(value) : value.toFixed(1);
  }
  return 'N/A';
}

export default function FluxAutomaticGeneralTable({
  data, tableLabel, percentView = false, lastColAsPercent = false,
}: {
  data: TableData | undefined,
  tableLabel: string,
  percentView?: boolean,
  lastColAsPercent?: boolean,
}) {
  const rowLabels = data?.rowLabels ?? [];
  const colLabels = data?.colLabels ?? [];
  const dataValues = (data?.values || [[]])
    .map((rowValues) => rowValues.map(
      (value, index) => (
        displayValue(value, percentView || (lastColAsPercent && index === rowValues.length - 1))
      ),
    ));
  const expectedDataResponse = {
    rowLabels,
    colLabels,
    values: dataValues,
  };

  const colLabelStyle: React.CSSProperties = {
    color: '#26548A',
    fontWeight: 700,
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
  };

  const rowLabelStyle: React.CSSProperties = {
    color: '#598CBB',
    fontWeight: 600,
    fontFamily: 'Source Sans Pro',
  };

  const valueCellStyle: React.CSSProperties = {
    color: '#212F58',
    fontWeight: 400,
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
  };

  const rows: RowData[] = [];
  expectedDataResponse.rowLabels.forEach((rowLabel, index) => {
    const values = expectedDataResponse.values[index];
    rows.push({
      label: rowLabel,
      values,
    });
  });

  return (
    <table className="table">
      <thead>
        <tr>
          <th>
            {tableLabel}
          </th>
          {expectedDataResponse
            .colLabels
            .map((colLabel) => (
              <th
                scope="col"
                style={colLabelStyle}
                key={`${colLabel}`}
              >
                {colLabel}
              </th>
            ))}
        </tr>
        {rows.map((rowData) => (
          <tr key={rowData.label}>
            <td style={rowLabelStyle}>{rowData.label}</td>
            {rowData.values.map((value, index) => (
              <td
                style={valueCellStyle}
                key={`${rowData.label}-${colLabels[index]}-${value}`}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </thead>
    </table>
  );
}

FluxAutomaticGeneralTable.defaultProps = { percentView: false, lastColAsPercent: false };
