import React from 'react';
import { User } from 'helper/users';
import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { UserSavingParams, UserSaveResponse } from 'services/api/users';
import { CustomAlert } from 'components/AlertsCollection';
import { store } from '../../../../redux/store';
import { setShow } from '../../../../redux/reducers/userModalReducer';

export default function onModalHide() {
  store.dispatch(setShow(false));
}

// TODO: split this function into smaller ones
export async function onUserFormSubmit(
  event: React.FormEvent,
  user: User | null,
  saveUser: MutationTrigger<MutationDefinition<UserSavingParams,
  BaseQueryFn<unknown>,
  never,
  UserSaveResponse,
  'api'>>,
  setValidated: React.Dispatch<React.SetStateAction<boolean>>,
  setAlerts: (alerts: CustomAlert[]) => void,
) {
  event.preventDefault();

  const form = event.target as HTMLFormElement;

  if (!form.checkValidity()) {
    setValidated(true);
    return;
  }

  const formData = new FormData(form);

  const formEmailsIds = formData.getAll('email_id[]');
  const emails = formData
    .getAll('email[]')
    .map((email, index) => ({
      id: Number(formEmailsIds[index]) || 0,
      user_id: user?.id || 0,
      email: String(email),
      confirmed_at: null,
      confirmation_token: '',
      is_primary: false,
    }))
    .filter((email) => (email.email.trim() !== ''));
  emails[0].is_primary = true;

  const formPhonesIds = formData.getAll('phone_id[]');
  const phones = formData
    .getAll('phone[]')
    .map((phone, index) => ({
      id: Number(formPhonesIds[index]) || 0,
      user_id: user?.id || 0,
      phone: String(phone),
      is_primary: false,
    }))
    .filter((phone) => (phone.phone.trim() !== ''));

  if (phones[0]) {
    phones[0].is_primary = true;
  }

  const params: UserSavingParams = {
    id: user?.id || 0,
    first_name: String(formData.get('first_name')),
    last_name: String(formData.get('last_name')),
    id_empresa: Number(formData.get('id_empresa')),
    username: user?.username || emails[0].email,
    cpf: String(formData.get('cpf')),
    emails,
    phones,
    active: formData.get('active') === 'true',
  };

  const saveUserResult = await saveUser(params);

  if ('error' in saveUserResult) {
    setAlerts([{
      title: 'Ocorreram erros',
      message: String(saveUserResult.error),
      variant: 'danger',
    }]);
  }
}
