import React from 'react';
import {
  Accordion,
} from 'react-bootstrap';
import StudyHorizon from './studyHorizon';
import OutputOptions from './outputOptions';
import MultiScenarios from './multiScenarios';
import PrevivazModel from './previvazModel';

export default function FormAccordion() {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="1" className="control-panel-item">
        <Accordion.Header>Horizonte do Estudo</Accordion.Header>
        <Accordion.Body>
          <StudyHorizon />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="control-panel-item">
        <Accordion.Header>Opções de Saída</Accordion.Header>
        <Accordion.Body>
          <OutputOptions />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="control-panel-item">
        <Accordion.Header>Modelo Previvaz</Accordion.Header>
        <Accordion.Body>
          <PrevivazModel />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="control-panel-item">
        <Accordion.Header>Multi Cenários</Accordion.Header>
        <Accordion.Body>
          <MultiScenarios />
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
  );
}
