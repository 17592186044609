import React, { PropsWithChildren } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './index.scss';
import useTutorialData from 'utils/hooks/useTutorialData';
import TutorialList from '../TutorialList';

export interface HomeBoxParams {
  title: string
  link: string
  selectedTutorialIds: number[]
}

export default function HomeBox({
  title, children, link, selectedTutorialIds,
}: PropsWithChildren<HomeBoxParams>) {
  const elements = useTutorialData(selectedTutorialIds);

  return (
    <Card className="mx-1">
      <Card.Title className="text-center home-box-header py-2">
        <Link to={link}>
          {title}
        </Link>
      </Card.Title>
      <Card.Body>
        <TutorialList tutorials={elements} />
        {children}
      </Card.Body>
    </Card>
  );
}
