import { SpacialSubdivisionOption, SpatialSubdivision } from 'helper/flux/automatic';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelSpatialSubdivision,
  setSpatialSubdivision,
  clearSpatialSubdivision,
  selectFluxAutomaticControlPanelMltOption,
  MltOptions,
  setMltOption as setMltOptionRedux,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';

export default function useFluxAutomaticFilterSelector() {
  const dispatch = useDispatch();
  const currentSubOption = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<SpatialSubdivision | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const mltOption = useSelector(selectFluxAutomaticControlPanelMltOption);

  const toggleDropdown = () => {
    setShowDropdown((v) => !v);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const closeModal = () => {
    setSelectedFilter(undefined);
    setShowModal(false);
  };

  const clearFilter = () => {
    setSelectedFilter(undefined);
    dispatch(clearSpatialSubdivision());
  };

  const handleFilter = (filter: SpatialSubdivision) => {
    setSelectedFilter(filter);
    setShowModal(true);
  };

  const setFilterWithinDivision = (
    newSuboption: SpacialSubdivisionOption,
    spatialSubdivision: SpatialSubdivision,
  ) => {
    dispatch(setSpatialSubdivision([spatialSubdivision, newSuboption]));
  };

  const setFilter = (newSuboption: SpacialSubdivisionOption | undefined) => {
    if (newSuboption === undefined) {
      clearFilter();
    } else {
      setFilterWithinDivision(newSuboption, selectedFilter as SpatialSubdivision);
    }
    closeModal();
    closeDropdown();
  };

  const setMltOption = (option: MltOptions) => {
    dispatch(setMltOptionRedux(option));
  };

  return {
    showDropdown,
    toggleDropdown,
    selectedFilter,
    clearFilter,
    currentSubOption,
    showModal,
    mltOption,
    handleFilter,
    setFilter,
    setFilterWithinDivision,
    setMltOption,
    closeModal,
  };
}
