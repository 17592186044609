import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Chart, DisplayMode, Runtime } from 'helper/products/synopticCharts';

interface SynopticChartState {
  chart: Chart | undefined;
  date: string | undefined;
  runtime: Runtime | undefined;
  displayMode: DisplayMode;
  gifIndex: number;
  imageIndex: number;
}

const initialState: SynopticChartState = {
  chart: undefined,
  date: undefined,
  runtime: undefined,
  displayMode: DisplayMode.Gif,
  gifIndex: 0,
  imageIndex: 0,
};

export const synopticChartSlice = createSlice({
  name: 'synopticChart',
  initialState,
  reducers: {
    setChart: (state, action: PayloadAction<Chart>) => {
      state.chart = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setRuntime: (state, action: PayloadAction<Runtime>) => {
      state.runtime = action.payload;
    },
    setDisplayMode: (state, action: PayloadAction<DisplayMode>) => {
      state.displayMode = action.payload;
    },
    setGifIndex: (state, action: PayloadAction<number>) => {
      state.gifIndex = action.payload;
    },
    setImageIndex: (state, action: PayloadAction<number>) => {
      state.imageIndex = action.payload;
    },
    reset: (state) => {
      state.chart = initialState.chart;
      state.date = initialState.date;
      state.runtime = initialState.runtime;
      state.displayMode = initialState.displayMode;
    },
  },
});

export const selectChart = (state: any) => state.synopticChart.chart;
export const selectDate = (state: any) => state.synopticChart.date;
export const selectRuntime = (state: any) => state.synopticChart.runtime;
export const selectDisplayMode = (state: any) => state.synopticChart.displayMode;
export const selectGifIndex = (state: any) => state.synopticChart.gifIndex;
export const selectImageIndex = (state: any) => state.synopticChart.imageIndex;

export const {
  setChart,
  setDate,
  setRuntime,
  setDisplayMode,
  setGifIndex,
  setImageIndex,
  reset,
} = synopticChartSlice.actions;

export default synopticChartSlice.reducer;
