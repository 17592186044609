import React from 'react';
import usePermissionCheck from 'utils/hooks/usePermissionCheck';
import { Col, Row } from 'react-bootstrap';
import CentralizedSpinner from 'components/CentralizedSpinner';
import RightSidebar from 'components/Layout/RightSidebar';
import FluxFileUploadForm from 'components/Flux/Upload/Form';
import TabbedPage from 'components/TabbedPage';

export default function FluxUploadWrapper() {
  const keySaved = usePermissionCheck('prevs-personalizado');

  if (!keySaved) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row>
        <Col className="md-6">
          <h1 className=" mb-3">FLUX - UPLOAD</h1>
        </Col>
      </Row>
      <Row style={{ flexWrap: 'nowrap', paddingBottom: '3.3125rem' }}>
        <Col md="auto" className="flex-fill">
          <TabbedPage>
            <TabbedPage.Tab title="MEUS ESTUDOS" path="/flux/upload" />
            <TabbedPage.Tab title="NOVO ESTUDO UPLOAD" path="/flux/upload/new" />
          </TabbedPage>
        </Col>
        <Col md="auto">
          <RightSidebar>
            <FluxFileUploadForm />
          </RightSidebar>
        </Col>
      </Row>
      <div className="bottom-bar" />
    </>
  );
}
