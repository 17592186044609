import React, { useState } from 'react';
import {
  Row, Col, Accordion,
} from 'react-bootstrap';
import { ListedReportMail } from 'helper/mailer';
import { ListedCompany } from 'helper/companies';
import { useClosure } from 'utils/hooks';
import LabelIcon from './LabelIcon';
import RecipientCompanyTable from '../RecipientCompanyTable';
import './index.scss';

export default function ReportRow({ report, companies }: {
  report: ListedReportMail,
  companies: ListedCompany[],
}) {
  const [renderCompanies, setRenderCompanies] = useState(false);

  return (
    <div>
      <Accordion
        className="mailer-report-row admin-table-row"
        flush
        onSelect={useClosure(setRenderCompanies, true)}
      >
        <Accordion.Item eventKey={report.processId}>
          <Accordion.Header>
            <Row className="w-100 align-items-center">
              <Col md="3">{report.processId}</Col>
              <Col md="2">{report.type}</Col>
              <Col md="3">{report.fileName}</Col>
              <Col md="2">{report.dtRequest}</Col>
              <Col md="2">
                <Row>
                  {['word', 'pdf', 'email', 'finished'].map(
                    (type: string) => <LabelIcon report={report} type={type} key={`report-${report.processId}-${type}-icon`} />,
                  )}
                </Row>
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            {renderCompanies && (
              <RecipientCompanyTable reportMailId={report.id} companies={companies} />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr className="admin-table-hr" />
    </div>
  );
}
