import { useCallback } from 'react';
import { useDeleteContractAttachmentMutation } from 'services/api/contracts';

export default function useDeleteContractAttachment({ attachmentId }: { attachmentId: number }) {
  const [deleteTrigger, { isLoading }] = useDeleteContractAttachmentMutation();

  const deleteContractAttachment = useCallback(() => {
    deleteTrigger({ attachmentId });
  }, [attachmentId, deleteTrigger]);

  return {
    deleteContractAttachment,
    isLoading,
  };
}
