import React, { useState } from 'react';
import { addDays, fromUnixTime } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectFluxPersonalizedFormEndDate, selectFluxPersonalizedFormStartDate } from 'redux/reducers/fluxPersonalizedFormReducer';
import { PersonalizedBlockDraft, PersonalizedScenarioDraft } from 'helper/personalized';
import { Button, Form } from 'react-bootstrap';
import CloseButton from 'components/CloseButton';
import BlockForm from './BlockForm';
import BlockDisplay from '../../BlockDisplay';
import './index.scss';

export default function Scenario({
  scenario, setName, setBlocks, validated, removeScenario,
}: {
  scenario: PersonalizedScenarioDraft,
  setName: (name: string) => void,
  setBlocks: (blocks: PersonalizedBlockDraft[]) => void,
  removeScenario: () => void,
  validated: boolean,
}) {
  const studyStartDate = useSelector(selectFluxPersonalizedFormStartDate);
  const studyEndDate = useSelector(selectFluxPersonalizedFormEndDate);
  const [blockStartDate, setBlockStartDate] = useState<Date>(fromUnixTime(studyStartDate));
  const [blockEndDate, setblockEndDate] = useState<Date | null>(null);
  const [showBlockForm, setShowBlockForm] = useState(false);

  const onBlockSave = (block: PersonalizedBlockDraft) => {
    setBlockStartDate(addDays(block.endDate, 1));
    setblockEndDate(block.endDate);
    setBlocks([...scenario.blocks, block]);
    setShowBlockForm(false);
  };

  const onBlockDelete = (block: PersonalizedBlockDraft) => {
    const blockIndex = scenario.blocks.findIndex((b) => b === block);
    const newBlockSet = scenario.blocks.slice(0, blockIndex);
    const newLastBlock = newBlockSet[newBlockSet.length - 1];

    if (newLastBlock) {
      setBlockStartDate(addDays(newLastBlock.endDate, 1));
      setblockEndDate(newLastBlock.endDate);
    } else {
      setBlockStartDate(fromUnixTime(studyStartDate));
      setblockEndDate(null);
    }
    setBlocks(newBlockSet);
    setShowBlockForm(false);
  };

  const unfilledScenario = !blockEndDate || blockEndDate < fromUnixTime(studyEndDate);

  return (
    <div className="pb-4 my-3 border-bottom">
      <div className="d-flex justify-content-end">
        <CloseButton onClick={removeScenario} label="" id={`remove-${JSON.stringify(scenario)}`} />
      </div>
      <Form
        noValidate
        validated={validated}
      >
        <Form.Group className="scenario-name mb-4">
          <Form.Label>Nome do Cenário</Form.Label>
          <Form.Control
            value={scenario.name}
            required
            onChange={(event) => { setName(event.target.value); }}
          />
          <Form.Control.Feedback type="invalid">O nome do cenário não pode ficar em branco</Form.Control.Feedback>
        </Form.Group>
      </Form>
      <div className="d-flex flex-row align-items-stretch scenario-blocks">
        {scenario.blocks.map((block, index) => (
          <div className="flex-fill" key={block.key}>
            <BlockDisplay
              key={block.key}
              title={`Bloco ${index + 1}`}
              model={block.model}
              rmv={Boolean(block.rmv)}
              startDate={block.startDate.toLocaleDateString('pt-BR')}
              endDate={block.endDate.toLocaleDateString('pt-BR')}
              sliceStartDate={block.sliceStartDate.toLocaleDateString('pt-BR')}
              sliceEndDate={block.sliceEndDate.toLocaleDateString('pt-BR')}
              onBlockDelete={() => onBlockDelete(block)}
            />
          </div>
        ))}
        {(!showBlockForm && unfilledScenario) && (
          <div>
            <Button
              className="new-block-button"
              onClick={() => setShowBlockForm(true)}
            >
              Novo bloco
              <br />
              +
            </Button>
          </div>
        )}
      </div>
      {(validated && unfilledScenario && !showBlockForm) && (
        <div className="block-feedback">
          Os blocos devem preencher todo o horizonte do estudo
        </div>
      )}
      {showBlockForm && (
        <BlockForm
          setShowBlockForm={setShowBlockForm}
          onBlockSave={onBlockSave}
          blockStartDate={blockStartDate}
        />
      )}
    </div>
  );
}
