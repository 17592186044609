import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectMailerLayoutCurrentTab,
  setIsDanger,
  selectMailerLayoutSearchQuery,
  setSearchQuery,
} from 'redux/reducers/mailerLayoutReducer';
import './index.scss';
import { Col, Row } from 'react-bootstrap';
import SearchBar from 'components/SearchBar';
import TabbedPage from 'components/TabbedPage';
import { store } from 'redux/store';

export default function MailerLayout() {
  const currentTab = useSelector(selectMailerLayoutCurrentTab);
  const [searchBarText, setSearchBarText] = useState('');
  const onSearch = (searchQuery: string) => (
    store.dispatch(setSearchQuery(searchQuery))
  );
  const searchQuery: string = useSelector(selectMailerLayoutSearchQuery);

  useEffect(() => {
    setSearchBarText(searchQuery);
  }, [searchQuery]);

  useEffect(() => { store.dispatch(setIsDanger(false)); }, []);

  return (
    <>
      <Row>
        <Col className="md-6">
          <h2 className="mailerTitle mb-3">MAILER</h2>
        </Col>
        <Col>
          <SearchBar
            text={searchBarText}
            setText={setSearchBarText}
            setSearchQuery={onSearch}
            disabled={currentTab === 'templates'}
          />
        </Col>
      </Row>
      <TabbedPage>
        <TabbedPage.Tab title="RELATÓRIOS" path="/admin/mailer/reports" />
        <TabbedPage.Tab title="E-MAIL EM MASSA" path="/admin/mailer/mass-emails" />
        <TabbedPage.Tab title="TEMPLATES" path="/admin/mailer/templates" />
      </TabbedPage>
    </>
  );
}
