import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import useInvoiceSubmission from 'utils/hooks/financial/useInvoiceSubmission';
import './index.scss';
import { ProcessingStatus } from 'redux/reducers/nfeProcessingReducer';

export default function Summary() {
  const {
    processingState,
    progress,
    errors,
    startProcessing,
    cancelProcessing,
  } = useInvoiceSubmission();

  const isIdle = processingState === ProcessingStatus.IDLE;
  const isProcessing = processingState === ProcessingStatus.PROCESSING;

  let button: any = null;
  if (isIdle) button = <Button variant="primary" onClick={startProcessing}>Iniciar</Button>;
  if (isProcessing) button = <Button variant="danger" onClick={cancelProcessing}>Cancelar</Button>;

  return (
    <Row className="w-100">
      {!isIdle && (
        <>
          <Col className="text-center mt-2">
            <div>
              <span className="invoice-summary-title">Progresso: </span>
              {progress}
            </div>
          </Col>
          <Col className="text-center mt-2">
            <span className="invoice-summary-title">Erros: </span>
            {errors}
          </Col>
        </>
      )}
      {button && <Col className="text-end">{button}</Col>}
    </Row>
  );
}
