import { useMemo } from 'react';
import { fromUnixTime as fromUnixTimeDF } from 'date-fns';

export default function useFromUnixTime(time: number | null | undefined): Date | null {
  return useMemo(() => {
    if (!time) {
      return null;
    }

    return fromUnixTimeDF(time);
  }, [time]);
}
