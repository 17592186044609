import { GenericResponse, ResponseError } from 'services/api/base';
import { User } from './users';

export const NA_VALUE = 0;

export enum SpecialModels {
  Climatology = 'climatologia',
  HistoricalRain = 'merge',
  Zero = 'zero',
}

export enum LockedRMVModels {
  Climatology = 'climatologia',
  HistoricalRain = 'merge',
  Zero = 'zero',
  ECMWF46_PROB = 'ecmwf46-prob',
  EC46_ONS_CLUSTER = 'ec46-ons-cluster',
}

export interface ListPersonalizedParams {
  ampereCases: boolean,
  page: number,
  pageSize: number,
}

export interface PersonalizedBlock {
  id?: number,
  id_cenario?: number,
  uuid?: string,
  ds_modelo: string,
  dt_data_prev: number,
  ck_rmv: boolean,
  dt_inicio: number,
  dt_fim: number,
  dt_recorte_inicio: number,
  dt_recorte_fim: number,
  preview?: string | null,
}

export interface PersonalizedScenario {
  id?: number,
  id_estudo?: number,
  uuid?: string,
  ds_nome: string,
  blocos: PersonalizedBlock[],
}

export interface PersonalizedStudyParams {
  ready: boolean,
  vl_execution_counter: number,
  ds_nome_estudo: string,
  dt_inicio: number,
  dt_fim: number,
  ds_nome_cenario?: string,
  tipo_modelo_base?: string,
  ck_prev_rmv?: boolean,
  dt_data_prev?: number,
  dt_data_fim_hist?: string,
  dt_cenario_inicio?: number,
  dt_cenario_fim?: number,
  cenarios: PersonalizedScenario[],
}

export interface PersonalizedStudyResponse {
  id: number,
  id_empresa: number,
  id_user: number,
  ds_nome_estudo: string,
  dt_inicio: number,
  dt_fim: number,
  dt_last_execution: number | null,
  dt_last_response: number | null,
  vl_execution_counter: number,
  ready: boolean,
  uuid: string,
  ck_internal_error: boolean,
  ck_is_file: boolean,
  ck_is_nt: boolean,
  cenarios: PersonalizedScenario[],
  user: User,
}

export type SaveCustomStudyResponse = GenericResponse<PersonalizedStudyResponse>;

export interface PersonalizedDownloadLinkParams {
  caso_id: number,
}

export interface DownloadLinkData {
  link: string,
}

export type DownloadLinkResponse = GenericResponse<DownloadLinkData>;

export interface PersonalizedBlockDraft {
  key: string,
  model: string,
  runtime: number,
  member: string,
  rmv: number,
  forecastDate: Date,
  startDate: Date,
  endDate: Date,
  sliceStartDate: Date,
  sliceEndDate: Date,
}

export interface PersonalizedScenarioDraft {
  name: string,
  blocks: PersonalizedBlockDraft[],
}

export interface RawPersonalizedHorizonResponse {
  status: number,
  data: {
    method: string,
    response: string,
    status_code: number,
    data_inicial: number,
    data_final: number,
    num_dias: number,
    message?: string,
  },
}

export interface PersonalizedHorizonParams {
  forecastDate: number,
  model: string,
  runtime: number,
  member: string,
}

export interface PersonalizedHorizon {
  minDate: number,
  maxDate: number,
  days: number,
}

export const transformPersonalizedMaxHorizonResponse = (
  response: RawPersonalizedHorizonResponse | undefined,
): {
  data: PersonalizedHorizon | undefined,
  error: ResponseError | undefined,
} => ({
  data: response?.data.status_code === 200 ? {
    minDate: response.data.data_inicial,
    maxDate: response.data.data_final,
    days: response.data.num_dias,
  } : undefined,
  error: (response?.data.status_code && response?.data.status_code !== 200) ? {
    status: response?.data.status_code,
    data: `${response?.data.response}: ${response?.data.message}`,
  } : undefined,
});
