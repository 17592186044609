import React, { useState } from 'react';
import {
  Button, Stack,
} from 'react-bootstrap';
import PlusButton from 'components/PlusButton';
import SortingArrow from 'components/SortingArrow';
import { ListedCompany } from 'helper/companies';
import { addAllCompanies, addCompanies } from 'helper/reportForm';
import { SORT_BY_NAME } from 'services/api/companies';
import { SORT_ASC, SORT_DESC, SortDirection } from 'constants/sorting';

export default function CompanyList({
  companies,
  companyIds,
  setCompanyIds,
} : {
  companies: ListedCompany[],
  companyIds: number[],
  setCompanyIds: React.Dispatch<React.SetStateAction<number[]>>,
}) {
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>();
  const sortedCompanies = companies.map((company) => company);

  if (sortDirection === SORT_ASC) sortedCompanies.sort();
  if (sortDirection === SORT_DESC) sortedCompanies.sort().reverse();

  if (companies.length < 1) {
    return (
      <div className="mb-2">
        <Stack direction="horizontal" className="ms-3 fw-bold">
          EMPRESA
          <SortingArrow
            field={SORT_BY_NAME}
            sortBy={SORT_BY_NAME}
            sortDirection={sortDirection}
            sort={setSortDirection}
          />
        </Stack>
        <hr className="bg-white" />
        <p className="text-center text-muted report-form-company-select">
          Nenhuma empresa encontrada
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between position-relative mb-2">
        <Stack direction="horizontal" className="ms-3 fw-bold">
          EMPRESA
          <SortingArrow
            field={SORT_BY_NAME}
            sortBy={SORT_BY_NAME}
            sortDirection={sortDirection}
            sort={setSortDirection}
          />
        </Stack>
        <Button
          onClick={() => addAllCompanies(setCompanyIds, companies)}
          size="sm"
          className="position-absolute top-0 end-0"
        >
          {`Adicionar todas (${companies.length})`}
        </Button>
      </div>
      <hr className="bg-white" />
      <div className="report-form-company-select">
        {sortedCompanies.map((company) => (
          <div key={company.id}>
            <div className="mx-3">
              <PlusButton
                id={`add-company-${company.id}`}
                label={`${company.nome_fantasia} | ${company.cnpj}`}
                onClick={() => addCompanies(setCompanyIds, companyIds, [company.id])}
              />
            </div>
            <hr className="bg-white" />
          </div>
        ))}
      </div>
    </>
  );
}
