import React, { useState } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSaveUserMutation } from 'services/api/users';
import range from 'helper/range';
import CPFInput, { PhoneInput } from 'components/Forms/Input';
import AlertsCollection, { CustomAlert } from 'components/AlertsCollection';
import useCompanySelect from 'utils/hooks/useCompanySelect';
import { User } from '../../../../helper/users';
import { onUserFormSubmit } from './utils';

function ExtraField(
  {
    name, type, initialValue, id,
  }: {
    name: string,
    type: string,
    initialValue: string | undefined,
    id: number | undefined
  },
) {
  const nameProp = `${name}[]`;
  const idProp = `${name}_id[]`;

  return (
    <Form.Group className="pb-1">
      <Form.Control defaultValue={initialValue} name={nameProp} type={type} />
      <Form.Control defaultValue={id} name={idProp} type="hidden" />
    </Form.Group>
  );
}

export function fetchDefaultValue(user: User | null) {
  if (user == null) {
    return { value: '', label: '' };
  }
  return { value: user.empresa.id, label: user.empresa.nome_fantasia };
}

export default function UserForm({ user }: { user: User | null }) {
  const [saveUser,
    { isLoading: isUserSaveLoading, data: saveUserData }] = useSaveUserMutation();

  const [emailFieldNumber, setEmailFieldNumber] = useState(
    (user?.emails && user.emails.length) || 1,
  );
  const [phoneFieldNumber, setPhoneFieldNumber] = useState(user?.phones?.length || 1);

  const [validated, setValidated] = useState(false);
  const { companyOptions, companiesLoading } = useCompanySelect();

  const [alerts, setAlerts] = useState<CustomAlert[]>([]);

  const companiesDefaultOption = fetchDefaultValue(user);

  if (companiesLoading) {
    return <Spinner className="mx-auto" animation="border" />;
  }

  // istanbul ignore next
  if (saveUserData) {
    return <div>{saveUserData.message}</div>;
  }

  const extraEmailFields = range(emailFieldNumber - 1).map(
    (index) => {
      const key = `email${index}`;
      const email = user?.emails[index + 1];
      return (
        <ExtraField
          name="email"
          type="email"
          initialValue={email?.email}
          key={key}
          id={email?.id}
        />
      );
    },
  );

  const extraPhoneFields = range(phoneFieldNumber - 1).map(
    (index) => {
      const key = `phone${index}`;
      const phone = user?.phones[index + 1];
      return (
        <Form.Group className="pb-1" key={key}>
          <PhoneInput
            defaultValue={phone?.phone}
            key={`phone${index}`}
          />
          <Form.Control
            required
            name="phone_id[]"
            defaultValue={phone?.id}
            type="hidden"
          />
        </Form.Group>
      );
    },
  );

  return (
    <div>
      <Form
        onSubmit={(event) => onUserFormSubmit(event, user, saveUser, setValidated, setAlerts)}
        validated={validated}
        noValidate
      >
        <Form.Control type="hidden" name="active" defaultValue={String(user?.active)} />

        <Row>
          <Col>
            <AlertsCollection alerts={alerts} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="userFirstName">
              <Form.Label>Nome</Form.Label>
              <Form.Control required type="name" name="first_name" defaultValue={user?.first_name} />
              <Form.Control.Feedback type="invalid">Nome não pode ser vazio</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="userLastName">
              <Form.Label>Sobrenome</Form.Label>
              <Form.Control required type="text" name="last_name" defaultValue={user?.last_name} />
              <Form.Control.Feedback type="invalid">Sobrenome não pode ser vazio</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="pt-3">
          <Form.Group controlId="userCPF">
            <Form.Label>CPF</Form.Label>
            <CPFInput defaultValue={user?.cpf || ''} />
            <Form.Control.Feedback type="invalid">CPF inválido</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="pt-3">
          <Form.Group controlId="userCompany">
            <Form.Label>Empresa</Form.Label>
            <Select
              id="id_empresa"
              name="id_empresa"
              options={companyOptions}
              defaultValue={companiesDefaultOption}
              placeholder=""
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: '1.6px',
                }),
              }}
            />
          </Form.Group>
        </Row>

        <Row className="pt-3">
          <Col>
            <div className="d-flex flex-column">
              <Form.Group className="pb-1" controlId="userEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  name="email[]"
                  placeholder="E-mail principal"
                  defaultValue={user?.emails[0] && user.emails[0].email}
                  type="email"
                />
                <Form.Control
                  required
                  name="email_id[]"
                  defaultValue={user?.emails[0] && user.emails[0].id}
                  type="hidden"
                />
                <Form.Control.Feedback type="invalid">Email inválido</Form.Control.Feedback>
              </Form.Group>

              {extraEmailFields}

              <Button variant="outline-secondary" onClick={() => setEmailFieldNumber(emailFieldNumber + 1)}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </Col>

          <Col>
            <div className="d-flex flex-column">
              <Form.Group className="pb-1" controlId="userEmail">
                <Form.Label>Telefone</Form.Label>
                <PhoneInput defaultValue={user?.phones[0] && user.phones[0].phone} placeholder="Telefone principal" />
                <Form.Control
                  required
                  name="phone_id[]"
                  defaultValue={user?.phones[0] && user.phones[0].id}
                  type="hidden"
                />
              </Form.Group>

              {extraPhoneFields}

              <Button variant="outline-secondary" onClick={() => setPhoneFieldNumber(phoneFieldNumber + 1)}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button className="mt-3 float-end primary-button" type="submit" disabled={isUserSaveLoading}>
              Salvar
              {isUserSaveLoading && /* istanbul ignore next */ <Spinner className="mx-auto" animation="border" />}
            </Button>
          </Col>
        </Row>

      </Form>
    </div>
  );
}
