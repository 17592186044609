import { ResponseError } from 'services/api/base';
import { usePopularComparisonsQuery } from 'services/api/Meteorology';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function usePopularComparisons() {
  const { data, isFetching, error } = usePopularComparisonsQuery();
  const comparisons = data?.data || [];

  useHandleRequestErrors(error as ResponseError);

  return {
    comparisons,
    isFetching,
  };
}
