import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { Email } from 'helper/mailer';
import { MEDIUM_BLUE } from 'constants/colors';
import './index.scss';
import { Accordion } from 'react-bootstrap';
import EmailRead from './EmailRead';

export default function ReportUserCard({ email }: {
  email: Email,
}) {
  const { reads } = email;

  return (
    <div className="report-user-card">
      <h3>{email.mailTo}</h3>
      <FontAwesomeIcon icon={faCode} size="sm" color={MEDIUM_BLUE} />
      <span className="ms-1">{email.identifier}</span>
      {reads.length > 0 && (
        <h4 className="mt-3 mb-1">Views:</h4>
      )}
      {reads.length === 1 && (
        <EmailRead readInfo={reads[0]} />
      )}
      {reads.length > 1 && (
        <Accordion flush>
          <Accordion.Header>
            <EmailRead readInfo={reads[0]} />
          </Accordion.Header>
          <Accordion.Body>
            {reads.slice(1).map((read) => (
              <div className="mt-3" key={read.identifier}>
                <EmailRead readInfo={read} />
              </div>
            ))}
          </Accordion.Body>
        </Accordion>
      )}
    </div>
  );
}
