import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PermissionTypes } from 'helper/products';

type ProductKeys = {
  [key in PermissionTypes]: string | null
};

const initialState: ProductKeys = {
  'file-viewer': null,
  flux: null,
  meteorologia: null,
  'meteorologia-videos': null,
  'prevs-personalizado': null,
  'prevs-automatico': null,
  'prevs-historico': null,
  'prevs-gt': null,
};

interface ProductKeyPayload {
  product: PermissionTypes,
  key: string | null,
}

export const productKeySlice = createSlice({
  name: 'productKeys',
  initialState,
  reducers: {
    setProductKey: (state, action: PayloadAction<ProductKeyPayload>) => {
      const { product, key } = action.payload;
      state[product] = key;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectFileViewerKey = (state: any) => state.productKeys['file-viewer'];
export const selectMeteorologyKey = (state: any) => state.productKeys.meteorologia;
export const selectMeteorologyVideosKey = (state: any) => state.productKeys['meteorologia-videos'];
export const selectCustomForecastKey = (state: any) => state.productKeys['prevs-personalizado'];
export const selectAutomaticForecastKey = (state: any) => state.productKeys['prevs-automatico'];
export const selectHistoricalForecastKey = (state: any) => state.productKeys['prevs-historico'];
export const { setProductKey } = productKeySlice.actions;

export default productKeySlice.reducer;
