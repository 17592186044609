import baseApi from 'services/api/base';
import { WeatherAreaHistory } from 'types/Meteorology/Weather/Area/getHistoryResponse';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import { store } from 'redux/store';
import transformGetHistoryResponse from 'helper/Meteorology/Weather/Area/History';
import GetHistoryParams from 'types/Meteorology/Weather/Area/getHistoryParams';

export const weatherAreaHistoryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getHistory: build.query<WeatherAreaHistory, GetHistoryParams>({
      transformResponse: transformGetHistoryResponse,
      query: ({ area }) => {
        const productKey = selectMeteorologyKey(store.getState());
        return {
          url: `produtos/meteorologia/clima/history/?product_key=${productKey}`,
          method: 'POST',
          body: { area },
        };
      },
    }),
  }),
});

export const {
  useGetHistoryQuery,
} = weatherAreaHistoryApi;
