import filterUndefined from 'utils/filterUndefined';
import { ListedCompany } from './companies';

export interface UserFilterParams {
  productIds?: number[]
  companyIds?: number[]
  enabled?: boolean
  hasProductsExpiringIn30Days?: boolean
  hasProductsExpiringIn60Days?: boolean
}

export interface UserModalFilters {
  [key: string]: number[] | string | boolean
  selectedProducts: number[]
  selectedCompany: string
  isEnabled: boolean
  isDisabled: boolean
  isExpiringIn30: boolean
  isExpiringIn60: boolean
}

export const modalToQueryFilters = (
  modalFilters: UserModalFilters,
  companies: ListedCompany[] | undefined,
) => {
  if (!companies) {
    return {} as UserFilterParams;
  }

  const productIds = modalFilters.selectedProducts;
  const companyIds = modalFilters.selectedCompany === '' ? undefined : [
    companies.find((company) => company.nome_fantasia === modalFilters.selectedCompany)?.id,
  ];

  return filterUndefined({
    productIds: productIds.length === 0 ? undefined : productIds,
    companyIds,
    enabled: modalFilters.isEnabled || (modalFilters.isDisabled ? false : undefined),
    hasProductsExpiringIn30Days: modalFilters.isExpiringIn30 || undefined,
    hasProductsExpiringIn60Days: modalFilters.isExpiringIn60 || undefined,
  }) as UserFilterParams;
};
