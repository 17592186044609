import React from 'react';

import { QueueStatus } from 'utils/hooks/flux/useQueueStatus';

export default function Status({ data }: { data: QueueStatus[] }) {
  return (
    <table className="status-tab w-100 mt-3">
      <thead>
        <tr>
          <th>Título</th>
          <th style={{ textAlign: 'center' }}>Em execução</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={`flux-queue-status-${item.title}`}>
            <td className="model-name">{item.title}</td>
            <td className="last-update">{item.running ? 'Sim' : 'Não'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
