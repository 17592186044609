import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPlotArea,
  regionObject,
  setStartPeriod,
  setEndPeriod,
  clearDates,
  nextDateLabels,
  SelectablePeriods,
  SelectableRegions,
  setSelectedPeriod,
  setAnomaly,
  selectMFControlPanelObservedDataStartPeriod,
  selectMFControlPanelObservedDataEndPeriod,
  selectMFControlPanelObservedDataPlotArea,
  selectMFControlPanelObservedDataSelectedPeriod,
  selectMFControlPanelObservedDataAnomaly,
  selectMFControlPanelObservedDataObsImageString,
  selectMFControlPanelObservedDataClimatologyImageString,
  selectMFControlPanelObservedDataAnomalyImageString,
  selectMFControlPanelObservedDataImageLoading,
  setObsImageString,
  setClimatologyImageString,
  setAnomalyImageString,
  setImageLoading,
  setErrorAlert,
  selectMFControlPanelObservedDataErrorAlert,
} from 'redux/reducers/meteorologyFieldsObservedDataControlPanelReducer';
import {
  setHours, addDays, isBefore, isAfter, startOfDay, roundToNearestMinutes,
} from 'date-fns';
import { useLazyGetObservedDataImagesQuery } from 'services/api/Meteorology';
import DatePicker from 'components/DatePicker';
import { AlternativeResponseError } from '../useDisplayErrorMessages';

export type PeriodModes = 'preset' | 'date' | 'free';

export default function useObservedDataControlPanel() {
  const dispatcher = useDispatch();
  const plotArea = useSelector(selectMFControlPanelObservedDataPlotArea);
  const startDate = useSelector(selectMFControlPanelObservedDataStartPeriod);
  const endDate = useSelector(selectMFControlPanelObservedDataEndPeriod);
  const selectedPeriod = useSelector(selectMFControlPanelObservedDataSelectedPeriod);
  const anomaly = useSelector(selectMFControlPanelObservedDataAnomaly);
  const obsBase64 = useSelector(selectMFControlPanelObservedDataObsImageString);
  const climatologyBase64 = useSelector(selectMFControlPanelObservedDataClimatologyImageString);
  const anomalyBase64 = useSelector(selectMFControlPanelObservedDataAnomalyImageString);
  const imagesAreFetching = useSelector(selectMFControlPanelObservedDataImageLoading);
  const alerts = useSelector(selectMFControlPanelObservedDataErrorAlert);
  const [queryTrigger, {
    data, error, isFetching,
  }] = useLazyGetObservedDataImagesQuery();

  const queryTriggerMethod = useMemo(() => (
    () => {
      queryTrigger({
        region: plotArea,
        variable: 'prec',
        dataPerStation: false,
        initialTimestamp: !startDate ? undefined : Math.ceil(startDate / 1000),
        finalTimestamp: !endDate ? undefined : Math.ceil(endDate / 1000),
        preset: selectedPeriod,
        anomaly,
      }, selectedPeriod !== undefined);
      dispatcher(clearDates());
    }
  ), [queryTrigger, dispatcher, plotArea, startDate, endDate, selectedPeriod, anomaly]);

  useEffect(() => {
    const obsImage = data?.data?.obs && !error ? data?.data?.obs : '';
    const climatologyImage = data?.data?.clim && !error ? data?.data?.clim : '';
    const anomalyImage = data?.data?.anom && !error ? data?.data?.anom : '';

    dispatcher(setObsImageString(obsImage));
    dispatcher(setClimatologyImageString(climatologyImage));
    dispatcher(setAnomalyImageString(anomalyImage));
    dispatcher(setErrorAlert(error as AlternativeResponseError | undefined));
    dispatcher(setImageLoading(isFetching));
  }, [data, dispatcher, error, isFetching]);

  function SelectionButton({
    text, value, currentValue, modifier, extraClassName,
  }: {
    text: string, value: string, currentValue: string,
    modifier: any, extraClassName: string | undefined,
  }) {
    const activeStyle = currentValue === value ? 'btn-control-panel-active' : '';

    return (
      <Button variant="link" onClick={() => modifier(value)} className={`btn-control-panel ${activeStyle} ${extraClassName}`}>
        { text }
      </Button>
    );
  }

  const setPeriodOption = (option: SelectablePeriods) => dispatcher(setSelectedPeriod(option));
  const setRegion = (region: SelectableRegions) => dispatcher(setPlotArea(region));
  const setStartTime = (date: Date | null) => dispatcher(setStartPeriod(date?.getTime()));
  const setEndTime = (date: Date | null) => dispatcher(setEndPeriod(date?.getTime()));
  const toggleAnomaly = () => dispatcher(setAnomaly(!anomaly));

  function PeriodSelectors() {
    const selectorValue = (key: string) => key.replace('ê', 'e').replace('ú', 'u');

    return (
      <>
        {
          Object.keys(nextDateLabels).map((key: string) => (
            <SelectionButton
              text={nextDateLabels[key]}
              value={selectorValue(nextDateLabels[key])}
              key={key}
              currentValue={selectedPeriod}
              modifier={setPeriodOption}
              extraClassName="period-pills"
            />
          ))
        }
      </>
    );
  }

  function RegionSelectorOD() {
    return (
      <>
        {
          regionObject.map(({ label, value }) => (
            <SelectionButton
              key={label}
              text={label}
              value={value}
              currentValue={plotArea}
              modifier={setRegion}
              extraClassName=""
            />
          ))
        }
      </>
    );
  }

  function DateSelector() {
    const initialStart = startDate ? addDays(new Date(startDate), 1) : null;
    const initialEnd = endDate ? new Date(endDate) : null;
    const [selectedStart, setSelectedStart] = useState<Date | null>(initialStart);
    const [selectedEnd, setSelectedEnd] = useState<Date | null>(initialEnd);
    const buttonActivationCondition = selectedStart !== null
      && selectedEnd !== null
      && plotArea !== undefined;

    const startDateFilter = (time: Date) => !isAfter(time, selectedEnd || new Date());
    const endDateFilter = (time: Date) => (
      (!startDate || !isBefore(time, startDate)) && !isAfter(time, new Date())
    );

    useEffect(() => {
      if (selectedStart) {
        const baseDay = addDays(selectedStart, -1);
        const newStartDate = setHours(baseDay, 12);
        setStartTime(newStartDate);
      }
    }, [selectedStart]);

    useEffect(() => {
      if (selectedEnd) {
        const newEndDate = setHours(selectedEnd, 11);
        setEndTime(newEndDate);
      }
    }, [selectedEnd]);

    return (
      <Stack>
        <DatePicker
          className="period-selector"
          selected={selectedStart}
          onChange={(d) => setSelectedStart(d)}
          placeholderText="Período Inicial"
          filterDate={startDateFilter}
        />
        <DatePicker
          className="period-selector"
          selected={selectedEnd}
          onChange={(d) => setSelectedEnd(d)}
          placeholderText="Período Final"
          filterDate={endDateFilter}
        />
        <Row className="pt-2 px-4">
          <Button variant="primary" disabled={!buttonActivationCondition} onClick={() => queryTriggerMethod()}>
            Realizar consulta
          </Button>
        </Row>
      </Stack>
    );
  }

  function FreePeriodSetter() {
    const presentDate = roundToNearestMinutes(new Date(), { nearestTo: 30, roundingMethod: 'floor' });
    const selectedStart = startDate ? new Date(startDate) : null;
    const selectedEnd = endDate ? new Date(endDate) : null;
    const maxDate = selectedEnd || presentDate;
    const buttonActivationCondition = selectedStart !== null
      && selectedEnd !== null
      && plotArea !== undefined;

    const startDateFilter = (date: Date) => !isAfter(date, maxDate);
    const endDateFilter = (date: Date) => (
      (!startDate || !isBefore(date, startOfDay(startDate))) && !isAfter(date, presentDate)
    );
    const endDateTimeFilter = (date: Date) => (
      (!startDate || !isBefore(date, startDate)) && !isAfter(date, presentDate)
    );

    const handleStartDate = (date: Date) => {
      if (isAfter(date, maxDate)) {
        setStartTime(maxDate);
      } else {
        setStartTime(date);
      }
    };
    const handleEndDate = (date: Date) => {
      if (selectedStart && isBefore(date, selectedStart)) {
        setEndTime(selectedStart);
      } else {
        setEndTime(date);
      }
    };

    return (
      <Stack>
        <DatePicker
          className="period-selector"
          selected={selectedStart}
          onChange={handleStartDate}
          placeholderText="Período Inicial"
          dateFormat="P HH:mm"
          showTimeSelect
          filterDate={startDateFilter}
          filterTime={startDateFilter}
        />
        <DatePicker
          className="period-selector"
          selected={selectedEnd}
          onChange={handleEndDate}
          placeholderText="Período Final"
          dateFormat="P HH:mm"
          showTimeSelect
          filterDate={endDateFilter}
          filterTime={endDateTimeFilter}
        />
        <Row className="pt-2 px-4">
          <Button variant="primary" disabled={!buttonActivationCondition} onClick={() => queryTriggerMethod()}>
            Realizar consulta
          </Button>
        </Row>
      </Stack>
    );
  }

  const getSelector = (mode: PeriodModes) => {
    switch (mode) {
      case 'preset':
        return <PeriodSelectors />;
      case 'date':
        return <DateSelector />;
      default:
        return <FreePeriodSetter />;
    }
  };

  return {
    RegionSelectorOD,
    region: plotArea,
    startDate,
    endDate,
    selectedPeriod,
    getSelector,
    anomaly,
    toggleAnomaly,
    obsBase64,
    climatologyBase64,
    anomalyBase64,
    queryTrigger: queryTriggerMethod,
    imagesAreFetching,
    alerts,
  };
}
