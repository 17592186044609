import React, { useState } from 'react';
import {
  Container, Form, Modal,
} from 'react-bootstrap';
import SearchBar from 'components/SearchBar';
import { SpacialSubdivisionOption } from 'helper/flux/automatic';
import removeAccents from 'helper/removeAccents';

import './index.scss';
import buildSets from 'helper/buildSets';

export default function SuboptionsModal({
  suboptions,
  show,
  onHide,
  onSave,
}: {
  suboptions: SpacialSubdivisionOption[],
  show: boolean,
  onHide: () => void,
  onSave: (maybeValue: SpacialSubdivisionOption | undefined) => void,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const formattedSearchTerm = removeAccents(searchTerm).toLowerCase();
  const filteredSuboptions: SpacialSubdivisionOption[] = suboptions
    .filter(({ label }) => (
      removeAccents(label).toLowerCase().includes(formattedSearchTerm)
    ));
  const suboptionRows = buildSets(filteredSuboptions, 5);

  const toggleValue = (label: string, value: number) => {
    onSave({ label, value });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onClose={onHide}
      dialogClassName="modal-dialog-scrollable modal-lg"
    >
      <Modal.Header closeButton>
        <SearchBar
          text={searchTerm}
          setText={setSearchTerm}
          setSearchQuery={setSearchTerm}
        />
      </Modal.Header>
      <Modal.Body>
        <Container className="p-4">
          {suboptionRows.map((row) => (
            <div className="d-flex flex-wrap" key={row.map(({ label }) => label).join('-')}>
              {row.map(({ label, value }) => (
                <Form.Check
                  id={`filter-selection-suboptions-modal-checkbox-${label}`}
                  onChange={() => toggleValue(label, value)}
                  label={label}
                  className="filter-selection-suboptions-modal-checkbox"
                  key={`filter-selection-suboptions-modal-checkbox-${label}`}
                />
              ))}
            </div>
          ))}
        </Container>
      </Modal.Body>
    </Modal>
  );
}
