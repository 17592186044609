import { useEffect, useRef, useState } from 'react';
import {
  useCancelMailerReportMutation,
  useLazyFillInMessageQuery,
  useListMailerOptionTypesQuery,
  useSendReportMailMutation,
} from 'services/api/mailer';
import { ResponseError } from 'services/api/base';
import { CustomAlert } from 'components/AlertsCollection';
import { FillInMessageParams } from 'helper/mailer';
import { format } from 'date-fns';
import useHandleRequestErrors from '../useHandleRequestErrors';
import useReportMailClientQueryAdapter from './useReportMailClientQueryAdapter';

export default function useReportForm() {
  const [isTest, setIsTest] = useState(true);

  const [typeId, setTypeId] = useState(-1);
  const [subtypeId, setSubtypeId] = useState(-1);
  const { data: types } = useListMailerOptionTypesQuery();
  const subtypes = types?.find((type) => type.id === typeId)?.subTypes || [];

  const [date, setDate] = useState<Date | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [companyIds, setCompanyIds] = useState<number[]>([]);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const [preview, setPreview] = useState(false);
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const [pid, setPid] = useState(-1);
  const [processIdentification, setProcessIdentification] = useState('');
  const [cancellationStatus, setCancellationStatus] = useState('');
  const {
    data: reportMailClients, isLoading: reportMailClientsLoading,
  } = useReportMailClientQueryAdapter({
    typeId, subProductId: subtypeId, date,
  });

  const [trigger, {
    data: submissionResponse, error: submissionError, isLoading, isError,
  }] = useSendReportMailMutation();

  const [cancelReportTrigger, {
    data: cancellationData, error: cancellationError,
  }] = useCancelMailerReportMutation();

  const [fillInMessageTrigger, { data: fillInMessageData }] = useLazyFillInMessageQuery();

  const submissionData = submissionResponse?.status && submissionResponse.data;
  const typeSelected = typeId > -1;
  const subtypeSelected = subtypeId > -1;
  const clearInputsDisabled = !(typeSelected || subtypeSelected || date || file);

  const validType = types?.find(({ id }) => typeId === id) !== undefined;
  const validSubtype = subtypes.find(({ id }) => subtypeId === id) !== undefined;

  const validDate = date !== null;

  const validFile = file !== null && /.*\.docx/.test(file.name);
  const invalidFile = file !== null && !/.*\.docx/.test(file.name);
  const invalidFileMessage = invalidFile && 'Arquivo deve ter formato .docx';

  const validCompanyIds = companyIds.length > 0;

  const fieldsValidated = validType && validSubtype && validDate && validFile;
  const formValidated = fieldsValidated && validCompanyIds;
  const submissionDisabled = !formValidated || isLoading;

  const showReportSubmission = (submissionData && cancellationStatus !== 'success') || isLoading || isError;
  const showCompanySelect = fieldsValidated && !showReportSubmission;

  useHandleRequestErrors(submissionError as ResponseError);
  useHandleRequestErrors(cancellationError as ResponseError);

  useEffect(() => {
    if (submissionResponse && cancellationStatus === '') {
      const {
        title: alertTitle, message, status, data,
      } = submissionResponse;

      setPid(data?.pid);
      setProcessIdentification(data?.process_identification);

      if (!status) {
        setAlerts([{ title: alertTitle, message, variant: 'danger' }]);
      }
    }

    if (cancellationStatus !== '') {
      if (cancellationData?.data?.success) {
        setCancellationStatus('success');
        setAlerts([{
          title: 'Envio cancelado',
          message: 'O envio do relatório foi abortado com sucesso.',
          variant: 'primary',
        }]);
      } else {
        setCancellationStatus('fail');
        setAlerts([{
          title: 'Cancelamento não ocorreu!',
          message: 'O envio do relatório não foi cancelado.',
          variant: 'danger',
        }]);
      }
    }
  }, [submissionResponse, cancellationData, cancellationStatus]);

  useEffect(() => {
    if (!date || typeId < 0 || subtypeId < 0) {
      return;
    }
    const params: FillInMessageParams = {
      type: typeId,
      subtype: subtypeId,
      printDate: format(date, 'dd/MM/yyyy'),
    };
    fillInMessageTrigger(params);
  }, [typeId, subtypeId, date, fillInMessageTrigger]);

  useEffect(() => {
    if (fillInMessageData) {
      setTitle(fillInMessageData.data.subject);
      setContent(fillInMessageData.data.body);
    }
  }, [fillInMessageData]);

  const updateFile = () => {
    if (fileRef.current?.files?.length) {
      setFile(fileRef.current.files[0]);
    } else {
      setFile(null);
    }
  };

  const clearInputs = () => {
    setTypeId(-1);
    setSubtypeId(-1);
    setDate(null);
    if (fileRef.current) {
      fileRef.current.value = '';
      updateFile();
    }
  };

  const submitReport = () => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      trigger({
        typeId,
        subtypeId,
        companies: companyIds,
        title,
        content,
        isTest,
        fileName: file.name,
        base64File: (reader.result as string).split(',')[1],
      });
    };

    setAlerts([]);
    setCancellationStatus('');
  };

  const cancelReport = () => {
    cancelReportTrigger({
      pid,
      process_identification: processIdentification,
      type: typeId.toString(),
      subtypeId,
      companies: companyIds,
      title,
      content,
      isTest,
    });

    setCancellationStatus('pending');
  };

  return {
    isTest,
    types,
    setIsTest,
    typeId,
    validType,
    subtypes,
    setTypeId,
    subtypeId,
    validSubtype,
    setSubtypeId,
    date,
    setDate,
    validDate,
    file,
    updateFile,
    validFile,
    invalidFile,
    invalidFileMessage,
    preview,
    setPreview,
    fileRef,
    clearInputsDisabled,
    clearInputs,
    submissionDisabled,
    showCompanySelect,
    companyIds,
    setCompanyIds,
    submitReport,
    submissionData,
    alerts,
    cancellationStatus,
    cancelReport,
    companies: reportMailClients,
    companiesLoading: reportMailClientsLoading,
    title,
    setTitle,
    content,
    setContent,
  };
}
