import React from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import useAreaHistory from 'hooks/Meteorology/Weather/Area/History/useAreaHistory';
import CentralizedSpinner from 'components/CentralizedSpinner';
import HistoryGraph from './HistoryGraph';

import './index.scss';

export default function History({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string,
  areaLabel: string,
}) {
  const { history, isLoading } = useAreaHistory({ area: areaAcronym });

  if (isLoading) {
    return <CentralizedSpinner />;
  }

  return (
    <Card className="shadow-sm forecast-instruction-card m-5">
      <Col className="flex-fill py-5 h-100">
        <div className="d-flex px-5 justify-content-between align-items-center">
          <div className="text-primary">{areaLabel}</div>
          <div className="text-primary fw-bold d-flex align-items-center">
            Baixar dados em CSV
            <Button
              id="download-something"
              size="sm"
              className="icon-button download-button ms-3"
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </div>
        </div>
        <div className="mt-5">
          <HistoryGraph
            categoryData={history?.categoryData || []}
            valueData={history?.valueData || []}
          />
        </div>
      </Col>
    </Card>
  );
}
