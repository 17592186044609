import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CustomAlert } from 'components/AlertsCollection';
import EditButton, { SelectedTemplate } from './EditButton';
import DeleteButton from './DeleteButton';

interface MailerTemplateListItem {
  type: any,
  subType: any,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedTemplate: React.Dispatch<React.SetStateAction<SelectedTemplate | null>>,
  setAlerts: React.Dispatch<React.SetStateAction<CustomAlert[]>>,
}

export default function Item({
  type, subType, setModalOpen, setSelectedTemplate, setAlerts,
}: MailerTemplateListItem) {
  return (
    <Row className="w-100 align-items-center admin-table-row">
      <Col md="5">
        { type.label }
      </Col>

      <Col md="5">
        { subType.label }
      </Col>

      <Col md="2">
        <EditButton
          type={type}
          subType={subType}
          setModalOpen={setModalOpen}
          setSelectedTemplate={setSelectedTemplate}
        />

        <DeleteButton
          type={type}
          subType={subType}
          setAlerts={setAlerts}
        />
      </Col>
    </Row>
  );
}
