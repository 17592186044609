import { useEffect, useMemo, useState } from 'react';
import { RawRevisionDataPerScenario, buildSubdivisionParam } from 'helper/flux/automatic';
import { useLazyGetRevisionPerScenariosQuery } from 'services/api/products/flux';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelMltOption,
  selectFluxAutomaticControlPanelSelectedRevision,
  selectFluxAutomaticControlPanelSpatialSubdivision,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';

interface RevisionScenarioError {
  data: { description: string }
}

export default function useRevisionScenarios(model: string | undefined, previsao: string) {
  const [trigger] = useLazyGetRevisionPerScenariosQuery();
  const chosenModel = model ?? '';
  const review = useSelector(selectFluxAutomaticControlPanelSelectedRevision);
  const revision = review ?? '';
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState<RawRevisionDataPerScenario | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const spatialSubdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  const triggerParameters = useMemo(() => ({
    data_acomph: 'latest',
    data_previsao: previsao,
    versao: 'latest',
    ndiasprev: 15,
    lista_cenarios: [chosenModel],
    unidade,
    revision,
  }), [chosenModel, previsao, revision, unidade]);

  useEffect(() => {
    if (spatialSubdivision !== undefined && model && revision) {
      const spatialSubdivisionParam = buildSubdivisionParam(spatialSubdivision);

      (async () => {
        setIsFetching(true);
        const res = await trigger({
          spatialSubdivisionParam,
          ...triggerParameters,
        });
        const responseError = res?.error as RevisionScenarioError | undefined;
        setErrorMessage(responseError?.data.description ?? '');
        setData(res?.data);
        setIsFetching(false);
      })();
    } else {
      setData(undefined);
      setIsFetching(false);
    }
  }, [model, revision, spatialSubdivision, trigger, triggerParameters]);

  return {
    data, isFetching, errorMessage,
  };
}
