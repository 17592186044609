import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import EditButton from 'components/Videos/EditButton';
import RemoveButton from 'components/Videos/RemoveButton';
import {
  VideoProduct,
  VideosGetLast,
  videoProductToRoute,
} from 'helper/videos';

import './index.scss';

export default function VideoListRow({
  openEditForm,
  data,
  product,
  viewOnly,
}: {
  openEditForm: (videoId: number) => void,
  data: VideosGetLast,
  product: VideoProduct,
  viewOnly: boolean,
}) {
  const userIsAdmin = useSelector(selectUserIsAdmin);
  const productRoute = videoProductToRoute[product];

  return (
    <Row className="m-3 bg-white position-relative">
      <Col>
        <Link
          to={`${productRoute}/${data.id}`}
          key={data.id}
          className="text-decoration-none"
        >
          <Row>
            <Col xs={5} className="d-flex flex-column justify-content-center p-0">
              <Image
                src={data.uri.pictures?.base_link}
                alt="video-preview"
                thumbnail
              />
            </Col>
            <Col xs={7} className="py-3">
              <div className="video-list-row-date">
                {new Date(data.uri.release_time).toLocaleDateString('pt-BR')}
              </div>
              <div className="video-list-row-title">
                {data.titulo}
              </div>
              <div
                className="video-list-row-description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </Col>
          </Row>
        </Link>
      </Col>
      {userIsAdmin && !viewOnly && (
        <Col xs={1} className="d-flex justify-content-end align-items-baseline mt-3 me-3">
          <EditButton id={data.id} onClick={() => openEditForm(data.id)} />
          <RemoveButton id={data.id} product={product} />
        </Col>
      )}
    </Row>
  );
}
