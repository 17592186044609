import React from 'react';
import {
  Col,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap';

import useFluxStatus from 'utils/hooks/flux/useStatus';
import useMeteorologyStatus from 'utils/hooks/meteorology/useStatus';
import useQueueStatus from 'utils/hooks/flux/useQueueStatus';
import useListHomeWarnings from 'hooks/Home/useListHomeWarnings';
import FluxQueueStatus from './FluxQueueStatus';
import Status from './Status';
import StatusFlux from './StatusFlux';
import Warnings from './Warnings';

import './index.scss';

export default function HomeTabs() {
  const { status: meteorologyStatusData } = useMeteorologyStatus();
  const { status: fluxStatusData } = useFluxStatus();
  const { items: fluxQueueItems } = useQueueStatus();
  const { warnings, refetch, showNotification } = useListHomeWarnings();

  return (
    <Col md={6} className="home-tab-container">
      <Tab.Container defaultActiveKey="meteorology-status" id="home-tabs">
        <Row>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="meteorology-status">STATUS METEOROLOGIA</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="flux-status">STATUS FLUX</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="flux-queue">FILA FLUX</Nav.Link>
            </Nav.Item>
            <Nav.Item className="position-relative">
              <Nav.Link eventKey="warnings">AVISOS</Nav.Link>
              {showNotification && (
                <span
                  className="badge warnings-badge"
                >
                  !
                </span>
              )}
            </Nav.Item>
          </Nav>
        </Row>
        <Row>
          <Tab.Content>
            <Tab.Pane eventKey="meteorology-status">
              <Status data={meteorologyStatusData} />
            </Tab.Pane>
            <Tab.Pane eventKey="flux-status">
              <StatusFlux data={fluxStatusData} />
            </Tab.Pane>
            <Tab.Pane eventKey="flux-queue">
              <FluxQueueStatus data={fluxQueueItems} />
            </Tab.Pane>
            <Tab.Pane eventKey="warnings">
              <Warnings warnings={warnings} refetch={refetch} />
            </Tab.Pane>
          </Tab.Content>
        </Row>
      </Tab.Container>
    </Col>
  );
}
