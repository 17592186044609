import { useSorting } from 'components/SortingArrow';
import { Sorter } from 'components/SortingArrow/helpers';
import { SORT_ASC, SortDirection } from 'constants/sorting';
import { REPORT_SORTABLE_FIELDS, ReportSortableField, SORT_BY_PROCESS_ID } from 'services/api/mailer';

export type ReportSortingState = [
  ReportSortableField,
  SortDirection,
  { [field: string]: Sorter },
];

export default function useReportSorting(): ReportSortingState {
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<ReportSortableField | undefined>(REPORT_SORTABLE_FIELDS);

  return [
    sortBy || SORT_BY_PROCESS_ID,
    sortDirection || SORT_ASC,
    sorters,
  ];
}
