import React from 'react';
import './index.scss';

export default function TemplateInstructions() {
  return (
    <div className="template-instructions">
      <h3>Referência de comandos</h3>
      <h4>Objeto datetime_obj</h4>
      <div>
        Refere-se à data definida no campo &apos;Data Envio&apos; do Enviador de relatórios.
      </div>
      <h4>Formatos de Data</h4>
      <ul>
        <li>
          Anos
          <div>[YY] - Ano com dois dígitos</div>
          <div>[YYYY] - Ano completo</div>
        </li>
        <li>
          Meses
          <div>[M] - Mês em formato numérico</div>
          <div>[MM] - Mês em formato numérico com dois dígitos</div>
          <div>[MMM] - Mês abreviado com três letras</div>
          <div>[MMMM] - Mês sem abreviação</div>
        </li>
        <li>
          Semanas
          <div>[W] - Dia da semana abreviado com uma letra</div>
          <div>[WW] - Dia da semana abreviado com três letras</div>
          <div>[WWW] - Dia da semana sem abreviação</div>
        </li>
        <li>
          Dias
          <div>[D] - Dia sem prefixo: Ex.: 2</div>
          <div>[DD] - Dia com dois dígitos: Ex.: 02</div>
        </li>
        <li>
          Horas
          <div>[H] - Hora com um dígito</div>
          <div>[HH] - Hora com dois dígitos</div>
        </li>
        <li>
          Minutos
          <div>[M] - Minuto com um dígito</div>
          <div>[MM] - Minuto com dois dígitos</div>
        </li>
      </ul>
      <h4>Função imprime_data()</h4>
      <div>Função para formatar datas.</div>
      <div className="instruction-subtitle">Assinatura do método:</div>
      <div>
        <div className="d-flex flex-row">
          <div>
            <span className="instruction-color-1">imprime_data</span>
            (
          </div>
          <div>
            <span className="instruction-color-2">data</span>
            =datetime_obj||Texto,
            <br />
            <span className="instruction-color-2">formato</span>
            =Opcional||Texto,
            <br />
            <span className="instruction-color-2">delta</span>
            =Opcional Booleano: True||False,
            <br />
            <span className="instruction-color-2">months</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">days</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">seconds</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">microseconds</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">milliseconds</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">minutes</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">hours</span>
            =Opcional Inteiro: 0,
            <br />
            <span className="instruction-color-2">weeks</span>
            =Opcional Inteiro: 0
          </div>
        </div>
        )
      </div>
      <div className="instruction-subtitle">Uso 01:</div>
      <div>
        <i>datetime_obj = 2019-02-22 23:59 &lt;&lt;&lt; &apos;Data Envio&apos;</i>
        <br />
        <i>Formato = [DD] de [MMMM] | [YYYY]</i>
      </div>
      <div>
        <span className="text-muted">Texto que não será interpretado </span>
        &#123;&#123;
        <span className="instruction-color-1"> imprime_data</span>
        (
        <span className="instruction-color-2">datetime_obj</span>
        ,
        <span className="instruction-color-3"> &apos;[DD] de [MMMM] | [YYYY]&apos;</span>
        ) &#125;&#125;
      </div>
      <div className="instruction-subtitle">Resultado:</div>
      <div>
        <span className="text-muted">Texto que não será interpretado </span>
        <span className="instruction-color-3">22 de Fevereiro | 2019</span>
      </div>
      <div className="instruction-subtitle">Uso 02:</div>
      <div>
        <i>datetime_obj = 2019-02-22 23:59 &lt;&lt;&lt; &apos;Data Envio&apos;</i>
        <br />
        <i>Formato = [DD] de [MMMM] | [YYYY]</i>
        <br />
        <i>
          <span className="instruction-color-2">delta </span>
          = True - Indica que a data
          <span className="instruction-color-2"> datetime_obj </span>
          deve ser incrementada
        </i>
        <br />
        <i>
          <span className="instruction-color-2">days </span>
          = 2 - Adiciona 2 dias à data
          <span className="instruction-color-2"> datetime_obj </span>
        </i>
        <br />
        <i>
          <span className="instruction-color-2">months </span>
          = 27 - Adiciona 27 meses à data
          <span className="instruction-color-2"> datetime_obj </span>
        </i>
      </div>
      <div>
        <span className="text-muted">Texto que não será interpretado </span>
        &#123;&#123;
        <span className="instruction-color-1"> imprime_data</span>
        (
        <span className="instruction-color-2">datetime_obj</span>
        ,
        <span className="instruction-color-3"> &apos;[DD] de [MMMM] | [YYYY]&apos;</span>
        ,
        <span className="instruction-color-2"> delta</span>
        =True,
        <span className="instruction-color-2"> days</span>
        =2,
        <span className="instruction-color-2"> months</span>
        =27) &#125;&#125;
      </div>
      <div className="instruction-subtitle">Resultado:</div>
      <div>
        <span className="text-muted">Texto que não será interpretado </span>
        <span className="instruction-color-3">24 de Maio | 2021</span>
      </div>
      <h4>Função retorna_inicio_semana_operativa()</h4>
      <div>
        Retorna a data inicial da semana operativa informada.
        <br />
        Deve ser usada juntamente com a função imprime_data() para formatar corretamente a saída.
      </div>
      <div className="instruction-subtitle">Assinatura do método:</div>
      <div>
        <span className="instruction-color-1">retorna_inicio_semana_operativa</span>
        (
        <span className="instruction-color-2">semop</span>
        =Texto: AAAASS)
      </div>
      <div className="instruction-subtitle">Uso:</div>
      <div>
        <span className="text-muted">Texto que não será interpretado </span>
        &#123;&#123;
        <span className="instruction-color-1"> imprime_data</span>
        (
        <span className="instruction-color-2">retorna_inicio_semana_operativa(</span>
        <span className="instruction-color-4">201901</span>
        <span className="instruction-color-2">)</span>
        ,
        <span className="instruction-color-3"> &apos;[DD] de [MMMM] | [YYYY]&apos;</span>
        ) &#125;&#125;
      </div>
      <div className="instruction-subtitle">Resultado:</div>
      <div>
        <span className="text-muted">Texto que não será interpretado </span>
        <span className="instruction-color-3">29 de Dezembro | 2018</span>
      </div>
      <h4>Função referencia_inicio_semana_operativa()</h4>
      <div>
        Retorna a data inicial da semana operativa informada.
        <br />
        Deve ser usada juntamente com a função imprime_data() para formatar corretamente a saída.
      </div>
      <div className="instruction-subtitle">Assinatura do método:</div>
      <div>
        <span className="instruction-color-1">referencia_inicio_semana_operativa</span>
        (
        <span className="instruction-color-2">data</span>
        =datetime_obj||Texto)
      </div>
      <h4>Função referencia_fim_semana_operativa()</h4>
      <div>
        Retorna a data final da semana operativa informada.
        <br />
        Deve ser usada juntamente com a função imprime_data() para formatar corretamente a saída.
      </div>
      <div className="instruction-subtitle">Assinatura do método:</div>
      <div>
        <span className="instruction-color-1">referencia_fim_semana_operativa</span>
        (
        <span className="instruction-color-2">data</span>
        =datetime_obj||Texto)
      </div>
    </div>
  );
}
