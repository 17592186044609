import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ListedMassMail } from 'helper/mailer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GRAY2, GREEN } from 'constants/colors';

export default function MassEmailRow({ massEmail }: {
  massEmail: ListedMassMail,
}) {
  return (
    <Row className="mb-3">
      <Col md={4}>
        {massEmail.identifierCode}
      </Col>
      <Col md={4}>
        {massEmail.subject}
      </Col>
      <Col md={3}>
        {massEmail.sendDate}
      </Col>
      <Col md={1} className="d-flex justify-content-center">
        <FontAwesomeIcon
          icon={faSquareCheck as IconProp}
          color={massEmail.isFinished ? GREEN : GRAY2}
        />
      </Col>
    </Row>
  );
}
