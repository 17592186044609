import React from 'react';

import './index.scss';

export default function Indicator({ active, suffix }: { active: boolean, suffix?: string }) {
  if (active) {
    return (
      <div className="indicator indicator-active px-2">
        Ativ
        {suffix}
      </div>
    );
  }
  return (
    <div className="indicator indicator-inactive px-2">
      Inativ
      {suffix}
    </div>
  );
}

Indicator.defaultProps = { suffix: 'a' };
