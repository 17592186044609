import React from 'react';

export default
(
  <div>
    Você tem certeza que deseja
    <br />
    <strong>apagar </strong>
    este arquivo?
  </div>
);
