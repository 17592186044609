import React from 'react';
import AdminLayout from 'components/Layout/Admin';
import Users from 'views/Admin/Users';
import Companies from 'views/Admin/Companies';
import Financial from 'views/Admin/Financial';
import mailerRouter from './MailerRouter';

const adminRouter = {
  path: 'admin',
  element: <AdminLayout />,
  children: [
    { path: 'users', element: <Users /> },
    { path: 'companies', element: <Companies /> },
    { path: 'financial', element: <Financial /> },
    mailerRouter,
  ],
};

export default adminRouter;
