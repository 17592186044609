import React, { useState } from 'react';
import {
  Button,
  Col, Container, FormCheck, Row, Stack,
} from 'react-bootstrap';

import Upload from 'components/Admin/Financial/Upload';
import { Invoice } from 'helper/financial';
import InvoiceTable from 'components/Admin/Financial/Invoices/Table';
import useInvoiceSelection from 'utils/hooks/financial/useInvoiceSelection';
import useInvoiceSubmission from 'utils/hooks/financial/useInvoiceSubmission';
import InvoiceDatePicker from 'components/Admin/Financial/Invoices/DatePicker';
import SendInvoiceModal from 'components/Admin/Financial/Invoices/SendInvoiceModal';
import { ProcessingStatus } from 'redux/reducers/nfeProcessingReducer';

export default function Financial() {
  const initialInvoices: Invoice[] = [];
  const [invoices, setInvoices] = useState(initialInvoices);
  const {
    selectedInvoices,
    toggleInvoiceSelection,
    allSelected,
    noneSelected,
    selectAll,
    selectNone,
    startDate,
    endDate,
    onDateChange,
  } = useInvoiceSelection(invoices);
  const { processingState, addToQueue, openModal } = useInvoiceSubmission();
  const previousSubmission = processingState !== ProcessingStatus.IDLE;

  return (
    <div>
      <SendInvoiceModal />
      <Container>
        <Row>
          <h1>FINANCEIRO</h1>
        </Row>
        {previousSubmission && (
          <div className="d-flex mb-4">
            <Button onClick={openModal}>Ver último envio</Button>
          </div>
        )}
        <Row>
          <Col>
            <Upload setInvoices={setInvoices} />
          </Col>
          <Col className="col-5">
            <Stack direction="horizontal" gap={3} className="float-end me-4">
              {invoices.length > 0 && noneSelected && 'Nenhuma selecionada'}
              {invoices.length > 0 && !noneSelected && `${selectedInvoices.length} selecionada(s)`}
              <Button
                onClick={() => addToQueue(selectedInvoices)}
                disabled={noneSelected}
              >
                Enviar
              </Button>
            </Stack>
          </Col>
        </Row>
        <div className="d-flex flex-row flex-wrap mt-4 align-items-center">
          <FormCheck
            id="invoice-check-all"
            className="me-5"
            label={`Todos (${invoices.length})`}
            type="radio"
            checked={allSelected}
            onChange={selectAll}
          />
          <FormCheck
            id="invoice-check-none"
            className="me-5"
            label="Nenhum"
            type="radio"
            checked={noneSelected}
            onChange={selectNone}
          />
          <InvoiceDatePicker startDate={startDate} endDate={endDate} onDateChange={onDateChange} />
        </div>
        <Row>
          <InvoiceTable
            invoices={invoices}
            selectedInvoices={selectedInvoices}
            toggleInvoiceSelection={toggleInvoiceSelection}
          />
        </Row>
      </Container>
    </div>
  );
}
