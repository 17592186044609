import React from 'react';
import { PersonalizedScenario } from 'helper/personalized';
import { Col, Row } from 'react-bootstrap';
import Block from './Block';
import './index.scss';

export default function Scenario({ scenario }: { scenario: PersonalizedScenario }) {
  let colWidth: number;
  switch (scenario.blocos.length) {
    case 1:
      colWidth = 12;
      break;
    case 2:
      colWidth = 6;
      break;
    default:
      colWidth = 4;
  }

  return (
    <div>
      <div className="flux-personalized-scenario mb-3">{scenario.ds_nome}</div>
      <Row>
        {scenario.blocos.map((block, index) => (
          <Col md={colWidth} key={block.dt_inicio}>
            <Block block={block} index={index} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
