import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectFluxPersonalizedFormShowInstructions, selectFluxPersonalizedFormShowSuccessModal, setShowSuccessModal } from 'redux/reducers/fluxPersonalizedFormReducer';
import { setCurrentTab } from 'redux/reducers/fluxPersonalizedLayoutReducer';
import { openRight } from 'redux/reducers/sidebarsReducer';
import { store } from 'redux/store';
import Instructions from 'components/Flux/Personalized/New/Instructions';
import NewPersonalizedStudy from 'components/Flux/Personalized/New';
import { Modal } from 'react-bootstrap';

export default function New() {
  const showInstructions = useSelector(selectFluxPersonalizedFormShowInstructions);
  const showSuccessModal = useSelector(selectFluxPersonalizedFormShowSuccessModal);

  useEffect(() => {
    store.dispatch(setCurrentTab('new'));
    store.dispatch(openRight());
  });

  return (
    <>
      <Modal
        show={showSuccessModal}
        onHide={() => {
          store.dispatch(setShowSuccessModal(false));
        }}
      >
        <Modal.Header closeButton className="bg-success" />
        <Modal.Body>
          <p>Seu estudo foi enviado com sucesso!</p>
        </Modal.Body>
      </Modal>
      {showInstructions && <Instructions />}
      {!showInstructions && <NewPersonalizedStudy />}
    </>
  );
}
