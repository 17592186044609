import { useUploadStudiesMutation } from 'services/api/studies';
import { UploadStudiesParams } from 'helper/studies';
import useFluxFileUploadForm from './fileUploadForm';

export default function useFluxFileUpload() {
  const {
    name,
    file,
    filename,
    startDate,
    endDate,
    multiScenarios,
    previvazModel,
    outputOptions,
    groupNumber,
    flowPercentileValue,
  } = useFluxFileUploadForm();

  const [uploadStudies, {
    data, error, isSuccess, isError, isLoading, reset, status, isUninitialized,
  }] = useUploadStudiesMutation();

  const uploadStudiesParams: UploadStudiesParams = {
    ds_nome_estudo: name,
    flag_produzir_mapas: outputOptions.includes('Produzir mapas de preciptação'),
    flag_produzir_arquivo_pmed_xlsx: outputOptions.includes('Produzir arquivo excel PMED'),
    flag_produzir_arquivos_dessem: outputOptions.includes('Produzir arquivos DESSEM'),
    flag_produzir_ena_diaria: outputOptions.includes('Produzir arquivo.csv de ENA diária'),
    flag_tabelar_prevs_produzidos: outputOptions.includes('Produzir memória de cálculo ENA semanal'),
    flag_produzir_prevs_mensais: outputOptions.includes('Produzir arquivos PREVS.RVX mensais'),
    flag_produzir_vazpast: outputOptions.includes('Produzir arquivos VAZPAST.DAT'),
    flag_produzir_todas_revisoes_intermediarias: previvazModel === 'Produzir todas as revisões',
    flag_produzir_somente_rv0_intermediarias: previvazModel === 'Produzir somente RV0/RVF/RVXmax',
    flag_produzir_somente_revisoes_rvf: previvazModel === 'Produzir RVF/RVXmax',
    flag_produzir_vmed: multiScenarios.includes('Produzir vazão média'),
    flag_produzir_vpercentil: multiScenarios.includes('Produzir percentis de vazão'),
    cenarios_vpercentil: multiScenarios.includes('Produzir percentis de vazão') ? flowPercentileValue : '',
    ck_numero_agrupamentos: multiScenarios.includes('Predefinir número de agrupamentos'),
    flag_produzir_cenarios_por_agrupamento: multiScenarios.includes('Produzir vazão por agrupamento:'),
    numero_agrupamentos: groupNumber ? groupNumber.toString() : '',
    ck_dimensoes_para_agrupamento: multiScenarios.includes('Definir dimensões para agrup.'),
    se_ne_v_s_n: multiScenarios.includes('SE+NE versus S+N'),
    se_s_v_s_ne_n: multiScenarios.includes('SE+S versus NE+N'),
    se_n_v_s_ne: multiScenarios.includes('SE+N versus S+NE'),
    se_v_s_ne_n: multiScenarios.includes('SE versus S+NE+N'),
    se_v_s_ne_s_n: multiScenarios.includes('SE vs S vs NE vs N'),
    dt_inicio: startDate,
    dt_fim: endDate,
    casos_ampere: false,
    file_name: filename,
    file_base64: file,
  };

  return {
    uploadStudies,
    uploadStudiesParams,
    data,
    error,
    isSuccess,
    isError,
    isLoading,
    status,
    isUninitialized,
    reset,
  };
}
