import React, { Children, PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import './index.scss';

interface FormModalProps {
  show: boolean
  title: string,
  lastAsFooter?: boolean,
  onHide: () => void
}

export default function FormModal({
  show,
  title,
  lastAsFooter,
  onHide,
  children,
}: PropsWithChildren<FormModalProps>) {
  const childrenArr = Children.toArray(children);
  const bodyComponents = lastAsFooter ? childrenArr.slice(0, -1) : childrenArr;
  const footerComponent = lastAsFooter ? childrenArr.slice(-1) : null;

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="default-modal modal-dialog-scrollable"
    >
      <Modal.Header closeButton>
        <Modal.Title className="default-modal-title">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {bodyComponents}
      </Modal.Body>
      {lastAsFooter && (
        <Modal.Footer>{footerComponent}</Modal.Footer>
      )}
    </Modal>
  );
}

FormModal.defaultProps = { lastAsFooter: false };
