import React from 'react';

import Overview from 'views/Meteorology/Weather/Overview';
import Area from 'views/Meteorology/Weather/Area';
import WeatherLayout from 'components/Layout/Meteorology/Weather';
import mapAnnotations from 'helper/Meteorology/Weather/Overview/AnomalyPanel/mapAnnotations';

const weatherRouter = {
  path: 'weather',
  element: <WeatherLayout />,
  children: [
    { path: 'overview', element: <Overview /> },
    ...mapAnnotations.map((entry) => ({
      path: entry.url, element: <Area areaAcronym={entry.url} areaLabel={entry.label} />,
    })),
  ],
};

export default weatherRouter;
