import React from 'react';
import '../CountersRow.scss';
import { Stack } from 'react-bootstrap';
import { CompaniesCount } from '../../../../../services/api/companies';
import { CompanyModalFilters } from '../../../../../helper/companyFilters';
import LoadingSpinner from '../../../../LoadingSpinner';

interface CounterBoxProps {
  isLoading: boolean,
  type: keyof CompaniesCount,
  filters: CompanyModalFilters,
  data: CompaniesCount | null | undefined,
  setFilters: React.Dispatch<React.SetStateAction<CompanyModalFilters>>,
}

const defaultObject = {
  active: 0,
  test: 0,
  consulting: 0,
  meteorologia: 0,
  '30_days_to_due_date': { consulting: 0, meteorologia: 0 },
  '60_days_to_due_date': { consulting: 0, meteorologia: 0 },
};

export function filtersSetter(
  setFilters: React.Dispatch<React.SetStateAction<CompanyModalFilters>>,
  filters: CompanyModalFilters,
) {
  return () => {
    setFilters(filters);
  };
}

export default function CounterBox({
  type, data, setFilters, filters, isLoading,
}: CounterBoxProps) {
  let value: number;
  let subValues: string;
  const boxTitles = {
    active: {
      titleLine1: 'Empresas',
      titleLine2: 'Ativas',
      className: 'active-company',
      setterArguments: { ...filters, isActive: !filters.isActive },
    },
    test: {
      titleLine1: 'Empresas',
      titleLine2: 'Teste',
      className: 'test-company',
      setterArguments: { ...filters, isTest: !filters.isTest },
    },
    '30_days_to_due_date': {
      titleLine1: 'Contrato vence',
      titleLine2: 'em até 30 dias',
      className: 'contract-expires-in-30-days',
      setterArguments: { ...filters, isExpiringIn30: !filters.isExpiringIn30 },
    },
    '60_days_to_due_date': {
      titleLine1: 'Contrato vence',
      titleLine2: 'entre 30 e 60 dias',
      className: 'contract-expires-in-60-days',
      setterArguments: { ...filters, isExpiringIn60: !filters.isExpiringIn60 },
    },
    consulting: {
      titleLine1: 'Empresas',
      titleLine2: 'Ampere Consultoria',
      className: 'consulting-company',
      setterArguments: { ...filters, isConsultancy: !filters.isConsultancy },
    },
    meteorologia: {
      titleLine1: 'Empresas',
      titleLine2: 'Ampere Metereologia',
      className: 'meteorology-company',
      setterArguments: { ...filters, isMetereology: !filters.isMetereology },
    },
  };
  const counterData = !data ? defaultObject : data;
  const setterFunction = filtersSetter(setFilters, boxTitles[type].setterArguments);

  if (type === '30_days_to_due_date' || type === '60_days_to_due_date') {
    value = counterData[type].meteorologia + counterData[type].consulting;
    subValues = `${counterData[type].meteorologia} ME | ${counterData[type].consulting} CO`;
  } else {
    value = counterData[type];
    subValues = '';
  }

  return (
    <div className={`counter-box ${boxTitles[type].className}`}>
      <Stack onClick={setterFunction} direction="vertical">
        <LoadingSpinner isLoading={isLoading}>
          <Stack direction="horizontal">
            <div className="ms-auto main-value">{value}</div>
          </Stack>
          <Stack direction="horizontal">
            <div className="ms-auto sub-values">{subValues}</div>
          </Stack>
          <div className="box-title">
            {boxTitles[type].titleLine1}
            <br />
            {boxTitles[type].titleLine2}
          </div>
        </LoadingSpinner>
      </Stack>
    </div>
  );
}
