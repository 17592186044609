import { ResponseError } from 'services/api/base';
import { useGetStatusQuery } from 'services/api/Meteorology';
import useHandleRequestErrors from '../useHandleRequestErrors';

interface Status {
  id: number,
  modelName: string,
  forecastDate: Date,
  lastUpdate: Date,
  member: string,
  runtime: string,
  variable: string,
  status: string,
  statusColor: string,
}

export default function useStatus(): ({ status: Status[] }) {
  const { data, error } = useGetStatusQuery();

  useHandleRequestErrors(error as ResponseError);

  const apiStatus = data?.data ?? [];

  const status = apiStatus.map((item) => ({
    id: item.id,
    modelId: item.id_modelo,
    modelName: item.label,
    forecastDate: new Date(item.data_previsao * 1000),
    lastUpdate: new Date(item.timestamp * 1000),
    member: item.membro,
    runtime: item.runtime,
    variable: item.variavel,
    status: item.status,
    statusColor: item.color,
  }));

  return {
    status,
  };
}
