import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PeriodOptions = 'd' | 's' | 'm' | 'p' | 't' | undefined;

interface MeteorologyFieldsComparatorControlPanelState {
  baseModel: string | undefined
  baseForecastDateDiff: string | undefined
  baseRuntime: number | undefined
  baseMember: string | undefined
  baseRMV: boolean
  confrontationalModel: string | undefined
  confrontationalForecastDateDiff: string | undefined,
  confrontationalRuntime: number | undefined
  confrontationalMember: string | undefined
  confrontationalRMV: boolean,
  period: PeriodOptions,
  title: string,
  subtitle: string,
  triggerActive: boolean,
}

const initialState: MeteorologyFieldsComparatorControlPanelState = {
  baseModel: undefined,
  baseForecastDateDiff: undefined,
  baseRuntime: undefined,
  baseMember: undefined,
  baseRMV: false,
  confrontationalModel: undefined,
  confrontationalForecastDateDiff: undefined,
  confrontationalRuntime: undefined,
  confrontationalMember: undefined,
  confrontationalRMV: false,
  period: 'd',
  title: '',
  subtitle: '',
  triggerActive: false,
};

export const meteorologyFieldsComparatorControlPanelSlice = createSlice({
  name: 'meteorologyFieldsComparatorControlPanel',
  initialState,
  reducers: {
    setBaseForecastDateDiff: (state, action: PayloadAction<string | undefined>) => {
      state.baseForecastDateDiff = action.payload;
    },
    setBaseModel: (state, action: PayloadAction<string>) => {
      state.baseModel = action.payload;
    },
    setBaseRuntime: (state, action: PayloadAction<number | undefined>) => {
      state.baseRuntime = action.payload;
    },
    setBaseMember: (state, action: PayloadAction<string | undefined>) => {
      state.baseMember = action.payload;
    },
    setBaseRMV: (state, action: PayloadAction<boolean>) => {
      state.baseRMV = action.payload;
    },
    setConfrontationalForecastDateDiff: (state, action: PayloadAction<string | undefined>) => {
      state.confrontationalForecastDateDiff = action.payload;
    },
    setConfrontationalModel: (state, action: PayloadAction<string>) => {
      state.confrontationalModel = action.payload;
    },
    setConfrontationalRuntime: (state, action: PayloadAction<number | undefined>) => {
      state.confrontationalRuntime = action.payload;
    },
    setConfrontationalMember: (state, action: PayloadAction<string | undefined>) => {
      state.confrontationalMember = action.payload;
    },
    setConfrontationalRMV: (state, action: PayloadAction<boolean>) => {
      state.confrontationalRMV = action.payload;
    },
    setPeriod: (state, action: PayloadAction<PeriodOptions>) => {
      state.period = action.payload;
    },
    setTitle: (state, action: PayloadAction<[title: string, subtitle: string]>) => {
      const [title, subtitle] = action.payload;
      state.title = title;
      state.subtitle = subtitle;
    },
    setTriggerActivity: (state, action: PayloadAction<boolean>) => {
      state.triggerActive = action.payload;
    },
    reset: (state) => {
      state.baseForecastDateDiff = undefined;
      state.baseModel = undefined;
      state.baseRuntime = undefined;
      state.baseMember = undefined;
      state.baseRMV = false;
      state.confrontationalForecastDateDiff = undefined;
      state.confrontationalModel = undefined;
      state.confrontationalRuntime = undefined;
      state.confrontationalMember = undefined;
      state.confrontationalRMV = false;
      state.period = 'd';
      state.title = '';
      state.subtitle = '';
      state.triggerActive = false;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectMFComparatorControlPanelBaseForecastDateDiff = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.baseForecastDateDiff
);
export const selectMFComparatorControlPanelBaseModel = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.baseModel
);
export const selectMFComparatorControlPanelBaseRuntime = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.baseRuntime
);
export const selectMFComparatorControlPanelBaseMember = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.baseMember
);
export const selectMFComparatorControlPanelBaseRMV = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.baseRMV
);
export const selectMFComparatorControlPanelConfrontationalForecastDateDiff = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.confrontationalForecastDateDiff
);
export const selectMFComparatorControlPanelConfrontationalModel = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.confrontationalModel
);
export const selectMFComparatorControlPanelConfrontationalRuntime = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.confrontationalRuntime
);
export const selectMFComparatorControlPanelConfrontationalMember = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.confrontationalMember
);
export const selectMFComparatorControlPanelConfrontationalRMV = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.confrontationalRMV
);
export const selectMFComparatorControlPanelPeriod = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.period
);
export const selectMFComparatorControlPanelTitle = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.title
);
export const selectMFComparatorControlPanelSubtitle = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.subtitle
);
export const selectMFComparatorControlPanelTriggerIsActive = (state: any) => (
  state.meteorologyFieldsComparatorControlPanel.triggerActive
);
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setBaseModel,
  setBaseForecastDateDiff,
  setBaseRuntime,
  setBaseMember,
  setBaseRMV,
  setConfrontationalModel,
  setConfrontationalForecastDateDiff,
  setConfrontationalRuntime,
  setConfrontationalMember,
  setConfrontationalRMV,
  setPeriod,
  setTitle,
  setTriggerActivity,
  reset,
} = meteorologyFieldsComparatorControlPanelSlice.actions;

export default meteorologyFieldsComparatorControlPanelSlice.reducer;
