import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { addHours } from 'date-fns';
import { Product, UNKNOWN_DATE } from 'helper/products';
import ProductTag from 'components/Admin/Products/Tag';

const formatDate = (dateString: string): string => {
  if (dateString === UNKNOWN_DATE) {
    return dateString;
  }
  // NOTE: Stay away from midnight to prevent locale inconsistencies
  const date = addHours(new Date(dateString), 12);
  return date.toLocaleDateString('pt-BR');
};

export default function ProductLine({ product }: { product: Product }) {
  const startDateFormatted = formatDate(product.startDateString);
  const endDateFormatted = formatDate(product.endDateString);
  const period = `${startDateFormatted} - ${endDateFormatted}`;
  const additionalClassName = product.available ? '' : ' unavailable-product';
  const nameClass = `product-description product-name ${additionalClassName}`;
  const periodClass = `product-description ${additionalClassName}`;

  return (
    <Row>
      <Col md="2">
        <ProductTag product={product} />
      </Col>
      <Col md="5">
        <p className={nameClass}>
          { product.name }
        </p>
      </Col>
      <Col md="5">
        <p className={periodClass}>
          { period }
        </p>
      </Col>
    </Row>
  );
}
