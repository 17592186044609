import React, { PropsWithChildren } from 'react';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEEP_BLUE, GRAY3 } from 'constants/colors';
import './index.scss';

interface Props {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean,
}

export default function BlockDatePickerWrapper({
  children,
  disabled = false,
}: PropsWithChildren<Props>) {
  const iconColor = disabled ? GRAY3 : DEEP_BLUE;

  return (
    <div className="block-datepicker">
      <span className="icon">
        <FontAwesomeIcon icon={faCalendar} color={iconColor} />
      </span>
      {children}
    </div>
  );
}
