import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';
import './index.scss';

export default function Instructions() {
  return (
    <Row className="p-5">
      <Col md={5}>
        <Card className="shadow-sm forecast-instruction-card mb-4">
          <Card.Header>
            NOVA PREVISÃO
          </Card.Header>
          <Card.Body>
            <p>
              Selecione as variáveis no painel de controle ao lado e sua previsão aparecerá na tela.
            </p>
            <p>
              Lembre que você sempre tem a opção de salvar a previsão para rápido acesso.
            </p>
          </Card.Body>
        </Card>
        <Card className="shadow-sm forecast-instruction-card">
          <Card.Header>
            PREFERÊNCIAS SALVAS
          </Card.Header>
          <Card.Body>
            <p>
              Se você costuma buscar sempre as mesmas previsões, essa funcionalidade é para você!
              Criamos uma área para que possa salvar buscas frequentes, assim você pode
              visualizá-las quando abrir o comparar e rapidamente selecionar a que deseja ver em
              detalhe.
            </p>
            <p>
              Para configurar preferências você seleciona no painel de controle ao lado a previsão
              que deseja ver e clica em salvar nova preferência. Ao salvar, você vai poder indicar
              um nome personalizado. O número de preferências salvas é ilimitado.
            </p>
          </Card.Body>
        </Card>
      </Col>
      <Col md={7}>
        <Card className="shadow-sm forecast-instruction-card h-100">
          <Card.Header>
            NAVEGAÇÃO
          </Card.Header>
          <Card.Body className="navigation-instruction">
            <div className="d-flex flex-row">
              <div className="instruction-number">1</div>
              <div>
                No canto superior esquerdo da página, você vai encontrar o dia para o qual a
                previsão está sendo feita seguido da
                <b> data de previsão </b>
                inicial selecionada.
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="instruction-number instruction-marker">2</div>
              <div>
                A
                <b> barra de rolagem </b>
                ajuda você a navegar entre as previsões rapidamente. Nas extremidades estão as
                <b> datas de início e fim do horizonte</b>
                . As setas podem também ser usadas para navegar entre os dias com mais precisão.
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="instruction-number instruction-marker">3</div>
              <div>
                Navegue entre os diferentes
                <b> modos de visualização </b>
                de sua previsão através destes botões no canto superior esquerdo da tela. As opções
                são:
                <b> single model e multi model</b>
                . Aquele que aparecer na cor azul escuro, será o modo selecionado.
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="instruction-number instruction-marker">4</div>
              <div>
                Faça
                <b> download </b>
                dos mapas separadamente de uma única vez, dos GIFs (quando aplicável) e da tela com
                todos os mapas de sua pesquisa como uma imagem única.
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
