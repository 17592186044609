import React from 'react';
import { ListedReportMail } from 'helper/mailer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileWord, faFilePdf, faEnvelopeCircleCheck, faSquareCheck, IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';

const iconNameToIcon: { [key: string]: IconDefinition } = {
  word: faFileWord,
  pdf: faFilePdf,
  email: faEnvelopeCircleCheck,
  finished: faSquareCheck,
};

const typeToAttribute: { [key: string]: string } = {
  word: 'ckWordGenerated',
  pdf: 'ckPdfGenerated',
  email: 'ckMailGenerated',
  finished: 'ckFinalized',
};

const activatedColor = '#a7cd78';
const deactivatedColor = '#c1c1c1';
const colorMap = new Map<boolean | null, string>();
colorMap.set(true, activatedColor);
colorMap.set(false, deactivatedColor);
colorMap.set(null, deactivatedColor);

export default function LabelIcon({ report, type }: {
  report: ListedReportMail,
  type: string,
}) {
  const icon: IconDefinition = iconNameToIcon[type];
  const activated = report.status[0][typeToAttribute[type]];
  const color = colorMap.get(activated);

  return (
    <Col md="2">
      <FontAwesomeIcon
        id={`${type}-generated-${report.processId}`}
        icon={icon}
        style={{ color }}
        size="lg"
      />
    </Col>
  );
}
