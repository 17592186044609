import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomAlert } from 'components/AlertsCollection';
import {
  selectChart,
  selectDate,
  selectRuntime,
  setImageIndex,
} from 'redux/reducers/synopticChartsReducer';
import { useLazyGetImagesQuery } from 'services/api/Meteorology/synopticCharts';

const DEFAULT_ALERT: CustomAlert = {
  title: 'Indisponível',
  message: 'Não há nenhuma imagem disponível para esta data.',
  variant: 'warning',
};

export default function useGetImages() {
  const dispatch = useDispatch();
  const [trigger, { data: baseData, error, isFetching }] = useLazyGetImagesQuery();
  const chart = useSelector(selectChart);
  const dateString = useSelector(selectDate);
  const runtime = useSelector(selectRuntime);

  const data = error ? undefined : baseData;
  const alert = error ? DEFAULT_ALERT : undefined;

  useEffect(() => {
    if (chart && dateString && runtime) {
      trigger({ chartId: chart.id, dateString, runtimeId: runtime.id });
      dispatch(setImageIndex(0));
    }
  }, [chart, dateString, runtime, trigger, dispatch]);

  return { data, isFetching, alert };
}
