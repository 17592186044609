import { HomeTutorialLink } from '../../helper/products/home';
import { useGetTutorialLinksQuery } from '../../services/api/products/home';
import useHandleRequestErrors from './useHandleRequestErrors';
import { ResponseError } from '../../services/api/base';

export default function useTutorialData(selectedIds: number[]): HomeTutorialLink[] {
  const { data, error } = useGetTutorialLinksQuery();
  useHandleRequestErrors(error as ResponseError | undefined);

  const fullList = data?.data ?? [];
  return selectedIds
    .map((id) => fullList.find((tutorial) => tutorial.id === id))
    .filter((element) => element !== undefined) as HomeTutorialLink[];
}
