import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faEye, faFile } from '@fortawesome/free-regular-svg-icons';
import { faNetworkWired, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { MEDIUM_BLUE } from 'constants/colors';
import { Read, readIconColor } from 'helper/mailer';
import './index.scss';

export default function EmailRead({ readInfo }: {
  readInfo: Read,
}) {
  const requestData: object | undefined = readInfo.requestData ? JSON.parse(readInfo.requestData.replace(/"/g, '\\"').replace(/'/g, '"')) : undefined;
  const date = new Date(readInfo.requestDate * 1000);

  return (
    <div className="email-read-text">
      <div>
        <FontAwesomeIcon
          className="email-read-icon"
          icon={faNetworkWired}
          size="sm"
          color={MEDIUM_BLUE}
        />
        <span className="me-1">{readInfo.ipAddress}</span>
        <FontAwesomeIcon
          className="ms-1"
          icon={faBullseye}
          size="sm"
          color={readIconColor(requestData, 'click')}
        />
        <FontAwesomeIcon
          className="ms-1"
          icon={faEye as IconProp}
          size="sm"
          color={readIconColor(requestData)}
        />
        <FontAwesomeIcon
          className="ms-1"
          icon={faFile as IconProp}
          size="sm"
          color={readIconColor(requestData, 'download-file')}
        />
      </div>
      <div>
        <FontAwesomeIcon
          className="email-read-icon"
          icon={faCalendar as IconProp}
          size="sm"
          color={MEDIUM_BLUE}
        />
        <span>{date.toLocaleDateString('pt-BR')}</span>
        <span className="ms-2">{date.toLocaleTimeString('pt-BR')}</span>
      </div>
    </div>
  );
}
