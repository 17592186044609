import React from 'react';
import useSavePreferencesModal from 'utils/hooks/meteorology/useSavePreferencesModal';
import { Modal } from 'react-bootstrap';
import SavePreferencesForm from './savePreferencesForm';

export default function SavePreferencesModal() {
  const { setShowFormModal, isShowingFormModal } = useSavePreferencesModal();

  const headerStyle = {
    backgroundColor: '#D9D9D9',
  };

  return (
    <Modal
      show={isShowingFormModal}
      onHide={() => { setShowFormModal(false); }}
      dialogClassName="default-modal modal-dialog-scrollable"
    >
      <Modal.Header closeButton className="confirmation-dialog-modal-header" style={headerStyle} />
      <Modal.Body className="p-4">
        <SavePreferencesForm />
      </Modal.Body>
    </Modal>
  );
}
