import React, { PropsWithChildren, MouseEvent } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './index.scss';

interface ConfirmationDialogBoxProps {
  open: boolean,
  dismiss: () => void,
  type: string,
}

export default function ConfirmationDialogBox(
  {
    children, open, dismiss, type,
  }: PropsWithChildren<ConfirmationDialogBoxProps>,
) {
  const close = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dismiss();
  };

  return (
    <Modal show={open} onHide={dismiss} className={`dialog-modal dialog-${type}`}>
      <Modal.Header closeButton className="dialog-modal-header" />
      <Modal.Body className="my-3 mx-auto dialog-modal-body">
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="dialog-modal-btn"
          onClick={close}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
