import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './index.scss';
import { Col, Row } from 'react-bootstrap';
import RightSidebar from 'components/Layout/RightSidebar';
import ControlPanel from 'components/Meteorology/Fields/ControlPanel';
import TabbedPage from 'components/TabbedPage';
import { openRight } from 'redux/reducers/sidebarsReducer';
import {
  setErrorAlert,
  setObsImageString,
  setClimatologyImageString,
  setAnomalyImageString,
} from 'redux/reducers/meteorologyFieldsObservedDataControlPanelReducer';

export default function MeteorologyFieldsLayout() {
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    dispatch(openRight());
    return () => {
      dispatch(setObsImageString(''));
      dispatch(setClimatologyImageString(''));
      dispatch(setAnomalyImageString(''));
      dispatch(setErrorAlert(undefined));
    };
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col className="md-6">
          <h1 className=" mb-3">CAMPOS METEOROLÓGICOS</h1>
        </Col>
      </Row>
      <Row style={{ flexWrap: 'nowrap', paddingBottom: '3.3125rem' }}>
        <Col md="auto" className="flex-fill">
          <TabbedPage setControlPanelVisibility={setShowSidebar}>
            <TabbedPage.Tab title="DADOS OBSERVADOS" path="/meteorology/fields/observeddata" showControlPanel />
            <TabbedPage.Tab title="PREVISÃO NUMÉRICA DO TEMPO" path="/meteorology/fields/numericforecast" showControlPanel />
            <TabbedPage.Tab title="COMPARADOR" path="/meteorology/fields/comparator" showControlPanel />
            <TabbedPage.Tab title="CAMPOS SINÓTICOS" path="/meteorology/fields/synopticcharts" />
          </TabbedPage>
        </Col>
        {showSidebar && (
          <Col md="auto">
            <RightSidebar>
              <ControlPanel />
            </RightSidebar>
          </Col>
        )}
      </Row>
      <div className="bottom-bar" />
    </>
  );
}
