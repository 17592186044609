import React from 'react';
import AppliedFilter from 'components/Filters/AppliedFilter';
import { ListedCompany } from 'helper/companies';
import { ListedProduct } from 'helper/products';
import { UserModalFilters } from 'helper/userFilters';
import { CompanyModalFilters } from 'helper/companyFilters';

export const onProductCheckChange = (
  id: number,
  selectedProducts: number[],
  setSelectedProducts: React.Dispatch<React.SetStateAction<number[]>>,
) => (() => {
  if (selectedProducts.includes(id)) {
    setSelectedProducts(selectedProducts.filter((productId) => (productId !== id)));
  } else {
    setSelectedProducts([...selectedProducts, id]);
  }
});

export const onUserFiltersClear = (
  setSelectedProducts: React.Dispatch<React.SetStateAction<number[]>>,
  setSelectedCompany: React.Dispatch<React.SetStateAction<string>>,
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  setisExpiringIn30: React.Dispatch<React.SetStateAction<boolean>>,
  setisExpiringIn60: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  setSelectedProducts([]);
  setSelectedCompany('');
  setIsEnabled(false);
  setIsDisabled(false);
  setisExpiringIn30(false);
  setisExpiringIn60(false);
};

export const onUserFiltersSave = (
  modalFilters: UserModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<UserModalFilters>>,
  closeModal: () => void,
) => () => {
  setFilters(modalFilters);
  closeModal();
};

export const loadUserFilters = (
  filters: UserModalFilters,
  setSelectedProducts: React.Dispatch<React.SetStateAction<number[]>>,
  setSelectedCompany: React.Dispatch<React.SetStateAction<string>>,
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  setisExpiringIn30: React.Dispatch<React.SetStateAction<boolean>>,
  setisExpiringIn60: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setSelectedProducts(filters.selectedProducts);
  setSelectedCompany(filters.selectedCompany);
  setIsEnabled(filters.isEnabled);
  setIsDisabled(filters.isDisabled);
  setisExpiringIn30(filters.isExpiringIn30);
  setisExpiringIn60(filters.isExpiringIn60);
};

export const loadCompanyFilters = (
  filters: CompanyModalFilters,
  setSelectedProducts: React.Dispatch<React.SetStateAction<number[]>>,
  setContractEndsBefore: React.Dispatch<React.SetStateAction<Date | null>>,
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>,
  setIsInactive: React.Dispatch<React.SetStateAction<boolean>>,
  setIsTest: React.Dispatch<React.SetStateAction<boolean>>,
  setIsExpiringIn30: React.Dispatch<React.SetStateAction<boolean>>,
  setIsExpiringIn60: React.Dispatch<React.SetStateAction<boolean>>,
  setIsMetereology: React.Dispatch<React.SetStateAction<boolean>>,
  setIsConsultancy: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setSelectedProducts(filters.selectedProducts);
  setContractEndsBefore(filters.contractEndsBefore);
  setIsActive(filters.isActive);
  setIsInactive(filters.isInactive);
  setIsTest(filters.isTest);
  setIsExpiringIn30(filters.isExpiringIn30);
  setIsExpiringIn60(filters.isExpiringIn60);
  setIsMetereology(filters.isMetereology);
  setIsConsultancy(filters.isConsultancy);
};

export const resetFilter = (
  key: string,
  resetValue: number[] | string | boolean | null,
  filters: UserModalFilters | CompanyModalFilters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilters: React.Dispatch<React.SetStateAction<any>>,
) => () => {
  setFilters({
    ...filters,
    [key]: resetValue,
  });
};

export const resetSearch = (
  setSearchBarText: React.Dispatch<React.SetStateAction<string>>,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
) => () => {
  setSearchQuery('');
  setSearchBarText('');
};

const productAppliedFilters = (
  products: ListedProduct[] | undefined,
  filters: UserModalFilters | CompanyModalFilters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilters: React.Dispatch<React.SetStateAction<any>>,
) => (
  !products ? [] : filters.selectedProducts.map((id) => (
    <AppliedFilter
      key={`product-${id}`}
      name={(products.find((product) => product.id === id) as ListedProduct).nome}
      onClick={resetFilter(
        'selectedProducts',
        filters.selectedProducts.filter((productId) => (productId !== id)),
        filters,
        setFilters,
      )}
    />
  ))
);

const selectedCompanyAppliedFilter = (
  filters: UserModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<UserModalFilters>>,
) => (
  filters.selectedCompany === '' ? [] : [
    <AppliedFilter
      key="company"
      name={filters.selectedCompany}
      onClick={resetFilter(
        'selectedCompany',
        '',
        filters,
        setFilters,
      )}
    />,
  ]
);

const checkboxAppliedFilter = (
  key: string,
  name: string,
  filters: UserModalFilters | CompanyModalFilters,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilters: React.Dispatch<React.SetStateAction<any>>,
) => (
  filters[key] ? [
    <AppliedFilter
      key={key}
      name={name}
      onClick={resetFilter(
        key,
        false,
        filters,
        setFilters,
      )}
    />,
  ] : []
);

const contractDateAppliedFilter = (
  filters: CompanyModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<CompanyModalFilters>>,
) => (
  filters.contractEndsBefore === null ? [] : [
    <AppliedFilter
      key="contractEndsBefore"
      name={`Contrato vigente até: ${filters.contractEndsBefore.toLocaleDateString('pt-BR')}`}
      onClick={resetFilter(
        'contractEndsBefore',
        null,
        filters,
        setFilters,
      )}
    />,
  ]
);

const searchQueryAppliedFilter = (
  searchQuery: string,
  setSearchBarText: React.Dispatch<React.SetStateAction<string>>,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
) => (
  searchQuery === '' ? [] : [
    <AppliedFilter
      key="searchQuery"
      name={searchQuery}
      onClick={resetSearch(setSearchBarText, setSearchQuery)}
    />,
  ]
);

export const userAppliedFilters = (
  products: ListedProduct[] | undefined,
  companies: ListedCompany[] | undefined,
  filters: UserModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<UserModalFilters>>,
  searchQuery: string,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
  setSearchBarText: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (!products || !companies) {
    return [...searchQueryAppliedFilter(searchQuery, setSearchBarText, setSearchQuery)];
  }

  return [
    ...searchQueryAppliedFilter(searchQuery, setSearchBarText, setSearchQuery),
    ...productAppliedFilters(products, filters, setFilters),
    ...selectedCompanyAppliedFilter(filters, setFilters),
    ...checkboxAppliedFilter('isEnabled', 'Habilitado', filters, setFilters),
    ...checkboxAppliedFilter('isDisabled', 'Desabilitado', filters, setFilters),
    ...checkboxAppliedFilter('isExpiringIn30', 'Vence em até 30 dias', filters, setFilters),
    ...checkboxAppliedFilter('isExpiringIn60', 'Vence entre 30 e 60 dias', filters, setFilters),
  ];
};

export const companyAppliedFilters = (
  products: ListedProduct[] | undefined,
  filters: CompanyModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<CompanyModalFilters>>,
  searchQuery: string,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
  setSearchBarText: React.Dispatch<React.SetStateAction<string>>,
) => ([
  ...searchQueryAppliedFilter(searchQuery, setSearchBarText, setSearchQuery),
  ...productAppliedFilters(products, filters, setFilters),
  ...contractDateAppliedFilter(filters, setFilters),
  ...checkboxAppliedFilter('isActive', 'Ativo', filters, setFilters),
  ...checkboxAppliedFilter('isInactive', 'Inativo', filters, setFilters),
  ...checkboxAppliedFilter('isTest', 'Teste', filters, setFilters),
  ...checkboxAppliedFilter('isExpiringIn30', '30 dias', filters, setFilters),
  ...checkboxAppliedFilter('isExpiringIn60', '30 - 60 dias', filters, setFilters),
  ...checkboxAppliedFilter('isMetereology', 'Ampere Metereologia', filters, setFilters),
  ...checkboxAppliedFilter('isConsultancy', 'Ampere Consultoria', filters, setFilters),
]);
