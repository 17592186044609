import {
  fromUnixTime,
  isBefore,
  startOfDay,
  subDays,
} from 'date-fns';

import { ResponseError } from 'services/api/base';
import { useGetAvailableDatesQuery } from 'services/api/Meteorology/synopticCharts';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function useGetAvailableDates() {
  const today = startOfDay(new Date());
  const minDate = subDays(today, 15);

  const { data: baseDates, error, isFetching } = useGetAvailableDatesQuery();
  const dates = baseDates
    ?.data
    ?.map(fromUnixTime)
    ?.filter((d) => !isBefore(d, minDate));

  useHandleRequestErrors(error as ResponseError);

  return { dates, isFetching };
}
