import React from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import HomeBox from './Box';

export default function HomeBoxes() {
  return (
    <Col md={6}>
      <h3 className="mb-4">PREFERÊNCIAS SALVAS</h3>
      <Row>
        <Col>
          <h4 className="text-primary"> Meteorologia </h4>
          <Stack gap={3}>
            <HomeBox
              title="Vídeos"
              link="/meteorology/videos/categories"
              selectedTutorialIds={[8, 9, 10, 11, 12, 13]}
            />
            <HomeBox
              title="Campos Meteorológicos"
              link="/meteorology/fields/observeddata"
              selectedTutorialIds={[14, 15, 16, 17]}
            />
          </Stack>
        </Col>
        <Col>
          <h4 className="text-primary mb-2"> Flux </h4>
          <Stack gap={3}>
            <HomeBox title="Upload" link="/flux/upload" selectedTutorialIds={[1, 2]} />
            <HomeBox title="Personalizado" link="/flux/personalized" selectedTutorialIds={[3, 4]} />
            <HomeBox title="Automático" link="/flux/automatic" selectedTutorialIds={[5, 6, 7]} />
          </Stack>
        </Col>
      </Row>
    </Col>
  );
}
