import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import { setCurrentTab } from 'redux/reducers/fluxUploadLayoutReducer';
import { Col, Form, Row } from 'react-bootstrap';
import StudyRow from 'components/Flux/Upload/StudyRow';
import EmptyListAlert from 'components/EmptyListAlert';
import { useListStudiesQuery } from 'services/api/studies';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import useListEnd from 'utils/hooks/useListEnd';
import './index.scss';

export default function Upload() {
  const dispatch = useDispatch();

  const isAdmin: boolean = useSelector(selectUserIsAdmin);
  const [ampereCases, setAmpereCases] = useState<boolean>(isAdmin);
  const [maxLength, setMaxLength] = useState<number>(-1);
  const pageSize = 12;
  const { page } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);

  const { data: studyList, error, isFetching } = useListStudiesQuery({
    ampereCases,
    page: 1,
    pageSize: dataLength,
  });
  useHandleRequestErrors(error as ResponseError | undefined);

  const { listEnded } = useListEnd(studyList, page, pageSize, isFetching);

  useEffect(() => {
    dispatch(setCurrentTab('list'));
  }, [dispatch]);

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, studyList)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, studyList, isFetching]);

  return (
    <div className="list-container pb-1">
      {isAdmin && (
        <div className="d-flex justify-content-end">
          <Form.Check
            className="me-5 mb-1"
            type="checkbox"
            label="Casos Ampere"
            checked={ampereCases}
            onChange={() => { setAmpereCases(!ampereCases); }}
          />
        </div>
      )}
      <Row>
        <Col md={4} className="special-header">
          DESCRIÇÃO
        </Col>
        <Col md={4} className="special-header">
          HORIZONTE
        </Col>
        <Col md={4}>
          STATUS
        </Col>
      </Row>
      <EmptyListAlert
        message="Não foi encontrado nenhum estudo"
        array={studyList?.map((study) => ({
          study,
          key: study.id,
        })) ?? []}
        component={StudyRow}
        isFetching={isFetching}
        listEnded={listEnded}
      />
    </div>
  );
}
