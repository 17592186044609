import React from 'react';
import { MailTemplateSubtype, MailTemplateType } from 'helper/mailer';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import useTemplateForm from 'utils/hooks/mailer/useTemplateForm';
import AlertsCollection from 'components/AlertsCollection';
import TemplateInstructions from './Instructions';

import './index.scss';

export default function TemplateForm({
  types,
  typesLoading,
  templateType = null,
  templateSubtype = null,
}: {
  types: MailTemplateType[] | undefined,
  typesLoading: boolean,
  // eslint-disable-next-line react/require-default-props
  templateType?: MailTemplateType | null,
  // eslint-disable-next-line react/require-default-props
  templateSubtype?: MailTemplateSubtype | null,
}) {
  const {
    id: templateTypeId,
    subtypeName,
    setSubtypeName,
    subject,
    setSubject,
    template: templateBody,
    setTemplate: setTemplateBody,
    submitDisabled,
    onSubmit,
    onClear,
    handleChange,
    handleTypeSelectChange,
    saveLoading,
    validated,
    alerts,
  } = useTemplateForm(templateType, templateSubtype, types);

  return (
    <div className="mt-3 pb-3">
      <Row>
        <Col>
          <AlertsCollection alerts={alerts} />
        </Col>
      </Row>
      <Form
        className="template-form"
        noValidate
        validated={validated}
      >
        <fieldset disabled={saveLoading || typesLoading}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="templateType">
                <Form.Label>Tipo</Form.Label>
                <Form.Select
                  className="invalid"
                  name="templateType"
                  value={templateTypeId}
                  onChange={handleTypeSelectChange}
                  required
                >
                  {typesLoading && <option>Carregando...</option>}
                  {!typesLoading && <option disabled value="">Tipo</option>}
                  {types?.map((type) => (
                    <option value={type.id} key={type.id}>{type.label}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Selecione o tipo
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="templateSubtype">
                <Form.Label>Subtipo</Form.Label>
                <Form.Control
                  type="text"
                  name="templateSubtype"
                  value={subtypeName}
                  onChange={handleChange(setSubtypeName)}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Não deixe o subtipo em branco
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2">
            <Form.Group controlId="templateSubject">
              <Form.Label>Assunto</Form.Label>
              <Form.Control
                type="text"
                name="templateSubject"
                value={subject}
                onChange={handleChange(setSubject)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Não deixe o assunto em branco
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mt-2">
            <Form.Group controlId="templateBody">
              <Form.Label>Corpo</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                name="templateBody"
                value={templateBody}
                onChange={handleChange(setTemplateBody)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Não deixe o corpo em branco
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className="d-flex flex-row mt-4">
            <Button className="secondary-button" onClick={onClear}>Limpar</Button>
            <Button
              className="primary-button ms-3"
              disabled={submitDisabled}
              onClick={onSubmit}
            >
              Enviar
            </Button>
          </div>
        </fieldset>
      </Form>
      <hr />
      <TemplateInstructions />
    </div>
  );
}
