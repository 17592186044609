import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addDays, getUnixTime } from 'date-fns';

interface FluxUploadFormReducer {
  name: string,
  file: string | undefined,
  filename: string | undefined,
  startDate: number,
  endDate: number | undefined,
  outputOptions: string[],
  multiScenarios: string[],
  groupNumber: number | undefined,
  previvazModel: string | undefined,
  percentileValuesText: string,
}

const initialState: FluxUploadFormReducer = {
  name: '',
  file: undefined,
  filename: undefined,
  startDate: getUnixTime(addDays(new Date(), 1)),
  endDate: undefined,
  outputOptions: [
    'Produzir mapas de preciptação',
    'Produzir arquivo excel PMED',
    'Produzir arquivos DESSEM',
    'Produzir arquivo.csv de ENA diária',
    'Produzir memória de cálculo ENA semanal',
    'Produzir arquivos PREVS.RVX mensais',
    'Produzir arquivos VAZPAST.DAT',
  ],
  multiScenarios: [],
  groupNumber: undefined,
  previvazModel: 'Produzir todas as revisões',
  percentileValuesText: '',
};

export const fluxUploadFormSlice = createSlice({
  name: 'fluxUploadForm',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setFile: (state, action: PayloadAction<string>) => {
      state.file = action.payload;
    },
    setFilename: (state, action: PayloadAction<string>) => {
      state.filename = action.payload;
    },
    setEndDate: (state, action: PayloadAction<number>) => {
      state.endDate = action.payload;
    },
    setOutputOptions: (state, action: PayloadAction<string[]>) => {
      state.outputOptions = action.payload;
    },
    setMultiScenarios: (state, action: PayloadAction<string[]>) => {
      state.multiScenarios = action.payload;
    },
    setPrevivazModel: (state, action: PayloadAction<string>) => {
      state.previvazModel = action.payload;
    },
    setGroupNumber: (state, action: PayloadAction<number | undefined>) => {
      state.groupNumber = action.payload;
    },
    setPercentileValues: (state, action: PayloadAction<string>) => {
      state.percentileValuesText = action.payload;
    },
    reset: (state) => {
      state.name = '';
      state.file = undefined;
      state.filename = undefined;
      state.startDate = getUnixTime(addDays(new Date(), 1));
      state.endDate = undefined;
      state.outputOptions = [
        'Produzir mapas de preciptação',
        'Produzir arquivo excel PMED',
        'Produzir arquivos DESSEM',
        'Produzir arquivo.csv de ENA diária',
        'Produzir memória de cálculo ENA semanal',
        'Produzir arquivos PREVS.RVX mensais',
        'Produzir arquivos VAZPAST.DAT',
      ];
      state.multiScenarios = [];
      state.groupNumber = undefined;
      state.previvazModel = 'Produzir todas as revisões';
      state.percentileValuesText = '';
    },
  },
});

export const selectFluxUploadFormName = (state: any) => state.fluxUploadForm.name;
export const selectFluxUploadFormFile = (state: any) => state.fluxUploadForm.file;
export const selectFluxUploadFormFilename = (state: any) => state.fluxUploadForm.filename;
export const selectFluxUploadFormStartDate = (state: any) => state.fluxUploadForm.startDate;
export const selectFluxUploadFormEndDate = (state: any) => state.fluxUploadForm.endDate;
export const selectFluxUploadFormOutputOptions = (state: any) => state.fluxUploadForm.outputOptions;
export const selectFluxUploadFormMultiScenarios = (state: any) => state
  . fluxUploadForm
  . multiScenarios;
export const selectFluxUploadFormGroupNumber = (state: any): number | undefined => state
  .fluxUploadForm
  .groupNumber;
export const selectFluxUploadFormPrevivazModel = (state: any) => state.fluxUploadForm.previvazModel;
export const selectFluxUploadFormPercentileValues = (state: any): string => state
  .fluxUploadForm
  .percentileValuesText;

export const {
  setName,
  setFile,
  setFilename,
  setEndDate,
  setOutputOptions,
  setMultiScenarios,
  setGroupNumber,
  setPrevivazModel,
  setPercentileValues,
  reset,
} = fluxUploadFormSlice.actions;

export default fluxUploadFormSlice.reducer;
