import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FileList, FileListDirectory } from 'helper/products/fileViewer';

export default function useFilePath(data?: FileList) {
  const [path, setPath] = useState<FileListDirectory[]>([]);
  const pathRef = useRef(path);

  const currentDir = path[path.length - 1];
  const entries = currentDir?.children || data;

  const selectDir = (dir: FileListDirectory) => {
    setPath((p) => [...p, dir]);
  };

  const goBack = (level: number) => {
    setPath((p) => p.slice(0, level));
  };

  const reinstatePath = useCallback(() => {
    if (!data) return;

    const previousPath = pathRef.current;
    const newPath: FileListDirectory[] = [];

    let dirs = data;
    for (let i = 0; i < previousPath.length; i += 1) {
      const targetDir = previousPath[i];

      const match = dirs
        .filter(({ type }) => type === 'D')
        .find(({ label }) => label === targetDir.label) as FileListDirectory | undefined;

      if (!match) break;

      newPath.push(match);
      dirs = match.children;
    }

    setPath(newPath);
  }, [data]);

  useEffect(() => {
    pathRef.current = path;
  }, [path]);

  useEffect(() => {
    reinstatePath();
  }, [reinstatePath]);

  return {
    entries,
    path,
    selectDir,
    goBack,
  };
}
