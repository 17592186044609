import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MeteorologyFieldsLayoutState {
  currentTab: string
}

const initialState: MeteorologyFieldsLayoutState = {
  currentTab: 'observeddata',
};

export const meteorologyFieldsLayoutSlice = createSlice({
  name: 'meteorologyFieldsLayout',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectMeteorologyFieldsLayoutCurrentTab = (state: any) => (
  state.meteorologyFieldsLayout.currentTab
);
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setCurrentTab,
} = meteorologyFieldsLayoutSlice.actions;

export default meteorologyFieldsLayoutSlice.reducer;
