import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  setShowFormModal as setShowFormModalRedux,
  setShowConfirmationModal as setShowConfirmationModalRedux,
  setShowErrorModal as setShowErrorModalRedux,
  setError as setErrorRedux,
  selectSavePreferencesModalShow,
  selectConfirmationModalShow,
  selectErrorModalShow,
  selectError,
} from 'redux/reducers/saveComparatorPreferencesModalReducer';

export default function useComparatorSavePreferencesModal() {
  const setShowFormModal = (show: boolean) => {
    store.dispatch(setShowFormModalRedux(show));
  };
  const setShowConfirmationModal = (show: boolean) => {
    store.dispatch(setShowConfirmationModalRedux(show));
  };
  const setShowErrorModal = (show: boolean) => {
    store.dispatch(setShowErrorModalRedux(show));
  };
  const setError = (error: string) => {
    store.dispatch(setErrorRedux(error));
  };
  const isShowingFormModal = useSelector(selectSavePreferencesModalShow);
  const isShowingConfirmationModal = useSelector(selectConfirmationModalShow);
  const isShowingErrorModal = useSelector(selectErrorModalShow);
  const error = useSelector(selectError);

  return {
    setShowFormModal,
    isShowingFormModal,
    setShowConfirmationModal,
    isShowingConfirmationModal,
    setShowErrorModal,
    isShowingErrorModal,
    setError,
    error,
  };
}
