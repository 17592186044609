import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TabbedPage from 'components/TabbedPage';
import mapAnnotations from 'helper/Meteorology/Weather/Overview/AnomalyPanel/mapAnnotations';

export default function WeatherLayout() {
  return (
    <>
      <Row>
        <Col className="md-6">
          <h1 className="mb-3">CLIMA</h1>
        </Col>
      </Row>
      <Row style={{ flexWrap: 'nowrap', paddingBottom: '3.3125rem' }}>
        <TabbedPage>
          <TabbedPage.Tab title="VISÃO GERAL" path="/meteorology/weather/overview" />
          {mapAnnotations.map((entry) => (
            <TabbedPage.Tab key={entry.url} title={entry.acronym} path={`/meteorology/weather/${entry.url}`} />
          ))}
        </TabbedPage>
      </Row>
      <div className="bottom-bar" />
    </>
  );
}
