import axios, { AxiosInstance } from 'axios';
import { selectAuthToken } from 'redux/reducers/authReducer';
import { store } from 'redux/store';

export interface FileCollection {
  [fileName: string]: File
}

export const fileUploadApi = (): AxiosInstance => axios.create({
  baseURL: process.env.REACT_APP_AMPERE_API_URL,
  headers: {
    Authorization: selectAuthToken(store.getState()),
  },
  responseType: 'json',
});

export const setupFilesForRequest = (files: FileCollection): FormData => {
  const form = new FormData();
  const entries = Object.entries(files);
  entries.forEach((entry) => {
    const [fileName, file] = entry;
    form.append(fileName, file);
  });
  return form;
};
