import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomAlert } from '../../components/AlertsCollection';

type NewResponseError = {
  code: number,
  message: string,
  description: string,
};

export const nextDateLabels = {
  last3hours: 'últimas 3h',
  last6hours: 'últimas 6h',
  last12hours: 'últimas 12h',
  today: 'hoje',
  yesterday: 'ontem',
  beforeYesterday: 'anteontem',
  operativeWeek: 'nesta semana operativa',
  lastOperativeWeek: 'semana operativa passada',
  last7days: 'últimos 7 dias',
  last15days: 'últimos 15 dias',
  thisMonth: 'neste mês',
  lastMonth: 'mês passado',
  beforeLastMonth: 'mês retrasado',
};

export const regionObject = [
  { label: 'Brasil', value: 'BR' },
  { label: 'Sul', value: 'S' },
  { label: 'Sudeste', value: 'SE' },
  { label: 'Centro-Oeste', value: 'CO' },
  { label: 'Nordeste', value: 'NE' },
  { label: 'Norte', value: 'N' },
];

export type SelectablePeriods = keyof typeof nextDateLabels;
export type SelectableRegions = 'BR' | 'S' | 'SE' | 'CO' | 'NE' | 'N';

export interface ObservedDataControlPanelOptions {
  startDate?: number;
  endDate?: number;
  plotArea?: SelectableRegions;
  selectedPeriod?: SelectablePeriods,
  anomaly: boolean,
  obsImageBase64: string,
  climatologyImageBase64: string,
  anomalyImageBase64: string,
  imagesAreLoading: boolean,
  errorTitle: string,
  errorMessage: string,
}

const initialState: ObservedDataControlPanelOptions = {
  startDate: undefined,
  endDate: undefined,
  selectedPeriod: undefined,
  plotArea: undefined,
  anomaly: false,
  obsImageBase64: '',
  climatologyImageBase64: '',
  anomalyImageBase64: '',
  imagesAreLoading: false,
  errorTitle: '',
  errorMessage: '',
};

export const meteorologyFieldsObservedDataControlPanelSlice = createSlice({
  name: 'meteorologyFieldsObservedDataControlPanel',
  initialState,
  reducers: {
    reset: (state) => {
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
      state.plotArea = initialState.plotArea;
      state.selectedPeriod = initialState.selectedPeriod;
      state.anomaly = initialState.anomaly;
      state.obsImageBase64 = initialState.obsImageBase64;
      state.climatologyImageBase64 = initialState.climatologyImageBase64;
      state.anomalyImageBase64 = initialState.anomalyImageBase64;
      state.imagesAreLoading = initialState.imagesAreLoading;
      state.errorTitle = initialState.errorTitle;
      state.errorMessage = initialState.errorMessage;
    },
    setSelectedPeriod: (state, action: PayloadAction<SelectablePeriods>) => {
      state.selectedPeriod = action.payload;
      state.startDate = undefined;
      state.endDate = undefined;
    },
    setStartPeriod: (state, action: PayloadAction<number | undefined>) => {
      state.startDate = action.payload;
      state.selectedPeriod = initialState.selectedPeriod;
    },
    setEndPeriod: (state, action: PayloadAction<number | undefined>) => {
      state.endDate = action.payload;
      state.selectedPeriod = initialState.selectedPeriod;
    },
    clearDates: (state) => {
      state.startDate = undefined;
      state.endDate = undefined;
    },
    setPlotArea: (state, action: PayloadAction<SelectableRegions>) => {
      state.plotArea = action.payload;
    },
    setAnomaly: (state, action: PayloadAction<boolean>) => {
      state.anomaly = action.payload;
    },
    setObsImageString: (state, action: PayloadAction<string>) => {
      state.obsImageBase64 = action.payload;
    },
    setClimatologyImageString: (state, action: PayloadAction<string>) => {
      state.climatologyImageBase64 = action.payload;
    },
    setAnomalyImageString: (state, action: PayloadAction<string>) => {
      state.anomalyImageBase64 = action.payload;
    },
    setImageLoading: (state, action: PayloadAction<boolean>) => {
      state.imagesAreLoading = action.payload;
    },
    setErrorAlert: (state, action: PayloadAction<NewResponseError | undefined>) => {
      state.errorTitle = action.payload?.message ?? '';
      state.errorMessage = action.payload?.description ?? '';
    },
  },
});

export const selectMFControlPanelObservedDataPlotArea = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.plotArea
);
export const selectMFControlPanelObservedDataSelectedPeriod = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.selectedPeriod
);
export const selectMFControlPanelObservedDataStartPeriod = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.startDate
);
export const selectMFControlPanelObservedDataEndPeriod = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.endDate
);
export const selectMFControlPanelObservedDataAnomaly = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.anomaly
);
export const selectMFControlPanelObservedDataObsImageString = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.obsImageBase64
);
export const selectMFControlPanelObservedDataClimatologyImageString = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.climatologyImageBase64
);
export const selectMFControlPanelObservedDataAnomalyImageString = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.anomalyImageBase64
);
export const selectMFControlPanelObservedDataImageLoading = (state: any) => (
  state.meteorologyFieldsObservedDataControlPanel.imagesAreLoading
);
export const selectMFControlPanelObservedDataErrorAlert = (state: any): CustomAlert[] => (
  state.meteorologyFieldsObservedDataControlPanel.errorTitle === '' ? [] : [
    {
      title: state.meteorologyFieldsObservedDataControlPanel.errorTitle,
      message: state.meteorologyFieldsObservedDataControlPanel.errorMessage,
      variant: 'danger',
    },
  ]
);

export const {
  reset,
  setSelectedPeriod,
  setStartPeriod,
  setEndPeriod,
  clearDates,
  setPlotArea,
  setAnomaly,
  setObsImageString,
  setClimatologyImageString,
  setAnomalyImageString,
  setImageLoading,
  setErrorAlert,
} = meteorologyFieldsObservedDataControlPanelSlice.actions;

export default meteorologyFieldsObservedDataControlPanelSlice.reducer;
