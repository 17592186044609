export interface InvoiceTag {
  id: number
  text: string
  valid: boolean
}

export interface Invoice {
  id: number
  nfe: string
  cnpj: string
  corporateName: string
  issueDate: string
  dueDate: string
  uf: string
  city: string
  url: string
  emails: string[]
  tags: InvoiceTag[]
}

export type SortableField = 'nfe' | 'email';
export const SORT_BY_NFE = 'nfe';
export const SORT_BY_EMAIL = 'email';
export const SORTABLE_FIELDS: (SortableField | undefined)[] = [
  SORT_BY_NFE,
  SORT_BY_EMAIL,
];

export const invoiceColor = (tags: InvoiceTag[], emails: string[]) => {
  if (!tags.every((tag) => tag.valid)) return 'red';
  if (emails.length === 0) return 'yellow';
  return 'green';
};
