import { useGetContractQuery } from 'services/api/contracts';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { ResponseError } from 'services/api/base';

export default function useContractData(id: number) {
  const { data, error, isLoading } = useGetContractQuery({ contractId: id }, { skip: id === 0 });
  useHandleRequestErrors(error as ResponseError);

  if (id === 0) {
    return { contractData: undefined, isContractLoading: false };
  }

  return { contractData: data, isContractLoading: isLoading };
}
