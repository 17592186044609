import React from 'react';
import { fromUnixTime } from 'date-fns';
import { PersonalizedBlock } from 'helper/personalized';
import BlockDisplay from 'components/Flux/Personalized/BlockDisplay';
import './index.scss';

export default function Block({ block, index }: {
  block: PersonalizedBlock,
  index: number
}) {
  const startDate = fromUnixTime(block.dt_inicio).toLocaleDateString('pt-BR');
  const endDate = fromUnixTime(block.dt_fim).toLocaleDateString('pt-BR');
  const sliceStartDate = fromUnixTime(block.dt_recorte_inicio).toLocaleDateString('pt-BR');
  const sliceEndDate = fromUnixTime(block.dt_recorte_fim).toLocaleDateString('pt-BR');

  return (
    <BlockDisplay
      title={`Bloco ${index + 1}`}
      model={block.ds_modelo}
      rmv={block.ck_rmv}
      startDate={startDate}
      endDate={endDate}
      sliceStartDate={sliceStartDate}
      sliceEndDate={sliceEndDate}
    />
  );
}
