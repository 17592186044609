import React from 'react';
import { Form } from 'react-bootstrap';
import { MailTemplateType } from 'helper/mailer';

export default function ProductSelect({
  types,
  typeId,
  setTypeId,
  setSubtypeId,
  isValid,
}: {
  types: MailTemplateType[] | undefined,
  typeId: number,
  setTypeId: (value: React.SetStateAction<number>) => void,
  setSubtypeId: (value: React.SetStateAction<number>) => void,
  isValid: boolean,
}) {
  if (!types) {
    return (
      <Form.Select className="rounded-0" disabled>
        <option>Carregando...</option>
      </Form.Select>
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const valueAsInt = parseInt(value, 10);
    setTypeId(valueAsInt);
    setSubtypeId(-1);
  };

  return (
    <Form.Select
      id="product-select"
      value={typeId}
      onChange={handleChange}
      isValid={isValid}
      className="rounded-0"
    >
      <option disabled value={-1}>Produto</option>
      {types.map((type) => (
        <option value={type.id} key={type.id}>{type.label}</option>
      ))}
    </Form.Select>
  );
}
