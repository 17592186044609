import React from 'react';
import { Col, Row } from 'react-bootstrap';

import MonitoringSelector from './MonitoringSelector';
import DateSelector from './DateSelector';
import RuntimeSelector from './RuntimeSelector';
import HelpIcon from './HelpIcon';
import PentadSelector from './PentadSelector';
import DownloadButton from './DownloadButton';
import ForecastDaySlider from './ForecastDaySlider';
import Map from './Map';

import './index.scss';

export default function SynopticChart() {
  return (
    <div className="synoptic-chart-container h-100 d-flex flex-column mt-4">
      <Row>
        <Col xs={2} className="ms-4">
          <MonitoringSelector />
        </Col>
        <Col xs={2}>
          <DateSelector />
        </Col>
        <Col xs={2}>
          <RuntimeSelector />
        </Col>
        <Col xs={1}>
          <HelpIcon />
        </Col>
        <Col>
          <PentadSelector />
        </Col>
        <Col xs={1}>
          <DownloadButton />
        </Col>
      </Row>
      <Row className="justify-content-center mt-5 position-relative z-4">
        <Col md={6}>
          <ForecastDaySlider />
        </Col>
      </Row>
      <Row>
        <Map />
      </Row>
    </div>
  );
}
