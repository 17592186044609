import { WeatherArea } from 'types/Meteorology/Weather/Area';
import GetAboutParams from 'types/Meteorology/Weather/Area/getAboutParams';
import EditAboutDescriptionParams from 'types/Meteorology/Weather/Area/editAboutDescriptionParams';
import baseApi, { GenericResponse } from 'services/api/base';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import { store } from 'redux/store';
import transformGetAboutResponse from 'helper/Meteorology/Weather/Area/About';

export const weatherAreaApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAbout: build.query<WeatherArea, GetAboutParams>({
      transformResponse: transformGetAboutResponse,
      query: ({ area, polarity }) => {
        const productKey = selectMeteorologyKey(store.getState());
        return {
          url: `produtos/meteorologia/clima/about/?product_key=${productKey}`,
          method: 'POST',
          body: { area, polarity },
        };
      },
    }),
    editAboutDescription: build.mutation<GenericResponse<null>, EditAboutDescriptionParams>({
      query: ({ id, description }) => ({
        url: 'produtos/meteorologia/clima/edit-descricao-about',
        method: 'POST',
        body: { id, descricao: description },
      }),
    }),
  }),
});

export const {
  useGetAboutQuery,
  useEditAboutDescriptionMutation,
} = weatherAreaApi;
