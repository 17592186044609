import React, { useEffect } from 'react';
import ReactSlider from 'react-slider';
import './index.scss';
import { add } from 'date-fns';
import { useClosure } from 'utils/hooks';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import useNumericForecastControlPanel
  from 'utils/hooks/meteorology/useNumericForecastControlPanel';
import { useDispatch } from 'react-redux';
import { setDateOffset } from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import useSliderProps from 'utils/hooks/meteorology/numericForecast/useSliderProps';
import useMaxOffset from 'utils/hooks/meteorology/numericForecast/useMaxOffset';

function formatDateToDDMM(date: Date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
  return `${day}.${month}`;
}

function formatDateToMM(date: Date) {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  return `${month}`;
}

function formatDate(date: Date, period: string | undefined) {
  const isMonth = period ? period[0] === 'm' : false;

  if (isMonth) {
    return formatDateToMM(date);
  }

  return formatDateToDDMM(date);
}

const offsetObject = {
  d: (offset: number) => ({ days: offset }),
  p: (offset: number, index: number) => {
    if (index === 0) return { days: 5 * (offset - 1) + 1 };
    return { days: 5 * offset };
  },
  m: (offset: number) => ({ months: offset }),
  s: (offset: number) => ({ weeks: offset }),
};

export interface ThumbState {
  index: number,
  valueNow: number,
}

function selectedDate(thumbState: ThumbState, period: string | undefined, baseDate: Date) {
  if (!period) {
    return baseDate;
  }

  const periodLetter = period[0].toLocaleLowerCase();
  const offsetFunction = offsetObject[periodLetter] ?? (() => ({ days: 0 }));
  return add(baseDate, offsetFunction(thumbState.valueNow - thumbState.index, thumbState.index));
}

export default function ForecastDaysRange() {
  const dispatch = useDispatch();
  const {
    period,
    forecastUnixDate,
    forecastDateIndex: dateOffset,
  } = useNumericForecastControlPanel();
  const baseDay = forecastUnixDate ? new Date(forecastUnixDate * 1000) : new Date();
  const maxValue = useMaxOffset();
  const minValue = ['d', 'p'].includes(period?.at(0)) ? 1 : 0;
  const modifyDateOffset = (value: number) => dispatch(setDateOffset(value));
  const firstDay = useClosure(modifyDateOffset, minValue);
  const dayBefore = useClosure(modifyDateOffset, Math.max(dateOffset - 1, minValue));
  const dayAfter = useClosure(modifyDateOffset, Math.min(dateOffset + 1, maxValue));
  const lastDay = useClosure(modifyDateOffset, maxValue);
  const {
    sliderClassName,
    sliderMax,
    sliderMin,
    sliderValue,
    sliderOnChange,
  } = useSliderProps(maxValue, minValue, dateOffset, period);

  useEffect(() => {
    if (dateOffset < minValue) {
      dispatch(setDateOffset(minValue));
    }
  });

  return (
    <div className="forecast-range-control">
      <Button variant="link" onClick={firstDay}>
        <FontAwesomeIcon className="slider-control-button" icon={faAngleDoubleLeft} />
      </Button>
      <Button variant="link" onClick={dayBefore}>
        <FontAwesomeIcon className="slider-control-button" icon={faAngleLeft} />
      </Button>
      <ReactSlider
        max={sliderMax}
        min={sliderMin}
        value={sliderValue}
        onChange={sliderOnChange}
        className={sliderClassName}
        thumbClassName="example-thumb"
        trackClassName="example-track"
        renderThumb={(props, state) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...props}>
            <div className="thumb-value">{formatDate(selectedDate(state, period, baseDay), period)}</div>
          </div>
        )}
        pearling
        minDistance={1}
      />
      <Button variant="link" onClick={dayAfter}>
        <FontAwesomeIcon className="slider-control-button" icon={faAngleRight} />
      </Button>
      <Button variant="link" onClick={lastDay}>
        <FontAwesomeIcon className="slider-control-button" icon={faAngleDoubleRight} />
      </Button>
    </div>
  );
}
