import React, { useEffect, useState } from 'react';
import { Accordion, FormCheck, Spinner } from 'react-bootstrap';
import FilterModal from 'components/Filters/FilterModal';
import FilterCategory from 'components/Filters/FilterCategory';
import FilterDatalist from 'components/Filters/FilterDatalist';
import { ListedProduct } from 'helper/products';
import { UserModalFilters } from 'helper/userFilters';
import { ListedCompany } from 'helper/companies';
import {
  onTextChange,
  onExclusiveCheckChange,
  onCheckChange,
} from 'helper/controlledField';
import {
  loadUserFilters,
  onUserFiltersClear,
  onUserFiltersSave,
  onProductCheckChange,
} from 'helper/filterModals';

interface UserFilterModalProps {
  open: boolean,
  closeModal: () => void,
  filters: UserModalFilters,
  setFilters: React.Dispatch<React.SetStateAction<UserModalFilters>>,
  productsLoading: boolean,
  products: ListedProduct[] | undefined,
  companiesLoading: boolean,
  companies: ListedCompany[] | undefined,
}

export default function UserFilterModal({
  open,
  closeModal,
  filters,
  setFilters,
  productsLoading,
  products,
  companiesLoading,
  companies,
}: UserFilterModalProps) {
  const [selectedProducts, setSelectedProducts] = useState([] as number[]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isExpiringIn30, setisExpiringIn30] = useState(false);
  const [isExpiringIn60, setisExpiringIn60] = useState(false);
  const companyNames = companies ? companies.map(
    (company) => company.nome_fantasia,
  ) : [];

  useEffect(() => {
    if (open) {
      loadUserFilters(
        filters,
        setSelectedProducts,
        setSelectedCompany,
        setIsEnabled,
        setIsDisabled,
        setisExpiringIn30,
        setisExpiringIn60,
      );
    }
  }, [open, filters]);

  return (
    <FilterModal
      open={open}
      closeModal={closeModal}
      onClearClick={onUserFiltersClear(
        setSelectedProducts,
        setSelectedCompany,
        setIsEnabled,
        setIsDisabled,
        setisExpiringIn30,
        setisExpiringIn60,
      )}
      onSaveClick={onUserFiltersSave(
        {
          selectedProducts,
          selectedCompany,
          isEnabled,
          isDisabled,
          isExpiringIn30,
          isExpiringIn60,
        },
        setFilters,
        closeModal,
      )}
    >
      <Accordion>
        <FilterCategory name="Produto">
          {productsLoading && <Spinner />}
          {!productsLoading && products && products.map((product) => (
            <FormCheck
              key={product.id}
              label={product.nome}
              checked={selectedProducts && selectedProducts.includes(product.id)}
              onChange={onProductCheckChange(
                product.id,
                selectedProducts,
                setSelectedProducts,
              )}
            />
          ))}
        </FilterCategory>
        <FilterCategory name="Empresa">
          {companiesLoading && <Spinner />}
          {!companiesLoading && (
            <FilterDatalist
              datalistOptions={companyNames}
              value={selectedCompany}
              categoryName="empresa"
              onChange={onTextChange(setSelectedCompany)}
            />
          )}
        </FilterCategory>
        <FilterCategory name="Status">
          <FormCheck
            id="check-enabled"
            label="Habilitado"
            checked={isEnabled}
            onChange={onExclusiveCheckChange(
              isEnabled,
              isDisabled,
              setIsEnabled,
              setIsDisabled,
            )}
          />
          <FormCheck
            id="check-disabled"
            label="Desabilitado"
            checked={isDisabled}
            onChange={onExclusiveCheckChange(
              isDisabled,
              isEnabled,
              setIsDisabled,
              setIsEnabled,
            )}
          />
          <FormCheck
            id="check-expiring-30"
            label="Vence em até 30 dias"
            checked={isExpiringIn30}
            onChange={onCheckChange(isExpiringIn30, setisExpiringIn30)}
          />
          <FormCheck
            id="check-expiring-60"
            label="Vence entre 30 e 60 dias"
            checked={isExpiringIn60}
            onChange={onCheckChange(isExpiringIn60, setisExpiringIn60)}
          />
        </FilterCategory>
      </Accordion>
    </FilterModal>
  );
}
