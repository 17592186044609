import React from 'react';
import { DisplayImage } from 'helper/products/meteorology';
import './index.scss';
import useImageRowSize from 'utils/hooks/meteorology/useImageRowSize';
import MeteorologyFieldsImage from './Image';

export default function MeteorologyFieldsRow({
  images,
  label,
  showImageLabels,
  selectSet,
  setImageIndex,
} : {
  images: DisplayImage[],
  label: string | undefined,
  showImageLabels: boolean,
  selectSet: () => void,
  setImageIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  const { rowSize } = useImageRowSize();
  const width = `${96 / rowSize}%`;

  return (
    <div className="mb-3 position-relative">
      <div className="d-flex justify-content-between">
        {images.map((image, i) => (
          <div
            key={image.key}
            style={{ width }}
            className="flex-shrink-0"
          >
            <MeteorologyFieldsImage
              image={image}
              showLabel={showImageLabels}
              selectImage={() => {
                selectSet();
                setImageIndex(i);
              }}
            />
          </div>
        ))}
      </div>
      <span className="meteorology-fields-row-label text-center">
        {label}
      </span>
    </div>
  );
}
