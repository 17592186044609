import { ListedCompany } from 'helper/companies';
import { handleApiError } from 'helper/error';
import { NamedListedReportRecipientCompany } from 'helper/mailer';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';
import { useListReportRecipientCompaniesQuery } from 'services/api/mailer';

export default function useNamedReportCompanies(reportMailId: number, companies: ListedCompany[]) {
  const {
    data,
    isLoading,
    error,
  } = useListReportRecipientCompaniesQuery({ reportMailId });
  const recipientCompanies: NamedListedReportRecipientCompany[] = data?.map((recipient) => (
    {
      ...recipient,
      name: companies.find((company) => (company.id === recipient.companyId))?.nome_fantasia || '',
    }
  )) || [];
  const navigate = useNavigate();

  if (error) {
    handleApiError(navigate, error as ResponseError);
  }

  return { recipientCompanies, isLoading };
}
