const LN_1000 = Math.log(1000);

export default function formatBytes(bytes: number) {
  const lnBytes = Math.log(bytes);
  const nThousands = Math.floor(lnBytes / LN_1000);
  const cappedThousands = Math.min(nThousands, 3);
  const formattedBytes = (bytes / 1000 ** cappedThousands).toFixed(2);

  switch (nThousands) {
    case 0:
      return `${formattedBytes} B`;
    case 1:
      return `${formattedBytes} kB`;
    case 2:
      return `${formattedBytes} MB`;
    default:
      return `${formattedBytes} GB`;
  }
}
