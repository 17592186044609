import React from 'react';
import { Spinner } from 'react-bootstrap';

import './index.scss';

export default function CentralizedSpinner() {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="centralized-spinner-container">
        <Spinner className="w-100 h-100" />
      </div>
    </div>
  );
}
