import React from 'react';
import FluxPersonalizedWrapper from 'components/RouteWrappers/Flux/Personalized';
import Personalized from 'views/Flux/Personalized';
import New from 'views/Flux/Personalized/New';

const personalizedRouter = {
  path: 'personalized',
  element: <FluxPersonalizedWrapper />,
  children: [
    { path: '', element: <Personalized /> },
    { path: 'new', element: <New /> },
  ],
};

export default personalizedRouter;
