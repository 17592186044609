import React, { useState } from 'react';
import usePermissionCheck from 'utils/hooks/usePermissionCheck';
import { Col, Row } from 'react-bootstrap';
import CentralizedSpinner from 'components/CentralizedSpinner';
import RightSidebar from 'components/Layout/RightSidebar';
import PersonalizedForm from 'components/Flux/Personalized/New/Form';
import TabbedPage from 'components/TabbedPage';

export default function FluxPersonalizedWrapper() {
  const keySaved = usePermissionCheck('prevs-personalizado');
  const [showControlPanel, setShowControlPanel] = useState(false);

  if (!keySaved) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row>
        <Col className="md-6">
          <h1 className=" mb-3">FLUX - PERSONALIZADO</h1>
        </Col>
      </Row>
      <Row style={{ flexWrap: 'nowrap', paddingBottom: '3.3125rem' }}>
        <Col md="auto" className="flex-fill">
          <TabbedPage setControlPanelVisibility={setShowControlPanel}>
            <TabbedPage.Tab title="MEUS ESTUDOS" path="/flux/personalized" />
            <TabbedPage.Tab title="NOVO ESTUDO PERSONALIZADO" path="/flux/personalized/new" showControlPanel />
          </TabbedPage>
        </Col>
        {showControlPanel && (
          <Col md="auto">
            <RightSidebar>
              <PersonalizedForm />
            </RightSidebar>
          </Col>
        )}
      </Row>
      <div className="bottom-bar" />
    </>
  );
}
