import React from 'react';
import { Invoice, invoiceColor } from 'helper/financial';
import {
  Col, Row,
} from 'react-bootstrap';
import EmailList from './EmailList';
import './index.scss';
import './invoiceCheckbox.scss';

export default function InvoiceRow({ invoice, selected, onCheckChange }: {
  invoice: Invoice,
  selected: boolean,
  onCheckChange: () => void,
}) {
  const {
    id, nfe, cnpj, corporateName, issueDate, dueDate, uf, city, url, tags, emails,
  } = invoice;
  const checkColor = invoiceColor(tags, emails);

  return (
    <Row>
      <Col md={8}>
        <div className="d-flex flex-row mb-2">
          { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label id={`invoice-checkbox-${id}`} className={`mt-1 invoice-checkbox checkbox-${checkColor}`}>
            <input
              id={`invoice-checkbox-input-${id}`}
              type="checkbox"
              checked={selected}
              onChange={onCheckChange}
            />
            <span className={`invoice-checkmark checkbox-${checkColor}`} />
          </label>
          <div className="ms-3">
            <h5 className="invoice-header">{`Nº NFe: ${nfe} - ${cnpj} | ${corporateName}`}</h5>
            <div className="mt-2">
              <span className="invoice-info-title">Emissão:</span>
              <span className="me-3">{issueDate}</span>
              <span className="invoice-info-title">Vencimento:</span>
              <span className="me-3">{dueDate}</span>
              <span className="invoice-info-title">Destino:</span>
              <span>{`${uf} - ${city}`}</span>
            </div>
            <a href={url}>{url}</a>
            <div className="my-2">
              {tags.map((tag) => (
                <span key={tag.id} className={`invoice-tag ${tag.valid ? 'tag-green' : 'tag-red'}`}>
                  {tag.text}
                </span>
              ))}
            </div>
          </div>
        </div>
      </Col>
      <Col md={4}>
        <EmailList emails={emails} />
      </Col>
      <hr />
    </Row>
  );
}
