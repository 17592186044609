import { ResponseError } from 'services/api/base';
import { useGetCompanyQuery } from 'services/api/companies';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useGetCompanyData(id: number) {
  const { data, error, isLoading } = useGetCompanyQuery({ companyId: id }, { skip: id === 0 });
  useHandleRequestErrors(error as ResponseError);

  if (id === 0) {
    return { companyData: undefined, isCompanyLoading: false };
  }

  return { companyData: data, isCompanyLoading: isLoading };
}
