import { store } from 'redux/store';
import {
  modifyProcessingState,
  ProcessingStatus,
  selectSubmissionReport,
  SubmissionReport,
} from 'redux/reducers/nfeProcessingReducer';
import { useSelector } from 'react-redux';
import { useCreateInvoiceMutation, useSendInvoiceMutation } from 'services/api/finances';
import { processNfeCreation } from 'helper/nfeProcessing';

export default function useNfeProcessors() {
  const [invoiceCreator] = useCreateInvoiceMutation();
  const [invoiceSender] = useSendInvoiceMutation();
  const reports: SubmissionReport[] = useSelector(selectSubmissionReport);

  return async () => {
    store.dispatch(modifyProcessingState(ProcessingStatus.PROCESSING));

    for (let i = 0; i < reports.length; i += 1) {
      const { invoice } = reports[i];
      // eslint-disable-next-line no-await-in-loop
      await processNfeCreation(invoice, i, invoiceCreator, invoiceSender);
    }

    store.dispatch(modifyProcessingState(ProcessingStatus.DONE));
  };
}
