import React, { useRef } from 'react';
import {
  Button,
  Form,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

import { FileListDirectory, FileListEntry, buildUniqueFilename } from 'helper/products/fileViewer';
import useFileUpload from 'utils/hooks/file_viewer/useFileUpload';

export default function UploadFileButton({
  path,
  entries,
}: {
  path: FileListDirectory[],
  entries: FileListEntry[],
}) {
  const [trigger, { isLoading }] = useFileUpload();
  const fileRef = useRef<HTMLInputElement>(null);

  const openFileSelector = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const uploadFile = () => {
    const file = fileRef.current?.files?.[0];
    const baseFormattedPath = path
      .map(({ label }) => label)
      .join('/');
    const formattedPath = `${baseFormattedPath}/`;
    const dirName = path[path.length - 1].name;

    if (file) {
      const filename = buildUniqueFilename(file.name, entries);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        trigger({
          dirName,
          formattedPath,
          filename,
          fileBase64: (reader.result as string).split(',')[1],
        });
      };
    }
  };

  return (
    <div className="mx-4">
      <Stack direction="horizontal" gap={2}>
        <Button className="px-2 py-1" onClick={openFileSelector} disabled={isLoading}>
          {!isLoading && <FontAwesomeIcon icon={faUpload as IconProp} />}
          {isLoading && <Spinner size="sm" />}
        </Button>
        <div>Enviar Arquivo</div>
      </Stack>
      <Form.Control
        type="file"
        className="rounded-0"
        ref={fileRef}
        onChange={uploadFile}
        hidden
      />
    </div>
  );
}
