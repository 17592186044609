import React, { useState } from 'react';
import { UserFilterParams } from 'helper/userFilters';
import SearchParams from 'helper/searchParams';

export type UserSearchParams = SearchParams & UserFilterParams;

export const areArraysEqual = (
  array1: number[] | undefined,
  array2: number[] | undefined,
): boolean => (
  (array1 === undefined && array2 === undefined)
  || (array1 !== undefined && array2 !== undefined
  && (array1.length === array2.length
  && array1.every((value, index) => value === array2[index])))
);

export const areSearchParametersDifferent = (
  params1: UserSearchParams,
  params2: UserSearchParams,
): boolean => (
  params1.query !== params2.query
  || params1.sortBy !== params2.sortBy
  || params1.sortDirection !== params2.sortDirection
  || !areArraysEqual(params1.productIds, params2.productIds)
  || !areArraysEqual(params1.companyIds, params2.companyIds)
  || params1.enabled !== params2.enabled
  || params1.hasProductsExpiringIn30Days !== params2.hasProductsExpiringIn30Days
  || params1.hasProductsExpiringIn60Days !== params2.hasProductsExpiringIn60Days
);

export const useRefreshUsersOnSearch = (
  searchParams: UserSearchParams,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  isFetching: boolean,
) => {
  const [localParams, setLocalParams] = useState(searchParams);
  const [isFiltering, setFiltering] = useState(false);

  if (isFiltering && !isFetching) {
    setFiltering(false);
  }

  if (areSearchParametersDifferent(searchParams, localParams)) {
    setPage(1);
    setFiltering(true);
    setLocalParams(searchParams);
  }

  return { isFiltering };
};
