import React, { useEffect, useState } from 'react';
import { fromUnixTime } from 'date-fns';
import { ListedStudy } from 'helper/studies';
import {
  Accordion, Button, Col, Row,
} from 'react-bootstrap';
import { useReexecuteMutation } from 'services/api/products/flux';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { usePersonalizedDownloadLinkMutation } from 'services/api/personalized';
import ConfirmationDialogBox from '../../../ConfirmationDialogBox';
import StatusTag from './StatusTag';
import PersonalizedScenario from './Scenario';
import './index.scss';

export default function PersonalizedRow({ study }: { study: ListedStudy }) {
  const [triggerReexecute] = useReexecuteMutation();
  const [openReexecutionPopUp, setOpenReexecutionPopUp] = useState(false);
  const start = fromUnixTime(study.dt_inicio).toLocaleDateString('pt-BR');
  const end = fromUnixTime(study.dt_fim).toLocaleDateString('pt-BR');
  const lastExecution = study.dt_last_execution ? fromUnixTime(study.dt_last_execution).toLocaleString('pt-BR') : 'Não executado';
  const lastResponse = study.dt_last_response ? fromUnixTime(study.dt_last_response).toLocaleString('pt-BR') : 'Não houve resposta';
  const [downloadTrigger, { data }] = usePersonalizedDownloadLinkMutation();
  const downloadDisabled = study.dt_last_response === null || study.ck_internal_error;

  useEffect(() => {
    if (data) {
      window.open(data.data.link, '_blank');
    }
  }, [data]);

  return (
    <Accordion.Item
      eventKey={study.id.toString()}
      className="flux-personalized-row"
    >
      <ConfirmationDialogBox
        open={openReexecutionPopUp}
        dismiss={() => { setOpenReexecutionPopUp(false); }}
        confirm={() => { triggerReexecute(study.id); }}
      >
        <div>
          Você tem certeza de que deseja reenviar este estudo (
          <b>{study.ds_nome_estudo}</b>
          ) ?
        </div>
      </ConfirmationDialogBox>
      <Accordion.Header>
        <Row className="w-100">
          <Col md={4} className="flux-personalized-details">
            <div className="flux-personalized-study">
              {study.ds_nome_estudo}
            </div>
            <div>{study.empresa}</div>
            <div>{study.user}</div>
          </Col>
          <Col md={4}>
            {`${start} -- ${end}`}
          </Col>
          <Col md={4} className="flux-personalized-status">
            <StatusTag study={study} />
            <div className="d-flex flex-row justify-content-between">
              <div>
                <div>
                  {`Última Execução: ${lastExecution}`}
                </div>
                <div>
                  {`Última Resposta: ${lastResponse}`}
                </div>
                <div>
                  {`Número de execuções: ${study.vl_execution_counter}`}
                </div>
              </div>
              <div className="me-3">
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenReexecutionPopUp(true);
                  }}
                  className="flux-personalized-button mb-2"
                >
                  <FontAwesomeIcon icon={faPaperPlane as IconProp} />
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadTrigger({ caso_id: study.id });
                  }}
                  className="flux-personalized-button"
                  disabled={downloadDisabled}
                >
                  <FontAwesomeIcon icon={faDownload as IconProp} />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        {study.cenarios.map((scenario) => (
          <PersonalizedScenario
            scenario={scenario}
            key={scenario.id.toString()}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
