import { CustomAlert } from 'components/AlertsCollection';
import { handleApiError } from 'helper/error';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';
import {
  SaveCompanyParams, useGetCompanyByCnpjMutation, useSaveCompanyMutation,
} from 'services/api/companies';
import useSaveContract from 'components/Admin/Contracts/Form/useSaveContract';

function numberFromSelect(value: FormDataEntryValue | null) {
  if (value === null || value === '') {
    return undefined;
  }

  return Number(value);
}

export function extractCompanyFormParameters(formData: FormData): SaveCompanyParams {
  return {
    id: Number(formData.get('id')),
    cnpj: String(formData.get('cnpj')),
    moniker: String(formData.get('moniker')),
    fantasyName: String(formData.get('fantasyName')),
    corporateName: String(formData.get('corporateName')),
    internal: Boolean(formData.get('internal')),
    fiscalEmails: String(formData.get('fiscalEmails')),
    address: String(formData.get('address')),
    state: numberFromSelect(formData.get('uf')),
    city: numberFromSelect(formData.get('city')),
    number: String(formData.get('number')),
    complement: String(formData.get('complement')),
    cep: String(formData.get('cep')),
    district: String(formData.get('district')),
    active: true,
  };
}

export default function useSaveCompany(): {
  isLoading: boolean,
  validated: boolean,
  alerts: CustomAlert[],
  requestSuccess: boolean,
  companySaveHook: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
} {
  const navigate = useNavigate();
  const [saveCompany, { isLoading }] = useSaveCompanyMutation();
  const [getCompanyByCnpj] = useGetCompanyByCnpjMutation();
  const {
    isLoading: isContractLoading,
    alerts: contractAlerts,
    requestSuccess: contractRequestSuccess,
    contractSaveHook,
  } = useSaveContract();
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const [validated, setValidated] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState<boolean>(false);
  const combinedAlerts = useMemo(() => [...alerts, ...contractAlerts], [alerts, contractAlerts]);

  return {
    isLoading: isLoading || isContractLoading,
    alerts: combinedAlerts,
    validated,
    requestSuccess: requestSuccess && contractRequestSuccess,
    companySaveHook: async (event) => {
      // Prevent the browser from reloading the page
      event.preventDefault();

      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);

      if (!form.checkValidity()) {
        setValidated(true);
        return;
      }

      const companyParams: SaveCompanyParams = extractCompanyFormParameters(formData);
      const companySaveResult = await saveCompany(companyParams);

      if ('data' in companySaveResult) {
        const { title, message } = companySaveResult.data;
        const baseAlerts = message instanceof Object
          ? Object.entries(message).map(([t, m]) => ({ title: t, message: m }))
          : [{ title, message }];

        if (companySaveResult.data.status) {
          const successAlerts = baseAlerts.map((baseAlert) => ({ ...baseAlert, variant: 'success' }));
          const companyByCnpj = await getCompanyByCnpj({ companyCnpj: companyParams.cnpj });

          if ('data' in companyByCnpj) {
            const companyId = companyByCnpj.data.id;
            await contractSaveHook(event, { companyId });
          } else {
            handleApiError(navigate, { status: 500, data: 'something wrong' });
          }
          setRequestSuccess(true);
          setAlerts(successAlerts);
        } else {
          const errorAlerts = baseAlerts.map((baseAlert) => ({ ...baseAlert, variant: 'danger' }));
          setAlerts(errorAlerts);
        }
      } else if ('error' in companySaveResult) {
        handleApiError(navigate, companySaveResult.error as ResponseError);
      }
    },
  };
}
