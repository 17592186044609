import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function Headers() {
  return (
    <Row className="fw-bold admin-table-header" key="tableHeader">
      <Col key="tableHeaderForType" md="5">
        TIPO
      </Col>
      <Col key="tableHeaderForSubtype" md="5">
        SUBTIPO
      </Col>
      <Col key="tableHeaderForActions" md="2">
        AÇÕES
      </Col>
    </Row>
  );
}
