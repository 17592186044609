import { SORT_DESC, SortDirection } from 'constants/sorting';
import { Invoice, SORT_BY_NFE, SortableField } from 'helper/financial';

export const compareInvoiceNfe = (a: Invoice, b: Invoice) => (
  a.nfe.localeCompare(b.nfe)
);

export const compareInvoiceEmails = (a: Invoice, b: Invoice) => {
  for (let i = 0; i < Math.min(a.emails.length, b.emails.length); i += 1) {
    if (a.emails[i] !== b.emails[i]) {
      return a.emails[i].localeCompare(b.emails[i]);
    }
  }

  return b.emails.length - a.emails.length;
};

export default function useInvoiceSorting(
  invoices: Invoice[],
  sortBy: SortableField | undefined,
  sortDirection: SortDirection | undefined,
) {
  const sortedInvoices = invoices.map((invoice) => (invoice));

  if (sortBy && sortDirection) {
    if (sortBy === SORT_BY_NFE) {
      sortedInvoices.sort(compareInvoiceNfe);
    } else {
      sortedInvoices.sort(compareInvoiceEmails);
    }

    if (sortDirection === SORT_DESC) {
      sortedInvoices.reverse();
    }
  }

  return sortedInvoices;
}
