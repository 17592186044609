import { FormEvent, useState, useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useEditOverviewSummaryMutation } from 'services/api/Meteorology/Weather/Overview';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useOverviewSummaryEdit(setOverviewSummary: (summary: string) => void): {
  submit: (event: FormEvent) => void;
  summary: string;
  setSummary: (summary: string) => void;
  isLoading: boolean;
  requestSuccess: boolean;
  validated: boolean;
} {
  const [editOverviewSummary, { data, error, isLoading }] = useEditOverviewSummaryMutation();
  const [summary, setSummary] = useState('');
  const [validated, setValidated] = useState(true);
  const requestSuccess = 'data' in (data || {});

  useEffect(() => {
    if (requestSuccess) {
      setOverviewSummary(summary);
    }
  }, [requestSuccess, setOverviewSummary, summary]);

  useHandleRequestErrors(error as ResponseError);

  const submit = (event: FormEvent) => {
    event.preventDefault();
    if (summary === '<p><br></p>' || summary === '') {
      setValidated(false);
      return;
    }
    setValidated(true);
    editOverviewSummary({ summary });
  };

  return {
    submit, summary, setSummary, isLoading, requestSuccess, validated,
  };
}
