import React, { useEffect } from 'react';
import {
  Form,
  Row,
} from 'react-bootstrap';
import { addDays } from 'date-fns';
import useNewStudyForm from 'utils/hooks/flux/personalized/useNewStudyForm';
import useFromUnixTime from 'utils/hooks/useFromUnixTime';
import DatePicker from 'components/DatePicker';

export default function StudyHorizon() {
  const {
    startDate, setStartDate, endDate, setEndDate, horizonsDisabled,
  } = useNewStudyForm();
  const selectedStartDate = useFromUnixTime(startDate);
  const selectedEndDate = useFromUnixTime(endDate);
  const startDateMin = addDays(new Date(), 1);
  const endDateMin = selectedStartDate;

  const labelStyle = { fontWeight: 400, color: '#585858' };

  useEffect(() => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    setStartDate(nextDay);
  }, [startDate, setStartDate]);

  return (
    <div>
      <Row className="mb-3">
        <Form.Label column className="text-start" style={labelStyle}>Início do estudo</Form.Label>
      </Row>
      <div className="mb-3 d-flex justify-content-center">
        <DatePicker
          showIcon
          placeholderText="Selecione uma data"
          selected={selectedStartDate}
          onChange={setStartDate}
          disabled={horizonsDisabled}
          minDate={startDateMin}
          readOnly
        />

      </div>

      <Row className="mb-3 justify-content-center">
        <Form.Label column className="text-start" style={labelStyle}>Fim do estudo</Form.Label>
      </Row>
      <div className="mb-3 d-flex justify-content-center">
        <DatePicker
          showIcon
          placeholderText="Selecione uma data"
          selected={selectedEndDate}
          onChange={setEndDate}
          disabled={horizonsDisabled || selectedStartDate === null}
          minDate={endDateMin}
        />
      </div>
    </div>
  );
}
