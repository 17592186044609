import React from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import useCompanySelect from 'utils/hooks/useCompanySelect';
import AlertsCollection from 'components/AlertsCollection';
import ContractFormFields from '../FormFields';
import useSaveContract from './useSaveContract';

export default function ContractsForm() {
  const { companyOptions, companiesLoading } = useCompanySelect();
  const {
    isLoading: isContractSaveLoading, validated, requestSuccess, contractSaveHook, alerts,
  } = useSaveContract();

  if (companiesLoading) {
    return <Spinner />;
  }

  if (requestSuccess) {
    return <>Contrato salvo com sucesso</>;
  }

  return (
    <Form
      onSubmit={contractSaveHook}
      validated={validated}
      noValidate
    >
      <fieldset disabled={isContractSaveLoading}>
        <Row>
          <Col>
            <AlertsCollection alerts={alerts} />
          </Col>
        </Row>
        <Form.Group controlId="contract-company-id">
          <Form.Label>Empresa</Form.Label>
          <Form.Select
            name="contract-company"
            id="contract-company"
            placeholder=""
            required
          >
            <option> </option>
            { companyOptions?.map((companyOption) => (
              <option key={companyOption.value} value={companyOption.value}>
                { companyOption.label }
              </option>
            )) }
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Uma empresa deve ser selecionada
          </Form.Control.Feedback>
        </Form.Group>
        <ContractFormFields contractId={0} />
        <Row className="mt-3">
          <Col>
            <Button className="primary-button mt-3 float-end" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}
