import React from 'react';

import { FileListDirectory, FileListFile } from 'helper/products/fileViewer';
import Directory from './Directory';
import File from './File';

import './index.scss';

export default function Entry({
  entry,
  selectDir,
}: {
  entry: FileListDirectory | FileListFile,
  selectDir: (dir: FileListDirectory) => void,
}) {
  return (
    <div>
      {entry.type === 'D' && <Directory dir={entry} selectDir={selectDir} />}
      {entry.type === 'F' && <File file={entry} />}
    </div>
  );
}
