import { ResponseError } from 'services/api/base';
import { CustomAlert } from 'components/AlertsCollection';
import { useNavigate } from 'react-router';
import { handleApiError } from 'helper/error';

export type AlternativeResponseError = {
  code: number,
  message: string,
  description: string,
};

export default function useDisplayErrorMessages(
  error: ResponseError | AlternativeResponseError | undefined,
): CustomAlert[] {
  const navigate = useNavigate();
  if (error && 'data' in error) {
    handleApiError(navigate, error);
    return [];
  }

  return error !== undefined ? [
    { title: error?.message ?? '', variant: 'danger', message: error?.description ?? '' },
  ] : [];
}
