import { differenceInCalendarDays } from 'date-fns';
import { APIResponse } from 'helper/apiResponse';

export type PermissionTypes = (
  'file-viewer' |
  'flux' |
  'meteorologia' |
  'meteorologia-videos' |
  'prevs-personalizado' |
  'prevs-automatico' |
  'prevs-historico' |
  'prevs-gt'
);

export type PermissionProductMap = {
  [key in PermissionTypes]: string;
};

export const permissionProductMap: PermissionProductMap = {
  'file-viewer': 'Meus Arquivos',
  flux: 'Flux',
  meteorologia: 'Meteorologia',
  'meteorologia-videos': 'Meteorologia',
  'prevs-personalizado': 'Flux',
  'prevs-automatico': 'Flux',
  'prevs-historico': 'Flux',
  'prevs-gt': 'Flux',
};

export interface UserHasPermissionData {
  product_key: string
}
export type UserHasPermissionResponse = APIResponse<UserHasPermissionData>;

export interface RawProductDate {
  dt_inicio?: string;
  dt_fim?: string;
  ck_ativo?: boolean;
}
export interface RawProductList { [name: string]: RawProductDate[] }

export const UNKNOWN_DATE = 'xx/xx/xxxx';
export const EXPIRED_COLOR = '#CA5959';
export const VERY_NEAR_EXPIRATION_COLOR = '#F18449';
export const NEAR_EXPIRATION_COLOR = '#FFE584';
export const EXPIRATION_FAR_COLOR = '#A7CD78';

export interface Product {
  name: string;
  acronym: string;
  available: boolean;
  startDateString: string,
  endDateString: string,
  color: string,
}

export interface ListedProductUnit {
  // NOTE: defining indexable fields allows us to read and write to fields of this type using
  // variables and other dynamic values.
  [key: string]: number | string | boolean | null | object,
  id: number
  nome: string
  descricao: string
  preco_base: string
  default_for_all: boolean
  active: boolean
  file_viewer: boolean
  individual_file_viewer: boolean
  individual_file_viewer_dir_name: string
  is_product_group: boolean
}

export interface ListedProduct extends ListedProductUnit {
  produtos: ListedProductUnit[]
}

export interface RawListedProductField {
  id: string,
  value: string | number | boolean | null | object
}
export type RawListedProduct = RawListedProductField[];
export type RawProductListResponse = APIResponse<RawListedProduct[]>;

type UsedAcronyms = { [acronym: string]: boolean };

export const mockedProductList = [
  {
    id: 1,
    nome: 'produto1',
    descricao: 'produto1',
    preco_base: '9500',
    default_for_all: false,
    active: true,
    file_viewer: false,
    individual_file_viewer: true,
    individual_file_viewer_dir_name: 'path',
    is_product_group: false,
    produtos: [],
  },
  {
    id: 2,
    nome: 'produto2',
    descricao: 'produto2',
    preco_base: '9500',
    default_for_all: false,
    active: true,
    file_viewer: false,
    individual_file_viewer: true,
    individual_file_viewer_dir_name: 'path',
    is_product_group: false,
    produtos: [],
  },
] as ListedProduct[];

const twoInitials = (splitName: string[], used: UsedAcronyms): string | undefined => {
  // Try initials but avoid repetitions
  let initials: string | undefined;
  let index = 0;
  do {
    index += 1;
    const char = splitName[index][0];
    if (char.match(/[a-z]/i)) {
      initials = `${splitName[0][0]}${splitName[index][0]}`;
    }
  } while (!initials || (used[initials] && index + 1 < splitName.length));
  if (initials && !used[initials]) {
    return initials;
  }
  return undefined;
};

const initialAndAnotherLetter = (productName: string, used: UsedAcronyms): string => {
  const first = productName.charAt(0);
  let substr;
  let index = 0;
  do {
    index += 1;
    const char = productName.charAt(index);
    if (char.match(/[a-z]/i)) {
      substr = `${first}${char}`;
    }
  } while (!substr || (used[substr] && index + 1 < productName.length));
  if (substr && !used[substr]) {
    return substr;
  }
  return productName.slice(0, 2); // duplicate acronym, hopefully doesn't happen
};

export const productAcronym = (productName: string, used: UsedAcronyms): string => {
  const splitName = productName.split(' ');
  let letters;
  if (splitName.length > 1) {
    letters = twoInitials(splitName, used);
  }
  if (!letters) {
    letters = initialAndAnotherLetter(productName, used);
  }
  return letters;
};

export const acronymGenerator = () => {
  const usedAcronyms: UsedAcronyms = {};
  return (productName: string): string => {
    const acronym = productAcronym(productName, usedAcronyms);
    usedAcronyms[acronym] = true;
    return acronym;
  };
};

export const productAvailability = (endDateString?: string, active?: boolean): {
  available: boolean;
  color: string;
} => {
  if (!endDateString || active === false) {
    return { available: false, color: EXPIRED_COLOR };
  }
  const endDate = new Date(endDateString);
  const todayDate = new Date();
  const days = differenceInCalendarDays(endDate, todayDate);
  if (days <= 0) {
    return { available: false, color: EXPIRED_COLOR };
  }
  if (days < 30) {
    return { available: true, color: VERY_NEAR_EXPIRATION_COLOR };
  }
  if (days < 60) {
    return { available: true, color: NEAR_EXPIRATION_COLOR };
  }
  return { available: true, color: EXPIRATION_FAR_COLOR };
};

export const transformProductList = (rawList: RawProductList): Product[] => {
  const makeAcronym = acronymGenerator();
  return Object.entries(rawList).map(([name, attributes]) => {
    const mergedAttributes = attributes.reduce((previous, next) => ({ ...previous, ...next }), {});
    const { available, color } = productAvailability(
      mergedAttributes.dt_fim,
      mergedAttributes.ck_ativo,
    );
    return {
      name,
      acronym: makeAcronym(name),
      startDateString: mergedAttributes.dt_inicio || UNKNOWN_DATE,
      endDateString: mergedAttributes.dt_fim || UNKNOWN_DATE,
      color,
      available,
    };
  });
};

export const transformProductListResponse = (response: RawProductListResponse): ListedProduct[] => (
  response.data.map((productFields: RawListedProduct) => {
    const listedProduct: ListedProduct = {} as ListedProduct;
    productFields.forEach((productField: RawListedProductField) => {
      listedProduct[productField.id] = productField.value;
    });
    return listedProduct;
  })
);
