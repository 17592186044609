import React from 'react';

import {
  Row, Col, Container, Spinner,
} from 'react-bootstrap';
import { ListedUser } from 'helper/users';
import { ResponseError } from 'services/api/base';
import { useGetUserQuery } from 'services/api/users';
import ProductLine from 'components/Admin/Products/Line';
import contractDate from 'utils/contractDate';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { getPrimaryElements } from './utils';

export default function Details({ user }: {
  user: ListedUser,
}) {
  const { data: userData, error, isLoading } = useGetUserQuery({ id: user.id });
  useHandleRequestErrors(error as ResponseError);

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  // istanbul ignore next
  const phones = userData?.phones || [];
  // istanbul ignore next
  const cpf = userData?.cpf || 'Não informado';
  // istanbul ignore next
  const emails = userData?.emails || [];

  return (
    <Container>
      <Row>
        <Col md="6">
          <h5 className="mt-5">INFORMAÇÕES PESSOAIS</h5>
          <div className="admin-info-set">
            <p className="admin-info">
              <span className="info-header">Telefone:</span>
              { getPrimaryElements(phones, 'phone') }
            </p>
            <p className="admin-info">
              <span className="info-header">CPF:</span>
              {cpf}
            </p>
            <p className="admin-info">
              <span className="info-header">E-mail:</span>
              { getPrimaryElements(emails, 'email') }
            </p>
          </div>
          <h5 className="mt-5">CONTRATO</h5>
          <Row>
            <Col md="6" className="admin-info-set">
              <p className="admin-info">
                <span className="info-header">Início contrato:</span>
                { contractDate(user.dt_inicio) }
              </p>
            </Col>
            <Col md="6" className="admin-info-set">
              <p className="admin-info">
                <span className="info-header">Fim contrato:</span>
                { contractDate(user.dt_fim) }
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <h5 className="mt-5">PRODUTOS</h5>
          <Container>
            { user.products.map((product) => (
              <ProductLine key={product.name} product={product} />
            )) }
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
