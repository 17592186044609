import { Invoice } from 'helper/financial';
import { fileUploadApi, setupFilesForRequest } from '../base';
import { transformInvoiceData } from './transformer';

export default async function sendInvoice(file: File): Promise<Invoice[]> {
  const form = setupFilesForRequest({ file });
  const response = await fileUploadApi()
    .post('admin/financeiro/upload-file/', form);

  return transformInvoiceData(response);
}
