import React, { useEffect } from 'react';
import {
  Accordion, Card, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';

import useComparatorControlPanel from 'utils/hooks/meteorology/comparator/useComparatorControlPanel';
import useAvailableDates from 'utils/hooks/meteorology/comparator/useAvailableDates';
import useControlPanelOptions from 'utils/hooks/meteorology/useControlPanelOptions';
import formatDateDifference from 'helper/formatDateDifference';
import dateDiffToDate from 'helper/dateDiffToDate';
import DatePicker from 'components/DatePicker';

export enum ModelType {
  BASE = 'base',
  CONFRONTATIONAL = 'confrontational',
}

function ModelRadioButton({
  value,
  label,
  modelType,
}: {
  value: string;
  label: string;
  modelType: ModelType;
}) {
  const {
    setBaseModel,
    baseModel,
    setConfrontationalModel,
    confrontationalModel,
    setTitle,
  } = useComparatorControlPanel();

  const [model, setModel] = modelType === ModelType.BASE
    ? [baseModel, setBaseModel]
    : [confrontationalModel, setConfrontationalModel];

  const handleModel = () => {
    setTitle('', '');
    setModel(value);
  };

  return (
    <Form.Check
      type="radio"
      className="text-uppercase pe-1"
      name={`model-${value}-${modelType}`}
      label={label}
      value={value}
      onChange={handleModel}
      checked={model === value}
    />
  );
}

type ModelAccordionAttributes = {
  title: string;
  modelType: ModelType;
};

export default function ModelAccordion({ title, modelType }: ModelAccordionAttributes) {
  const { models, getModelOptions, isFetching } = useControlPanelOptions('comparador');

  const sectionTitleStyle = { color: '#c1c1c1', fontSize: '18px', fontWeight: '600' };

  const {
    baseModel,
    confrontationalModel,
    setBaseForecastDateDiff,
    setConfrontationalForecastDateDiff,
    baseForecastDateDiff,
    confrontationalForecastDateDiff,
    baseRuntime,
    confrontationalRuntime,
    setBaseRuntime,
    setConfrontationalRuntime,
    baseMember,
    confrontationalMember,
    setBaseMember,
    setConfrontationalMember,
    setBaseRMV,
    setConfrontationalRMV,
    baseRMV,
    confrontationalRMV,
    setTitle,
  } = useComparatorControlPanel();

  function titleClearer<T>(setter: (v: T) => void) {
    return (v: T) => {
      setTitle('', '');
      setter(v);
    };
  }

  const [
    dateDiff, setDateDiff,
    runtime, setRuntime,
    member, setMember,
    rmv, setRMV,
    model,
  ] = modelType === ModelType.BASE
    ? [
      baseForecastDateDiff, setBaseForecastDateDiff,
      baseRuntime, setBaseRuntime,
      baseMember, setBaseMember,
      baseRMV, setBaseRMV,
      baseModel,
    ]
    : [
      confrontationalForecastDateDiff, setConfrontationalForecastDateDiff,
      confrontationalRuntime, setConfrontationalRuntime,
      confrontationalMember, setConfrontationalMember,
      confrontationalRMV, setConfrontationalRMV,
      confrontationalModel,
    ];

  const handleRuntime = titleClearer(setRuntime);
  const handleMember = titleClearer(setMember);
  const handleRMV = titleClearer(setRMV);
  const availableDates = useAvailableDates(model);
  const runtimeOptions = getModelOptions(model).runtimes;
  const memberOptions = getModelOptions(model).members;

  const date = dateDiffToDate(dateDiff, availableDates);
  const setDate = (d: Date | null) => {
    const newDiff = formatDateDifference(d, availableDates);
    setDateDiff(newDiff);
  };
  const handleDate = titleClearer(setDate);

  useEffect(() => {
    const runtimeIsAvailable = runtimeOptions.some((ro) => ro.id === runtime);
    if (!isFetching && !runtimeIsAvailable) {
      setRuntime(undefined);
    }
  }, [isFetching, runtime, runtimeOptions, setRuntime]);

  useEffect(() => {
    const memberIsAvailable = memberOptions.some((mo) => mo.id === member);
    if (!isFetching && !memberIsAvailable) {
      setMember(undefined);
    }
  }, [isFetching, member, memberOptions, setMember]);

  if (isFetching) {
    return (
      <div className="text-center mb-1">
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Row className="mt-4">
        <Col className="d-inline-flex justify-content-center">
          <p style={sectionTitleStyle}>{title}</p>
        </Col>
      </Row>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="3" className="control-panel-item">
          <Accordion.Header>Modelo</Accordion.Header>
          <Accordion.Body className="d-grid control-panel-columns">
            <div>
              {models.map((m) => (
                <ModelRadioButton
                  modelType={modelType}
                  value={m.value}
                  label={m.label}
                  key={`modelRadioButton-${m.value}-${modelType}`}
                />
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="control-panel-item">
          <Accordion.Header>Data de Previsão</Accordion.Header>
          <Accordion.Body className="d-grid">
            <DatePicker
              inline
              selected={date}
              onChange={handleDate}
              includeDates={availableDates}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="control-panel-item">
          <Accordion.Header>Runtime</Accordion.Header>
          <Accordion.Body className="d-grid">
            {runtimeOptions.map((ro) => (
              <Form.Check
                key={ro.id}
                type="radio"
                label={ro.label}
                value={ro.id}
                checked={runtime === ro.id}
                onChange={() => handleRuntime(ro.id)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6" className="control-panel-item">
          <Accordion.Header>Membro</Accordion.Header>
          <Accordion.Body className="d-grid">
            {memberOptions.map((mo) => (
              <Form.Check
                key={mo.id}
                type="radio"
                label={mo.label}
                value={mo.id}
                checked={member === mo.id}
                onChange={() => handleMember(mo.id)}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7" className="control-panel-item">
          <Accordion.Header>RMV</Accordion.Header>
          <Accordion.Body className="d-grid control-panel-columns">
            <Card.Body>
              <Form.Check
                type="radio"
                name={`withRMV-${modelType}`}
                label="Com RMV"
                value={`withRMV-${modelType}`}
                checked={rmv}
                onChange={() => handleRMV(true)}
              />
              <Form.Check
                type="radio"
                name={`withoutRMV-${modelType}`}
                label="Sem RMV"
                value={`withoutRMV-${modelType}`}
                checked={!rmv}
                onChange={() => handleRMV(false)}
              />
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
