import { useState, useMemo, useEffect } from 'react';

export default function useDateRange({ defaultStartDate, defaultEndDate }: {
  defaultStartDate: Date | null, defaultEndDate: Date | null
}) {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const onChangeStartDate = useMemo(
    () => (date: Date | null) => { setStartDate(date); },
    [setStartDate],
  );
  const onChangeEndDate = useMemo(
    () => (date: Date | null) => { setEndDate(date); },
    [setEndDate],
  );

  useEffect(() => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, [setStartDate, setEndDate, defaultStartDate, defaultEndDate]);

  return {
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
  };
}
