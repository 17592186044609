export const onCheckChange = (
  checked: boolean,
  setState: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  setState(!checked);
};

export const onExclusiveCheckChange = (
  checked: boolean,
  otherChecked: boolean,
  setState: React.Dispatch<React.SetStateAction<boolean>>,
  otherSetState: React.Dispatch<React.SetStateAction<boolean>>,
) => () => {
  if (!checked && otherChecked) {
    otherSetState(false);
  }
  setState(!checked);
};

export const onTextChange = (
  setState: React.Dispatch<React.SetStateAction<string>>,
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  setState(event.currentTarget.value);
};

export const onDatePickerChange = (
  setState: React.Dispatch<React.SetStateAction<Date | null>>,
) => (date: Date | null) => {
  setState(date);
};
