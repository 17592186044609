import React from 'react';
import './index.scss';
import useComparatorControlPanel from 'utils/hooks/meteorology/comparator/useComparatorControlPanel';
import { useClosure } from 'utils/hooks';
import {
  PeriodOptions,
  setTriggerActivity,
} from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import { useDispatch } from 'react-redux';

export default function PeriodSetter() {
  const dispatch = useDispatch();
  const { period: currentPeriod, setComparativePeriod } = useComparatorControlPanel();
  const classNameSetter = (period: PeriodOptions) => (
    period === currentPeriod ? 'period-selector-current' : 'period-selector'
  );
  const modifyComparativePeriod = (period: PeriodOptions) => {
    setComparativePeriod(period);
    dispatch(setTriggerActivity(true));
  };

  return (
    <div className="period-selection">
      <button
        className={classNameSetter('d')}
        type="button"
        onClick={useClosure(modifyComparativePeriod, 'd')}
      >
        D
      </button>
      <button
        className={classNameSetter('p')}
        type="button"
        onClick={useClosure(modifyComparativePeriod, 'p')}
      >
        P
      </button>
      <button
        className={classNameSetter('s')}
        type="button"
        onClick={useClosure(modifyComparativePeriod, 's')}
      >
        S
      </button>
      <button
        className={classNameSetter('m')}
        type="button"
        onClick={useClosure(modifyComparativePeriod, 'm')}
      >
        M
      </button>
      <button
        className={classNameSetter('t')}
        type="button"
        onClick={useClosure(modifyComparativePeriod, 't')}
      >
        T
      </button>
    </div>
  );
}
