import './index.scss';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import ConfirmationDialogBox from 'components/ConfirmationDialogBox';
import { useNavigate } from 'react-router';
import {
  VideoProduct,
  videoProductToPath,
  videoProductToPermission,
  videoProductToRoute,
} from 'helper/videos';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { useDeleteVideoMutation } from 'services/api/videos';

export interface RemoveButtonProps {
  id: number,
  product: VideoProduct,
  categoryId?: number
}

export default function RemoveButton({ id, product, categoryId }: RemoveButtonProps) {
  const isAdmin = useSelector(selectUserIsAdmin);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [videoDeletionTrigger, { error }] = useDeleteVideoMutation();
  useHandleRequestErrors(error as ResponseError | undefined);

  const openModal = () => setOpen(true);

  const removeVideo = async () => {
    const productPath = videoProductToPath[product];
    const permissionType = videoProductToPermission[product];
    const productRoute = videoProductToRoute[product];

    await videoDeletionTrigger({ id, productPath, permissionType });
    setOpen(false);
    if (categoryId) {
      navigate(`${productRoute}/categories/${categoryId}`);
    }
  };

  if (!isAdmin) {
    return <div />;
  }

  return (
    <>
      <ConfirmationDialogBox
        open={open}
        confirm={removeVideo}
        dismiss={() => setOpen(false)}
      >
        Você quer deletar este vídeo?
      </ConfirmationDialogBox>
      <Button
        onClick={openModal}
        id={`remove-video-${id}`}
        className="icon-button video-action-button"
      >
        <FontAwesomeIcon
          icon={faTrashCan as IconProp}
          size="xs"
        />
      </Button>
    </>
  );
}

RemoveButton.defaultProps = { categoryId: 0 };
