import { useCallback, useEffect, useState } from 'react';

import { ResponseError } from 'services/api/base';
import { useAddDirectoryMutation, useLazyGetFileListQuery } from 'services/api/fileViewer';
import { AddDirectoryParams, AddDirectoryResponse } from 'helper/products/fileViewer';
import usePermissionCheck from '../usePermissionCheck';
import useHandleRequestErrors from '../useHandleRequestErrors';

type TriggerFunction = (id: AddDirectoryParams) => void;
interface Response {
  data: AddDirectoryResponse | undefined;
  isLoading: boolean;
}
type UseDirectoryCreationgReturnValue = [TriggerFunction, Response];

export default function useDirectoryCreation(): UseDirectoryCreationgReturnValue {
  const keySaved = usePermissionCheck('file-viewer');
  const [refetch] = useLazyGetFileListQuery();
  const [addDirectoryTrigger, { data, error }] = useAddDirectoryMutation();
  const [addDirectoryParams, setAddDirectoryParams] = useState<AddDirectoryParams>();
  const [isLoading, setIsLoading] = useState(false);

  const trigger = (params: AddDirectoryParams) => {
    setAddDirectoryParams(params);
  };

  const addDirectory = useCallback(async () => {
    if (addDirectoryParams) {
      try {
        setIsLoading(true);
        await addDirectoryTrigger(addDirectoryParams);
        await refetch();
      } finally {
        setIsLoading(false);
      }
    }
  }, [addDirectoryTrigger, addDirectoryParams, refetch]);

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (keySaved && addDirectoryParams) {
      addDirectory();
      setAddDirectoryParams(undefined);
    }
  }, [keySaved, addDirectoryParams, addDirectory]);

  return [trigger, { data, isLoading }];
}
