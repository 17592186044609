import React from 'react';
import { Outlet } from 'react-router';
import usePermissionCheck from 'utils/hooks/usePermissionCheck';
import CentralizedSpinner from 'components/CentralizedSpinner';

export default function MeteorologyWrapper() {
  const keySaved = usePermissionCheck('meteorologia');

  if (!keySaved) {
    return <CentralizedSpinner />;
  }

  return <Outlet />;
}
