import { useLazyVerifyZipQuery } from 'services/api/products/flux';

interface VerifyZipError {
  data: string
}

export default function useVerifyZipFile() {
  const [verifyZip, { data, error, isLoading }] = useLazyVerifyZipQuery();

  return {
    verifyZip, data, error: error as VerifyZipError | undefined, isLoading,
  };
}
