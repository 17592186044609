import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import jwtDecode from 'jwt-decode';
import { store } from 'redux/store';
import { JWTContent } from 'redux/reducers/authReducer';
import {
  AutomaticOptions,
  DailyData,
  GetDailyDataParams,
  RawAutomaticOptions,
  transformAutomaticOptions,
  transformDailyDataResponse,
  PastSimulation,
  transformListPastSimulationResponse,
  SavePastSimulationParams,
  MonthlyData,
  GetMonthlyDataParams,
  GetRevisionDataParams,
  AutomaticRevisionData,
  RawRevisionDataPerScenario,
  GetRevisionScenarioParams,
} from 'helper/flux/automatic';
import {
  AutomaticPreference,
  AutomaticPreferenceType,
  SaveAutomaticPreferenceParams,
  VerifyZipParams,
  VerifyZipResponse,
  transformAutomaticPreferences,
  RawStatus,
  RawQueue,
} from 'helper/products/flux';
import baseApi, { GenericResponse, rawBaseQuery } from '../base';

type StatusResponse = GenericResponse<RawStatus[]>;

type QueueStatusResponse = GenericResponse<RawQueue>;

const fluxApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    reexecute: build.mutation<GenericResponse<any>, number>({
      query: (processID) => ({
        url: 'produtos/flux/re-execute',
        method: 'POST',
        body: {
          process_id: processID,
        },
      }),
      invalidatesTags: ['PersonalizedStudy'],
    }),
    automaticOptions: build.query<AutomaticOptions, void>({
      queryFn: async (_, api, extraOptions) => {
        const productKey = store.getState().productKeys['prevs-automatico'];
        const url = `produtos/previvaz-automatico/get-options?product_key=${productKey}`;
        const res = await rawBaseQuery(url, api, extraOptions);
        const data = (res.data as GenericResponse<RawAutomaticOptions>)?.data;
        return {
          ...res,
          data: transformAutomaticOptions(data),
        } as QueryReturnValue<AutomaticOptions, unknown, unknown>;
      },
    }),
    getAutomaticPreferences: build.query<AutomaticPreference[], AutomaticPreferenceType>({
      query: (type) => {
        const userId = jwtDecode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/previvaz-ena-diaria/preferencias-flux-auto-list/',
          method: 'POST',
          body: { user_id: userId, type },
        };
      },
      transformResponse: transformAutomaticPreferences,
      providesTags: ['FaPreferences'],
    }),
    saveAutomaticPreference: build.mutation<any, SaveAutomaticPreferenceParams>({
      query: ({
        name, scenarios, type, spatialSubdivisionParam, acomph, mlt, pastSimulations,
      }) => {
        const userId = jwtDecode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/previvaz-ena-diaria/preferencias-flux-auto-save',
          method: 'POST',
          body: {
            id: 0,
            user_id: userId,
            name,
            preferencias_json: {
              data_acomph: acomph,
              data_previsao: mlt,
              versao: '',
              lista_cenarios: scenarios,
              lista_simulacoes_antigas: pastSimulations,
              ...spatialSubdivisionParam,
            },
            type,
          },
        };
      },
      invalidatesTags: ['FaPreferences'],
    }),
    getDailyData: build.query<DailyData, GetDailyDataParams>({
      query: (params) => {
        const productKey = store.getState().productKeys['prevs-automatico'];

        return {
          url: `produtos/previvaz-ena-diaria/get-data-daily/?product_key=${productKey}`,
          method: 'POST',
          body: {
            data_acomph: params.data_acomph,
            data_previsao: params.data_previsao,
            versao: params.versao,
            lista_cenarios: params.lista_cenarios,
            incluir_acomph: params.incluir_acomph,
            incluir_mlt: params.incluir_mlt,
            lista_simulacoes_antigas: params.pastSimulations,
            ...params.spatialSubdivisionParam,
          },
        };
      },
      transformResponse: transformDailyDataResponse,
    }),
    verifyZip: build.query<VerifyZipResponse, VerifyZipParams>({
      query: (verifyZipParams) => ({
        url: 'produtos/previvaz-arquivo/verify-zip-file',
        method: 'POST',
        body: verifyZipParams,
      }),
    }),
    listPastSimulations: build.query<PastSimulation[], void>({
      query: () => {
        const userId = jwtDecode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/previvaz-ena-diaria/simulacoes-antigas-list/',
          method: 'POST',
          body: {
            user_id: userId,
          },
        };
      },
      transformResponse: transformListPastSimulationResponse,
      providesTags: ['PastSimulation'],
    }),
    savePastSimulation: build.mutation<GenericResponse<any>, SavePastSimulationParams>({
      query: ({
        id = 0, acomphDate, forecastDate, version, model,
      }: SavePastSimulationParams) => {
        const userId = jwtDecode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/previvaz-ena-diaria/simulacoes-antigas-save',
          method: 'POST',
          body: {
            id,
            user_id: userId,
            simulacao_json: {
              data_acomph: acomphDate,
              data_previsao: forecastDate,
              versao: version,
              modelo: model,
            },
          },
        };
      },
      invalidatesTags: ['PastSimulation'],
    }),
    deletePastSimulation: build.mutation<any, number>({
      query: (pastSimulationId: number) => {
        const userId = jwtDecode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/previvaz-ena-diaria/simulacoes-antigas-delete',
          method: 'DELETE',
          body: {
            user_id: userId,
            id: pastSimulationId,
          },
        };
      },
      invalidatesTags: ['PastSimulation'],
    }),
    getPastSimulationsOptions: build.query<GenericResponse<any>, void>({
      query: () => {
        const productKey = store.getState().productKeys['prevs-automatico'];

        return {
          url: `produtos/previvaz-automatico/get-list/?product_key=${productKey}`,
        };
      },
    }),
    deleteAutomaticPreference: build.mutation<any, number>({
      query: (preferenceId: number) => {
        const userId = jwtDecode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/previvaz-ena-diaria/preferencias-flux-auto-delete',
          method: 'DELETE',
          body: {
            user_id: userId,
            id: preferenceId,
          },
        };
      },
      invalidatesTags: ['FaPreferences'],
    }),
    getMonthlyData: build.query<GenericResponse<MonthlyData>, GetMonthlyDataParams>({
      query: ({
        data_acomph, data_previsao, versao, lista_cenarios, unidade,
        spatialSubdivisionParam, incluir_acomph, incluir_mlt, pastSimulations,
      }) => {
        const productKey = store.getState().productKeys['prevs-automatico'];

        return {
          url: `produtos/previvaz-ena-diaria/get-data-monthly/?product_key=${productKey}`,
          method: 'POST',
          body: {
            versao,
            unidade,
            data_acomph,
            data_previsao,
            lista_cenarios,
            incluir_acomph,
            incluir_mlt,
            lista_simulacoes_antigas: pastSimulations,
            ...spatialSubdivisionParam,
          },
        };
      },
    }),
    getRevisionData: build.query<GenericResponse<AutomaticRevisionData>, GetRevisionDataParams>({
      query: ({
        data_acomph, data_previsao, versao, ndiasprev, lista_cenarios, unidade,
        spatialSubdivisionParam, pastSimulations, revision,
      }) => {
        const productKey = store.getState().productKeys['prevs-automatico'];

        return {
          url: `produtos/previvaz-ena-diaria/rvx-acompanhamento/?product_key=${productKey}`,
          method: 'POST',
          body: {
            versao,
            unidade,
            data_acomph,
            data_previsao,
            ndiasprev,
            lista_cenarios,
            revisao: revision,
            lista_simulacoes_antigas: pastSimulations,
            ...spatialSubdivisionParam,
          },
        };
      },
    }),
    getRevisionPerScenarios: build.query<RawRevisionDataPerScenario, GetRevisionScenarioParams>({
      query: ({
        data_acomph, data_previsao, versao, lista_cenarios, unidade,
        spatialSubdivisionParam, revision,
      }) => {
        const productKey = store.getState().productKeys['prevs-automatico'];

        return {
          url: `produtos/previvaz-ena-diaria/rvx/?product_key=${productKey}`,
          method: 'POST',
          body: {
            versao,
            unidade,
            data_acomph,
            data_previsao,
            lista_cenarios,
            revisao: revision,
            ...spatialSubdivisionParam,
          },
        };
      },
    }),
    getRevisionOptions: build.query<GenericResponse<string[]>, void>({
      query: () => {
        const productKey = store.getState().productKeys['prevs-automatico'];
        const url = `produtos/previvaz-ena-diaria/rvx-options/?product_key=${productKey}`;
        return { url };
      },
    }),
    getFluxStatus: build.query<StatusResponse, void>({
      query: () => ({
        url: 'produtos/previvaz-automatico/verify-last-results-automatico',
        method: 'POST',
        body: {},
      }),
    }),
    getQueueStatus: build.query<QueueStatusResponse, void>({
      query: () => ({
        url: 'produtos/flux/get-queue-status',
        method: 'POST',
        body: {},
      }),
    }),
  }),
});

export const {
  useReexecuteMutation,
  useAutomaticOptionsQuery,
  useGetAutomaticPreferencesQuery,
  useSaveAutomaticPreferenceMutation,
  useVerifyZipQuery,
  useLazyVerifyZipQuery,
  useGetDailyDataQuery,
  useLazyGetDailyDataQuery,
  useListPastSimulationsQuery,
  useSavePastSimulationMutation,
  useGetPastSimulationsOptionsQuery,
  useDeleteAutomaticPreferenceMutation,
  useLazyGetMonthlyDataQuery,
  useLazyGetRevisionDataQuery,
  useLazyGetRevisionPerScenariosQuery,
  useGetRevisionOptionsQuery,
  useGetFluxStatusQuery,
  useLazyGetFluxStatusQuery,
  useGetQueueStatusQuery,
  useLazyGetQueueStatusQuery,
  useDeletePastSimulationMutation,
} = fluxApi;
