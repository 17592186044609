import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { selectChart } from 'redux/reducers/synopticChartsReducer';
import useGetOptions from 'utils/hooks/synopticCharts/useGetOptions';
import MonitoringOption from './MonitoringOption';

export default function MonitoringSelector() {
  const { monitoringOptions } = useGetOptions();
  const chart = useSelector(selectChart);

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        className="synoptic-charts-dropdown-selector-toggle d-flex justify-content-between align-items-center w-100"
      >
        <span className="text-truncate">
          {chart?.campo || 'Campos Sinóticos'}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {monitoringOptions && (
          monitoringOptions.map((monitoring) => (
            <MonitoringOption key={monitoring.label} monitoring={monitoring} />
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
