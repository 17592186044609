import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';

import './index.scss';

export default function EditButton({
  onClick,
  id,
}: {
  onClick: () => void,
  id: number,
}) {
  return (
    <Button
      id={`edit-summary-${id}`}
      size="sm"
      className="icon-button edit-summary-button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
    </Button>
  );
}
