import React, { useState } from 'react';
import {
  Button, Card, Form, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import AlertsCollection, { CustomAlert } from 'components/AlertsCollection';
import { useForgotPasswordMutation } from '../../services/api/auth';
import { recoverPassword } from './utils';

export default function PasswordRecovery() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  return (
    <>
      <Card.Title className="auth-card-title my-5">
        Recuperação de senha
      </Card.Title>
      <AlertsCollection alerts={alerts} />
      <Card.Body className="my-3 mx-5">
        <Form
          onSubmit={
            (event) => (recoverPassword(event, forgotPassword, username, navigate, setAlerts))
          }
        >
          <fieldset disabled={isLoading}>
            <Form.Group controlId="passRecoveryFormUsername">
              <Form.Label className="auth-form-label">Username ou e-mail</Form.Label>
              <Form.Control
                required
                type="email"
                onChange={(event) => { setUsername(event.target.value); }}
              />
              <Form.Control.Feedback type="invalid">
                Por favor insira um e-mail válido.
              </Form.Control.Feedback>
            </Form.Group>
            <Stack direction="horizontal" className="mt-5 mb-2">
              <Button type="submit" className="primary-btn mx-auto">
                Enviar link de acesso
              </Button>
            </Stack>
          </fieldset>
        </Form>
      </Card.Body>
    </>
  );
}
