import { VideosGetLast, GetLastVideosParams } from 'helper/videos';
import { useEffect, useState } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetLastVideosQuery } from 'services/api/videos';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useGetVideos({
  page: targetPage,
  pageSize,
  category,
  searchTerm,
  uploadDate,
  productPath,
  permissionType,
}: GetLastVideosParams) {
  const [trigger, { data, error }] = useLazyGetLastVideosQuery();
  const [isFetching, setIsFetching] = useState(true);
  const [listEnded, setListEnded] = useState(false);
  const [pages, setPages] = useState<VideosGetLast[][]>([]);
  const page = Math.min(pages.length + 1, targetPage);
  const videos = pages.flat();

  useHandleRequestErrors(error as ResponseError | undefined);

  useEffect(() => {
    if (listEnded) return;

    trigger({
      page, pageSize, category, searchTerm, uploadDate, productPath, permissionType,
    });
    setIsFetching(true);
  }, [
    page, pageSize, category, searchTerm, uploadDate, productPath, permissionType,
    listEnded, trigger, setIsFetching,
  ]);

  useEffect(() => {
    if (!data) return;

    const newVideos = data.data.video_info;

    setIsFetching(false);
    if (newVideos.length) setPages((oldPages) => [...oldPages, newVideos]);
    if (newVideos.length < pageSize) setListEnded(true);
  }, [data, pageSize]);

  return { videos, isFetching, listEnded };
}
