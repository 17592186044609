import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../helper/users';

interface UserModalState {
  user: User | null
  title: string
  show: boolean
}

const initialState: UserModalState = {
  user: null,
  title: '',
  show: false,
};

export const userModalSlice = createSlice({
  name: 'userModal',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectUserModalUser = (state: any) => state.userModal.user;
export const selectUserModalTitle = (state: any) => state.userModal.title;
export const selectUserModalShow = (state: any) => state.userModal.show;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setUser,
  setTitle,
  setShow,
} = userModalSlice.actions;

export default userModalSlice.reducer;
