import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FluxPersonalizedLayoutTabs = 'list' | 'new';

interface FluxPersonalizedLayoutReducer {
  currentTab: FluxPersonalizedLayoutTabs,
}

const initialState: FluxPersonalizedLayoutReducer = {
  currentTab: 'list',
};

export const fluxPersonalizedLayoutSlice = createSlice({
  name: 'fluxPersonalizedLayout',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<FluxPersonalizedLayoutTabs>) => {
      state.currentTab = action.payload;
    },
  },
});

// eslint-disable-next-line max-len
export const selectFluxPersonalizedLayoutCurrentTab = (state: any) => state.fluxPersonalizedLayout.currentTab;

export const {
  setCurrentTab,
} = fluxPersonalizedLayoutSlice.actions;

export default fluxPersonalizedLayoutSlice.reducer;
