import { handleApiError } from 'helper/error';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUserId } from 'redux/reducers/authReducer';
import { ResponseError } from 'services/api/base';
import { useSendContractAttachmentMutation } from 'services/api/contracts';

export interface UseSendContractAttachmentParams {
  companyId: number
  contractId: number
}

export default function useSendContractAttachment(
  { companyId, contractId }: UseSendContractAttachmentParams,
) {
  const userId = useSelector(selectUserId);
  const [sendFileTrigger, {
    isLoading: isFileUploadLoading,
    isSuccess: isFileUploadSuccess,
    error: fileUploadError,
  }] = useSendContractAttachmentMutation();

  const fileInputOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    await sendFileTrigger({
      userId,
      companyId,
      contractId,
      file: event.target.files[0],
    });

    // Clear the input after a successful upload
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const navigate = useNavigate();
  if (fileUploadError) {
    handleApiError(navigate, fileUploadError as ResponseError);
  }

  return {
    isFileUploadLoading,
    isFileUploadSuccess,
    fileInputOnChange,
  };
}
