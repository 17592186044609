import { ResponseError } from 'services/api/base';
import { useGetHistoryQuery } from 'services/api/Meteorology/Weather/Area/History';
import GetHistoryParams from 'types/Meteorology/Weather/Area/getHistoryParams';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useAreaHistory(params: GetHistoryParams) {
  const {
    data, error, isLoading, isFetching,
  } = useGetHistoryQuery(params);

  useHandleRequestErrors(error as ResponseError);

  return { history: data, isLoading, isFetching };
}
