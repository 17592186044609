import React from 'react';
import { useSelector } from 'react-redux';
import { selectFluxPersonalizedFormShowInstructions } from 'redux/reducers/fluxPersonalizedFormReducer';
import {
  Button,
  Col,
  Form, Row,
} from 'react-bootstrap';
import useNewStudyForm from 'utils/hooks/flux/personalized/useNewStudyForm';
import FormAccordion from './Accordion';

export default function PersonalizedForm() {
  const {
    name, setName, addScenarioDisabled, onAddScenarioClick, onCancelClick,
  } = useNewStudyForm();
  const showInstructions = useSelector(selectFluxPersonalizedFormShowInstructions);

  return (
    <Form>
      <Row>
        <Col>
          <Form.Group controlId="PersonalizedFileName" className="mb-3">
            <Form.Label column className="text-center" style={{ fontWeight: 400, color: '#585858' }}>Nome do Estudo</Form.Label>
            <Form.Control required type="text" value={name} onChange={(e) => { setName(e.target.value); }} />
          </Form.Group>
        </Col>
      </Row>
      <FormAccordion />
      <Row className="mt-3">
        {showInstructions && (
          <Button
            disabled={addScenarioDisabled}
            onClick={onAddScenarioClick}
          >
            Adicionar Cenário
          </Button>
        )}
      </Row>
      <Row>
        <Button
          className="secondary-button"
          onClick={onCancelClick}
        >
          Cancelar Estudo
        </Button>
      </Row>
    </Form>
  );
}
