import React, { useState } from 'react';

import {
  Button, Spinner,
} from 'react-bootstrap';

import './index.scss';
import buildSets from 'helper/buildSets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import usePopularComparisons from 'utils/hooks/meteorology/usePopularComparisons';
import useSetComparison from 'utils/hooks/meteorology/comparator/useSetComparison';

const PAGE_SIZE = 6;

export default function PopularComparisons() {
  const { comparisons, isFetching } = usePopularComparisons();
  const setComparison = useSetComparison();
  const [page, setPage] = useState(0);
  const comparisonSets = buildSets(comparisons, PAGE_SIZE);
  const lastPage = comparisonSets.length - 1;

  if (isFetching) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="card-element">
      {page > 0 && (
        <Button variant="link" onClick={() => setPage((p) => p - 1)}>
          <FontAwesomeIcon icon={faChevronUp} />
        </Button>
      )}
      {comparisonSets[page]?.map((comparison) => (
        <Button
          className="popular-comparison"
          key={comparison.id}
          onClick={() => setComparison(comparison, 'Comparações Populares')}
        >
          {comparison.name}
        </Button>
      ))}
      {page < lastPage && (
        <Button variant="link" className="text-center" onClick={() => setPage((p) => p + 1)}>
          <FontAwesomeIcon icon={faChevronDown} />
        </Button>
      )}
    </div>
  );
}
