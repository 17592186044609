import React from 'react';
import { Modal } from 'react-bootstrap';
import { SendMassMailParams, useMassMailerPreviewQuery } from 'services/api/mailer';

export default function MassMailPreview({
  show,
  handleClose,
  previewParams,
}: {
  show: boolean,
  handleClose: () => void,
  previewParams: SendMassMailParams,
}) {
  const previewResponse = useMassMailerPreviewQuery(previewParams);
  const previewData = previewResponse.data;
  const previewContent = previewData?.toString() || '';
  const expiredSessionMessage = '{"status":0,"code":403,"message":"JWT Inv\\u00e1lido","title":"JWT Inv\\u00e1lido","data":"JWT inv\\u00e1lido."}';

  const exhibitPreview = () => {
    if (previewResponse.isError || !previewData) {
      return <h3>Um erro ocorreu. Tente novamente.</h3>;
    }

    return (
      previewContent === expiredSessionMessage
        ? <h3>Sua sessão expirou. Faça o login novamente.</h3>
        // eslint-disable-next-line react/no-danger
        : <div dangerouslySetInnerHTML={{ __html: previewContent }} />
    );
  };

  return (
    <Modal
      id="mass-mail-preview-modal"
      show={show}
      onHide={handleClose}
      className="preview-modal"
      size="xl"
      scrollable
    >
      <Modal.Header className="ms-3 me-3" closeButton>
        <h3 className="preview-title"><strong>Prévia do E-mail em massa</strong></h3>
      </Modal.Header>
      <Modal.Body className="m-3">
        {exhibitPreview()}
      </Modal.Body>
    </Modal>
  );
}
