import React from 'react';
import { Modal } from 'react-bootstrap';
import { useMailerPreviewQuery } from 'services/api/mailer';
import './index.scss';

export default function ReportPreview({
  show,
  handleClose,
  type,
  subType,
  printDate,
}: {
  show: boolean,
  handleClose: () => void,
  type: number,
  subType: number,
  printDate: string,
}) {
  const { data } = useMailerPreviewQuery({ type, subType, printDate });
  const previewData = data?.toString() || '';

  const exhibitPreview = () => {
    if (printDate === '') {
      return (
        <div className="text-center mt-1 p-3">
          <h3>Insira uma data para visualizar a prévia do relatório.</h3>
        </div>
      );
    }

    if (previewData.replace(/ /g, '').includes('"status":0')) {
      return (
        <div className="text-center mt-1 p-3">
          <h3>Ocorreu um erro. Tente novamente mais tarde.</h3>
        </div>
      );
    }

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: previewData }} />;
  };

  return (
    <Modal
      id="report-preview-modal"
      show={show}
      onHide={handleClose}
      className="preview-modal"
      size="xl"
      scrollable
    >
      <Modal.Header className="ms-3 me-3" closeButton>
        <h3 className="preview-title"><strong>Prévia do Relatório</strong></h3>
      </Modal.Header>
      <Modal.Body>
        { exhibitPreview() }
      </Modal.Body>
    </Modal>
  );
}
