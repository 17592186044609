import { useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { useCompanyStatesQuery, useCitiesByStateMutation } from 'services/api/companies';

export type SelectValue = SingleValue<{
  label: string | undefined;
  value: number | undefined;
}>;

export default function useStatesAndCitiesSelect() {
  const { data: statesData, isLoading: isStatesLoading } = useCompanyStatesQuery();
  const [citiesByStateTrigger,
    { data: citiesData }] = useCitiesByStateMutation();

  const stateOptions = useMemo(
    () => statesData?.map(
      (state) => ({ value: state.id, label: state.uf }),
    ),
    [statesData],
  );

  const cityOptions = useMemo(
    () => citiesData?.map(
      (city) => ({ value: city.id, label: city.nome }),
    ),
    [citiesData],
  );

  const [cityValue, setCityValue] = useState<SelectValue>({
    label: undefined,
    value: undefined,
  });
  const [stateValue, setStateValue] = useState<SelectValue>({
    label: undefined,
    value: undefined,
  });

  const onCityChange = (newValue: SelectValue) => {
    setCityValue(newValue);
  };

  const onStateChange = (newValue: SelectValue) => {
    if (stateValue?.label !== newValue?.label || stateValue?.value !== newValue?.value) {
      setStateValue(newValue);
      setCityValue(null);

      if (newValue?.value) {
        citiesByStateTrigger({ stateId: newValue.value });
      }
    }
  };

  return {
    stateOptions,
    cityOptions,
    citiesByStateTrigger,
    isStatesLoading,
    cityValue,
    stateValue,
    onCityChange,
    onStateChange,
  };
}
