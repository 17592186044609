import { useSaveAutomaticPreferenceMutation } from 'services/api/products/flux';
import { buildSubdivisionParam } from 'helper/flux/automatic';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelPastSimulations,
  selectFluxAutomaticControlPanelSpatialSubdivision,
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { useEffect, useMemo } from 'react';
import { ResponseError } from 'services/api/base';
import {
  selectDisplayFluxAutomaticPreferencesModal,
  selectFluxAutomaticPreferencesError,
  selectDisplayFluxAutomaticErrorModal,
  setDisplayPreferencesErrorModal,
  setDisplayPreferencesFormModal,
  setPreferencesError,
  selectDisplayFluxAutomaticConfirmationModal,
  setDisplayPreferencesConfirmationModal,
} from 'redux/reducers/fluxAutomaticPreferencesModalReducer';
import {
  FluxAutomaticLayoutTabs,
  selectFluxAutomaticLayoutCurrentTab,
} from 'redux/reducers/fluxAutomaticLayoutReducer';
import useAcomphMLT from './useAcomphMLT';

export function fluxtAutomaticTypeForQuery(type: FluxAutomaticLayoutTabs) {
  switch (type) {
    case 'daily':
      return 'diaria';
    case 'monthly':
      return 'mensal';
    default:
      return 'revisao';
  }
}

export default function useSaveFluxAutomaticPreference() {
  const dispatch = useDispatch();
  const apiError = useSelector(selectFluxAutomaticPreferencesError);
  const showFormModal = useSelector(selectDisplayFluxAutomaticPreferencesModal);
  const showErrorModal = useSelector(selectDisplayFluxAutomaticErrorModal);
  const showConfirmationModal = useSelector(selectDisplayFluxAutomaticConfirmationModal);
  const fluxAutomaticTab = useSelector(selectFluxAutomaticLayoutCurrentTab);
  const { acomph, mlt } = useAcomphMLT();

  const setShowFormModal = (v: boolean) => dispatch(setDisplayPreferencesFormModal(v));
  const setShowErrorModal = (v: boolean) => dispatch(setDisplayPreferencesErrorModal(v));
  const setShowConfirmationModal = (v: boolean) => dispatch(
    setDisplayPreferencesConfirmationModal(v),
  );

  const [
    savePreferenceTrigger, { isLoading, error },
  ] = useSaveAutomaticPreferenceMutation();

  useEffect(() => {
    const typedError = error as ResponseError | undefined;
    dispatch(setPreferencesError(typedError?.data));
  }, [dispatch, error]);

  const pastSimulations = useSelector(selectFluxAutomaticControlPanelPastSimulations);
  const spatialSubdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const loadedStudyOptions = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const saveIsEnabled = useMemo(
    () => spatialSubdivision !== undefined && loadedStudyOptions.length,
    [loadedStudyOptions.length, spatialSubdivision],
  );

  const savePreference = async (name: string) => {
    if (spatialSubdivision !== undefined && loadedStudyOptions.length) {
      const spatialSubdivisionParam = buildSubdivisionParam(spatialSubdivision);

      await savePreferenceTrigger({
        name,
        mlt,
        acomph,
        pastSimulations,
        scenarios: loadedStudyOptions,
        type: fluxtAutomaticTypeForQuery(fluxAutomaticTab),
        spatialSubdivisionParam,
      });
    }
  };

  return {
    saveIsEnabled,
    savePreference,
    isFetching: isLoading,
    error: apiError,
    showFormModal,
    showErrorModal,
    showConfirmationModal,
    setShowFormModal,
    setShowErrorModal,
    setShowConfirmationModal,
  };
}
