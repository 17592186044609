import {
  useEffect, useState, Dispatch, SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';

import { RootState, store } from 'redux/store';
import { setInfiniteScrollBottomState } from 'redux/reducers/infiniteScrollReducer';

export default function useInfiniteScrollPaging(): {
  page: number,
  setPage: Dispatch<SetStateAction<number>>
} {
  const [page, setPage] = useState(1);
  const triggerInfiniteScroll = useSelector((state: RootState) => state.infiniteScroll.isBottom);

  useEffect(() => {
    if (triggerInfiniteScroll) {
      store.dispatch(setInfiniteScrollBottomState(false));
      setPage(page + 1);
    }
  }, [triggerInfiniteScroll, page, setPage]);

  return { page, setPage };
}
