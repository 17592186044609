import { useState } from 'react';
import { useNavigate } from 'react-router';
import { handleApiError } from 'helper/error';
import { MailTemplateSubtype, MailTemplateType } from 'helper/mailer';
import { ResponseError } from 'services/api/base';
import { useSaveTemplateMutation } from 'services/api/mailer';
import { CustomAlert } from 'components/AlertsCollection';

export default function useTemplateForm(
  templateType: MailTemplateType | null,
  templateSubtype: MailTemplateSubtype | null,
  types: MailTemplateType[] | undefined,
) {
  const [id, setId] = useState<number | ''>(templateType?.id || '');
  const [type, setType] = useState(templateType?.label || '');
  const [subtypeName, setSubtypeName] = useState(templateSubtype?.label || '');
  const [subject, setSubject] = useState('');
  const [template, setTemplate] = useState('');
  const subtypeId = templateSubtype?.id || 0;
  const [trigger, { isLoading: saveLoading }] = useSaveTemplateMutation();
  const submitDisabled = id === '' || !type || !subtypeName;
  const [validated, setValidated] = useState(false);
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);
  const navigate = useNavigate();

  const handleTypeSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newId = Number(event.target.value);
    setId(newId);
    setType(types?.find((t) => t.id === newId)?.label || '');
  };

  const handleChange = (
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  const onSubmit = async () => {
    setValidated(true);
    setAlerts([]);

    if (!submitDisabled) {
      const saveTemplateResult = await trigger({
        id,
        type,
        subtypeId,
        subtypeName,
        subject,
        template,
      });
      setValidated(false);

      if ('data' in saveTemplateResult) {
        const { title, message } = saveTemplateResult.data;
        setAlerts([{
          title,
          message,
          variant: saveTemplateResult.data.status ? 'success' : 'danger',
        }]);
      } else if ('error' in saveTemplateResult) {
        handleApiError(navigate, saveTemplateResult.error as ResponseError);
      }
    }
  };

  const onClear = () => {
    setId('');
    setType('');
    setSubtypeName('');
    setSubject('');
    setTemplate('');
  };

  return {
    id,
    subtypeName,
    setSubtypeName,
    subject,
    setSubject,
    template,
    setTemplate,
    submitDisabled,
    onSubmit,
    onClear,
    handleChange,
    handleTypeSelectChange,
    saveLoading,
    validated,
    alerts,
  };
}
