import {
  PermissionTypes,
  UserHasPermissionResponse,
  ListedProduct,
  transformProductListResponse,
} from 'helper/products';
import baseApi from '../base';

export const productsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    userHasPermission: build.query<UserHasPermissionResponse, PermissionTypes>({
      query: (product) => (
        `admin/contratos/current-user-has-permission/?item=${product}`
      ),
    }),
    listProducts: build.query<Array<ListedProduct>, void>({
      query: () => 'admin/produtos/get-all',
      transformResponse: transformProductListResponse,
    }),
  }),
});

export const {
  useListProductsQuery,
  useUserHasPermissionQuery,
} = productsApi;
