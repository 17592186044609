import { addDays, subDays } from 'date-fns';

export default function dateDiffToDate(
  dateDiff: string | undefined,
  availableDates: Date[],
): Date | undefined {
  if (!dateDiff || availableDates.length === 0) return undefined;

  const deltaMatch = dateDiff.match(/d(\+|-)(\d+)/);
  if (deltaMatch) {
    const sign = deltaMatch[1];
    const diff = Number(deltaMatch[2]);

    if (sign === '+') return addDays(new Date(), diff);

    return subDays(new Date(), diff);
  }

  if (dateDiff === 'latest') return availableDates[availableDates.length - 1];
  if (dateDiff === '2nd_latest') return availableDates[availableDates.length - 2];

  return undefined;
}
