import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import useFluxFileUploadForm from 'utils/hooks/flux/fileUploadForm';
import { VerifyZipParams } from 'helper/products/flux';
import useGetUser from 'utils/hooks/users/useGetUser';
import useGetCompany from 'utils/hooks/companies/useGetCompany';
import useVerifyZipFile from 'utils/hooks/flux/verifyZipFile';
import useVerifyZipFileModal from 'utils/hooks/flux/verifyZipFileModal';
import useFluxFileUpload from 'utils/hooks/flux/fileUpload';
import UploadAccordion from './UploadAccordion';

export default function FluxFileUploadForm() {
  const {
    name, setName,
    file: zipFile, setFile,
    filename, setFilename,
    fileRef,
    reset,
    startDate, endDate,
    valid,
  } = useFluxFileUploadForm();
  const userData = useGetUser();
  const companyData = useGetCompany(userData?.id_empresa);
  const {
    verifyZip, isLoading, error,
  } = useVerifyZipFile();
  const {
    modalTitle,
    setTitle,
    modalBody,
    setBody,
    modalHeaderClass,
    setHeaderClass,
    showModal,
    setShowModal,
  } = useVerifyZipFileModal();
  const {
    uploadStudies,
    uploadStudiesParams,
    isLoading: isUploadLoading,
  } = useFluxFileUpload();

  useEffect(() => {
    if (error) {
      setBody(error.data);
    }
  }, [error, setBody]);

  const fileNotReady = !userData || !companyData || !filename || !zipFile || !startDate || !endDate;

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    setFilename(file.name);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (reader.result) {
          setFile(reader.result.toString().split(',')[1]);
        }
      };
    }
  };

  const verifyZipFile = async () => {
    if (fileNotReady) {
      return;
    }
    const params: VerifyZipParams = {
      user: userData.username,
      empresa: companyData.apelido,
      nome_estudo: name,
      options: '',
      data_inicial: startDate,
      data_final: endDate,
      file_name: filename,
      file_base64: zipFile,
    };
    const response = await verifyZip(params);
    if (response.isSuccess) {
      setTitle(response.data.title);
      setBody(response.data.message);
      setHeaderClass('bg-success');
      setShowModal(true);
    } else {
      setTitle('Erro');
      setHeaderClass('bg-danger');
      setShowModal(true);
    }
  };

  const submitStudy = async () => {
    await uploadStudies(uploadStudiesParams)
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setTitle('');
          setBody('Seu estudo foi enviado com sucesso!');
          setHeaderClass('bg-success');
          setShowModal(true);
        }
      })
      .catch(() => {
        setTitle('Erro');
        setHeaderClass('bg-danger');
        setShowModal(true);
      });
    reset();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton className={modalHeaderClass}>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{modalBody}</p>
        </Modal.Body>
      </Modal>
      <Form>
        <Row>
          <Col>
            <Form.Group controlId="UploadFileName" className="mb-3">
              <Form.Label column className="text-center" style={{ fontWeight: 400, color: '#585858' }}>Nome do Estudo</Form.Label>
              <Form.Control required type="text" value={name} onChange={(e) => { setName(e.target.value); }} />
            </Form.Group>
            <Form.Group>
              <Form.Control
                ref={fileRef}
                type="file"
                id="fluxFileInput"
                onChange={handleFileInput}
                className="custom-file-input mb-3"
              />
            </Form.Group>
          </Col>
        </Row>
        <UploadAccordion />
        <Row className="mt-3 d-grid gap-2">
          <Button
            onClick={verifyZipFile}
            disabled={fileNotReady || isLoading || showModal}
          >
            {isLoading && <Spinner animation="border" size="sm" className="me-2" />}
            Verificar Zip
          </Button>
        </Row>
        <Row className="mt-1">
          <Button
            disabled={!valid || isUploadLoading}
            onClick={submitStudy}
          >
            {isUploadLoading && <Spinner animation="border" size="sm" />}
            Enviar Estudo

          </Button>
        </Row>
        <Row>
          <Button
            variant="link"
            onClick={reset}
          >
            Cancelar Estudo
          </Button>
        </Row>
      </Form>

    </>
  );
}
