import React from 'react';
import { Image } from 'react-bootstrap';

import { DisplayImage } from 'helper/products/meteorology';

import './index.scss';

export default function MeteorologyFieldsImage({
  image,
  showLabel,
  selectImage,
} : {
  image: DisplayImage,
  showLabel: boolean,
  selectImage: () => void,
}) {
  return (
    <div className="d-flex justify-content-center">
      <div>
        {showLabel && <p className="meteorology-fields-display-row-image-label mb-2">{image.title}</p>}
        <Image
          fluid
          alt=""
          src={`data:image/png;base64,${image.base64}`}
          className="clickable-image meteorology-fields-display-row-image"
          onClick={selectImage}
        />
      </div>
    </div>
  );
}
