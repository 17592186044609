import React from 'react';
import {
  Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import SortingArrow from 'components/SortingArrow';
import EmptyListAlert from 'components/EmptyListAlert';
import {
  SORT_BY_IDENTIFIER,
  SORT_BY_SEND_DATE,
  SORT_BY_SUBJECT,
} from 'services/api/mailer';
import useMassEmailSorting from 'utils/hooks/mailer/useMassEmailSorting';
import useMassEmailListing from 'utils/hooks/mailer/useMassEmailListing';
import { useSelector } from 'react-redux';
import { selectMailerLayoutSearchQuery } from 'redux/reducers/mailerLayoutReducer';
import MassEmailRow from './Row';

export default function MassEmailTable() {
  const [orderBy, orderDirection, sorters] = useMassEmailSorting();
  const searchTerms: string = useSelector(selectMailerLayoutSearchQuery);
  const {
    massEmailList,
    isLoading,
    isFetching,
    listEnded,
  } = useMassEmailListing({
    pageSize: 20,
    orderBy,
    orderDirection,
    searchTerms,
  });

  if (isLoading) {
    return <Spinner className="mt-5 mx-auto" />;
  }

  return (
    <div className="mx-3">
      <Row className="mt-4 fw-bold admin-table-header px-0">
        <Col md={4}>
          <Stack direction="horizontal">
            IDENTIFICADOR
            <SortingArrow
              field={SORT_BY_IDENTIFIER}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.identificador}
            />
          </Stack>
        </Col>
        <Col md={4}>
          <Stack direction="horizontal">
            ASSUNTO
            <SortingArrow
              field={SORT_BY_SUBJECT}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.subject}
            />
          </Stack>
        </Col>
        <Col md={3}>
          <Stack direction="horizontal">
            DATA
            <SortingArrow
              field={SORT_BY_SEND_DATE}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.send_date}
            />
          </Stack>
        </Col>
        <Col md={1} className="d-flex justify-content-center">
          STATUS
        </Col>
      </Row>
      <EmptyListAlert
        component={MassEmailRow}
        array={massEmailList?.map((massEmail) => ({
          massEmail,
          key: massEmail.id,
        }))}
        message="Não foi encontrado nenhum e-mail em massa"
        isFetching={isFetching}
        listEnded={listEnded}
      />
    </div>
  );
}
