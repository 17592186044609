import React from 'react';
import {
  Accordion,
} from 'react-bootstrap';
import List from './List';
import New from './New';

export default function PastSimulations() {
  return (
    <Accordion.Item eventKey="4" className="control-panel-item">
      <Accordion.Header>Incluir Simulações antigas</Accordion.Header>
      <Accordion.Body>
        <List />
        <New />
      </Accordion.Body>
    </Accordion.Item>
  );
}
