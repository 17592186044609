import { ResponseError } from 'services/api/base';
import { useGetFluxStatusQuery } from 'services/api/products/flux';
import useHandleRequestErrors from '../useHandleRequestErrors';

interface Status {
  id: number;
  acomphDate: string;
  forecastDate: string;
  scenario: string;
  lastUpdate: Date;
}

export default function useStatus(): ({ status: Status[] }) {
  const { data, error: baseError } = useGetFluxStatusQuery();
  const error = (baseError as ResponseError)?.status === 404 ? undefined : baseError;

  useHandleRequestErrors(error as ResponseError);

  const apiStatus = data?.data ?? [];

  const status = apiStatus.map((item) => ({
    scenario: item.cenario,
    lastUpdate: new Date(item.last_update * 1000),
    acomphDate: item.data_acomph,
    forecastDate: item.data_previsao,
    id: item.id,
  }));

  return {
    status,
  };
}
