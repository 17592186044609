import React, { ChangeEventHandler } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { useGetReadjustmentRatesQuery } from 'services/api/contracts';

export default function ReadjustmentRate({
  value,
  onChange,
  required = false,
}: {
  value: number | null | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
}) {
  const { data } = useGetReadjustmentRatesQuery();

  return (
    <Form.Group controlId="companyReadjustmentRate">
      <Form.Label>Índice de reajuste</Form.Label>
      <Row className="align-items-center">
        {data?.map((option) => (
          <Col key={option.id} className="col-2">
            <Form.Check
              inline
              name="readjustmentRate"
              id={`readjustmentRate${option.label}`}
              value={option.id}
              checked={value === option.id}
              onChange={onChange}
              label={option.label}
              type="radio"
              required={required}
            />
          </Col>
        ))}
      </Row>
    </Form.Group>
  );
}

ReadjustmentRate.defaultProps = {
  required: false,
};
