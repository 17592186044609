import React, {
  Attributes, FunctionComponent, ReactNode, createElement,
} from 'react';
import { Alert, Row, Spinner } from 'react-bootstrap';
import './EmptyListAlert.scss';

interface EmptyListAlertProps {
  // eslint-disable-next-line react/require-default-props
  array?: unknown[];
  message: string,
  component: unknown,
  isFetching: boolean,
  listEnded: boolean,
}

export default function EmptyListAlert({
  component,
  message,
  array,
  isFetching,
  listEnded,
}: EmptyListAlertProps) {
  let returnedElement: ReactNode;
  if (isFetching || array?.length) {
    returnedElement = (
      <>
        {array && array.map(
          (element) => createElement(
            component as FunctionComponent,
            element as Attributes,
          ),
        )}
        {isFetching && (
          <Row>
            <Spinner className="mx-auto" />
          </Row>
        )}
        {listEnded && (
          <Row>
            <Alert variant="light">
              Exibindo todos os itens disponíveis
            </Alert>
          </Row>
        )}
      </>
    );
  } else {
    returnedElement = (
      <Alert variant="light">
        { message }
      </Alert>
    );
  }
  return returnedElement as JSX.Element;
}
