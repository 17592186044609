import React from 'react';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Chart } from 'helper/products/synopticCharts';
import { selectChart } from 'redux/reducers/synopticChartsReducer';

import './index.scss';

export default function HelpIcon() {
  const chart: Chart | undefined = useSelector(selectChart);
  const tooltipText = chart ? chart.descricao : 'Selecione um campo para ver sua descrição.';

  return (
    <div className="h-100 d-flex align-items-center">
      <div className="synoptic-charts-tooltip-container position-relative">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle as IconProp}
            className="position-absolute top-0 start-0 h-100"
          />
        </OverlayTrigger>
      </div>
    </div>
  );
}
