import { useState, useCallback } from 'react';

type UseNumericTextInputReturnValue = [
  number,
  (v: number) => void,
  string,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
];

export default function useNumericTextInput(): UseNumericTextInputReturnValue {
  const [textValue, setTextValue] = useState('0');
  const numberValue = parseInt(textValue, 10);

  const setNumberValue = useCallback((newValue: number) => {
    setTextValue(newValue.toString());
  }, [setTextValue]);

  const onChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '') || '0';
    setTextValue(newValue);
  }, [setTextValue]);

  return [numberValue, setNumberValue, textValue, onChangeText];
}
