import { ResponseError } from 'services/api/base';
import { useGetQueueStatusQuery } from 'services/api/products/flux';
import { RawQueue } from 'helper/products/flux';
import useHandleRequestErrors from '../useHandleRequestErrors';

export interface QueueStatus {
  title: string,
  running: boolean,
}

export default function useQueueStatus() {
  const { data, error } = useGetQueueStatusQuery();

  const apiItems = data?.data ?? ({} as RawQueue);

  const items = ([] as QueueStatus[]);

  useHandleRequestErrors(error as ResponseError);

  Object.entries(apiItems).forEach(([title, details]) => {
    items.push({
      title,
      running: details.running,
    });
  });

  return {
    items,
  };
}
