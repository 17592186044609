import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import baseApi, { GenericResponse, rawBaseQuery } from './base';

export interface CreateInvoiceResponse {
  status: number,
  data: string,
  message?: string,
}

export interface SendInvoiceResponse {
  status: number,
  code: number,
  type: number,
  title: string | null,
  message: string | null,
  data: string[]
}

export interface InvoiceData {
  nfe: string,
  emissao: string,
  contraparte: string,
  cnpj: string,
  cidade: string,
  uf: string,
  vencimento: string,
  emails: string[],
  link: string
}

export interface SendInvoiceParams {
  notas: InvoiceData[],
}

export interface CreateInvoiceParams {
  link: string,
  n_nota: string,
  cnpj: string,
  r_social: string,
}

export const financesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    sendInvoice: build.mutation<GenericResponse<SendInvoiceResponse>, SendInvoiceParams>({
      query: (params: SendInvoiceParams) => ({
        url: 'admin/financeiro/send-nfe/',
        method: 'PUT',
        body: {
          notas: params.notas,
        },
      }),
    }),
    createInvoice: build.mutation<CreateInvoiceResponse, CreateInvoiceParams>({
      queryFn: async (params, api, extraOptions) => rawBaseQuery({
        url: 'admin/financeiro/create-nfe/',
        method: 'PUT',
        body: params,
      }, api, extraOptions) as QueryReturnValue<CreateInvoiceResponse, unknown, unknown>,
    }),
  }),
});

export const {
  useSendInvoiceMutation,
  useCreateInvoiceMutation,
} = financesApi;
