import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faSquare } from '@fortawesome/free-solid-svg-icons';

import './index.scss';

export default function ModeSelector({
  multiMapMode,
  setMultiMapMode,
}: {
  multiMapMode: boolean,
  setMultiMapMode: React.Dispatch<React.SetStateAction<boolean>>,
}) {
  return (
    <div className="text-center my-3 me-2">
      <FontAwesomeIcon
        icon={faSquare}
        size="xl"
        className={`me-3 meteorology-fields-toolbar-mode-selector ${!multiMapMode ? 'selected' : ''}`}
        onClick={() => setMultiMapMode(false)}
      />
      <FontAwesomeIcon
        icon={faGripVertical}
        size="xl"
        className={`meteorology-fields-toolbar-mode-selector ${multiMapMode ? 'selected' : ''}`}
        onClick={() => setMultiMapMode(true)}
      />
    </div>
  );
}
