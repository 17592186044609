import {
  rawToListedReportMail,
  rawToListedReportRecipientCompanies,
  rawToListedMassMail,
  ListedReportMail,
  ListedReportRecipientCompany,
  RawListedReportMailParams,
  ListedMassMail, MailTemplateType, rawToMailerOptions,
  RawSendReportMailResponse,
  FillInMessageParams,
  FillInMessageResponse,
} from 'helper/mailer';
import filterUndefined from 'utils/filterUndefined';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ListedCompany } from 'helper/companies';
import baseApi, { GenericResponse, rawBaseQuery } from './base';

export interface ListReportRecipientCompanyParams {
  reportMailId: number
}

export type ReportSortableField = 'process_id' | 'tipo' | 'file_name' | 'dt_request';
export const SORT_BY_PROCESS_ID = 'process_id';
export const SORT_BY_TYPE = 'tipo';
export const SORT_BY_FILENAME = 'file_name';
export const SORT_BY_DATETIME_REQUEST = 'dt_request';

export const REPORT_SORTABLE_FIELDS: (ReportSortableField | undefined)[] = [
  SORT_BY_PROCESS_ID,
  SORT_BY_TYPE,
  SORT_BY_FILENAME,
  SORT_BY_DATETIME_REQUEST,
];

export type MassEmailSortableField = 'identificador' | 'subject' | 'send_date';
export interface ListMassMailParams {
  page: number,
  pageSize: number,
  orderBy: MassEmailSortableField,
  orderDirection: 'ASC' | 'DESC',
  searchTerms?: string,
}

export const SORT_BY_IDENTIFIER = 'identificador';
export const SORT_BY_SUBJECT = 'subject';
export const SORT_BY_SEND_DATE = 'send_date';
export const MASS_EMAIL_SORTABLE_FIELDS: (MassEmailSortableField | undefined)[] = [
  SORT_BY_IDENTIFIER,
  SORT_BY_SUBJECT,
  SORT_BY_SEND_DATE,
];

export interface MailerProduct {
  id: number,
  label: string,
}

export interface MailTemplateResponseSubtypes {
  [name: string]: number
}

export type MailTemplateResponseType = {
  id: number,
  subtipos: MailTemplateResponseSubtypes[],
};

export interface MailTemplateTypes {
  [name: string]: MailTemplateResponseType,
}

export type SendReportMailResponse = GenericResponse<RawSendReportMailResponse>;
export interface SendReportMailParams {
  typeId: number,
  subtypeId: number,
  companies: number[] | null,
  title: string,
  content: string,
  isTest: boolean,
  fileName: string,
  base64File: string,
}

export interface RawSendStatusItem {
  done: boolean
  count_status: number
  count_total: number
}
export interface RawSendStatusReponse {
  mail: RawSendStatusItem
  pdf: RawSendStatusItem
  send: RawSendStatusItem
  word: RawSendStatusItem
}
export type SendStatusReponse = GenericResponse<RawSendStatusReponse>;

export interface CancelReportParams {
  pid: number,
  process_identification: string,
  type: string,
  subtypeId: number,
  companies: number[] | null,
  title: string,
  content: string,
  isTest: boolean,
}

export type MailPreviewResponse = string | GenericResponse<string>;
export interface MailPreviewParams {
  type: number,
  subType: number,
  printDate: string,
}

export type SendMassMailBodyType = 'nenhum' | 'default' | 'notify';
export interface MassMailSendingFile {
  fileName: string,
  base64File: string,
}

export interface SendMassMailParams {
  body: string,
  subject: string,
  isTest: boolean,
  bodyType: SendMassMailBodyType,
  companyIds: number[],
  productIds: number[],
  files: MassMailSendingFile[],
}

export type SaveTemplateResponse = GenericResponse<null>;
export interface SaveTemplateParams {
  id: number | null,
  type: string,
  subtypeId: number | null,
  subtypeName: string,
  subject: string,
  template: string,
}

export type DeleteTemplateResponse = GenericResponse<null>;
export interface DeleteTemplateParams {
  type: number | string,
  subtypeId: number | string,
}

export interface GetMailerClientsByProducts {
  products: number[]
}

export interface EmailClientsGotByProduct {
  empresas: ListedCompany[]
}

export interface ReportMailClientsParams {
  productId: string | number,
  dataString: string,
  subProductId: number,
}

export const mailerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listReports: build.query<ListedReportMail[], RawListedReportMailParams>({
      query: (rawListedReportMailParams) => ({
        url: 'admin/enviador/get-all',
        method: 'POST',
        body: rawListedReportMailParams,
      }),
      transformResponse: rawToListedReportMail,
    }),
    listReportRecipientCompanies:
      build.query<ListedReportRecipientCompany[], ListReportRecipientCompanyParams>({
        query: ({ reportMailId }) => `admin/enviador/get-client-list/?id_processo=${reportMailId}`,
        transformResponse: rawToListedReportRecipientCompanies,
      }),
    listMassMails: build.query<ListedMassMail[], ListMassMailParams>({
      query: ({
        page, pageSize, orderBy, orderDirection, searchTerms,
      }) => ({
        url: 'admin/mailer/get-all',
        method: 'POST',
        body: filterUndefined({
          page,
          order_by: orderBy,
          page_size: pageSize,
          order_direction: orderDirection,
          search_terms: searchTerms,
        }),
      }),
      transformResponse: rawToListedMassMail,
    }),
    listMailerProducts: build.query<GenericResponse<MailerProduct[]>, void>({
      query: () => 'admin/mailer/get-produtos',
    }),
    listMailerOptionTypes: build.query<MailTemplateType[], void>({
      query: () => 'admin/enviador/options-tipo',
      transformResponse: rawToMailerOptions,
    }),
    sendReportMail: build.mutation<SendReportMailResponse, SendReportMailParams>({
      query: (params: SendReportMailParams) => ({
        url: 'admin/enviador/send',
        method: 'POST',
        body: {
          tipo: params.typeId,
          subtipo: params.subtypeId,
          empresas: params.companies,
          title: params.title,
          content: params.content,
          teste: params.isTest,
          file_name: params.fileName,
          file_base64: params.base64File,
        },
      }),
    }),
    getSendStatus: build.query<SendStatusReponse, RawSendReportMailResponse>({
      query: ({ pid, process_identification }) => (
        `admin/enviador/get-send-status?pid=${pid}&process_identification=${process_identification}`
      ),
    }),
    cancelMailerReport: build.mutation<GenericResponse<any>, CancelReportParams>({
      query: (params: CancelReportParams) => ({
        url: `admin/enviador/try-cancel/?pid=${params.pid}&process_identification=${params.process_identification}`,
        method: 'DELETE',
        body: {
          tipo: params.type,
          subtipo: params.subtypeId,
          empresas: params.companies,
          title: params.title,
          content: params.content,
          teste: params.isTest,
        },
      }),
    }),
    mailerPreview: build.query<MailPreviewResponse, MailPreviewParams>({
      queryFn: async ({ type, subType, printDate }, api, extraOptions) => rawBaseQuery({
        url: 'admin/enviador/preview',
        method: 'POST',
        body: {
          tipo: type,
          subtipo: subType,
          imprime_data: printDate,
        },
        responseHandler: 'text',
      }, api, extraOptions) as QueryReturnValue<string, GenericResponse<string>>,
    }),
    sendMassMail: build.mutation<GenericResponse<unknown[]>, SendMassMailParams>({
      query: ({
        body, bodyType, companyIds, productIds, files, subject, isTest,
      }) => ({
        url: 'admin/mailer/send',
        method: 'POST',
        body: {
          empresas: companyIds,
          subject,
          tipo_corpo: bodyType,
          body,
          teste: isTest,
          produtos: productIds,
          files: files.map(
            ({ fileName, base64File }) => ({ file_name: fileName, file_base64: base64File }),
          ),
        },
      }),
    }),
    massMailerPreview: build.query<MailPreviewResponse, SendMassMailParams>({
      queryFn: async ({
        companyIds, subject, bodyType, body, isTest, files,
      }, api, extraOptions) => rawBaseQuery({
        url: 'admin/mailer/preview',
        method: 'POST',
        body: {
          empresas: companyIds,
          subject,
          tipo_corpo: bodyType,
          body,
          teste: isTest,
          files: files.map(
            ({ fileName, base64File }) => ({ file_name: fileName, file_base64: base64File }),
          ),
        },
        responseHandler: 'text',
      }, api, extraOptions) as QueryReturnValue<string, GenericResponse<string>>,
    }),
    saveTemplate: build.mutation<SaveTemplateResponse, SaveTemplateParams>({
      query: ({
        id, type, subtypeId, subtypeName, subject, template,
      }) => ({
        url: 'admin/enviador/save-template',
        method: 'POST',
        body: {
          id,
          mail_tipo: type,
          mail_subtipo_id: subtypeId,
          mail_subtipo_name: subtypeName,
          mail_subject: subject,
          template,
        },
      }),
    }),
    deleteTemplate: build.mutation<DeleteTemplateResponse, DeleteTemplateParams>({
      query: ({
        type, subtypeId,
      }) => ({
        url: 'admin/enviador/delete-template',
        method: 'POST',
        body: {
          mail_tipo: type,
          mail_subtipo_id: subtypeId,
        },
      }),
    }),
    getMassMailClients:
      build.query<GenericResponse<EmailClientsGotByProduct>, GetMailerClientsByProducts>({
        query: ({ products }) => ({
          url: 'admin/mailer/get-clientes',
          method: 'POST',
          body: { produtos: products },
        }),
      }),
    getReportMailerClients:
      build.query<GenericResponse<EmailClientsGotByProduct>, ReportMailClientsParams>({
        query: (
          { productId, subProductId, dataString },
        ) => ({
          url: `admin/enviador/get-clientes/?id_produto=${productId}&id_subproduto=${subProductId}&data_ref=${dataString}`,
        }),
      }),
    fillInMessage: build.query<GenericResponse<FillInMessageResponse>, FillInMessageParams>({
      query: (
        { type, subtype, printDate },
      ) => ({
        url: 'admin/enviador/fill-in-message/',
        method: 'POST',
        body: {
          tipo: type,
          subtipo: subtype,
          imprime_data: printDate,
        },
      }),
    }),
  }),
});

export const {
  useListReportsQuery,
  useListReportRecipientCompaniesQuery,
  useListMassMailsQuery,
  useListMailerProductsQuery,
  useListMailerOptionTypesQuery,
  useSendReportMailMutation,
  useGetSendStatusQuery,
  useCancelMailerReportMutation,
  useMailerPreviewQuery,
  useSendMassMailMutation,
  useMassMailerPreviewQuery,
  useSaveTemplateMutation,
  useDeleteTemplateMutation,
  useGetMassMailClientsQuery,
  useLazyGetReportMailerClientsQuery,
  useLazyFillInMessageQuery,
} = mailerApi;
