import React from 'react';
import { Accordion, Button, Spinner } from 'react-bootstrap';
import useAutomaticOptions from 'utils/hooks/flux/useAutomaticOptions';

export default function AutomaticFrequentScenarios() {
  const {
    labeledScenarios,
    isFetching,
    loadFrequentScenario,
    checkLabeledScenarios,
  } = useAutomaticOptions();

  const isFetchingBody = () => <Spinner animation="border" />;

  const bodyWithScenarios = () => labeledScenarios.map((labeledScenario) => {
    const alreadySelected = checkLabeledScenarios(labeledScenario);
    return (
      <div
        className="my-1 row text-left"
        key={labeledScenario.label}
      >
        <Button
          className="btn btn-block"
          style={alreadySelected
            ? { background: '#212F58' }
            : { background: 'white' }}
          onClick={() => loadFrequentScenario(labeledScenario)}
        >
          <span
            style={{
              fontFamily: 'Source Sans Pro',
              fontWeight: 600,
              fontSize: '16px',
              color: alreadySelected ? 'white' : '#212F58',
            }}
          >
            {labeledScenario.label}
          </span>
        </Button>
      </div>
    );
  });

  const body = () => {
    if (isFetching) {
      return isFetchingBody();
    }
    return bodyWithScenarios();
  };

  return (
    <Accordion.Item eventKey="0" className="control-panel-item">
      <Accordion.Header>Cenários Frequentes</Accordion.Header>
      <Accordion.Body>
        { body() }
      </Accordion.Body>
    </Accordion.Item>
  );
}
