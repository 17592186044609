import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { store } from 'redux/store';
import { selectMailerLayoutIsDanger, setIsDanger } from 'redux/reducers/mailerLayoutReducer';
import CloseButton from 'components/CloseButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useReportForm from 'utils/hooks/mailer/useReportForm';
import { onTextChange } from 'helper/controlledField';
import AlertsCollection from 'components/AlertsCollection';
import ReactQuill from 'react-quill';
import { useClosure } from 'utils/hooks';
import DatePicker from 'components/DatePicker';
import ReportPreview from './ReportPreview';
import ProductSelect from './ProductSelect';
import SubtypeSelect from './SubtypeSelect';
import CompanySelect from './CompanySelect';
import ReportSubmission from './ReportSubmission';

import './index.scss';

export default function ReportForm({
  close,
}: {
  close: () => void,
}) {
  const isDanger = useSelector(selectMailerLayoutIsDanger);
  const {
    isTest,
    setIsTest,
    types,
    typeId,
    setTypeId,
    validType,
    subtypes,
    subtypeId,
    setSubtypeId,
    validSubtype,
    date,
    setDate,
    validDate,
    file,
    updateFile,
    fileRef,
    validFile,
    invalidFile,
    invalidFileMessage,
    preview,
    setPreview,
    companyIds,
    setCompanyIds,
    clearInputsDisabled,
    clearInputs,
    submissionDisabled,
    showCompanySelect,
    submitReport,
    submissionData,
    alerts,
    cancellationStatus,
    cancelReport,
    companies,
    companiesLoading,
    title,
    setTitle,
    content,
    setContent,
  } = useReportForm();

  useEffect(() => {
    store.dispatch(setIsDanger(!isTest));

    return () => {
      store.dispatch(setIsDanger(false));
    };
  }, [isTest]);

  return (
    <div>
      <div className={`report-form-header px-3 py-3 ${isDanger ? 'danger' : ''}`}>
        <div className="d-flex justify-content-between align-items-end">
          <CloseButton
            id="close-new-report"
            label="Novo Relatório"
            onClick={close}
          />
          <div className="mx-4 d-flex">
            <Form.Check className="me-2" checked={isTest} onChange={() => setIsTest(!isTest)} />
            Envio de testes
          </div>
        </div>
      </div>
      <hr className="mt-0 mb-2" />
      <div className="p-3">
        <AlertsCollection alerts={alerts} />
        <Row className="align-items-center mt-4 mb-2">
          <Col>
            <ProductSelect
              types={types}
              typeId={typeId}
              setTypeId={setTypeId}
              setSubtypeId={setSubtypeId}
              isValid={validType}
            />
          </Col>
          <Col>
            <SubtypeSelect
              subtypes={subtypes}
              subtypeId={subtypeId}
              setSubtypeId={setSubtypeId}
              isValid={validSubtype}
            />
          </Col>
          <Col>
            <DatePicker
              isClearable
              placeholderText="Data"
              selected={date}
              onChange={(d) => setDate(d)}
              className={`report-form-date-picker ${validDate ? 'report-form-valid-border' : ''}`}
            />
          </Col>
          <Col className="text-center">
            <Button
              id="report-preview-button"
              className="primary-button"
              onClick={() => setPreview(true)}
            >
              <FontAwesomeIcon icon={faEye as IconProp} className="me-2" />
              Visualizar
            </Button>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={3}>
            <Form.Control
              type="file"
              className="rounded-0"
              ref={fileRef}
              onChange={updateFile}
              isValid={validFile}
              isInvalid={invalidFile}
            />
            <Form.Control.Feedback type="invalid">
              {invalidFileMessage}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group>
            <Form.Label>Título</Form.Label>
            <Form.Control
              value={title}
              onChange={onTextChange(setTitle)}
            />
          </Form.Group>
        </Row>
        <Row className="mb-4">
          <Form.Group>
            <Form.Label>Conteúdo</Form.Label>
            <ReactQuill
              className="admin-report-form-quill"
              theme="snow"
              value={content}
              onChange={useClosure(setContent)}
            />
          </Form.Group>
        </Row>
        <div className="d-flex pb-3 mb-4">
          <Button className="mx-1" disabled={clearInputsDisabled} onClick={clearInputs}>Limpar</Button>
          <Button className="mx-1" disabled={submissionDisabled} onClick={submitReport}>Enviar</Button>
        </div>
        {showCompanySelect && (
          <CompanySelect
            companies={companies}
            selectedCompanyIds={companyIds}
            companiesAreLoading={companiesLoading}
            setSelectedCompanyIds={setCompanyIds}
          />
        )}
        {submissionData && cancellationStatus !== 'success' && (
          <ReportSubmission
            filename={file?.name}
            submissionData={submissionData}
            cancelReport={cancelReport}
          />
        )}
        <ReportPreview
          show={preview}
          handleClose={() => setPreview(false)}
          type={typeId}
          subType={subtypeId}
          printDate={date?.toLocaleDateString('PT-BR') || ''}
        />
      </div>
    </div>
  );
}
