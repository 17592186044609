import React from 'react';
import { Button } from 'react-bootstrap';
import { Comparison } from 'helper/products/meteorology';
import useSetComparison from 'utils/hooks/meteorology/comparator/useSetComparison';
import { useDispatch } from 'react-redux';
import { setTriggerActivity } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';

export default function PopularComparison({ comparison }: { comparison: Comparison }) {
  const dispatch = useDispatch();
  const setComparison = useSetComparison();

  const loadPreference = () => {
    setComparison(comparison, 'Comparações Populares');
    dispatch(setTriggerActivity(true));
  };

  return (
    <div>
      <Button
        variant="link"
        onClick={loadPreference}
        className="btn-control-panel text-truncate"
        title={comparison.name}
      >
        {comparison.name}
      </Button>
    </div>
  );
}
