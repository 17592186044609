import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FluxAutomaticLayoutTabs = 'daily' | 'monthly' | 'revision';

interface FluxAutomaticLayoutReducer {
  currentTab: FluxAutomaticLayoutTabs,
}

const initialState: FluxAutomaticLayoutReducer = {
  currentTab: 'daily',
};

export const fluxAutomaticLayoutSlice = createSlice({
  name: 'fluxAutomaticLayout',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<FluxAutomaticLayoutTabs>) => {
      state.currentTab = action.payload;
    },
  },
});

export const selectFluxAutomaticLayoutCurrentTab = (state: any) => (
  state.fluxAutomaticLayout.currentTab
);

export const {
  setCurrentTab,
} = fluxAutomaticLayoutSlice.actions;

export default fluxAutomaticLayoutSlice.reducer;
