import React from 'react';

import { Col, Row } from 'react-bootstrap';
import Title from './Title';
import ModeSelector from './ModeSelector';
import DownloadButton from './DownloadButton';
import ForecastDaysRange from '../ForecastDaysRange';

import './index.scss';

export default function Toolbar({
  showInstructions,
  multiMapMode,
  setMultiMapMode,
  onDownloadClick,
  downloadDisabled,
}: {
  showInstructions: boolean,
  multiMapMode: boolean,
  setMultiMapMode: React.Dispatch<React.SetStateAction<boolean>>,
  onDownloadClick: () => void,
  downloadDisabled: boolean,
}) {
  const elementClassName = showInstructions ? 'show-instruction' : '';

  return (
    <Row className="p-3 numeric-forecast-toolbar">
      <Col md={3} className={`d-flex justify-content-center align-items-center ${elementClassName}`}>
        <Title showInstructions={showInstructions} multiMapMode={multiMapMode} />
        {showInstructions && (
          <div className="instruction-number">
            1
          </div>
        )}
      </Col>
      <Col className={`mt-2 ${elementClassName}`}>
        <ForecastDaysRange />
        {showInstructions && (
          <div className="instruction-number">
            2
          </div>
        )}
      </Col>
      <Col md={2} className={`d-flex justify-content-center align-items-center ${elementClassName}`}>
        <ModeSelector multiMapMode={multiMapMode} setMultiMapMode={setMultiMapMode} />
        {showInstructions && (
          <div className="instruction-number">
            3
          </div>
        )}
      </Col>
      <Col md={1} className={`d-flex justify-content-center align-items-center ${elementClassName}`}>
        <DownloadButton onClick={onDownloadClick} disabled={downloadDisabled} />
        {showInstructions && (
          <div className="instruction-number">
            4
          </div>
        )}
      </Col>
    </Row>
  );
}
