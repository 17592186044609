import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

export default function DownloadButton({ onClick, disabled }: {
  onClick: () => void,
  disabled: boolean,
}) {
  return (
    <Button
      className="numeric-forecast-download-button"
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );
}
