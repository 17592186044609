import React from 'react';
import {
  Col, Row, Stack,
} from 'react-bootstrap';
import 'components/Admin/table.scss';
import PlusButton from 'components/PlusButton';
import {
  SORT_BY_DATETIME_REQUEST,
  SORT_BY_FILENAME,
  SORT_BY_PROCESS_ID,
  SORT_BY_TYPE,
} from 'services/api/mailer';
import SortingArrow from 'components/SortingArrow';
import useReportSorting from 'utils/hooks/mailer/useReportSorting';
import useReportListing from 'utils/hooks/mailer/useReportListing';
import EmptyListAlert from 'components/EmptyListAlert';
import { useListCompaniesQuery } from 'services/api/companies';
import { useSelector } from 'react-redux';
import { selectMailerLayoutSearchQuery } from 'redux/reducers/mailerLayoutReducer';
import ReportRow from './Row';

export default function ReportsTable({
  openReportForm,
}: {
  openReportForm: () => void,
}) {
  const [orderBy, orderDirection, sorters] = useReportSorting();
  const searchQuery: string = useSelector(selectMailerLayoutSearchQuery);
  const params = {
    pageSize: 12,
    orderBy,
    orderDirection,
    searchQuery,
  };
  const {
    reportList,
    isLoading,
    isFetching,
    listEnded,
  } = useReportListing(params);
  // TODO: Add error message in case of error
  const {
    data: companies,
    isLoading: companiesLoading,
    // error: companiesError,
    // TODO: Add this error to the error handling
  } = useListCompaniesQuery();

  return (
    <div key="ReportsTable" className="admin-table-main">
      <div className="p-3 mb-3">
        <PlusButton
          id="new-report"
          label="Novo Relatório"
          onClick={() => openReportForm()}
        />
      </div>
      <Row className="fw-bold admin-table-header" key="tableHeader">
        <Col key="tableHeaderForIDs" md="3">
          <Stack direction="horizontal">
            IDENTIFICADOR
            <SortingArrow
              field={SORT_BY_PROCESS_ID}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.process_id}
            />
          </Stack>
        </Col>
        <Col key="tableHeaderForProducts" md="2">
          <Stack direction="horizontal">
            PRODUTO
            <SortingArrow
              field={SORT_BY_TYPE}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.tipo}
            />
          </Stack>
        </Col>
        <Col key="tableHeaderForFiles" md="3">
          <Stack direction="horizontal">
            ARQUIVO
            <SortingArrow
              field={SORT_BY_FILENAME}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.file_name}
            />
          </Stack>
        </Col>
        <Col key="tableHeaderForDatetime" md="2">
          <Stack direction="horizontal">
            DATA/HORA
            <SortingArrow
              field={SORT_BY_DATETIME_REQUEST}
              sortBy={orderBy}
              sortDirection={orderDirection}
              sort={sorters.dt_request}
            />
          </Stack>
        </Col>
        <Col key="tableHeaderForStatus" md="2">STATUS</Col>
      </Row>
      <EmptyListAlert
        component={ReportRow}
        array={reportList?.map((report) => ({
          report,
          companies,
          key: report.processId,
        }))}
        message="Não foi encontrado nenhum relatório"
        isFetching={isFetching || isLoading || companiesLoading}
        listEnded={listEnded}
      />
    </div>
  );
}
