import React from 'react';
import MailerMassEmail from 'views/Admin/Mailer/MassEmail';

const massEmailRouter = {
  path: 'mass-emails',
  children: [
    { path: '', element: <MailerMassEmail /> },
  ],
};

export default massEmailRouter;
