import React from 'react';

import { FileListDirectory, FileListEntry } from 'helper/products/fileViewer';
import AddDirectoryButton from './AddDirectoryButton';
import UploadFileButton from './UploadFileButton';

import './index.scss';

export default function Actions({
  path,
  entries,
}: {
  path: FileListDirectory[],
  entries: FileListEntry[],
}) {
  return (
    <div className="file-list-actions d-flex justify-content-between">
      <AddDirectoryButton path={path} entries={entries} />
      <UploadFileButton path={path} entries={entries} />
    </div>
  );
}
