import React, { useEffect } from 'react';
import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';
import {
  faFileWord, faFilePdf, faEnvelopeCircleCheck, faSquareCheck,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useGetSendStatusQuery } from 'services/api/mailer';
import { RawSendReportMailResponse } from 'helper/mailer';

import './index.scss';

export default function ReportSubmission({
  filename,
  submissionData,
  cancelReport,
}: {
  filename: string | undefined
  submissionData: RawSendReportMailResponse,
  cancelReport: () => void,
}) {
  const { data: response, refetch } = useGetSendStatusQuery(submissionData);
  const data = response?.data;
  let done = false;

  if (data) {
    const {
      word, pdf, mail, send,
    } = data;
    done = word.done && pdf.done && mail.done && send.done;
  }

  useEffect(() => {
    if (!done) {
      const interval = setInterval(() => {
        refetch();
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [refetch, done]);

  const stats = {
    [faFileWord.iconName]: data?.word,
    [faFilePdf.iconName]: data?.pdf,
    [faEnvelopeCircleCheck.iconName]: data?.mail,
    [faSquareCheck.iconName]: data?.send,
  };

  let icon = <Spinner animation="border" />;
  if (done) icon = <FontAwesomeIcon icon={faCheckCircle as IconProp} size="2xl" />;

  return (
    <Row className="m-2 pb-3 report-form-submission-container">
      <Col xs={10}>
        <div className="m-2 p-1">
          <div className="d-flex align-items-center">
            {icon}
            <span className="ms-3 fs-3">{filename}</span>
          </div>
        </div>
        <div className="d-flex ms-5 mb-4">
          {[faFileWord, faFilePdf, faEnvelopeCircleCheck, faSquareCheck].map((i) => (
            <div className="mx-3" key={i.iconName}>
              <FontAwesomeIcon
                icon={i}
                size="xl"
              />
              <span className="ms-2">
                {`${stats[i.iconName]?.count_status || 0}/${stats[i.iconName]?.count_total || 0}`}
              </span>
            </div>
          ))}
        </div>
      </Col>
      <Col xs={2} className="d-flex flex-column justify-content-end">
        <Button id="report-cancellation-button" onClick={cancelReport} className="me-1">
          Cancelar
        </Button>
      </Col>
    </Row>
  );
}
