import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';
import useDeleteContractAttachment from 'utils/hooks/companies/useDeleteContractAttachment';
import { ContractAttachment } from 'helper/contracts';
import useConfirmationDialogBox from 'utils/useConfirmationDialogBox';
import DownloadButton from 'components/DownloadButton';
import { downloadContractBlob } from 'services/fileDownloadApi';
import confirmationDialog from './utils';

export default function AttachmentRow(
  { attachment }: { attachment: ContractAttachment },
) {
  const {
    isLoading,
    deleteContractAttachment,
  } = useDeleteContractAttachment({ attachmentId: attachment.id });
  const { confirmationDialogBox, setConfirmationDialogBox } = useConfirmationDialogBox();
  const onClick = () => setConfirmationDialogBox(
    true,
    confirmationDialog,
    deleteContractAttachment,
  );

  return (
    <>
      {confirmationDialogBox}
      <Row className="attached-file align-items-center">
        <Col>
          {attachment.originalFilename}
        </Col>
        <Col>
          <Row xs="auto">
            <Col>
              <DownloadButton
                blobDownloader={downloadContractBlob}
                params={attachment.id}
                filename={attachment.originalFilename}
                tooltipText={attachment.originalFilename}
                className=""
              />
            </Col>
            <Col>
              <Button
                className="ms-auto"
                onClick={onClick}
                title="Deletar arquivo"
                aria-label="Deletar arquivo"
              >
                {!isLoading && <FontAwesomeIcon icon={faXmarkCircle as IconProp} />}
                {isLoading && <Spinner className="spinner-border-sm" role="status" aria-hidden />}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
