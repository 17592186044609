import React, { useState } from 'react';
import { SpatialSubdivisionParam } from 'helper/flux/automatic';
import { downloadFaDailyZip } from 'services/fileDownloadApi';
import { fluxZipFile } from 'views/Flux/Automatic';
import { FluxAutomaticLayoutTabs } from 'redux/reducers/fluxAutomaticLayoutReducer';
import { MltOptions } from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { Button, Spinner, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import useFileDownloadApi from '../../useFileDownloadApi';
import ConfirmationDialogBox from '../../../../components/ConfirmationDialogBox';

export interface FluxAutomaticDOwnloadParams {
  review?: string
  models: string[]
  unidade?: MltOptions
  pastSimulationNames: string[]
  type: FluxAutomaticLayoutTabs
  spatialSubdivisionParam: SpatialSubdivisionParam
  forecastDate?: string
}

export const layoutCorrespondence = {
  daily: 'diaria',
  monthly: 'mensal',
  revision: 'revisao',
};

export default function useFluxAutomaticZipDownload({
  review,
  models,
  unidade,
  spatialSubdivisionParam,
  type,
  pastSimulationNames,
  forecastDate = 'latest',
}: FluxAutomaticDOwnloadParams) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isLoading,
    onClick,
  } = useFileDownloadApi({
    blobDownloader: downloadFaDailyZip,
    params: {
      models,
      review,
      unidade,
      version: 'latest',
      data_acomph: 'latest',
      data_previsao: forecastDate,
      spatialSubdivisionParam,
      type: layoutCorrespondence[type] as 'diaria' | 'mensal' | 'revisao',
    },
    filename: fluxZipFile(type),
  });

  const enableZipButton = models.length > 0
    && (type === 'revision' ? review !== undefined : true);

  const downloadZip = async () => {
    await onClick();
    setIsModalOpen(false);
  };

  function DownloadConfirmationDialog() {
    return (
      <ConfirmationDialogBox
        open={isModalOpen}
        dismiss={() => setIsModalOpen(false)}
        confirm={downloadZip}
      >
        <Stack>
          <p>
            Você quer mesmo baixar o arquivo
            {' '}
            <b>{fluxZipFile(type)}</b>
            ?
          </p>
          <p>
            Modelos selecionados:
            <b>
              {[...models, ...pastSimulationNames].join(', ')}
            </b>
          </p>
        </Stack>
      </ConfirmationDialogBox>
    );
  }

  function DownloadZipButton() {
    return (
      <Button
        className="numeric-forecast-download-button"
        onClick={() => setIsModalOpen(true)}
        disabled={isLoading || !enableZipButton}
      >
        {isLoading ? <Spinner /> : <FontAwesomeIcon icon={faDownload} />}
      </Button>
    );
  }

  return {
    DownloadZipButton,
    DownloadConfirmationDialog,
  };
}
