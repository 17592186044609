import React, { useState } from 'react';
import { PersonalizedBlockDraft, PersonalizedScenarioDraft } from 'helper/personalized';
import { Button, Spinner } from 'react-bootstrap';
import useSendStudy from 'utils/hooks/flux/personalized/useSendStudy';
import AlertsCollection from 'components/AlertsCollection';
import Scenario from './Scenario';
import './index.scss';

export default function NewPersonalizedStudy() {
  const [scenarios, setScenarios] = useState<Record<string, PersonalizedScenarioDraft>>({
    0: { name: '', blocks: [] },
  });
  const [scenarioCount, setScenarioCount] = useState(1);
  const {
    onSendClick,
    validated,
    sendLoading,
    alerts,
  } = useSendStudy(Object.values(scenarios));

  const setName = (key: string) => (name: string) => {
    setScenarios({ ...scenarios, [key]: { ...scenarios[key], name } });
  };

  const setBlocks = (key: string) => (blocks: PersonalizedBlockDraft[]) => {
    setScenarios({ ...scenarios, [key]: { ...scenarios[key], blocks } });
  };

  const removeScenario = (key: string) => () => {
    const newScenarios = { ...scenarios };
    delete newScenarios[key];
    const newLength = Object.keys(newScenarios).length;

    if (newLength === 0) {
      setScenarios({ [scenarioCount]: { name: '', blocks: [] } });
      setScenarioCount(scenarioCount + 1);
    } else {
      setScenarios(newScenarios);
    }
  };

  const newScenario = () => {
    setScenarios({ ...scenarios, [scenarioCount]: { name: '', blocks: [] } });
    setScenarioCount(scenarioCount + 1);
  };

  if (sendLoading) return <div className="text-center mt-3"><Spinner /></div>;

  return (
    <div className="scenarios-container">
      <AlertsCollection alerts={alerts} />
      {Object.keys(scenarios).map((key) => (
        <Scenario
          key={key}
          scenario={scenarios[key]}
          setName={setName(key)}
          setBlocks={setBlocks(key)}
          removeScenario={removeScenario(key)}
          validated={validated}
        />
      ))}
      <Button
        onClick={onSendClick}
      >
        Enviar estudo
      </Button>
      <Button
        onClick={newScenario}
        className="secondary-button mt-1"
      >
        Adicionar cenário
      </Button>
    </div>
  );
}
