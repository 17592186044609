import {
  DeleteVideoParams,
  DeleteVideoResponse,
  DownloadBulletinParams,
  DownloadBulletinResponse,
  GetLastVideosParams,
  GetLastVideosResponse,
  GetVideoByIdParams,
  GetVideoCategoriesParams,
  GetVideoCategoriesResponse,
  GetVideoDataByIdResponse,
  SaveVideoParams,
  SaveVideoResponse,
} from 'helper/videos';
import { store } from 'redux/store';
import filterUndefined from 'utils/filterUndefined';
import baseApi from './base';

export const meteorologyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getVideoCategories: build.query<GetVideoCategoriesResponse, GetVideoCategoriesParams>({
      query: ({ productPath }) => `produtos/${productPath}/options`,
    }),
    getLastVideos: build.query<GetLastVideosResponse, GetLastVideosParams>({
      query: ({
        page, pageSize, category, searchTerm, uploadDate, productPath, permissionType,
      }) => {
        const productKey = store.getState().productKeys[permissionType];

        return {
          url: `produtos/${productPath}/get-last/?product_key=${productKey}`,
          method: 'POST',
          body: filterUndefined({
            categoria: category,
            page,
            page_size: pageSize,
            search_term: searchTerm,
            dt_upload: uploadDate,
          }),
        };
      },
      providesTags: ['Videos'],
    }),
    getVideoDataById: build.query<GetVideoDataByIdResponse, GetVideoByIdParams>({
      query: ({ id, productPath }) => ({
        url: `produtos/${productPath}/get-by-id`,
        method: 'POST',
        body: { id },
      }),
    }),
    saveVideo: build.mutation<SaveVideoResponse, SaveVideoParams>({
      query: ({
        id, tags, videoId, videoTitle, videoDescription,
        category, dtUpload, fileName, base64file, productPath,
      }) => ({
        url: `produtos/${productPath}/save-video`,
        method: 'POST',
        body: filterUndefined({
          id: id || 0,
          video_id: videoId || 0,
          tags,
          video_title: videoTitle,
          video_description: videoDescription,
          categoria: category,
          dt_upload: dtUpload,
          file: { file_name: fileName, file_base64: base64file },
        }),
      }),
      invalidatesTags: ['Videos'],
    }),
    deleteVideo: build.mutation<DeleteVideoResponse, DeleteVideoParams>({
      query: ({ id, productPath, permissionType }) => {
        const productKey = store.getState().productKeys[permissionType];

        return {
          url: `produtos/${productPath}/delete-video/?product_key=${productKey}`,
          method: 'DELETE',
          body: { id },
        };
      },
      invalidatesTags: ['Videos'],
    }),
    downloadBulletin: build.query<DownloadBulletinResponse, DownloadBulletinParams>({
      query: ({ id, productPath }) => ({
        url: `produtos/${productPath}/download-boletim`,
        method: 'POST',
        body: { id },
      }),
    }),
  }),
});

export const {
  useGetVideoCategoriesQuery,
  useGetLastVideosQuery,
  useLazyGetLastVideosQuery,
  useGetVideoDataByIdQuery,
  useLazyGetVideoDataByIdQuery,
  useSaveVideoMutation,
  useDeleteVideoMutation,
  useLazyDownloadBulletinQuery,
} = meteorologyApi;
