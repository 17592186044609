import React from 'react';

import {
  Row, Col, Accordion,
} from 'react-bootstrap';

import { ListedCompanyContract } from 'helper/companies';
import ProductShortList from 'components/Admin/Products/ShortList';
import contractDate from 'utils/contractDate';
import Actions from 'components/Admin/Companies/Table/Row/Actions';
import Indicator from 'components/Admin/EnablementIndicator';
import useRequestToggle from 'utils/hooks/companies/useRequestToggle';
import useToggle from 'utils/hooks/useToggle';
import useDetails from 'utils/hooks/companies/useDetails';

export default function CompanyRow({ company, setConfirmationDialogBox, onEditClick }: {
  company: ListedCompanyContract,
  setConfirmationDialogBox: (open: boolean, body: JSX.Element, confirm: () => void) => void,
  onEditClick: (id: number) => () => void,
}) {
  const key = company.contrato_id || company.empresa_id;
  const { requestCompanyActivityToggle } = useRequestToggle({ companyId: company.empresa_id });
  const { toggled, callback } = useToggle(company.active, requestCompanyActivityToggle);
  const { details, load } = useDetails();

  return (
    <div key={key}>
      <Accordion className="admin-table-row" flush onSelect={() => { load(company); }}>
        <Accordion.Item eventKey={key.toString()}>
          <Accordion.Header>
            <Row className="w-100 align-items-center">
              <Col md="2">{company.razao_social}</Col>
              <Col md="2">{contractDate(company.dt_inicio)}</Col>
              <Col md="2">{contractDate(company.dt_fim)}</Col>
              <Col md="3"><ProductShortList products={company.products} /></Col>
              <Col md="1"><Indicator active={toggled} /></Col>
              <Col md="2">
                <Actions
                  company={company}
                  setConfirmationDialogBox={setConfirmationDialogBox}
                  toggled={toggled}
                  callback={callback}
                  onEditClick={onEditClick}
                />
              </Col>
            </Row>
          </Accordion.Header>
          <Accordion.Body>
            {details}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr className="admin-table-hr" />
    </div>
  );
}
