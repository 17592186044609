import { useState } from 'react';

export default function useViewToggler() {
  const [showGraph, setShowGraph] = useState(true);

  const toggleGraph = () => {
    setShowGraph(true);
  };

  const toggleTable = () => {
    setShowGraph(false);
  };

  const showTable = !showGraph;

  return {
    showGraph,
    showTable,
    toggleGraph,
    toggleTable,
  };
}
