import React from 'react';
import { Form, Spinner } from 'react-bootstrap';

import { useGetStatusOptionsQuery } from 'services/api/contracts';

export default function Status({ defaultValue }: { defaultValue: number | undefined }) {
  const { data, isLoading } = useGetStatusOptionsQuery();

  return (
    <Form.Group>
      <Form.Label>Status</Form.Label>
      {isLoading && <div className="text-center"><Spinner size="sm" /></div>}
      {!isLoading && (
        <div className="d-flex justify-content-between">
          {data?.map((option) => (
            <Form.Check
              key={option.id}
              id={`status-option-${option.id}`}
              type="radio"
              name="status"
              value={option.id}
              label={option.label}
              defaultChecked={option.id === defaultValue}
              required
              inline
            />
          ))}
        </div>
      )}
    </Form.Group>
  );
}
