import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InfiniteScrollState {
  isBottom: boolean;
}

const initialState: InfiniteScrollState = {
  isBottom: false,
};

export const infiniteScrollSlice = createSlice({
  name: 'infiniteScroll',
  initialState,
  reducers: {
    setInfiniteScrollBottomState: (state, action: PayloadAction<boolean>) => {
      state.isBottom = action.payload;
    },
  },
});

export const {
  setInfiniteScrollBottomState,
} = infiniteScrollSlice.actions;

export default infiniteScrollSlice.reducer;
