import { GenericResponse } from 'services/api/base';

export const RUNTIME_KEY = 'runtimes';
export const MEMBER_KEY = 'membros';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const IMAGE_DATE_REGEX = /(\d{4}-\d{2}-\d{2})T(\d{2})/;

export enum DisplayMode {
  Gif = 'gif',
  Images = 'images',
}

export interface Chart {
  id: number;
  campo: string;
  descricao: string;
}
export interface RawRuntime {
  [key: string]: string;
}
export interface RawMember {
  [key: string]: string;
}
export type RawOptions = {
  runtimes: RawRuntime[];
  membros: RawMember[];
} & {
  [key: string]: Chart[];
};
export type RawGetOptionsResponse = GenericResponse<RawOptions>;

export interface Monitoring {
  label: string;
  charts: Chart[];
}
export interface Runtime {
  id: string;
  label: string;
}
export interface Member {
  id: string;
  label: string;
}
export interface Options {
  monitoringOptions: Monitoring[];
  runtimes: Runtime[];
  members: Member[];
}

export type GetAvailableDatesResponse = GenericResponse<number[]>;

export interface GetGifsParams {
  dateString: string;
  runtimeId: string;
  chartId: number;
}
export interface GifData {
  p1: string;
  p2: string;
  p3: string;
}
export type GetGifsResponse = GenericResponse<GifData>;
export type Gifs = string[];

export interface GetImagesParams {
  dateString: string;
  runtimeId: string;
  chartId: number;
}
export interface ImageData {
  [date: string]: string;
}
export type GetImagesResponse = GenericResponse<ImageData>;
export interface Image {
  date: string;
  base64: string;
}
export type Images = Image[];

export const transformGetOptionsResponse = (res: RawGetOptionsResponse): Options => {
  const monitoringOptions = Object
    .entries(res.data)
    .filter(([label]) => label !== RUNTIME_KEY && label !== MEMBER_KEY)
    .map(([label, charts]) => ({ label, charts })) as Monitoring[];
  const runtimes = res
    .data[RUNTIME_KEY]
    .map((e) => ({ id: Object.keys(e)[0], label: Object.values(e)[0] }));
  const members = res
    .data[MEMBER_KEY]
    .map((e) => ({ id: Object.keys(e)[0], label: Object.values(e)[0] }));

  return { monitoringOptions, runtimes, members };
};

export const transformGetGifsResponse = (res: GetGifsResponse): Gifs => (
  Object
    .entries(res.data)
    .sort(([labelA], [labelB]) => labelA.localeCompare(labelB))
    .map(([, base64]) => base64)
);

export const transformGetImagesResponse = (res: GetImagesResponse): Images => (
  Object
    .entries(res.data)
    .sort(([dateStringA], [dateStringB]) => dateStringA.localeCompare(dateStringB))
    .map(([date, base64]) => ({ date, base64 }))
);
