import React from 'react';
import { Form } from 'react-bootstrap';
import { MailTemplateOptionType } from 'helper/mailer';

export default function SubtypeSelect({
  subtypes,
  subtypeId,
  setSubtypeId,
  isValid,
}: {
  subtypes: MailTemplateOptionType[],
  subtypeId: number,
  setSubtypeId: (value: React.SetStateAction<number>) => void,
  isValid: boolean,
}) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const valueAsInt = parseInt(value, 10);
    setSubtypeId(valueAsInt);
  };

  return (
    <Form.Select
      id="subtype-select"
      value={subtypeId}
      onChange={handleChange}
      className="rounded-0"
      disabled={subtypes.length < 1}
      isValid={isValid}
    >
      <option disabled value={-1}>Subtipo</option>
      {subtypes.map((subtype) => (
        <option value={subtype.id} key={subtype.id}>{subtype.label}</option>
      ))}
    </Form.Select>
  );
}
