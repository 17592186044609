import React from 'react';
import { Contract } from 'helper/contracts';
import './index.scss';
import AttachmentRow from './attachment';

export default function ContractAttachments({ contract }: { contract: Contract | undefined }) {
  if (contract?.attachments?.length) {
    return (
      <div className="ps-2 mt-4">
        {contract.attachments.map((attachment) => (
          <AttachmentRow attachment={attachment} key={attachment.id} />
        ))}
      </div>
    );
  }

  return null;
}
