import { CustomAlert } from 'components/AlertsCollection';
import { handleApiError } from 'helper/error';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useUserPasswordResetMutation } from 'services/api/auth';
import { ResponseError } from 'services/api/base';

interface PasswordResetParams {
  resetToken: string,
  password: string,
  confirm: string,
}

export default function usePasswordReset({
  resetToken, password, confirm,
}: PasswordResetParams): {
    alerts: CustomAlert[],
    passwordResethook: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  } {
  const navigate = useNavigate();
  const [passwordReset] = useUserPasswordResetMutation();
  const [alerts, setAlerts] = useState<CustomAlert[]>([]);

  return {
    alerts,
    passwordResethook: async (event: React.FormEvent<HTMLFormElement>) => {
      // Prevent the browser from reloading the page
      event.preventDefault();

      const passwordResetResult = await passwordReset({ resetToken, password, confirm });

      if ('data' in passwordResetResult) {
        const { title, message } = passwordResetResult.data;
        if (passwordResetResult.data.status) {
          navigate('/signin', {
            state: {
              alerts: [
                { title, message, variant: 'success' },
              ],
            },
          });
        } else {
          setAlerts([{ title, message, variant: 'danger' }]);
        }
      } else if ('error' in passwordResetResult) {
        handleApiError(navigate, passwordResetResult.error as ResponseError);
      }
    },
  };
}
