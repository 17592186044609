import React from 'react';
import { ListedUser } from 'helper/users';
import Toggle from 'components/Toggle';
import EditUserButton from './EditUserButton';
import ResetPasswordButton from './ResetPasswordButton';
import SendTokenButton from './SendTokenButton';
import { confirmationDialog } from './utils';

export default function UserActions({
  user, setConfirmationDialogBox, toggled, changeUserActivity,
}: {
  user: ListedUser,
  setConfirmationDialogBox: (open: boolean, body: JSX.Element, confirm: () => void) => void,
  toggled: boolean,
  changeUserActivity: () => void,
}) {
  return (
    <>
      <span className="me-1">
        <Toggle
          toggled={toggled}
          callback={(userActive: boolean) => {
            setConfirmationDialogBox(true, confirmationDialog(userActive), changeUserActivity);
          }}
        />
      </span>
      <SendTokenButton userId={user.id} />
      <ResetPasswordButton userId={user.id} username={user.username} />
      <EditUserButton userId={user.id} />
    </>
  );
}
