import React from 'react';
import {
  Accordion, Col, Container, Form, Row,
} from 'react-bootstrap';

export default function Model(
  { onChange, models }: { onChange: (string) => void, models: string[] },
) {
  return (
    <Accordion.Item eventKey="1" className="control-panel-item">
      <Accordion.Header>Modelo</Accordion.Header>
      <Accordion.Body>
        <Container>
          {models.map((model) => (
            <Row key={model}>
              <Col key={model}>
                <Form.Check
                  name="model"
                  type="radio"
                  label={model}
                  onChange={onChange}
                  value={model}
                />
              </Col>
            </Row>
          ))}
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
