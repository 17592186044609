import { ResponseError } from 'services/api/base';
import { useGetOptionsQuery } from 'services/api/Meteorology/synopticCharts';
import useHandleRequestErrors from '../useHandleRequestErrors';

export default function useGetOptions() {
  const { data, error, isLoading } = useGetOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  return {
    monitoringOptions: data?.monitoringOptions,
    runtimes: data?.runtimes,
    members: data?.members,
    isLoading,
  };
}
