import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert, Col, Modal, Row,
} from 'react-bootstrap';
import { selectFluxAutomaticControlPanelMltOption, selectFluxAutomaticControlPanelSpatialSubdivision } from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { RevisionDataPerScenario, buildSubdivisionParam } from 'helper/flux/automatic';
import useFluxAutomaticZipDownload from 'utils/hooks/flux/automatic/useFluxAutomaticZipDownload';
import './index.scss';
import FluxAutomaticGeneralTable from '../../GeneralTable';

export interface RevisionModal {
  isOpen: boolean
  modelName: string
  period: string
  onClose(): void
  revision: string | undefined
  errorMessage: string | undefined
  data: RevisionDataPerScenario | undefined,
}

export default function RevisionSubTableModal({
  isOpen, modelName, period, onClose, data, errorMessage, revision,
}: RevisionModal) {
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  const subdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const spatialSubdivisionParam = useMemo(() => buildSubdivisionParam(subdivision), [subdivision]);

  const { DownloadZipButton, DownloadConfirmationDialog } = useFluxAutomaticZipDownload({
    models: [modelName],
    review: revision,
    unidade,
    forecastDate: period,
    type: 'revision',
    pastSimulationNames: [],
    spatialSubdivisionParam,
  });

  const emptyData = {
    colLabels: [],
    rowLabels: [],
    values: [],
  };
  const fallbackObject = {
    bacias: emptyData,
    postos: emptyData,
    sistema: emptyData,
    subsistemas: emptyData,
    reservatorios: emptyData,
  };
  const selectedModelData = data !== undefined && modelName in data
    ? data[modelName]
    : fallbackObject;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      dialogClassName="default-modal modal-dialog-scrollable flux-sub-table"
    >
      <Modal.Header>
        <Modal.Title>
          <Row>
            <Col className="header">
              <span className="model-name">{modelName}</span>
              <span className="revision">{revision}</span>
              <span className="period">{period}</span>
            </Col>
            <Col md={1}>
              <DownloadZipButton />
              <DownloadConfirmationDialog />
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { errorMessage
          ? (
            <Alert variant="light">
              {errorMessage}
            </Alert>
          )
          : Object.keys(selectedModelData).map((system) => (
            <FluxAutomaticGeneralTable
              data={selectedModelData[system]}
              lastColAsPercent
              tableLabel={system}
              key={system}
            />
          ))}
      </Modal.Body>
    </Modal>
  );
}
