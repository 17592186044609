import { useEffect, useMemo, useState } from 'react';
import {
  DailyData,
  MonthlyData,
  buildSubdivisionParam,
} from 'helper/flux/automatic';
import { useLazyGetMonthlyDataQuery } from 'services/api/products/flux';
import { ResponseError } from 'services/api/base';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
  selectFluxAutomaticControlPanelMltOption,
  selectFluxAutomaticControlPanelPastSimulations,
  selectFluxAutomaticControlPanelSpatialSubdivision,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { useHandleRequestErrors } from '../../index';
import useAcomphMLT from './useAcomphMLT';

export default function useMonthlyData() {
  const [monthlyData, setMonthlyData] = useState<MonthlyData | undefined>(undefined);
  const [trigger, { error }] = useLazyGetMonthlyDataQuery();
  const [isFetching, setIsFetching] = useState(false);
  const spatialSubdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const { acomph, mlt } = useAcomphMLT();
  const models = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const pastSimulations = useSelector(selectFluxAutomaticControlPanelPastSimulations);
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  useHandleRequestErrors(error as ResponseError);

  const triggerParameters = useMemo(() => ({
    data_acomph: 'latest',
    data_previsao: 'latest',
    versao: 'latest',
    lista_cenarios: models,
    incluir_acomph: acomph,
    incluir_mlt: mlt,
    pastSimulations,
    unidade,
  }), [acomph, mlt, models, pastSimulations, unidade]);

  useEffect(() => {
    if (spatialSubdivision !== undefined && models.length > 0) {
      const spatialSubdivisionParam = buildSubdivisionParam(spatialSubdivision);

      (async () => {
        setIsFetching(true);
        const res = await trigger({
          spatialSubdivisionParam,
          ...triggerParameters,
        });
        setMonthlyData(res?.data?.data);
        setIsFetching(false);
      })();
    } else {
      setMonthlyData(undefined);
      setIsFetching(false);
    }
  }, [models, spatialSubdivision, trigger, triggerParameters]);

  const graphicReadyData = useMemo(() => {
    if (monthlyData === undefined) return {};
    const graphicData = {} as DailyData;

    monthlyData.rowLabels.forEach((rowName, rowIndex) => {
      const rowValues = monthlyData.values[rowIndex];
      graphicData[rowName] = monthlyData.colLabels.map(
        (column, colIndex) => [column, rowValues[colIndex]],
      );
    });

    return graphicData;
  }, [monthlyData]);

  return {
    data: monthlyData, isFetching, graphicReadyData,
  };
}
