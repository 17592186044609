import { useState, useEffect } from 'react';

// We receive times in YYYY-M/YYYY-MM format, we need to convert them to YYYY-MM-DD.
export default function useParseHistoryCategories(categoryData: string[]) {
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    const newCategories: string[] = [];
    categoryData.forEach((data) => {
      const parsing = data.split('-');
      if (parsing[1].length === 1) {
        parsing[1] = `0${parsing[1]}`;
      }
      parsing.push('01');
      newCategories.push(parsing.join('-'));
    });
    setCategories([...newCategories]);
  }, [categoryData]);

  return { categories };
}
