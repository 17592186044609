import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { NavigateFunction } from 'react-router';
import { CustomAlert } from 'components/AlertsCollection';
import resetMeteorologicFieldsPanels from 'helper/resetMeteorologicFieldsPanels';
import { handleApiError } from '../../helper/error';
import { setAuthState } from '../../redux/reducers/authReducer';
import { store } from '../../redux/store';
import { LoginParams, LoginResponse } from '../../services/api/auth';
import { ResponseError } from '../../services/api/base';

interface AlertsLocationState {
  alerts?: CustomAlert[],
}

export const processLogin = async (
  event: React.FormEvent<HTMLFormElement>,
  login: MutationTrigger<MutationDefinition<LoginParams, BaseQueryFn<unknown>, never, LoginResponse, 'api'>>,
  username: string,
  password: string,
  navigate: NavigateFunction,
  setValidated: React.Dispatch<React.SetStateAction<boolean>>,
  setAlerts: React.Dispatch<React.SetStateAction<CustomAlert[]>>,
) => {
  // Prevent the browser from reloading the page
  event.preventDefault();

  const alerts: CustomAlert[] = [];
  const form = event.target as HTMLFormElement;

  if (!form.checkValidity()) {
    setValidated(true);
    return;
  }

  const loginResult = await login({ username, password });

  if ('data' in loginResult) {
    if (loginResult.data.status) {
      const { data } = loginResult.data;
      store.dispatch(setAuthState({
        accessToken: data.access_token,
        jwt: data.jwt,
        refreshToken: data.refresh_token,
        admin: data.admin,
        timeout: data.timeout,
      }));
      resetMeteorologicFieldsPanels();
      navigate('/home');
    } else {
      alerts.push({
        title: loginResult.data.title,
        message: loginResult.data.message,
        variant: 'danger',
      });
      setAlerts(alerts);
      setValidated(false);
    }
  } else {
    handleApiError(navigate, loginResult.error as ResponseError);
  }
};

export function initialAlerts(locationState: AlertsLocationState): CustomAlert[] {
  if (locationState && 'alerts' in locationState) {
    return locationState.alerts as CustomAlert[];
  }
  return [];
}
