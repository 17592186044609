import { ResponseError } from 'services/api/base';
import { useListWarningsQuery } from 'services/api/home';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useListHomeWarnings() {
  const { data, error, refetch } = useListWarningsQuery();

  useHandleRequestErrors(error as ResponseError | undefined);

  const warnings = data?.data ?? [];
  const timestamps = warnings.map((warning) => warning.timestamp);
  const latest = Math.max(...timestamps);
  const hourDifference = ((Date.now() / 1000) - latest) / 3600;
  const showNotification = hourDifference <= 18;

  return {
    warnings,
    refetch,
    showNotification,
  };
}
