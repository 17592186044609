import { DisplayData, DisplayImage, NumericForecastImage } from 'helper/products/meteorology';

export default function buildMultiMapImageDisplayData(images: NumericForecastImage[]): DisplayData {
  const data: DisplayData = {
    columnLabels: [],
    rowLabels: [],
    imageSets: [],
  };

  const allModels = images.map(({ model }) => model);
  const uniqueModels = new Set(allModels);
  const isSingleModel = uniqueModels.size === 1;
  const allPeriods = images.map(({ period }) => period);
  const uniquePeriods = new Set(allPeriods);
  const columnLabels: string[] = [];
  const rowLabels: string[] = [];

  if (isSingleModel) {
    rowLabels.push(allModels[0]);
    uniquePeriods.forEach((v) => { columnLabels.push(v); });
  } else {
    uniquePeriods.forEach((v) => { rowLabels.push(v); });
    uniqueModels.forEach((v) => { columnLabels.push(v); });
  }

  data.rowLabels = rowLabels.sort();
  data.columnLabels = columnLabels.sort();
  const imageSets: DisplayImage[][] = [];
  rowLabels.forEach((row) => {
    imageSets.push([]);
    columnLabels.forEach((col) => {
      const lastSet = imageSets[imageSets.length - 1];
      const model = isSingleModel ? row : col;
      const period = isSingleModel ? col : row;
      const image = images.find((img) => img.period === period && img.model === model);
      lastSet.push({
        key: `${row}-${col}`,
        title: '',
        base64: image?.base64 || '',
      });
    });
  });
  data.imageSets = imageSets;

  return data;
}
