import React from 'react';
import './index.scss';

export default function InvoiceEmailList({ emails }: {
  emails: string[]
}) {
  if (emails.length === 0) {
    return (
      <div className="email-list undefined-email">
        indefinido
      </div>
    );
  }

  return (
    <>
      {emails.map((email) => (
        <div className="email-list listed-email" key={email}>
          {email}
        </div>
      ))}
    </>
  );
}
