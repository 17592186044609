import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { selectAuthToken } from 'redux/reducers/authReducer';
import { store } from 'redux/store';
import { ResponseError } from 'services/api/base';

export const ampereFileApi = (): AxiosInstance => axios.create({
  baseURL: process.env.REACT_APP_AMPERE_API_URL,
  headers: {
    Authorization: selectAuthToken(store.getState()),
  },
  responseType: 'blob',
});

export const prepareResponse = async (response: AxiosResponse): Promise<Blob | ResponseError> => {
  const blob = new Blob([response.data]);

  try {
    const parsedData = JSON.parse(await blob.text());

    if (parsedData.code && parsedData.code !== 200) {
      return { status: parsedData.code, data: parsedData.message };
    }

    return blob;
  } catch (e) {
    return blob;
  }
};
