import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import useAutomaticOptions from 'utils/hooks/flux/useAutomaticOptions';
import useSaveFluxAutomaticPreference from 'utils/hooks/flux/automatic/useSaveFluxAutomaticPreference';

export default function AutomaticControlPanelActions() {
  const { clearStudyOptions } = useAutomaticOptions();
  const { setShowFormModal, saveIsEnabled } = useSaveFluxAutomaticPreference();

  return (
    <Stack className="pt-5">
      <div className="mx-auto mb-2">
        <Button
          disabled={!saveIsEnabled}
          onClick={() => setShowFormModal(true)}
        >
          Salvar nova preferência
        </Button>
      </div>
      <div className="mx-auto">
        <Button
          variant="link"
          className="automatic-page-clear-filter-button"
          onClick={clearStudyOptions}
        >
          Limpar filtros
        </Button>
      </div>
    </Stack>
  );
}
