import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImagesRowSize {
  rowSize: number,
  maxRowSize: number,
}

const initialState: ImagesRowSize = {
  rowSize: 5,
  maxRowSize: 5,
};

export const imagesRowSizeSlice = createSlice({
  name: 'imagesRowSize',
  initialState,
  reducers: {
    setRowSize: (state, action: PayloadAction<number>) => {
      state.rowSize = action.payload;
    },
    setMaxRowSize: (state, action: PayloadAction<number>) => {
      state.maxRowSize = action.payload;
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectRowSize = (state: any) => state.imagesRowSize.rowSize;
export const selectMaxRowSize = (state: any) => state.imagesRowSize.maxRowSize;

export const {
  setRowSize,
  setMaxRowSize,
} = imagesRowSizeSlice.actions;

export default imagesRowSizeSlice.reducer;
