import React, { useEffect, useState } from 'react';
import {
  Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { handleApiError } from 'helper/error';
import {
  useListCompaniesContractsQuery,
  SortableField,
  SORTABLE_FIELDS,
  SORT_BY_NAME,
  SORT_BY_CONTRACT_START,
  SORT_BY_CONTRACT_END,
  CompanyFilterParams,
} from 'services/api/companies';
import { ResponseError } from 'services/api/base';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import useConfirmationDialogBox from 'utils/useConfirmationDialogBox';
import useListEnd from 'utils/hooks/useListEnd';
import SortingArrow, { useSorting } from 'components/SortingArrow';
import EmptyListAlert from 'components/EmptyListAlert';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import { useRefreshCompaniesOnSearch } from './utils';
import CompanyRow from './Row';
import 'components/Admin/table.scss';

interface CompaniesTableProps {
  searchQuery: string,
  filters: CompanyFilterParams,
  onEditClick: (id: number, contract_id: number) => () => void,
}

export default function CompaniesTable({ searchQuery, filters, onEditClick }: CompaniesTableProps) {
  const [maxLength, setMaxLength] = useState<number>(-1);
  const pageSize = 12;
  const { page, setPage } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<SortableField | undefined>(SORTABLE_FIELDS);
  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useListCompaniesContractsQuery({
    page: 1,
    pageSize: dataLength,
    nameContains: searchQuery,
    orderBy: sortBy,
    orderDirection: sortDirection,
    ...filters,
  });
  const navigate = useNavigate();
  const { confirmationDialogBox, setConfirmationDialogBox } = useConfirmationDialogBox();
  const { listEnded } = useListEnd(data, page, pageSize, isFetching);

  useEffect(() => {
    if (error) {
      handleApiError(navigate, error as ResponseError);
    }
  });

  useEffect(() => {
    refetch();
    setMaxLength(-1);
  }, [filters, refetch, searchQuery]);

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, data)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, data, isFetching]);

  const { isFiltering } = useRefreshCompaniesOnSearch({
    query: searchQuery,
    sortBy,
    sortDirection,
    ...filters,
  }, setPage, isFetching);

  if (isLoading || isFiltering) {
    return <Spinner className="mt-5 mx-auto" animation="border" />;
  }

  return (
    <>
      {confirmationDialogBox}
      <div key="CompaniesTable" className="admin-table-main">
        <Row className="fw-bold admin-table-header" key="tableHeader">
          <Col key="tableHeaderForNames" md="2">
            <Stack direction="horizontal">
              RAZÃO SOCIAL
              <SortingArrow
                field={SORT_BY_NAME}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sorters.nome}
              />
            </Stack>
          </Col>
          <Col key="tableHeaderForEmails" md="2">
            <Stack direction="horizontal">
              INÍCIO CONTRATO
              <SortingArrow
                field={SORT_BY_CONTRACT_START}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sorters.contract_start}
              />
            </Stack>
          </Col>
          <Col key="tableHeaderForCompanies" md="2">
            <Stack direction="horizontal">
              FIM CONTRATO
              <SortingArrow
                field={SORT_BY_CONTRACT_END}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={sorters.contract_end}
              />
            </Stack>
          </Col>
          <Col md="3">PRODUTOS</Col>
          <Col md="1" />
          <Col md="2">AÇÕES</Col>
        </Row>
        <EmptyListAlert
          component={CompanyRow}
          array={data?.map((company) => ({
            company,
            key: (company.contrato_id || company.empresa_id),
            setConfirmationDialogBox,
            onEditClick,
          }))}
          message="Não foram encontrados resultados para os filtros selecionados"
          isFetching={isFetching}
          listEnded={listEnded}
        />
      </div>
    </>
  );
}
