import React from 'react';
import { Spinner } from 'react-bootstrap';

import useFileList from 'utils/hooks/file_viewer/useFileList';
import useFilePath from 'utils/hooks/file_viewer/useFilePath';
import Breadcrums from './Breadcrums';
import Actions from './Actions';
import Entry from './Entry';

import './index.scss';

export default function FileList() {
  const { data, isFetching } = useFileList();
  const {
    path,
    entries,
    selectDir,
    goBack,
  } = useFilePath(data);

  return (
    <div className="h-100 d-flex flex-column overflow-hidden">
      <div className="file-list-header d-flex justify-content-between">
        <Breadcrums path={path} goBack={goBack} />
        {path.length > 0 && <Actions path={path} entries={entries} />}
      </div>
      {isFetching && <div className="mt-4 text-center"><Spinner /></div>}
      {!isFetching && !data && <div className="mt-4 text-center">Sem dados.</div>}
      {!isFetching && data && entries.length === 0 && (
        <div className="mt-4 text-center">Nenhum arquivo encontrado.</div>
      )}
      {!isFetching && data && entries.length > 0 && (
        <div className="file-list-body flex-shrink-1">
          <div>
            {entries.map((entry) => (
              <Entry key={entry.id} entry={entry} selectDir={selectDir} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
