import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import useReadFinancialFile from 'utils/hooks/financial/useReadFinancialFile';
import { Invoice } from 'helper/financial';
import LoadingSpinner from 'components/LoadingSpinner';

export default function Upload({ setInvoices }: {
  setInvoices: (invoices: Invoice[]) => void,
}) {
  const {
    callback: read, isLoading: readIsLoading,
  } = useReadFinancialFile({ setInvoices });

  return (
    <Form>
      <Row>
        <Col className="md-6">
          <Form.Group>
            <Form.Label>Selecione o arquivo</Form.Label>
            <Form.Control type="file" onChange={read} disabled={readIsLoading} />
          </Form.Group>
        </Col>
        <Col className="md-6 mt-auto">
          <LoadingSpinner isLoading={readIsLoading} />
        </Col>
      </Row>
    </Form>
  );
}
