import React, { useEffect, useMemo, useState } from 'react';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/fluxAutomaticLayoutReducer';
import {
  Alert, Col, Row, Spinner,
} from 'react-bootstrap';
import FilterSelector from 'components/Flux/Automatic/FilterSelector';
import MLTSelector from 'components/Flux/Automatic/MLTSelector';
import ActiveFilters from 'components/Flux/Automatic/ActiveFilters';
import RevisionSubTableModal from 'components/Flux/Revision/SubTableModal';
import RevisionTable from 'components/Flux/Revision/Table';
import usePastSimulationNames from 'utils/hooks/flux/automatic/usePastSimulationNames';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelMltOption,
  selectFluxAutomaticControlPanelSelectedRevision,
  selectFluxAutomaticControlPanelSpatialSubdivision,
  selectFluxAutomaticControlPanelAutomaticStudyScenarios,
  possibleMltOptions,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { buildSubdivisionParam } from 'helper/flux/automatic';
import useFluxAutomaticZipDownload from 'utils/hooks/flux/automatic/useFluxAutomaticZipDownload';
import useRevisionData from 'utils/hooks/flux/automatic/useRevisionData';
import useRevisionScenarios from 'utils/hooks/flux/automatic/useRevisionScenarios';
import RevisionSelector from 'components/Flux/Automatic/RevisionSelector';
import useFluxAutomaticFilterSelector from 'utils/hooks/flux/automatic/filterSelector';
import './index.scss';

export default function Revision() {
  const { pastSimulationNames } = usePastSimulationNames();
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);
  const models = useSelector(selectFluxAutomaticControlPanelAutomaticStudyScenarios);
  const review = useSelector(selectFluxAutomaticControlPanelSelectedRevision);
  const subdivision = useSelector(selectFluxAutomaticControlPanelSpatialSubdivision);
  const [isOpen, setIsOpen] = useState(false);
  const [modelName, setModelName] = useState('');
  const [period, setPeriod] = useState('');
  const { revisionRows, isFetching } = useRevisionData();
  const { data, errorMessage } = useRevisionScenarios(modelName, period);
  const dataIsEmpty = revisionRows.length === 0;
  const { setMltOption } = useFluxAutomaticFilterSelector();

  useEffect(() => {
    store.dispatch(setCurrentTab('revision'));
    setMltOption(possibleMltOptions[0]); // %MLT
  });
  const popDataModal = (chosenModel: string, chosenPeriod: string) => {
    setIsOpen(true);
    setModelName(chosenModel);
    setPeriod(chosenPeriod);
  };
  const spatialSubdivisionParam = useMemo(() => buildSubdivisionParam(subdivision), [subdivision]);
  const { DownloadZipButton, DownloadConfirmationDialog } = useFluxAutomaticZipDownload({
    models,
    review,
    unidade,
    type: 'revision',
    pastSimulationNames,
    spatialSubdivisionParam,
  });

  return (
    <>
      <RevisionSubTableModal
        isOpen={isOpen}
        period={period}
        data={data?.data}
        revision={review}
        modelName={modelName}
        errorMessage={errorMessage}
        onClose={() => setIsOpen(false)}
      />
      <div className="overflow-auto mt-3 mx-2 vh-100" style={{ maxHeight: '650px' }}>
        <DownloadConfirmationDialog />
        <Row className="px-3 sticky-flux-review-controls">
          <Col xs={3}>
            <FilterSelector />
          </Col>
          <Col>
            <MLTSelector disabled />
          </Col>
          <Col>
            <RevisionSelector />
          </Col>
          <Col xs={1}>
            <DownloadZipButton />
          </Col>
        </Row>
        <Row className="py-2 sticky-flux-review-controls" style={{ zIndex: 2 }}>
          <Col className="ps-0"><ActiveFilters /></Col>
        </Row>
        <Row>
          { isFetching && <div className="text-center py-4"><Spinner /></div> }
          { !isFetching && dataIsEmpty && <Alert variant="light">Nenhum dado para os filtros selecionados.</Alert> }
          { !isFetching && !dataIsEmpty && <RevisionTable popDataModal={popDataModal} /> }
        </Row>
      </div>
    </>
  );
}
