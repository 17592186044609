import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from '../../helper/financial';

interface SendInvoiceModalState {
  invoices: Invoice[],
  show: boolean,
}

const initialState: SendInvoiceModalState = {
  invoices: [],
  show: false,
};

export const sendInvoiceModalSlice = createSlice({
  name: 'sendInvoiceModal',
  initialState,
  reducers: {
    setInvoices: (state, action: PayloadAction<Invoice[]>) => {
      state.invoices = action.payload;
    },
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectSendInvoiceModalInvoices = (state: any) => state.sendInvoiceModal.invoices;
export const selectSendInvoiceModalShow = (state: any) => state.sendInvoiceModal.show;
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setInvoices,
  setShow,
} = sendInvoiceModalSlice.actions;

export default sendInvoiceModalSlice.reducer;
