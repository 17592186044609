import React, { useState } from 'react';

import { ListedUser } from 'helper/users';
import Details from 'components/Admin/Users/UserRow/Details';

interface UserDataElement {
  is_primary: boolean;
  email?: string;
  phone?: string;
}
export type UserDataKey = 'email' | 'phone';

export const useUserDetails = () => {
  const [details, setDetails] = useState(<div>Detalhes</div>);

  const load = (user: ListedUser) => {
    setDetails(<Details user={user} />);
  };

  return { details, load };
};

export function getPrimaryElements(elements: UserDataElement[], key: UserDataKey): string {
  const element = elements.find((el: UserDataElement) => el.is_primary);
  return element ? element[key] || 'Não informado' : 'Não informado';
}

export default useUserDetails;
