import * as Sentry from '@sentry/react';

import { store } from 'redux/store';
import {
  selectTokenTimeOut,
  selectTokenTimeStamp,
  selectTokenUserName,
  selectUserId,
  selectUserIsAdmin,
} from 'redux/reducers/authReducer';
import { ResponseError } from 'services/api/base';
import { addSeconds, format, fromUnixTime } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const PARAM_BLOCKLIST = ['product_key', 'password'];
const TIMESTAMP_FORMAT = 'P HH:mm:ss.SSS';

function getRequestData(error: ResponseError) {
  const method = error.meta?.method || '';
  const query = {};
  let url: string;
  let hostname: string;
  let resource: string;

  try {
    const parsedUrl = new URL(error.meta?.url as string);

    url = `${parsedUrl.origin}${parsedUrl.pathname}`;
    hostname = parsedUrl.hostname;
    resource = parsedUrl.pathname;

    parsedUrl.searchParams.forEach((value, key) => {
      if (!PARAM_BLOCKLIST.includes(key)) query[key] = value;
    });
  } catch (e) {
    url = '';
    hostname = '';
    resource = '';
  }

  return {
    url,
    hostname,
    resource,
    method,
    query,
  };
}

function parseData(data: any) {
  let title = 'NO DATA';
  let description = 'Description missing';

  if (data instanceof Object) {
    if (typeof data.description === 'string') {
      const parts = data
        .description
        .split(':')
        .map((l) => l.trim())
        .filter((l) => l);
      [title] = parts;
      description = parts.join(': ');
    } else {
      title = Object.keys(data).join(', ');
      description = Object
        .entries(data)
        .map(([k, v]) => `${k}: ${JSON.stringify(v)}`)
        .join('\n');
    }
  }

  if (typeof data === 'string') {
    const lines = data
      .split('\n')
      .map((l) => l.trim())
      .filter((l) => l);
    [title] = lines;
    description = lines.join('\n');
  }

  return { title, description };
}

function getUserData() {
  const state = store.getState();
  const id = selectUserId(state).toString();
  const username = selectTokenUserName(state);
  const userIsAdmin = selectUserIsAdmin(state);

  return { id, username, userIsAdmin };
}

function getAuthData() {
  const state = store.getState();

  const tokenTimestamp = selectTokenTimeStamp(state);
  const authenticatedAt = fromUnixTime(tokenTimestamp);
  const formattedAuthenticatedAt = format(authenticatedAt, TIMESTAMP_FORMAT, { locale: ptBR });

  const tokenTimout = selectTokenTimeOut(state);
  const expiresAt = addSeconds(authenticatedAt, tokenTimout);
  const formattedExpiredAt = format(expiresAt, TIMESTAMP_FORMAT, { locale: ptBR });

  return { formattedAuthenticatedAt, formattedExpiredAt };
}

function getArgs(error: ResponseError) {
  if (!error.meta) return undefined;

  if (typeof error.meta.args !== 'object') return error.meta.args;

  const args = { ...error.meta.args };
  PARAM_BLOCKLIST.forEach((k) => delete args[k]);

  return args;
}

export default function sendErrorToSentry(error: ResponseError) {
  const status = error.status.toString();
  const { title, description } = parseData(error.data);
  const fullTitle = `[ERROR PAGE][STATUS ${status}] ${title}`;
  const {
    url,
    hostname,
    resource,
    method,
    query,
  } = getRequestData(error);
  const { id, username, userIsAdmin } = getUserData();
  const { formattedAuthenticatedAt, formattedExpiredAt } = getAuthData();
  const args = getArgs(error);

  Sentry.withScope((scope) => {
    scope.setLevel('error');
    scope.setFingerprint([hostname, resource, status, title]);
    scope.setTransactionName(resource);
    scope.setUser({
      id,
      username,
      email: username,
      Admin: userIsAdmin ? '✅' : '❌',
    });
    scope.setContext('Response Details', {
      URL: hostname,
      Resource: resource,
      Method: method,
      Code: status,
      Description: description,
      Query: query,
      Arguments: args,
    });
    scope.setContext('Auth Details', {
      Authentication: formattedAuthenticatedAt,
      Expiration: formattedExpiredAt,
      Error: format(new Date(), TIMESTAMP_FORMAT, { locale: ptBR }),
    });
    scope.setTag('url', url);
    Sentry.captureMessage(fullTitle);
  });
}
