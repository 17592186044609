import React from 'react';
import PlusButton from 'components/PlusButton';
import MassEmailTable from '../Table';

export default function MassEmailListing({ onNewClick }: {
  onNewClick: () => void,
}) {
  return (
    <>
      <div className="p-3 mb-3">
        <PlusButton
          id="new-mass-email"
          label="Novo E-mail em massa"
          onClick={onNewClick}
        />
      </div>
      <MassEmailTable />
    </>
  );
}
