import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetOverviewQuery } from 'services/api/Meteorology/Weather/Overview';
import { Overview } from 'types/Meteorology/Weather/Overview';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

export default function useOverview(period: string): {
  overview: Overview | undefined, isLoading: boolean, isFetching: boolean
} {
  const [trigger, {
    data, error, isLoading, isFetching,
  }] = useLazyGetOverviewQuery();

  useEffect(() => {
    if (period !== '') trigger({ period });
  }, [period, trigger]);

  useHandleRequestErrors(error as ResponseError);

  return { overview: data as Overview, isLoading, isFetching };
}
