import { useSelector } from 'react-redux';
import { selectUserId } from 'redux/reducers/authReducer';
import { GetUserParams, useGetUserQuery } from 'services/api/users';

export default function useGetUser() {
  const userId = useSelector(selectUserId);
  const params: GetUserParams = { id: userId };

  const { data: userData } = useGetUserQuery(params);
  return userData;
}
