import { Overview } from 'types/Meteorology/Weather/Overview';
import { GetOverviewResponse } from 'types/Meteorology/Weather/Overview/getOverviewResponse';
import {
  GetLatestReportPathResponse,
} from 'types/Meteorology/Weather/Overview/getLatestReportPathResponse';
import { GetOverviewOptionsResponse, OverviewOption } from 'types/Meteorology/Weather/Overview/getOverviewOptionsResponse';

export const transformGetOverviewResponse = (response: GetOverviewResponse): Overview => ({
  summary: response.data?.descricao.data ?? '',
  imageBase64: response.data?.result,
});

export const transformGetLatestReportPathReponse = (
  response: GetLatestReportPathResponse,
): string => response.data;

export const transformGetOverviewOptionsResponse = (
  response: GetOverviewOptionsResponse,
): OverviewOption[] => (
  Object.entries(response.data?.periodos_overview).map(([key, value]) => (
    { value: key, label: value }
  ))
);
