import { useMemo } from 'react';
import { useListCompaniesQuery } from 'services/api/companies';

export default function useCompanySelect(): {
  companyOptions: { value: number, label: string }[] | undefined,
  companiesLoading: boolean,
} {
  const {
    data: companiesData,
    isLoading: companiesLoading,
  } = useListCompaniesQuery();

  const companyOptions = useMemo(
    () => companiesData?.map(
      (company) => ({ value: company.id, label: company.razao_social }),
    ),
    [companiesData],
  );

  return {
    companyOptions,
    companiesLoading,
  };
}
