import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectMeteorologyFieldsLayoutCurrentTab,
} from 'redux/reducers/meteorologyFieldsLayoutReducer';
import ObservedData from 'components/Meteorology/Fields/ControlPanel/ObservedData';
import NumericForecast from 'components/Meteorology/Fields/ControlPanel/NumericForecast';
import Comparator from 'components/Meteorology/Fields/ControlPanel/Comparator';
import './index.scss';

export default function ControlPanel() {
  const currentTab = useSelector(selectMeteorologyFieldsLayoutCurrentTab);

  return (
    <div>
      { currentTab === 'observeddata' && <ObservedData /> }
      { currentTab === 'numericforecast' && <NumericForecast /> }
      { currentTab === 'comparator' && <Comparator /> }
    </div>
  );
}
