import React, { useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { SendTokenResponse, UserParams, useSendTokenMutation } from 'services/api/users';
import { handleApiError } from 'helper/error';
import { ResponseError } from 'services/api/base';
import ActionButton from 'components/Admin/ActionButton';
import DialogBox from 'components/DialogBox';

export const onClick = async (
  sendToken: MutationTrigger<MutationDefinition<
  UserParams,
  BaseQueryFn<unknown>,
  never,
  SendTokenResponse,
  'api'
  >>,
  userId: number,
  navigate: NavigateFunction,
  setDialogOpen: (value: boolean) => void,
) => {
  const response = await sendToken({ user_id: userId });
  if ('error' in response) {
    handleApiError(navigate, response.error as ResponseError);
  } else {
    setDialogOpen(true);
  }
};

export default function SendTokenButton({ userId }: { userId: number }) {
  const [sendToken, { isLoading: loading }] = useSendTokenMutation();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div className="action-button-container">
      <DialogBox open={dialogOpen} dismiss={() => setDialogOpen(false)} type="success">
        <div>O token foi enviado com sucesso.</div>
      </DialogBox>

      <ActionButton
        loading={loading}
        onClick={() => onClick(sendToken, userId, navigate, setDialogOpen)}
        tooltipText="Enviar token por e-mail"
      >
        <FontAwesomeIcon
          id={`user-send-token-${userId}`}
          icon={faPaperPlane as IconProp}
          size="xs"
        />
      </ActionButton>
    </div>
  );
}
