import React from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { selectRowSize } from 'redux/reducers/imagesRowSizeReducer';

export default function ColumnLabels({
  labels,
}: {
  labels: string[],
}) {
  const rowSize = useSelector(selectRowSize);
  const width = `${96 / rowSize}%`;

  return (
    <div className="d-flex justify-content-between sticky-top">
      {labels.map((label) => (
        <div
          key={label}
          style={{ width }}
          className="flex-shrink-0 text-center pb-2 meteorology-fields-display-column-label"
        >
          {label}
        </div>
      ))}
    </div>
  );
}
