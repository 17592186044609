import filterUndefined from 'utils/filterUndefined';
import {
  ListedUser, transformUserListResponse, transformUserResponse, User,
  transformUserSaveErrorResponse,
} from 'helper/users';
import baseApi, { GenericResponse } from './base';

export interface GetUserParams {
  id: number
}

export interface UserParams {
  user_id: number
}

export type SortableField = 'nome' | 'empresa' | 'username';
export interface UserListParams {
  page: number,
  pageSize: number,
  nameContains?: string,
  emailContains?: string,
  companyNameContains?: string,
  productIds?: number[],
  companyIds?: number[],
  enabled?: boolean,
  hasProductsExpiringIn30Days?: boolean,
  hasProductsExpiringIn60Days?: boolean,
  orderBy?: SortableField,
  orderDirection?: 'ASC' | 'DESC'
}

export const SORT_BY_NAME = 'nome';
export const SORT_BY_EMAIL = 'username';
export const SORT_BY_COMPANY = 'empresa';
export const SORTABLE_FIELDS: (SortableField | undefined)[] = [
  SORT_BY_NAME,
  SORT_BY_EMAIL,
  SORT_BY_COMPANY,
];

export interface UserSavingParams extends Partial<User> {
  id_empresa: number;
}

export type EnableUserResponse = GenericResponse<null>;

export type DisableUserResponse = GenericResponse<null>;

export type UserSaveResponse = GenericResponse<User>;

export interface ResendActivationTokenResponse {
  status: number,
  code: number,
  type: number,
  title: string,
  message: string,
  data: null
}

export interface SendTokenResponse {
  status: number,
  code: number,
  type: number,
  title: string,
  message: string,
  data: null
}

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listUsers: build.query<Array<ListedUser>, UserListParams>({
      query: (queryParams: UserListParams) => ({
        url: 'user-list',
        method: 'POST',
        body: filterUndefined({
          page: queryParams.page,
          page_size: queryParams.pageSize,
          name_contains: queryParams.nameContains,
          email_contains: queryParams.emailContains,
          company_name_contains: queryParams.companyNameContains,
          product_ids: queryParams.productIds,
          company_ids: queryParams.companyIds,
          enabled: queryParams.enabled,
          has_products_expiring_in_30_days: queryParams.hasProductsExpiringIn30Days,
          has_products_expiring_in_60_days: queryParams.hasProductsExpiringIn60Days,
          order_by: queryParams.orderBy || 'nome',
          order_direction: queryParams.orderDirection,
        }),
      }),
      providesTags: ['User'],
      transformResponse: transformUserListResponse,
    }),
    getUser: build.query<User, GetUserParams>({
      query: ({ id }) => `/user-by-id/?user_id=${id}`,
      transformResponse: transformUserResponse,
      providesTags: ['User'],
    }),
    enableUser: build.mutation<EnableUserResponse, UserParams>({
      query: ({ user_id }) => ({
        url: 'user-enable',
        method: 'PUT',
        body: {
          user_id,
        },
      }),
      invalidatesTags: ['User'],
    }),
    disableUser: build.mutation<DisableUserResponse, UserParams>({
      query: ({ user_id }) => ({
        url: 'user-disable',
        method: 'PUT',
        body: {
          user_id,
        },
      }),
      invalidatesTags: ['User'],
    }),
    resendActivationToken: build.mutation<ResendActivationTokenResponse, UserParams>({
      query: ({ user_id }) => ({
        url: 'resend-activation-token',
        method: 'PUT',
        body: {
          user_id,
        },
      }),
    }),
    sendToken: build.mutation<SendTokenResponse, UserParams>({
      query: ({ user_id }) => ({
        url: 'user-send-token',
        method: 'POST',
        body: {
          user_id,
        },
      }),
    }),
    saveUser: build.mutation<UserSaveResponse, UserSavingParams>({
      query: (saveParams: UserSavingParams) => ({
        url: 'user-save',
        method: 'PUT',
        body: saveParams,
      }),
      transformErrorResponse: transformUserSaveErrorResponse,
      invalidatesTags: ['User'],
    }),
  }),

});

export const {
  useListUsersQuery,
  useGetUserQuery,
  useEnableUserMutation,
  useDisableUserMutation,
  useResendActivationTokenMutation,
  useSendTokenMutation,
  useSaveUserMutation,
} = usersApi;
