import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import useImageZoom from 'utils/hooks/meteorology/numericForecast/useImageZoom';
import './index.scss';

export default function ZoomButtons({
  imagesQuantity,
}: {
  imagesQuantity: number,
}) {
  const {
    minusDisabled,
    plusDisabled,
    onMinusClicked,
    onPlusClicked,
  } = useImageZoom(imagesQuantity);

  return (
    <div className="zoom-container">
      <Button
        className="zoom-button"
        onClick={onMinusClicked}
        disabled={minusDisabled}
      >
        <FontAwesomeIcon icon={faMinusCircle} size="2xl" />
      </Button>
      <Button
        className="zoom-button"
        onClick={onPlusClicked}
        disabled={plusDisabled}
      >
        <FontAwesomeIcon icon={faPlusCircle} size="2xl" />
      </Button>
    </div>
  );
}
