import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMFControlPanelRegion,
  setRegion as setRegionRedux,
  selectMFControlPanelPeriod,
  setPeriod as setPeriodRedux,
  selectMFControlPanelModels,
  setModels,
  selectMFControlPanelForecastUnixDate,
  setForecastUnixDate as setForecastUnixDateRedux,
  selectMFControlPanelDateOffset,
  setDateOffset,
  selectMFControlPanelAnomaly,
  setAnomaly as setAnomalyRedux,
  reset,
} from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { getUnixTime } from 'date-fns';

export default function useNumericForecastControlPanel() {
  const setRegion = (region: string) => {
    store.dispatch(setRegionRedux(region));
  };
  const region = useSelector(selectMFControlPanelRegion);

  const setPeriod = (period: string) => {
    store.dispatch(setPeriodRedux(period));
  };
  const period = useSelector(selectMFControlPanelPeriod);

  const models = useSelector(selectMFControlPanelModels);
  const toggleModel = (value: string) => {
    let newModels;

    if (models.includes(value)) {
      newModels = models.filter((model) => (model !== value));
    } else {
      newModels = models.concat(value);
    }

    store.dispatch(setModels(newModels));
  };

  const setModelArray = (value: string[]) => {
    store.dispatch(setModels(value));
  };

  const setForecastUnixDate = (value: Date | null) => {
    let unixValue;

    if (value !== null) {
      unixValue = getUnixTime(value);
    } else {
      unixValue = value;
    }

    store.dispatch(setForecastUnixDateRedux(unixValue));
  };
  const forecastUnixDate = useSelector(selectMFControlPanelForecastUnixDate);
  const resetPreferences = () => {
    store.dispatch(reset());
  };

  const setDateIndex = (value: number) => {
    store.dispatch(setDateOffset(value));
  };
  const forecastDateIndex = useSelector(selectMFControlPanelDateOffset);

  const setAnomaly = (anomaly: boolean) => {
    store.dispatch(setAnomalyRedux(anomaly));
  };
  const anomaly = useSelector(selectMFControlPanelAnomaly);

  return {
    setRegion,
    region,
    setPeriod,
    period,
    toggleModel,
    setModelArray,
    models,
    setForecastUnixDate,
    forecastUnixDate,
    setDateIndex,
    forecastDateIndex,
    setAnomaly,
    anomaly,
    resetPreferences,
  };
}
