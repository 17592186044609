import React from 'react';
import { useGetRevisionOptionsQuery } from 'services/api/products/flux';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRevisionDate,
  selectFluxAutomaticControlPanelSelectedRevision,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { Dropdown } from 'react-bootstrap';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';

export default function RevisionSelector() {
  const dispatch = useDispatch();
  const modifyRevision = (revision: string) => dispatch(setRevisionDate(revision));
  const selectedRevision = useSelector(selectFluxAutomaticControlPanelSelectedRevision);
  const { data, error } = useGetRevisionOptionsQuery();
  const revisionOptions = data?.data ?? [];
  useHandleRequestErrors(error as ResponseError);

  return (
    <Dropdown>
      <Dropdown.Toggle className="flux-automatic-filter-selector-toggle">
        {selectedRevision || 'Selecione uma revisão'}
      </Dropdown.Toggle>
      <Dropdown.Menu className="flux-automatic-filter-selector-menu">
        {revisionOptions.map((option) => (
          <Dropdown.Item
            key={option}
            onClick={() => modifyRevision(option)}
            className={`flux-automatic-filter-selector-item ${option === selectedRevision ? 'active' : ''}`}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
