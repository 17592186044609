import {
  setShow,
  setBody as setBodyRedux,
  setTitle as setTitleRedux,
  setHeaderClass as setHeaderClassRedux,
  selectVerifyZipFileTitle,
  selectVerifyZipFileBody,
  selectVerifyZipFileHeaderClassName,
  selectVerifyZipFileShow,
} from 'redux/reducers/verifyZipFileModalReducer';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

export default function useVerifyZipFileModal() {
  const setShowModal = (show: boolean) => {
    store.dispatch(setShow(show));
  };

  const setBody = (body: string) => {
    store.dispatch(setBodyRedux(body));
  };

  const setTitle = (title: string) => {
    store.dispatch(setTitleRedux(title));
  };

  const setHeaderClass = (headerClass: string) => {
    store.dispatch(setHeaderClassRedux(headerClass));
  };

  const modalTitle = useSelector(selectVerifyZipFileTitle);
  const modalBody = useSelector(selectVerifyZipFileBody);
  const modalHeaderClass = useSelector(selectVerifyZipFileHeaderClassName);
  const showModal = useSelector(selectVerifyZipFileShow);

  return {
    modalTitle,
    setTitle,
    modalBody,
    setBody,
    modalHeaderClass,
    setHeaderClass,
    showModal,
    setShowModal,
  };
}
