import React from 'react';

import CloseButton from 'components/CloseButton';
import { ResponseError } from 'services/api/base';
import { useListMailerOptionTypesQuery } from 'services/api/mailer';
import { useHandleRequestErrors } from 'utils/hooks';
import TemplateForm from '../Form';

export default function NewTemplate({
  close,
}: {
  close: () => void;
}) {
  const { data: types, isLoading, error } = useListMailerOptionTypesQuery();
  useHandleRequestErrors(error as ResponseError);

  return (
    <div className="m-3">
      <CloseButton
        id="close-new-template"
        label="Novo Template"
        onClick={close}
      />
      <TemplateForm types={types} typesLoading={isLoading} />
    </div>
  );
}
