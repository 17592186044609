import React from 'react';
import Card from 'react-bootstrap/Card';
import { ScrollRestoration, Outlet } from 'react-router-dom';
import './index.scss';
import logo from '../../../assets/logo-h.webp';

export default function AuthLayout() {
  return (
    <div className="auth-background d-flex flex-column">
      <img className="auth-logo" src={logo} alt="Logo" />
      <div className="flex-grow-1 mx-3">
        <Card className="auth-card col-md-7 col-lg-4">
          <Outlet />
        </Card>
      </div>

      <ScrollRestoration />
    </div>
  );
}
