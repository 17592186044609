import { useEffect, useState } from 'react';
import useOverviewOptions from './useOverviewOptions';

export default function usePeriodSelection() {
  const { overviewOptions, isLoading } = useOverviewOptions();
  const [period, setPeriod] = useState('');
  const handlePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setPeriod(value);
  };

  useEffect(() => {
    if (overviewOptions?.length) {
      setPeriod(overviewOptions[0].value);
    }
  }, [overviewOptions, setPeriod]);

  return {
    periodOptions: overviewOptions ?? [],
    period,
    handlePeriodChange,
    isLoading,
  };
}
