import { GenericResponse } from 'services/api/base';

export interface RawFileListFile {
  type: 'F';
  id: string;
  size: number;
  name: string;
  extension: string;
  modified: number;
}
export interface RawFileListDirectory {
  id: string;
  type: 'D';
  name: string;
  children: {
    [key: string]: RawFileListDirectory | RawFileListFile;
  }
}
export type RawFileListEntry = RawFileListDirectory | RawFileListFile;
export interface RawFileList {
  [key: string]: RawFileListDirectory;
}
export type FileListResponse = GenericResponse<RawFileList>;

export interface FileListFile {
  label: string;
  type: 'F';
  id: string;
  size: number;
  name: string;
  extension: string;
  modified: number;
}
export interface FileListDirectory {
  label: string;
  id: string;
  type: 'D';
  name: string;
  children: (FileListDirectory | FileListFile)[];
}
export type FileListEntry = FileListDirectory | FileListFile;
export type FileList = FileListEntry[];

export interface AddDirectoryParams {
  formattedPath: string;
}
export type AddDirectoryResponse = GenericResponse<null>;

export interface UploadFileParams {
  dirName: string;
  formattedPath: string;
  filename: string;
  fileBase64: string;
}
export type UploadFileResponse = GenericResponse<null>;

const transformRawFileListEntry = (entry: RawFileListEntry, label: string): FileListEntry => {
  if (entry.type === 'D') {
    const children = Object
      .entries(entry.children)
      .map(([l, e]) => transformRawFileListEntry(e, l));
    return { ...entry, label, children };
  }
  return { ...entry, label };
};

export const transformFileListReponse = (response: FileListResponse): FileList => (
  Object
    .entries(response.data)
    .map(([label, e]) => transformRawFileListEntry(e, label))
);

export const buildUniqueFilename = (baseName: string, entries: FileListEntry[]) => {
  const existingNames = entries.map(({ name }) => name);
  const nameIsTaken = existingNames.includes(baseName);

  if (!nameIsTaken) return baseName;

  const nameRegex = new RegExp(`${baseName} \\((\\d+)\\)`);
  const takenIndexes = existingNames
    .filter((name) => nameRegex.test(name))
    .map((name) => name.match(nameRegex)?.[1] as string)
    .map((i) => parseInt(i, 10));
  const maxIndex = Math.max(0, ...takenIndexes);
  const nextIndex = maxIndex + 1;

  return `${baseName} (${nextIndex})`;
};
