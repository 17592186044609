import React from 'react';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import './filters.scss';

interface FilterButtonProps {
  filterActive: boolean,
  onClick: () => void,
}

export default function FilterButton({
  filterActive,
  onClick,
}: FilterButtonProps) {
  const filterClassName = `icon-button filter-button${
    filterActive ? ' filter-button-active' : ''
  }`;

  return (
    <Button
      id="filter-button"
      className={filterClassName}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faFilter} />
    </Button>
  );
}
