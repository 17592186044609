import { useEffect, useState } from 'react';
import { handleApiError } from 'helper/error';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import { useNavigate } from 'react-router';
import { ResponseError } from 'services/api/base';
import { MassEmailSortableField, useListMassMailsQuery } from 'services/api/mailer';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import useListEnd from '../useListEnd';

export default function useMassEmailListing({
  pageSize,
  orderBy,
  orderDirection,
  searchTerms,
}: {
  pageSize: number,
  orderBy: MassEmailSortableField,
  orderDirection: 'ASC' | 'DESC',
  searchTerms: string | undefined,
}) {
  const [maxLength, setMaxLength] = useState<number>(-1);
  const { page, setPage } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);
  const {
    data,
    error,
    isLoading,
    isFetching,
  } = useListMassMailsQuery({
    page,
    pageSize: dataLength,
    orderBy,
    orderDirection,
    searchTerms,
  });
  const navigate = useNavigate();
  const { listEnded } = useListEnd(data, page, pageSize, isFetching);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerms);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (error) {
      handleApiError(navigate, error as ResponseError);
    }
  }, [error, navigate]);

  useEffect(() => {
    setMaxLength(-1);
  }, [searchTerms]);

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, data)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, data, isFetching]);

  if (isSearching && !isFetching) {
    setIsSearching(false);
  }

  if (searchTerms !== localSearchTerm) {
    setPage(1);
    setIsSearching(true);
    setLocalSearchTerm(searchTerms);
  }

  return {
    massEmailList: data,
    isLoading: isLoading || isSearching,
    isFetching,
    listEnded,
  };
}
