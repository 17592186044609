export default function buildSets<T>(data: T[] | undefined, setSize: number): T[][] {
  if (!data) return [];

  const sets: T[][] = [];

  data.forEach((entry) => {
    const lastSet = sets[sets.length - 1];

    if (lastSet && lastSet.length < setSize) {
      lastSet.push(entry);
    } else {
      sets.push([entry]);
    }
  });

  return sets;
}
