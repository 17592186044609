import React from 'react';
import { format, parse } from 'date-fns';
import ReactECharts from 'echarts-for-react';

export interface GraphProps {
  data: {
    [key: string]: (string | number)[][],
  },
}

export default function Graph({ data }: GraphProps) {
  const refDate = new Date();
  const series = Object.keys(data).map((name) => ({
    name,
    data: data[name].map((dataPoint) => ({
      name: dataPoint[0],
      value: [format(parse(dataPoint[0] as string, 'dd/MM/yyyy', refDate), 'yyyy-MM-dd'), dataPoint[1]],
    })),
    type: 'line',
    smooth: true,
  }));

  const options = {
    xAxis: {
      type: 'time',
    },
    yAxis: {
      type: 'value',
      min: 0.0,
    },
    series,
    tooltip: {
      trigger: 'axis',
      order: 'valueDesc',
      valueFormatter: (v: number) => v.toFixed(1),
      axisPointer: {
        label: {
          formatter(params) {
            const date = new Date(params.value);
            return date.toLocaleDateString('pt-BR');
          },
        },
      },
    },
    dataZoom: [
      {
        type: 'slider',
        show: 'true',
        xAxisIndex: [0],
        start: 0,
        end: 100,
        borderRadius: 32,
        borderColor: '#c1c1c1',
        height: 9,
        fillerColor: '#26548a',
        showDetail: false,
        showDataShadow: false,
        handleIcon: 'path://m50,50 a100,100 0 1,0 -200,0 a 100,100 0 1,0 200,0 Z',
        handleStyle: {
          borderColor: '#26548a',
          borderWidth: 2,
        },
        moveHandleStyle: {
          opacity: 0,
        },
        brushSelect: false,
        brushStyle: {
          opacity: 0,
        },
        dataBackground: {
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            opacity: 0,
          },
        },
      },
      {
        type: 'slider',
        show: 'true',
        yAxisIndex: [0],
        start: 0,
        end: 100,
        filterMode: 'none',
        borderRadius: 32,
        borderColor: '#c1c1c1',
        fillerColor: '#26548a',
        width: 9,
        showDetail: false,
        showDataShadow: false,
        handleIcon: 'path://m50,50 a100,100 0 1,0 -200,0 a 100,100 0 1,0 200,0 Z',
        handleStyle: {
          borderColor: '#26548a',
          borderWidth: 2,
        },
        moveHandleStyle: {
          opacity: 0,
        },
        brushSelect: false,
        brushStyle: {
          opacity: 0,
        },
        dataBackground: {
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            opacity: 0,
          },
        },
      },
    ],
  };

  return (
    <div className="my-3" style={{ backgroundColor: '#ffffff' }}>
      <ReactECharts
        option={options}
        style={{
          width: '100%',
          height: 'auto',
          aspectRatio: '16 / 9',
        }}
      />
    </div>
  );
}
