import React, { useState } from 'react';
import {
  Row, Col, Spinner, Form,
} from 'react-bootstrap';

import useProductsOptions from 'utils/hooks/useProductsOptions';
import Toggle from 'components/Toggle';
import useDateRange from 'utils/hooks/useDateRange';
import ActionButton from 'components/Admin/ActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { ContractProduct } from 'helper/contracts';
import { useFromUnixTime } from 'utils/hooks';
import DatePicker from 'components/DatePicker';

interface ProductProps {
  index: number,
  removeProductLineMethod: (elementIndex: number) => void,
  defaultStartDate: Date | null,
  defaultEndDate: Date | null,
  contractProduct?: ContractProduct | undefined,
}

export default function Product({
  index,
  removeProductLineMethod,
  defaultStartDate,
  defaultEndDate,
  contractProduct,
}: ProductProps) {
  const productKey = `product${index}`;
  const fieldId = `product-id-${index}`;
  const {
    startDate, endDate, onChangeStartDate, onChangeEndDate,
  } = useDateRange({
    defaultStartDate: useFromUnixTime(contractProduct?.startDate) || defaultStartDate,
    defaultEndDate: useFromUnixTime(contractProduct?.endDate) || defaultEndDate,
  });
  const [isToggled, setIsToggled] = useState(contractProduct?.enabled === true);
  const deleteProduct = () => removeProductLineMethod(index);
  const switcherMethod = (booleanState: boolean) => setIsToggled(!booleanState);

  const { options: productOptions, isLoading: productsIsLoading } = useProductsOptions();

  if (productsIsLoading) {
    return <Spinner />;
  }

  return (
    <Row key={productKey} className="pb-2">
      <Form.Control defaultValue={contractProduct?.id} name="product_instance_id[]" type="hidden" />
      <Col xs={4} className="pe-1">
        <Form.Group controlId="product-ids">
          <Form.Select
            className="form-control"
            name="product_id[]"
            placeholder=""
            id={fieldId}
            defaultValue={contractProduct?.productId}
            required
          >
            <option> </option>
            { productOptions?.map((productOption) => (
              <option
                key={productOption.value}
                value={productOption.value}
              >
                { productOption.label }
              </option>
            )) }
          </Form.Select>
          <Form.Control.Feedback type="invalid" id="companyIdErrorMessage">
            Um produto deve ser selecionado
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col className="px-1">
        <DatePicker
          required
          selected={startDate}
          name="product_start_date[]"
          className="form-control"
          wrapperClassName="w-100"
          onChange={onChangeStartDate}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </Col>
      <Col className="px-1">
        <DatePicker
          required
          selected={endDate}
          name="product_end_date[]"
          className="form-control"
          wrapperClassName="w-100"
          onChange={onChangeEndDate}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </Col>
      <Col xs={2} className="d-flex justify-content-around align-items-center ps-1">
        <Toggle name="product_enabled[]" toggled={isToggled} callback={switcherMethod} />
        {!contractProduct?.id && (
          <ActionButton
            loading={false}
            tooltipText="Remover produto"
            onClick={deleteProduct}
          >
            <FontAwesomeIcon icon={faSquareXmark} />
          </ActionButton>
        )}
      </Col>
    </Row>
  );
}

Product.defaultProps = { contractProduct: undefined };
