import React, { useEffect, useState } from 'react';
import {
  Accordion, Row, Button,
} from 'react-bootstrap';
import {
  useDeleteComparatorPreferencesMutation, useListComparatorPreferencesQuery,
} from 'services/api/Meteorology';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import useComparatorControlPanel from 'utils/hooks/meteorology/comparator/useComparatorControlPanel';
import useComparatorSavePreferencesModal from 'utils/hooks/meteorology/useComparatorSavePreferencesModal';
import useSetComparison from 'utils/hooks/meteorology/comparator/useSetComparison';
import useComparatorParams from 'utils/hooks/meteorology/comparator/useComparatorParams';
import { useDispatch } from 'react-redux';
import { setTriggerActivity } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import PreferencesAccordion from './PreferencesAccordion';
import PopularComparisonsAccordion from '../PopularComparisonsAccordion';
import ModelAccordion, { ModelType } from './modelAccordion';
import ConfirmationModal from '../NumericForecast/confirmationModal';
import ErrorModal from '../NumericForecast/errorModal';
import SavePreferencesModal from './SavePreferencesModal';

export default function Comparator() {
  const dispatch = useDispatch();
  const { data, error } = useListComparatorPreferencesQuery();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preferences = data?.data || [];
  const [onPreferenceDeleteTrigger, {
    isLoading: preferenceDeleteLoading,
    error: preferenceDeleteError,
  }] = useDeleteComparatorPreferencesMutation();
  useHandleRequestErrors(error as ResponseError | undefined);
  useHandleRequestErrors(preferenceDeleteError as ResponseError | undefined);
  const { resetPreferences } = useComparatorControlPanel();
  const setPreference = useSetComparison();
  const {
    setShowFormModal,
    isShowingConfirmationModal,
    setShowConfirmationModal,
    isShowingErrorModal,
    setShowErrorModal,
    error: saveError,
  } = useComparatorSavePreferencesModal();
  const comparatorParams = useComparatorParams();
  const queryTriggerMethod = () => dispatch(setTriggerActivity(true));

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (data === undefined || !firstLoad) {
      return;
    }
    setFirstLoad(false);
    if (preferences.length === 0) {
      return;
    }
    setPreference(preferences[0], 'Preferências Salvas');
    dispatch(setTriggerActivity(true));
  }, [firstLoad, data, preferences, setPreference, dispatch]);

  return (
    <div>
      <ConfirmationModal
        open={isShowingConfirmationModal}
        closing={() => { setShowConfirmationModal(false); }}
      />
      <ErrorModal
        open={isShowingErrorModal}
        message={saveError}
        closing={() => { setShowErrorModal(false); }}
      />
      <SavePreferencesModal />
      <Accordion defaultActiveKey="0">
        <PopularComparisonsAccordion />
        <PreferencesAccordion
          preferenceList={preferences}
          deleteTrigger={onPreferenceDeleteTrigger}
          deleteLoading={preferenceDeleteLoading}
        />
        <Accordion.Item eventKey="2" className="control-panel-item">
          <Accordion.Header>Nova Comparação</Accordion.Header>
          <Accordion.Body>
            <ModelAccordion title="Modelo Confrontante" modelType={ModelType.CONFRONTATIONAL} />
            <ModelAccordion title="Modelo Base" modelType={ModelType.BASE} />

            <Row className="pt-5">
              <Button variant="primary" onClick={() => setShowFormModal(true)}>
                Salvar nova preferência
              </Button>
            </Row>
            <Row className="pt-2">
              <Button variant="primary" disabled={comparatorParams === undefined} onClick={queryTriggerMethod}>
                Realizar consulta
              </Button>
            </Row>
            <Row>
              <Button variant="link" className="clear-filters" onClick={resetPreferences}>
                Limpar filtros
              </Button>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
