import React from 'react';
import useMonthlyData from 'utils/hooks/flux/automatic/useMonthlyData';
import { useSelector } from 'react-redux';
import {
  selectFluxAutomaticControlPanelMltOption,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import FluxAutomaticGeneralTable from '../../../GeneralTable';

export default function Table() {
  const { data } = useMonthlyData();
  const unidade = useSelector(selectFluxAutomaticControlPanelMltOption);

  return (
    <div className="overflow-auto" style={{ maxHeight: '650px' }}>
      <FluxAutomaticGeneralTable data={data} tableLabel="" percentView={unidade === '%mlt'} />
    </div>
  );
}
