import React from 'react';
import Toggle from 'components/Toggle';
import { ListedCompanyContract, activityToggleMessage } from 'helper/companies';
import EditButton from './EditButton';

export default function Actions({
  company, setConfirmationDialogBox, toggled, callback, onEditClick,
}: {
  company: ListedCompanyContract,
  setConfirmationDialogBox: (open: boolean, body: JSX.Element, confirm: () => void) => void,
  toggled: boolean,
  callback: () => void,
  onEditClick: (id: number, contract_id: number) => () => void,
}) {
  return (
    <div className="d-flex flex-row flex-wrap align-items-center">
      <span className="me-3 my-2">
        <Toggle
          toggled={toggled}
          callback={(companyActive: boolean) => {
            setConfirmationDialogBox(true, activityToggleMessage(companyActive), callback);
          }}
        />
      </span>
      <EditButton
        contractId={company.contrato_id}
        onEditClick={onEditClick(company.empresa_id, company.contrato_id)}
      />
    </div>
  );
}
