import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AutomaticPreference } from 'helper/products/flux';
import { SpatialSubdivision, SpatialSubdivisions } from 'helper/flux/automatic';
import { useDispatch } from 'react-redux';
import {
  setMLT,
  setAcomph,
  setPastSimulations,
  setAutomaticStudyPreferenceScenarios,
} from 'redux/reducers/fluxAutomaticControlPanelReducer';
import useFluxAutomaticFilterSelector from 'utils/hooks/flux/automatic/filterSelector';

export default function Preference({
  preference,
  onDelete,
  deleteLoading,
  subdivisions,
}: {
  preference: AutomaticPreference,
  onDelete: (id: number) => void,
  deleteLoading: boolean,
  subdivisions: SpatialSubdivisions,
}) {
  const dispatch = useDispatch();
  const { setFilterWithinDivision } = useFluxAutomaticFilterSelector();
  const subOption = subdivisions[`${preference.spatialSubDivisionKey}s`]
    .find((option) => option.label === preference.spatialSubDivisionValue);

  const onDeleteClick = () => {
    onDelete(preference.id);
  };

  const selectPreference = () => {
    setFilterWithinDivision(
      subOption,
      `${preference.spatialSubDivisionKey}s` as SpatialSubdivision,
    );
    dispatch(setAutomaticStudyPreferenceScenarios(preference));
    dispatch(setAcomph(preference.acomph));
    dispatch(setMLT(preference.mlt));
    dispatch(setPastSimulations(preference));
  };

  const deleteButtonIcon = deleteLoading ? (
    <Spinner size="sm" />
  ) : (
    <FontAwesomeIcon icon={faTrash} size="sm" />
  );

  return (
    <div className="d-flex flex-row align-items-center">
      <Button
        variant="link"
        onClick={selectPreference}
        className="btn-control-panel flex-grow-1"
      >
        {preference.name}
      </Button>
      <Button
        className="btn-preference"
        onClick={onDeleteClick}
        disabled={deleteLoading}
      >
        {deleteButtonIcon}
      </Button>
    </div>
  );
}
