import {
  ListedCompany,
  transformCompanyListResponse,
  ListedCompanyContract,
  transformCompaniesContractsResponse,
  RawGetCompanyResponse,
} from 'helper/companies';
import filterUndefined from 'utils/filterUndefined';
import { SortDirection } from 'constants/sorting';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import baseApi, { GenericResponse, rawBaseQuery } from './base';

export interface CompanyFilterParams {
  productIds?: number[]
  contractEndsBefore?: string
  consulting?: boolean
  enabled?: boolean
  expiringIn30Days?: boolean
  expiringIn60Days?: boolean
  isTest?: boolean
}

export interface CountCompanyParams extends CompanyFilterParams {
  nameContains?: string;
  orderBy?: SortableField;
  orderDirection?: SortDirection;
}

export interface ListCompanyParams extends CountCompanyParams {
  page: number,
  pageSize: number,
}

export interface CompanyParams {
  companyId: number
}

export interface ContractParams {
  contractId: number
}

export interface CompaniesCategoriesCount {
  consulting: number;
  meteorologia: number;
}

export interface CompaniesCount extends CompaniesCategoriesCount {
  active: number;
  test: number;
  '30_days_to_due_date': CompaniesCategoriesCount;
  '60_days_to_due_date': CompaniesCategoriesCount;
}

export type CompaniesCountResponse = GenericResponse<CompaniesCount>;

export interface SaveCompanyParams {
  id: number
  corporateName: string
  moniker: string
  cnpj: string
  fantasyName?: string
  internal?: boolean // means the company belongs to Ampere
  fiscalEmails?: string
  logo?: string | null
  active?: boolean
  ie?: string
  im?: string
  cep?: string
  address?: string
  number?: string
  complement?: string
  district?: string
  state?: number
  city?: number
}

export type EnableCompanyResponse = GenericResponse<null>;

interface SaveCompanyValidationErrors {
  [errorType: string]: string,
}
export type SaveCompanyResponse = GenericResponse<null, string | SaveCompanyValidationErrors>;

export type CompanyState = {
  id: number,
  uf: string,
  nome: string,
  id_regiao: number,
  regiao: string,
};

export type CompanyCity = {
  id: number,
  nome: string,
  cod_ibge: string,
};

export const companiesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listCompanies: build.query<ListedCompany[], void>({
      query: () => ({
        url: 'empresa-list',
      }),
      providesTags: ['Company'],
      transformResponse: transformCompanyListResponse,
    }),
    listCompaniesContracts: build.query<ListedCompanyContract[], ListCompanyParams>({
      query: (params: ListCompanyParams) => {
        const body = filterUndefined({
          page: params.page,
          page_size: params.pageSize,
          name_contains: params.nameContains,
          product_ids: params.productIds,
          contract_ends_before: params.contractEndsBefore,
          consulting: params.consulting,
          enabled: params.enabled,
          expiring_in_30_days: params.expiringIn30Days,
          expiring_in_60_days: params.expiringIn60Days,
          is_test: params.isTest,
          order_by: params.orderBy,
          order_direction: params.orderDirection,
        });
        return {
          url: 'contratos-empresa-list',
          method: 'POST',
          body,
        };
      },
      providesTags: ['Company'],
      transformResponse: transformCompaniesContractsResponse,
    }),
    companiesCount: build.query<CompaniesCountResponse, CountCompanyParams>({
      query: ({
        nameContains, productIds, contractEndsBefore, consulting,
        enabled, expiringIn30Days, expiringIn60Days, isTest,
      }) => ({
        url: 'empresas-count',
        method: 'POST',
        body: filterUndefined({
          name_contains: nameContains,
          product_ids: productIds,
          contract_ends_before: contractEndsBefore,
          consulting,
          enabled,
          expiring_in_30_days: expiringIn30Days,
          expiring_in_60_days: expiringIn60Days,
          is_test: isTest,
        }),
      }),
      providesTags: ['Company'],
    }),
    enableCompany: build.mutation<EnableCompanyResponse, CompanyParams>({
      query: ({ companyId }) => ({
        url: 'empresa-enable',
        method: 'PUT',
        body: {
          empresa_id: companyId,
        },
      }),
      invalidatesTags: ['Company'],
    }),
    disableCompany: build.mutation<EnableCompanyResponse, CompanyParams>({
      query: ({ companyId }) => ({
        url: 'empresa-disable',
        method: 'PUT',
        body: {
          empresa_id: companyId,
        },
      }),
      invalidatesTags: ['Company'],
    }),
    companyStates: build.query<CompanyState[], void>({
      queryFn: async (_arg, api, extraOptions) => rawBaseQuery({
        url: 'empresa-estado',
        method: 'GET',
      }, api, extraOptions) as QueryReturnValue<CompanyState[], unknown, unknown>,
    }),
    citiesByState: build.mutation<CompanyCity[], { stateId: number }>({
      queryFn: async (arg, api, extraOptions) => rawBaseQuery({
        url: 'empresa-cidades-by-estado',
        method: 'POST',
        body: {
          id_estado: arg.stateId,
        },
      }, api, extraOptions) as QueryReturnValue<CompanyCity[], unknown, unknown>,
    }),
    saveCompany: build.mutation<SaveCompanyResponse, SaveCompanyParams>({
      query: (params: SaveCompanyParams) => ({
        url: 'empresa-save',
        method: 'PUT',
        body: filterUndefined({
          id: params.id,
          logomarca: params.logo,
          active: params.active,
          parte: params.internal,
          apelido: params.moniker,
          razao_social: params.corporateName,
          nome_fantasia: params.fantasyName,
          cnpj: params.cnpj,
          ie: params.ie,
          im: params.im,
          cep: params.cep,
          endereco: params.address,
          numero: params.number,
          complemento: params.complement,
          bairro: params.district,
          emails_fiscais: params.fiscalEmails,
          uf: params.state,
          cidade: params.city,
        }),
      }),
      invalidatesTags: ['Company'],
    }),
    getCompanyByCnpj: build.mutation<ListedCompany, { companyCnpj: string }>({
      queryFn: async (arg, api, extraOptions) => rawBaseQuery({
        url: '/empresa-by-cnpj/',
        method: 'POST',
        body: {
          cnpj: arg.companyCnpj,
        },
      }, api, extraOptions) as QueryReturnValue<ListedCompany, unknown, unknown>,
    }),
    getCompany: build.query<ListedCompany, CompanyParams>({
      query: ({ companyId }) => `/empresa-by-id/?empresa_id=${companyId}`,
      providesTags: ['Company'],
      transformResponse: (response: RawGetCompanyResponse) => response.data,
    }),
  }),
});

export type SortableField = 'nome' | 'contract_start' | 'contract_end';
export const SORT_BY_NAME = 'nome';
export const SORT_BY_CONTRACT_START = 'contract_start';
export const SORT_BY_CONTRACT_END = 'contract_end';
export const SORTABLE_FIELDS: (SortableField | undefined)[] = [
  SORT_BY_NAME,
  SORT_BY_CONTRACT_START,
  SORT_BY_CONTRACT_END,
];

export const {
  useListCompaniesQuery,
  useListCompaniesContractsQuery,
  useEnableCompanyMutation,
  useDisableCompanyMutation,
  useCompanyStatesQuery,
  useCitiesByStateMutation,
  useSaveCompanyMutation,
  useGetCompanyQuery,
  useCompaniesCountQuery,
  useGetCompanyByCnpjMutation,
} = companiesApi;
