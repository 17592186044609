import React from 'react';
import {
  Accordion, Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import {
  SORT_BY_NAME,
  RECIPIENT_COMPANY_SORTABLE_FIELDS,
  RecipientCompanySortableFields,
} from 'helper/mailer';
import SortingArrow, { useSorting } from 'components/SortingArrow';
import useRecipientCompanySorting from 'utils/hooks/mailer/useRecipientCompanySorting';
import { ListedCompany } from 'helper/companies';
import useNamedReportCompanies from 'utils/hooks/mailer/useNamedReportCompanies';
import RecipientCompanyRow from './Row';

export default function RecipientCompanyTable({ reportMailId, companies }: {
  reportMailId: number,
  companies: ListedCompany[],
}) {
  const { recipientCompanies, isLoading } = useNamedReportCompanies(reportMailId, companies);
  const [
    sortBy,
    sortDirection,
    sorters,
  ] = useSorting<RecipientCompanySortableFields | undefined>(RECIPIENT_COMPANY_SORTABLE_FIELDS);
  const sortedRecipientCompanies = useRecipientCompanySorting(
    recipientCompanies,
    sortBy,
    sortDirection,
  );

  if (isLoading) {
    return <div className="text-center"><Spinner /></div>;
  }

  return (
    <>
      <Row className="fw-bold admin-table-header">
        <Col md={9}>
          <Stack direction="horizontal">
            EMPRESA
            <SortingArrow
              field={SORT_BY_NAME}
              sortBy={sortBy}
              sortDirection={sortDirection}
              sort={sorters.name}
            />
          </Stack>
        </Col>
        <Col md={1}>WORD</Col>
        <Col md={1}>PDF</Col>
        <Col md={1}>EMAIL</Col>
      </Row>
      <Accordion flush className="mailer-report-company">
        {sortedRecipientCompanies.map((recipientCompany) => (
          <RecipientCompanyRow
            recipientCompany={recipientCompany}
            key={recipientCompany.id}
          />
        ))}
      </Accordion>
    </>
  );
}
