import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { CompanyParams, useGetCompanyQuery } from 'services/api/companies';

export default function useGetCompany(companyId?: number) {
  const params: CompanyParams | undefined = companyId ? { companyId } : undefined;

  const { data: companyData } = useGetCompanyQuery(params ?? skipToken);

  return companyData;
}
