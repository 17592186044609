import { GenericResponse } from 'services/api/base';
import { PermissionTypes } from './products';

export enum VideoProduct {
  Flux,
  Meteorology,
}
type VideoProductMap<T = string> = {
  [product in VideoProduct]: T;
};
export const videoProductToPath: VideoProductMap = {
  [VideoProduct.Flux]: 'videos_flux',
  [VideoProduct.Meteorology]: 'videos',
};
export const videoProductToRoute: VideoProductMap = {
  [VideoProduct.Flux]: '/flux/videos',
  [VideoProduct.Meteorology]: '/meteorology/videos',
};
export const videoProductToPageTitle: VideoProductMap = {
  [VideoProduct.Flux]: 'VÍDEOS - Flux',
  [VideoProduct.Meteorology]: 'VÍDEOS',
};
export const videoProductToPermission: VideoProductMap<PermissionTypes> = {
  [VideoProduct.Flux]: 'flux',
  [VideoProduct.Meteorology]: 'meteorologia-videos',
};

interface ProductPathParam {
  productPath: string;
}
interface PermissionTypeParam {
  permissionType: PermissionTypes;
}
type ParamsWithProductPath<T extends object> = T & ProductPathParam;
type ParamsWithProductKey<T extends object> = ParamsWithProductPath<T> & PermissionTypeParam;

export type GetVideoCategoriesParams = ProductPathParam;
export interface Category {
  id: number;
  label: string;
}
export type GetVideoCategoriesResponse = GenericResponse<Category[]>;

export interface VideoEmbedInfo {
  html: string;
  badges: {
    hdr: boolean;
    live: {
      streaming: boolean;
      archived: boolean
    };
    staff_pick: {
      normal: boolean;
      best_of_the_month: boolean;
      best_of_the_year: boolean;
      premiere: boolean;
    };
    vod: boolean;
    weekend_challenge: boolean;
  };
  interactive: boolean;
  [key: string]: any;
}

export type GetLastVideosParams = ParamsWithProductKey<{
  page: number;
  pageSize: number;
  category: number;
  searchTerm?: string;
  uploadDate?: string;
}>;
interface Size {
  width: number;
  height: number;
  link: string;
  link_with_play_button: string;
}
export interface VideoPicturesInfo {
  uri: string;
  active: true;
  type: string;
  base_link: string;
  sizes: Size[];
}
export interface VideoInfo {
  app: { name: string; uri: string };
  categories: string[];
  content_rating: string[];
  content_rating_class: string;
  created_time: string;
  pictures: VideoPicturesInfo;
  modified_time: string;
  release_time: string;
  description: any;
  embed: VideoEmbedInfo;
  last_user_action_event_data: string;
  license: any;
  link: string;
  stats: { plays: number };
  manage_link: string;
  metadata: any;
  player_embed_url: string;
  status: string;
  type: string;
  duration: number;
  has_audio: boolean;
  is_playable: boolean;
  language: any;
  tags: any[];
  height: number;
  width: number;
  uri: string;
  name: string;
  [key: string]: any;
}
export interface VideosGetLast {
  id: number;
  tags: string | null;
  uri: VideoInfo;
  titulo: string;
  video_id: string;
  file_name: string | null;
  description: string;
  dt_date_upload: number;
}
export type GetLastVideosResponse = GenericResponse<{
  video_info: VideosGetLast[];
}>;

export type GetVideoByIdParams = ParamsWithProductPath<{
  id: number;
}>;
export interface VideoData {
  id: number;
  tags: string;
  video_id: string;
  categoria: number;
  video_title: string;
  file_name: string | null;
  video_description: string;
  uri: {
    player_embed_url: string;
    release_time: string;
  };
}
export type GetVideoDataByIdResponse = GenericResponse<VideoData>;

export type SaveVideoParams = ParamsWithProductPath<{
  id?: number;
  tags?: string;
  videoId?: number;
  videoTitle?: string;
  videoDescription?: string;
  category?: number;
  dtUpload?: string;
  fileName?: string;
  base64file?: string;
}>;
export type SaveVideoResponse = GenericResponse<null>;

export type DeleteVideoParams = ParamsWithProductKey<{
  id: number;
}>;
export type DeleteVideoResponse = GenericResponse<null>;

export type DownloadBulletinParams = ParamsWithProductPath<{
  id: number;
}>;
export interface DownloadBulletinData {
  file_b64: string;
  file_name: string;
}
export type DownloadBulletinResponse = GenericResponse<DownloadBulletinData>;
