import React from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import usePopularComparisons from 'utils/hooks/meteorology/usePopularComparisons';
import PopularComparison from './PopularComparison';

export default function PopularComparisonsAccordion() {
  const { comparisons, isFetching } = usePopularComparisons();

  return (
    <Accordion.Item eventKey="0" className="control-panel-item">
      <Accordion.Header>Comparações populares</Accordion.Header>
      <Accordion.Body className="text-center">
        {isFetching && <Spinner />}
        {!isFetching && (
          comparisons.map((comparison) => (
            <PopularComparison
              key={comparison.id}
              comparison={comparison}
            />
          ))
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}
