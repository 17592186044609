import React from 'react';
import {
  Form,
  Row,
} from 'react-bootstrap';

import useFluxFileUploadForm from 'utils/hooks/flux/fileUploadForm';
import useFromUnixTime from 'utils/hooks/useFromUnixTime';
import DatePicker from 'components/DatePicker';

export default function StudyHorizon() {
  const {
    startDate, endDate, setEndDate,
  } = useFluxFileUploadForm();
  const selectedStartDate = useFromUnixTime(startDate);
  const selectedEndDate = useFromUnixTime(endDate);
  const endDateMin = selectedStartDate;

  const labelStyle = { fontWeight: 400, color: '#585858' };

  return (
    <div>
      <Row className="mb-3">
        <Form.Label column className="text-start" style={labelStyle}>Início do estudo</Form.Label>
      </Row>
      <div className="mb-3 d-flex justify-content-center">
        <DatePicker
          showIcon
          disabled
          selected={selectedStartDate}
          onChange={() => {}}
        />

      </div>

      <Row className="mb-3 justify-content-center">
        <Form.Label column className="text-start" style={labelStyle}>Fim do estudo</Form.Label>
      </Row>
      <div className="mb-3 d-flex justify-content-center">
        <DatePicker
          showIcon
          placeholderText="Selecione uma data"
          selected={selectedEndDate}
          onChange={setEndDate}
          disabled={selectedStartDate === null}
          minDate={endDateMin}
        />
      </div>
    </div>
  );
}
