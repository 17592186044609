import React, { useState, useMemo } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row, Col, Button, Form,
} from 'react-bootstrap';

import { ContractProduct } from 'helper/contracts';
import Product from './Product';

export function addProductLine(
  identifiers: number[],
  identifiersSetter: React.Dispatch<React.SetStateAction<number[]>>,
) {
  return () => {
    if (identifiers.length > 0) {
      const lastElement = identifiers.at(-1) as number;
      identifiersSetter([...identifiers, lastElement + 1]);
    } else {
      identifiersSetter([0]);
    }
  };
}

export function useRemoveProductMethod(
  identifiers: number[],
  identifiersSetter: React.Dispatch<React.SetStateAction<number[]>>,
) {
  return useMemo(
    () => (
      (identifier: number) => {
        identifiersSetter(identifiers.filter((el: number) => el !== identifier));
      }
    ),
    [identifiers, identifiersSetter],
  );
}

export default function Products({
  defaultProducts,
  defaultStartDate,
  defaultEndDate,
}: {
  defaultProducts: ContractProduct[] | undefined,
  defaultStartDate: Date | null,
  defaultEndDate: Date | null,
}) {
  const initialIdentifiers = defaultProducts ? defaultProducts.map((_val, index) => index) : [];
  const [identifiers, setIdentifiers] = useState<number[]>(initialIdentifiers);
  const newProduct = addProductLine(identifiers, setIdentifiers);
  const removeProduct = useRemoveProductMethod(identifiers, setIdentifiers);

  const products = identifiers.map(
    (index) => {
      let contractProduct: ContractProduct | undefined;

      if (defaultProducts && index < defaultProducts.length) {
        contractProduct = defaultProducts.at(index);
      }

      return (
        <Product
          removeProductLineMethod={removeProduct}
          key={`product-${index}`}
          index={index}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          contractProduct={contractProduct}
        />
      );
    },
  );

  return (
    <Form.Group controlId="companyProducts">
      <Form.Label>Produtos</Form.Label>

      {products}

      <Row>
        <Col className="col-12">
          <Button id="newProduct" variant="outline-secondary" className="w-100" onClick={newProduct}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
    </Form.Group>
  );
}
