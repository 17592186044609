import React, { useEffect, useState } from 'react';

import { store } from 'redux/store';
import { setCurrentTab, setSearchQuery } from 'redux/reducers/mailerLayoutReducer';
import NewTemplate from 'components/Admin/Mailer/Templates/NewTemplate';
import TemplateList from 'components/Admin/Mailer/Templates/TemplateList';

export default function MailerTemplates() {
  const [showNewTemplateForm, setShowNewTemplateForm] = useState(false);

  useEffect(() => {
    store.dispatch(setCurrentTab('templates'));
    store.dispatch(setSearchQuery(''));
  }, []);

  return (
    <div style={{ backgroundColor: '#f4f4f4' }}>
      {showNewTemplateForm
        ? <NewTemplate close={() => setShowNewTemplateForm(false)} />
        : <TemplateList openNewTemplateForm={() => setShowNewTemplateForm(true)} />}
    </div>
  );
}
