import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetAvailableFluxPersonalizedDatesQuery } from 'services/api/Meteorology';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import sortDates from '../../../sortDates';

export default function useFluxPersonalisedAvailableDates(model: string | undefined) {
  const [trigger, { data, error }] = useLazyGetAvailableFluxPersonalizedDatesQuery();

  useEffect(() => {
    if (model !== undefined) trigger({ model });
  }, [model, trigger]);
  useHandleRequestErrors(error as ResponseError | undefined);

  return sortDates(data?.data);
}
