import { useDispatch } from 'react-redux';
import { setDateOffset } from 'redux/reducers/meteorologyFieldsControlPanelReducer';

export default function useSliderProps(
  maxValue: number,
  minValue: number,
  dateOffset: number | undefined,
  period: string | undefined,
) {
  const dispatch = useDispatch();
  const isPentad = period ? period[0] === 'p' : false;

  if (isPentad) {
    return {
      sliderClassName: 'pentad-slider',
      sliderMax: maxValue + 1,
      sliderMin: minValue,
      sliderValue: [dateOffset || 0, (dateOffset || 0) + 1],
      sliderOnChange: (value, index) => {
        const newDateOffset = index === 0 ? value[0] : value[1] - 1;
        dispatch(setDateOffset(newDateOffset));
      },
    };
  }
  return {
    sliderClassName: 'horizontal-slider',
    sliderMax: maxValue,
    sliderMin: minValue,
    sliderValue: dateOffset,
    sliderOnChange: (value) => dispatch(setDateOffset(value)),
  };
}
