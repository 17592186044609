import { useState } from 'react';
import { SortDirection, SORT_ASC, SORT_DESC } from 'constants/sorting';
import { SortableField as CompanySortableField } from 'services/api/companies';
import { SortableField as UserSortableField } from 'services/api/users';
import { SortableField as InvoiceSortableField } from 'helper/financial';
import { MassEmailSortableField, ReportSortableField } from 'services/api/mailer';
import { RecipientCompanySortableFields } from 'helper/mailer';

export type Sorter = (sortDirection?: SortDirection) => void;
export type SortingState<FieldName> = [
  FieldName,
  SortDirection | undefined,
  { [field: string]: Sorter },
];
export type SortableField = UserSortableField
| CompanySortableField
| InvoiceSortableField
| MassEmailSortableField
| ReportSortableField
| RecipientCompanySortableFields
| undefined;

export function useSorting<FieldName>(sortByFields: FieldName[]): SortingState<FieldName> {
  const [sortBy, setSortBy] = useState(sortByFields[0]);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(undefined);
  return [
    sortBy,
    sortDirection,
    Object.fromEntries(sortByFields.map((field) => [
      field,
      (direction) => {
        setSortBy(field);
        setSortDirection(direction);
      },
    ])),
  ];
}

export const nextDirection = (sort: Sorter, sortDirection: SortDirection | undefined) => {
  switch (sortDirection) {
    case SORT_ASC:
      sort(SORT_DESC);
      break;
    case SORT_DESC:
      sort(undefined);
      break;
    default: // likely undefined, which means "sorting disabled"
      sort(SORT_ASC);
      break;
  }
};
