import { WeatherArea } from 'types/Meteorology/Weather/Area';
import { GetAboutResponse } from 'types/Meteorology/Weather/Area/getAboutResponse';

const transformGetAboutResponse = (response: GetAboutResponse): WeatherArea => ({
  data: response.data?.descricao.data ?? '',
  id: response.data?.descricao.id ?? 0,
  imageBase64: response.data?.result,
});

export default transformGetAboutResponse;
