import React from 'react';

import FileViewer from 'views/FileViewer';

const fileViewerRouter = {
  path: 'fileviewer',
  children: [
    { path: '', element: <FileViewer /> },
  ],
};

export default fileViewerRouter;
