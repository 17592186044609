import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Comparison } from 'helper/products/meteorology';
import useSetComparison from 'utils/hooks/meteorology/comparator/useSetComparison';
import { setTriggerActivity } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import { useDispatch } from 'react-redux';

export default function Preference({ preference, onDelete, deleteLoading }: {
  preference: Comparison,
  onDelete: ({ id }: { id: number }) => void,
  deleteLoading: boolean,
}) {
  const dispatch = useDispatch();
  const setPreference = useSetComparison();
  const loadPreference = () => {
    setPreference(preference, 'Preferências Salvas');
    dispatch(setTriggerActivity(true));
  };
  const onDeleteClick = () => {
    onDelete({ id: preference.id });
  };
  const deleteButtonIcon = deleteLoading ? (
    <Spinner size="sm" />
  ) : (
    <FontAwesomeIcon icon={faTrash} size="sm" />
  );

  return (
    <div className="d-flex flex-row align-items-center">
      <Button
        variant="link"
        onClick={loadPreference}
        className="btn-control-panel flex-grow-1"
      >
        { preference.name }
      </Button>
      <Button
        className="btn-preference"
        onClick={onDeleteClick}
        disabled={deleteLoading}
      >
        {deleteButtonIcon}
      </Button>
    </div>
  );
}
