import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CurrentPanel from './CurrentPanel';
import PreviewPanel from './PreviewPanel';

export default function Status() {
  return (
    <Row className="flex-fill p-5 h-100">
      <Col xl={6}>
        <CurrentPanel />
      </Col>
      <Col xl={6} className="mt-3 mt-xl-0">
        <PreviewPanel />
      </Col>
    </Row>
  );
}
