import { Comparison } from 'helper/products/meteorology';
import useComparatorControlPanel from 'utils/hooks/meteorology/comparator/useComparatorControlPanel';
import { PeriodOptions } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';

export default function useSetComparison() {
  const {
    setBaseModel,
    setBaseRuntime,
    setBaseMember,
    setBaseRMV,
    setBaseForecastDateDiff,
    setConfrontationalModel,
    setConfrontationalRuntime,
    setConfrontationalMember,
    setConfrontationalRMV,
    setConfrontationalForecastDateDiff,
    setComparativePeriod,
    setTitle,
    resetPreferences,
  } = useComparatorControlPanel();

  const setComparison = (comparison: Comparison, title: string) => {
    resetPreferences();
    setBaseModel(comparison.json.base.modelo);
    setBaseRuntime(comparison.json.base.runtime);
    setBaseMember(comparison.json.base.membro.toString());
    setBaseRMV(comparison.json.base.rmv === 1);
    setBaseForecastDateDiff(comparison.json.base.data_previsao);
    setConfrontationalModel(comparison.json.confrontante.modelo);
    setConfrontationalRuntime(comparison.json.confrontante.runtime);
    setConfrontationalMember(comparison.json.confrontante.membro.toString());
    setConfrontationalRMV(comparison.json.confrontante.rmv === 1);
    setComparativePeriod(comparison.json.periodo?.charAt(0) as PeriodOptions || 'd');
    setConfrontationalForecastDateDiff(comparison.json.confrontante.data_previsao);
    setTitle(title, comparison.name);
  };

  return setComparison;
}
