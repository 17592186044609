export default function useListEnd(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listData: any[] | undefined,
  page: number,
  pageSize: number,
  isListFetching: boolean,
): { listEnded: boolean } {
  return {
    listEnded: !isListFetching && listData !== undefined && listData.length < page * pageSize,
  };
}
