import React from 'react';

import {
  Col,
  Container,
  FormCheck,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap';

import { ListedCompanyContract } from 'helper/companies';
import { useGetCompanyQuery } from 'services/api/companies';
import { useGetContractQuery, useGetReadjustmentRatesQuery } from 'services/api/contracts';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import { ResponseError } from 'services/api/base';
import ProductLine from 'components/Admin/Products/Line';
import { CONSULTANCY, METEREOLOGY } from 'constants/contractType';
import { formattedContractValue } from 'helper/contracts';
import ContractAttachments from './Attachments';

export default function Details({ company }: { company: ListedCompanyContract }) {
  const { data: companyData, error, isLoading } = useGetCompanyQuery(
    { companyId: company.empresa_id },
  );
  const {
    data: contractData,
    error: contractError,
    isLoading: contractLoading,
  } = useGetContractQuery({ contractId: company.contrato_id });

  const {
    data: readjustmentRateData,
    isLoading: readjustmentDataIsLoading,
  } = useGetReadjustmentRatesQuery();
  const readjustmentIndex = contractData?.readjustmentIndex;
  const readjustmentRate = readjustmentRateData?.find(({ id }) => id === readjustmentIndex);
  const readjustmentRateLabel = readjustmentRate?.label || 'Não informado';

  useHandleRequestErrors(error as ResponseError);
  useHandleRequestErrors(contractError as ResponseError);

  if (isLoading || contractLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <Container>
      <Row>
        <Col md="6">
          <h5 className="mt-5">INFORMAÇÕES GERAIS</h5>
          <div className="admin-info-set">
            <p className="admin-info">
              <span className="info-header">Nome Fantasia:</span>
              { companyData?.nome_fantasia }
            </p>
            <p className="admin-info">
              <span className="info-header">Apelido:</span>
              { companyData?.apelido }
            </p>
            <p className="admin-info">
              <span className="info-header">CNPJ:</span>
              { companyData?.cnpj }
            </p>
            <p className="admin-info">
              <span className="info-header">E-mails:</span>
              { companyData?.emails_fiscais }
            </p>
          </div>
          <h5 className="mt-5">ENDEREÇO</h5>
          <Container className="admin-info-set">
            <Row>
              <Col lg="12" className="px-0">
                <p className="admin-info">
                  <span className="info-header">Logradouro:</span>
                  { companyData?.endereco }
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="4" className="px-0">
                <p className="admin-info">
                  <span className="info-header">Nº</span>
                  { companyData?.numero }
                </p>
              </Col>
              <Col lg="4" className="px-0">
                <p className="admin-info">
                  <span className="info-header">Complemento:</span>
                  { companyData?.complemento }
                </p>
              </Col>
              <Col lg="4" className="px-0">
                <p className="admin-info">
                  <span className="info-header">CEP:</span>
                  { companyData?.cep }
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="4" className="px-0">
                <p className="admin-info">
                  <span className="info-header">Bairro:</span>
                  { companyData?.bairro }
                </p>
              </Col>
              <Col lg="4" className="px-0">
                <p className="admin-info">
                  <span className="info-header">Cidade:</span>
                  { companyData?.municipio?.nome }
                </p>
              </Col>
              <Col lg="4" className="px-0">
                <p className="admin-info">
                  <span className="info-header">UF:</span>
                  { companyData?.estado?.uf }
                </p>
              </Col>
            </Row>
          </Container>
          <Row>
            <Col md="12">
              <h5 className="mt-5">USUÁRIOS</h5>
              <Container>
                { companyData?.users.map((user) => (
                  <Row key={user.id}>
                    <Col md="6" className={`user-description user-name ${user.active ? '' : 'unavailable-user'}`}>
                      {user.first_name}
                        &nbsp;
                      {user.last_name}
                    </Col>
                    <Col md="6" className={`user-description ${user.active ? '' : 'unavailable-user'}`}>
                      {user.username}
                    </Col>
                  </Row>
                )) }
              </Container>
            </Col>
          </Row>
        </Col>
        <Col md="6">
          <Container>
            <Row>
              <Col md="12">
                <h5 className="mt-5">PRODUTOS</h5>
                <Container>
                  { company.products.map((product) => (
                    <ProductLine key={product.name} product={product} />
                  )) }
                </Container>
              </Col>
            </Row>
            <h5 className="mt-5">CONTRATO</h5>
            <Container className="admin-info-set px-0">
              <p className="admin-info">
                <span className="info-header">Valor contrato:</span>
                { formattedContractValue(contractData) }
              </p>
              <p className="admin-info">
                <span className="info-header">Índice de reajuste:</span>
                {readjustmentDataIsLoading ? <Spinner size="sm" /> : readjustmentRateLabel}
              </p>
              <Row className="px-2 mt-3">
                <Col md="6">
                  <Stack direction="horizontal" gap={2}>
                    <FormCheck
                      checked={contractData?.type === METEREOLOGY}
                      disabled
                    />
                    Ampere Metereologia
                  </Stack>
                </Col>
                <Col md="6">
                  <Stack direction="horizontal" gap={2}>
                    <FormCheck
                      checked={contractData?.type === CONSULTANCY}
                      disabled
                    />
                    Ampere Consultoria
                  </Stack>
                </Col>
              </Row>
              <ContractAttachments contract={contractData} />
            </Container>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
