import React, { useEffect, useState } from 'react';
import {
  Row, Col, Accordion, Form,
} from 'react-bootstrap';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import { setCurrentTab } from 'redux/reducers/fluxPersonalizedLayoutReducer';
import EmptyListAlert from 'components/EmptyListAlert';
import { ResponseError } from 'services/api/base';
import { useListPersonalizedStudiesQuery } from 'services/api/personalized';
import { useHandleRequestErrors } from 'utils/hooks';
import PersonalizedRow from 'components/Flux/Personalized/Row';
import { checkDataCompletion, getDataLength } from 'helper/scrollStopper';
import useInfiniteScrollPaging from 'utils/useInfiniteScrollPaging';
import useListEnd from 'utils/hooks/useListEnd';
import './index.scss';

export default function Personalized() {
  const isAdmin: boolean = useSelector(selectUserIsAdmin);
  const [ampereCases, setAmpereCases] = useState<boolean>(isAdmin);
  const [maxLength, setMaxLength] = useState<number>(-1);
  const pageSize = 12;
  const { page } = useInfiniteScrollPaging();
  const pageLength = pageSize * page;
  const dataLength = getDataLength(maxLength, pageLength);

  const { data: personalizedStudies, isFetching, error } = useListPersonalizedStudiesQuery({
    ampereCases,
    page: 1,
    pageSize: dataLength,
  });

  const { listEnded } = useListEnd(personalizedStudies, page, pageSize, isFetching);

  useHandleRequestErrors(error as ResponseError | undefined);

  useEffect(() => {
    store.dispatch(setCurrentTab('list'));
  });

  useEffect(() => {
    if (checkDataCompletion(dataLength, isFetching, personalizedStudies)) {
      setMaxLength(dataLength);
    }
  }, [dataLength, personalizedStudies, isFetching]);

  return (
    <div className="list-container">
      {isAdmin && (
        <div className="d-flex justify-content-end">
          <Form.Check
            className="me-5 mb-1"
            type="checkbox"
            label="Casos Ampere"
            checked={ampereCases}
            onChange={() => { setAmpereCases(!ampereCases); }}
          />
        </div>
      )}
      <Row className="flux-personalized-list-header">
        <Col md={4} className="special-header">
          DESCRIÇÃO
        </Col>
        <Col md={4} className="special-header">
          HORIZONTE
        </Col>
        <Col md={4} className="special-header text-black">
          STATUS
        </Col>
      </Row>
      <Accordion>
        <EmptyListAlert
          message="Não foi encontrado nenhum estudo"
          array={personalizedStudies?.map((study) => ({
            study,
            key: study.id,
          })) ?? []}
          component={PersonalizedRow}
          isFetching={isFetching}
          listEnded={listEnded}
        />
      </Accordion>
    </div>
  );
}
