import baseApi from '../base';
import { HomeTutorialLinkResponse } from '../../../helper/products/home';

export const homeDataApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTutorialLinks: build.query<HomeTutorialLinkResponse, void>({
      query: () => 'atalhos-list/',
    }),
  }),
});

export const {
  useGetTutorialLinksQuery,
} = homeDataApi;
