const onCompanyEditClick = (
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModalTitle: React.Dispatch<React.SetStateAction<string>>,
  setSelectedCompanyId: React.Dispatch<React.SetStateAction<number>>,
  setSelectedContractId: React.Dispatch<React.SetStateAction<number>>,
) => (id: number, contract_id: number) => () => {
  setSelectedCompanyId(id);
  setSelectedContractId(contract_id);
  setModalTitle('Editar Empresa');
  setModalOpen(true);
};

export default onCompanyEditClick;
