import React from 'react';
import {
  Accordion, Col, Container, Form, Row,
} from 'react-bootstrap';

export default function Version(
  { onChange, versions }: { onChange: (string) => void, versions: string[] },
) {
  return (
    <Accordion.Item eventKey="2" className="control-panel-item">
      <Accordion.Header>Versão</Accordion.Header>
      <Accordion.Body>
        <Container>
          {versions.map((version) => (
            <Row key={version}>
              <Col key={version}>
                <Form.Check
                  name="version"
                  type="radio"
                  label={version}
                  onChange={onChange}
                  value={version}
                />
              </Col>
            </Row>
          ))}
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
