import React, { useEffect } from 'react';
import { NavigateFunction, Outlet, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { selectAuthToken, selectUserIsAdmin } from 'redux/reducers/authReducer';

export function checkIfAdmin(
  navigateFuncion: NavigateFunction,
  isAdmin: boolean,
  accessToken: string | null,
) {
  const alertObject = {
    state: {
      alerts: [{
        title: '',
        message: 'Você não tem autorização para acessar esta página.',
        variant: 'warning',
      }],
    },
  };

  if (!isAdmin) {
    if (accessToken) {
      navigateFuncion('/', alertObject);
    } else {
      navigateFuncion('/signin', alertObject);
    }
  }
}

export default function AdminLayout() {
  const navigate = useNavigate();
  const admin: boolean = useSelector(selectUserIsAdmin);
  const accessToken = useSelector(selectAuthToken);

  useEffect(() => {
    checkIfAdmin(navigate, admin, accessToken);
  });

  return <Outlet />;
}
