import React, { useState } from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import { useClosure } from 'utils/hooks';
import useObservedDataControlPanel, { PeriodModes } from 'utils/hooks/meteorology/useObservedDataControlPanel';
import './index.scss';

export default function ObservedData() {
  const {
    RegionSelectorOD,
    getSelector,
    anomaly,
    toggleAnomaly,
  } = useObservedDataControlPanel();
  const [mode, setMode] = useState<PeriodModes>('preset');
  const modeSelectorClassName = (modeName: PeriodModes) => (mode === modeName ? 'mode-selector-current' : 'mode-selector');

  return (
    <div>
      <Accordion>
        <Accordion.Item eventKey="1" className="control-panel-item">
          <Accordion.Header>Área de plotagem</Accordion.Header>
          <Accordion.Body className="d-grid">
            <RegionSelectorOD />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="control-panel-item">
          <Accordion.Header>Período de seleção</Accordion.Header>
          <Accordion.Body>
            <div className="mode-switcher">
              <div className={modeSelectorClassName('preset')}>
                <Button variant="link" className="button-link" onClick={useClosure(setMode, 'preset')}>
                  Pré-definido
                </Button>
              </div>
              <div className={modeSelectorClassName('date')}>
                <Button variant="link" className="button-link" onClick={useClosure(setMode, 'date')}>
                  Data
                </Button>
              </div>
              <div className={modeSelectorClassName('free')}>
                <Button variant="link" className="button-link" onClick={useClosure(setMode, 'free')}>
                  Livre
                </Button>
              </div>
            </div>
            {getSelector(mode)}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Form.Check
        className="mt-4"
        type="checkbox"
        label="Anomalia"
        checked={anomaly}
        onChange={toggleAnomaly}
      />
    </div>
  );
}
