import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import useAreaAbout from 'hooks/Meteorology/Weather/Area/About/useAreaAbout';
import usePolaritySelection from 'hooks/Meteorology/Weather/Area/About/usePolaritySelection';
import FormModal from 'components/FormModal';
import CentralizedSpinner from 'components/CentralizedSpinner';
import DescriptionPanel from './DescriptionPanel';
import DescriptionForm from './DescriptionForm';
import ImagePanel from './ImagePanel';

export default function About({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string,
  areaLabel: string,
}) {
  const { polarity, handlePolarityChange } = usePolaritySelection();
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState('');
  const { about, isFetching } = useAreaAbout({ polarity, area: areaAcronym });

  useEffect(() => {
    setDescription(about?.data || '');
  }, [about]);

  if (isFetching) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row className="flex-fill p-5 h-100">
        <Col md={4}>
          <DescriptionPanel description={description} setEditing={setEditing} />
        </Col>
        <Col md={8} className="mt-3 mt-md-0">
          <ImagePanel
            name={areaLabel}
            imageSrc={`data:image/png;base64,${about?.imageBase64}`}
            polarity={polarity}
            handlePolarityChange={handlePolarityChange}
          />
        </Col>
      </Row>
      <FormModal title="Editar descrição" show={editing} onHide={() => setEditing(false)}>
        <DescriptionForm
          areaId={about?.id}
          aboutDescription={description}
          setAboutDescription={setDescription}
        />
      </FormModal>
    </>
  );
}
