import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FlexUploadLayoutTabs = 'list' | 'new';

interface FluxUploadLayoutReducer {
  currentTab: FlexUploadLayoutTabs,
}

const initialState: FluxUploadLayoutReducer = {
  currentTab: 'list',
};

export const flexUploadLayoutSlice = createSlice({
  name: 'flexUploadLayout',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<FlexUploadLayoutTabs>) => {
      state.currentTab = action.payload;
    },
  },
});

export const selectFlexUploadLayoutCurrentTab = (state: any) => state.flexUploadLayout.currentTab;

export const {
  setCurrentTab,
} = flexUploadLayoutSlice.actions;

export default flexUploadLayoutSlice.reducer;
