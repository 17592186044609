import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectMFComparatorControlPanelBaseRMV,
  selectMFComparatorControlPanelBaseModel,
  selectMFComparatorControlPanelConfrontationalRMV,
  selectMFComparatorControlPanelConfrontationalModel,
  selectMFComparatorControlPanelBaseForecastDateDiff,
  selectMFComparatorControlPanelConfrontationalForecastDateDiff,
  selectMFComparatorControlPanelBaseMember,
  selectMFComparatorControlPanelBaseRuntime,
  selectMFComparatorControlPanelConfrontationalMember,
  selectMFComparatorControlPanelConfrontationalRuntime,
} from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import { ComparisonPeriodsParams } from 'services/api/Meteorology';

function hasAnUndefinedValue(params: ComparisonPeriodsParams) {
  return Object.values(params.base).some((value) => value === undefined)
    || Object.values(params.confronting).some((value) => value === undefined);
}

export default function useComparatorParams() {
  const baseModel = useSelector(selectMFComparatorControlPanelBaseModel);
  const confrontationalModel = useSelector(selectMFComparatorControlPanelConfrontationalModel);
  const baseRMV = useSelector(selectMFComparatorControlPanelBaseRMV);
  const confrontationalRMV = useSelector(selectMFComparatorControlPanelConfrontationalRMV);
  const baseDateDiff = useSelector(selectMFComparatorControlPanelBaseForecastDateDiff);
  const confrontingDateDiff = useSelector(
    selectMFComparatorControlPanelConfrontationalForecastDateDiff,
  );
  const baseRuntime = useSelector(selectMFComparatorControlPanelBaseRuntime);
  const confrontingRuntime = useSelector(selectMFComparatorControlPanelConfrontationalRuntime);
  const baseMember = useSelector(selectMFComparatorControlPanelBaseMember);
  const confrontingMember = useSelector(selectMFComparatorControlPanelConfrontationalMember);

  const params: ComparisonPeriodsParams = useMemo(() => ({
    base: {
      model: baseModel,
      forecastDate: baseDateDiff,
      rmv: Number(baseRMV),
      runtime: baseRuntime,
      member: baseMember,
    },
    confronting: {
      model: confrontationalModel,
      forecastDate: confrontingDateDiff,
      rmv: Number(confrontationalRMV),
      runtime: confrontingRuntime,
      member: confrontingMember,
    },
  }), [
    baseDateDiff,
    baseModel,
    baseRMV,
    confrontationalModel,
    confrontationalRMV,
    confrontingDateDiff,
    baseRuntime,
    baseMember,
    confrontingRuntime,
    confrontingMember,
  ]);

  return hasAnUndefinedValue(params) ? undefined : params;
}
